import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"

import ReduxToastr from "react-redux-toastr"
import { useSelector } from "react-redux"

import CustomFrame from "../../components/controllers/CustomFrame"
import Whitemark from "../tools/WhitemarkExt"
import FormFrame from "./FormFrame"

import { considerAsPwdField } from "../tools/extensionUtils"
import { callBackground } from "../tools/extensionUtils"

import specificListenerForWebsites from "../tools/specificListenerForWebsites.json"

import "react-redux-toastr/lib/css/react-redux-toastr.min.css"

const FormFrameMemo = React.memo(function FollowIds({
	inputsToComplete,
	styleLink,
}) {
	const extState = useSelector((state) => state.extension)
	const [idsToKeep, setIdsToKeep] = useState([])
	const [credToEdit, setCredToEdit] = useState()
	const [loginUrl, setLoginUrl] = useState()
	const [editMode, setEditMode] = useState(false)

	const idsToKeepRef = useRef()
	const prodEnv = useMemo(() => process.env.NODE_ENV === "production", [])

	let _submitEventTookPriority = false

	useEffect(() => {
		// console.log("index autoAdd", autoAdd);
		// if (!autoAdd) return

		watchForPurposeIds()
		window.addEventListener("keydown", handleKeyDown)
		//console.log("%cMOUNT INDEX FOLLOW IDS", "background-color: red;")

		return () => window.removeEventListener("keydown", handleKeyDown)
	}, [])

	useEffect(() => {
		idsToKeepRef.current = idsToKeep
	}, [idsToKeep])

	useEffect(() => {
		if (inputsToComplete.length) watchForSumbit()

		return () => removeWatcher()
	}, [inputsToComplete])

	function handleKeyDown(e) {
		if (e.key === "Escape" && idsToKeepRef.current.length) return clearIds()
		else return
	}

	function watchForPurposeIds() {
		/* eslint-disable no-undef */
		try {
			if (chrome.runtime) {
				chrome.runtime.onMessage.addListener((request) => {
					// Handle of background call for displaying add/edit proposal
					// execOnUrl allow to do that only on main frame -> filtered by url
					if (request.message === "LSpurposeIds") {
						if (
							request.execOnUrl &&
							document.location.href !== request.execOnUrl
						)
							return

						//console.log("%cPurpose ids :", "background-color: cyan; color: orange;", request)
						setLoginUrl(request.domain)
						setCredToEdit({
							...request.credToEdit,
							domain: request.domain,
							pp: request.pp ? request.pp : undefined,
						})
						setEditMode(request.purposeEdit)
						return setIdsToKeep(request.ids)
					} else return
				})
			}
		} catch (e) {}
	}

	function removeWatcher() {
		if (
			specificListenerForWebsites?.[
				window.location.hostname + window.location.pathname
			] &&
			specificListenerForWebsites?.[
				window.location.hostname + window.location.pathname
			]?.selector
		) {
			document
				.querySelector(
					specificListenerForWebsites?.[
						window.location.hostname + window.location.pathname
					]?.selector,
				)
				.removeEventListener(
					specificListenerForWebsites?.[
						window.location.hostname + window.location.pathname
					]?.event,
					handleSubmit,
				)
		}

		return document.removeEventListener("submit", handleSubmit)
	}

	function watchForSumbit() {
		const inputs = inputsToComplete

		// check if current site is registered as blocked for follow ids
		chrome?.storage?.sync.get([document.location.origin], (result) => {
			if (!result?.[document.location.origin]?.blockFollowIds) {
				if (!inputs?.length) return

				//console.log("INPUTS in watch", inputs)

				if (inputs?.length) {
					// Waiting for catched web request, from BG
					// After what we check each as we do when a submit event is fired on page
					/* eslint-disable no-undef */
					try {
						if (chrome.runtime) {
							chrome.runtime.onMessage.addListener((request) => {
								//console.log(request.message)
								if (request.message === "LScatchedRequest") {
									//console.log("%cCatched request :", "background-color: cyan; color: orange;", request)
									return handleCheckCatchedPayload(
										request.payloadObj,
									)
								} else return
							})
						}
					} catch (e) {}
				}

				if (
					specificListenerForWebsites?.[
						window.location.hostname + window.location.pathname
					] &&
					specificListenerForWebsites?.[
						window.location.hostname + window.location.pathname
					]?.selector
				) {
					document
						.querySelector(
							specificListenerForWebsites?.[
								window.location.hostname +
									window.location.pathname
							]?.selector,
						)
						.addEventListener(
							specificListenerForWebsites?.[
								window.location.hostname +
									window.location.pathname
							]?.event,
							handleSubmit,
							{ capture: true },
						)
				}

				return document.addEventListener("submit", handleSubmit, {
					capture: true,
				})
			} else {
				removeWatcher()
			}
		})
	}

	function findInObject(obj, f) {
		return Object.keys(obj).some((a) => {
			if (
				(Array.isArray(obj[a]) || typeof obj[a] === "object") &&
				obj[a] !== null
			)
				return findInObject(obj[a], f)
			if (a === "LScatchedUrl") return obj[a].includes(f)
			else return obj[a] === f
		})
	}

	function handleCheckCatchedPayload(payload) {
		if (_submitEventTookPriority) {
			//console.log("%c_submitEventTookPriority", "background-color: purple; color: white;")
			return // prevent double execution
		}

		//console.log("payload", payload)
		//console.log(inputsToComplete)

		let catchedValues = []
		let allInputedValues = []
		let valsFromPasswordFields = []
		let allFilled = true

		inputsToComplete.forEach((item) => {
			if (considerAsPwdField(item))
				valsFromPasswordFields.push(item.value)

			allInputedValues.push(item.value)
			if (!item.value) allFilled = false
			if (item.value && findInObject(payload, item.value))
				catchedValues.push(item.value)
		})

		//console.log("Similar value(s) found : ", catchedValues)
		//console.log("All filled : ", allFilled)

		if (catchedValues.length > 0) {
			// Workaround for some sites that hash the password in front
			// So we dose not found awaited equal values from catched request
			// SO we try to purpose in-field values
			if (
				catchedValues.length === 1 &&
				inputsToComplete.length === 2 &&
				allFilled
			) {
				catchedValues = allInputedValues
			}

			return callBackground({
				message: "LSdetectedIds",
				ids: catchedValues,
				valsFromPasswordFields,
				frameHost: document.location.hostname,
			})
		} else return

		/*if (catchedValues.length === 2) {
			// Classical case with credential combo
			return callBackground({ message: "LSdetectPairOfIds", ids: catchedValues })
			//return shouldPurposeSave(catchedValues)
		} else if (catchedValues.length === 1) {
			// Only one same value -> two step connection
			// We save first val for purpose it after second step
			return callBackground({ message: "LSdetectOneIds", ids: catchedValues })
			let possibleFirstValue = localStorage.getItem("LSfirstStepPossibleValue")
			if (possibleFirstValue) {
				// means we already passed first step
				return shouldPurposeSave([possibleFirstValue, ...catchedValues])
			} else {
				return localStorage.setItem("LSfirstStepPossibleValue", catchedValues[0])
			}
		} else return*/
	}

	function handleSubmit(e) {
		_submitEventTookPriority = true
		// Just for dev test time
		// e.preventDefault()
		// e.stopPropagation()

		let targetElmt = e.target
		let targets = inputsToComplete
		let useVals = []
		let valsFromPasswordFields = []

		//console.log("%cSUBMIT DETECTED FRONT SIDE", "background-color: red; color: white;")

		if (
			specificListenerForWebsites?.[
				window.location.hostname + window.location.pathname
			]
		) {
			targetElmt = e.target.closest(
				specificListenerForWebsites?.[
					window.location.hostname + window.location.pathname
				].formSelector,
			)
		}

		const formData = new FormData(targetElmt)

		targets.forEach((input) => {
			if (input.value && targetElmt.contains(input)) {
				if (considerAsPwdField(input))
					valsFromPasswordFields.push(input.value)

				useVals.push(input.value)
			} else {
				for (const [key, value] of formData) {
					if (input.name.startsWith(key) && !!value) {
						useVals.push(value)
					}
				}
			}
		})

		//console.log("Founded",useVals)

		if (!prodEnv) {
			// Set fakes values for dev env auto edit/add pop
			//setCredToEdit({ name: "_PassDevName_", id: 333, categoryName: "_CatDevName_" })
			//setEditMode(true)
			return setIdsToKeep(useVals)
		} else if (useVals.length > 0) {
			return callBackground({
				message: "LSdetectedIds",
				valsFromPasswordFields,
				ids: useVals,
			})
		} else {
			return
		}
	}

	function clearIds(callBg = true) {
		if (callBg) callBackground({ message: "LSclearIdsForTab" })
		removeWatcher()
		return setIdsToKeep([])
	}

	const className = "extPopStyle followIds"
	const id = "followIdsFrameTarget"

	const styleHeadMemo = useMemo(() => {
		return !styleLink ? null : (
			<head>
				<link rel="stylesheet" href={styleLink} />
				<style
					dangerouslySetInnerHTML={{
						__html: "body { overflow: hidden } followIdsFrame { padding: 0; width: 100% }",
					}}
				/>
			</head>
		)
	}, [styleLink])

	const formFrameMemo = useMemo(
		() => (
			<FormFrame
				idsToKeep={idsToKeep}
				clearIds={clearIds}
				editMode={editMode}
				credToEdit={credToEdit}
				loginUrl={loginUrl}
			/>
		),
		[idsToKeep, clearIds, editMode, credToEdit, loginUrl],
	)

	const customFrameStyle = useMemo(
		() => ({
			position: "fixed",
			top: 0,
			left: 0,
			zIndex: 34232342323,
			borderWidth: 0,
			borderRadius: 3,
			overflow: "hidden",
			height: "100%",
			/*visibility: idsToKeep.length ? null : "hidden"*/
		}),
		[idsToKeep.length, extState?.followIds],
	)

	const customStyleInjection = useMemo(
		() => `
		#${id} {
			max-width: ${
				idsToKeep.length
					? extState.followIds?.frameMaxxed
						? "530px"
						: "370px"
					: "0px !important"
			};
			padding: ${idsToKeep.length ? "" : "0px !important"};
			min-width: ${idsToKeep.length ? "unset" : "0px !important"};
			width: ${
				idsToKeep.length
					? extState.followIds?.frameMaxxed
						? "530px"
						: "370px"
					: "0px !important"
			};
		}
	`,
		[idsToKeep.length, extState?.followIds],
	)

	return !prodEnv ? (
		<div style={customFrameStyle} className={className} id={id}>
			<style>{customStyleInjection}</style>
			{formFrameMemo}
		</div>
	) : (
		<>
			<style>{customStyleInjection}</style>

			<CustomFrame
				title="LockSelf"
				style={customFrameStyle}
				className={className}
				id={id}
			>
				<Whitemark extMode>
					<ReduxToastr
						timeOut={4000}
						newestOnTop={false}
						preventDuplicates
						position="top-left"
						getState={(state) => state.toastr}
						transitionIn="fadeIn"
						transitionOut="fadeOut"
						progressBar
						closeOnToastrClick
					/>
					{styleHeadMemo}
					{formFrameMemo}
				</Whitemark>
			</CustomFrame>
		</>
	)
})

export default FormFrameMemo

FormFrameMemo.propTypes = {
	inputsToComplete: PropTypes.arrayOf(PropTypes.node),
	styleLink: PropTypes.string,
}
