import React, {
	useState,
	useEffect,
	useLayoutEffect,
	useRef,
	useMemo,
} from "react"
import PropTypes from "prop-types"
import * as _ from "lodash"

import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import Mapping from "./Mapping"
import CatTree from "./categoriesTree/CatTree"
import Slider from "../controllers/Slider"
import LoaderBars from "../animations/LoaderBars"

import { backwardCompApi } from "../../actions/generalUtils"

import { ReactComponent as ArrowTurnDown } from "../../assets/images/arrowTurnDown.svg"
import { ReactComponent as Search } from "../../assets/images/search.svg"
import { ReactComponent as Folder } from "../../assets/images/folder.svg"
import { ReactComponent as Recursive } from "../../assets/images/recursive.svg"
import { Button } from "../../stories/buttons/Button"
import ClassicInput from "../../stories/inputs/Input/ClassicInput"

const SelectCategory = (props) => {
	const mappingProps = props
	const { step, setStep, popupRef, active } = props
	const dispatch = useDispatch()
	const [t] = useTranslation()

	const lastActionResult = useSelector((state) => state.pass.lastActionResult)
	const favCats = useSelector((state) => state.pass.favCats)
	const actionType = useSelector((state) => state.action.type)

	const initialRender = useRef(true)
	const widthController = useRef(true)

	const [_newRep, _setNewRep] = useState()
	const [searchString, setSearchString] = useState("")
	const [debouncedSearchString, setDebouncedSearchString] = useState("")
	const [searchResults, setSearchResults] = useState()
	const [loader, setLoader] = useState(true)
	const [persoFetched, setPersoFetched] = useState(false)
	const [sharedFetched, setSharedFetched] = useState(false)
	const [searchLoader, setSearchLoader] = useState(false)
	const [displayTree, setDisplayTree] = useState(false)
	const [displayAll, setDisplayAll] = useState()
	const [favList, setFavList] = useState([])
	const [recentList, setRecentList] = useState([])
	const [persoCategories, setPersoCategories] = useState()
	const [sharedCategories, setSharedCategories] = useState()

	const globalPerso = useMemo(() => {
		return { persoCategories, setPersoCategories }
	}, [persoCategories, setPersoCategories])
	const globalShared = useMemo(() => {
		return { sharedCategories, setSharedCategories }
	}, [sharedCategories, setSharedCategories])
	const displayStateObject = { displayAll, setDisplayAll }

	const searchObject = {
		searchLoader,
		setSearchLoader,
		setSearchString,
		debouncedSearchString,
		setDebouncedSearchString,
		searchResults,
	}

	// ======== USEEFFECTS ========
	// On initial render, get the user categories, including its favorites and recently used.

	useEffect(() => {
		if (initialRender.current && !props.mapping && active === "lockPass") {
			if (!persoCategories || !sharedCategories) {
				dispatch({
					type: "LP_GET_ROOT_CATEGORIES_SAGA",
					payload: { runFromInput: true },
				})
				dispatch({
					type: "LP_GET_PERSO_CATEGORIES_SAGA",
					payload: { setInLar: true },
				})
			}

			// Only load favorite categories if the api version allows it.
			if (backwardCompApi("1.4.2")) {
				dispatch({ type: "LP_GET_FAV" })
			}

			const recents = JSON.parse(
				window.localStorage.getItem("LPrecentCats"),
			)
			if (recents) setRecentList(constructArrayForSlider(recents, 2))
		}
	}, [])

	// Listen for sagas execution. We need to execute code when a saga has fetched categories.
	// THE USELAYOUT EFFECT IS IMPORTANT ! Without it, the component may not catch the second
	// LAR change, when fetching personnal categories and shared root, displaying the loader
	// indefinitely.
	useLayoutEffect(() => {
		if (!initialRender.current && !props.mapping && active === "lockPass") {
			// When the GET_ROOT_CATEGORIES saga has ended, add the root categories to the sharedCategories
			// object (only happens twice : during first render and after initial sagas call)
			if (lastActionResult?.message === "rootCategoriesFetched") {
				const newState = { name: t("mLayout.shared") }
				newState.children = lastActionResult.copyData

				setSharedCategories(newState)
				setSharedFetched(true)
			}

			// When the GET_PERSO_CATEGORIES saga has ended, add the personnal categories tree to the
			// persoCategories object (only happens twice : during first render and after initial sagas call)
			if (lastActionResult?.message === "successPC") {
				const newState = { id: 0, name: t("mLayout.personnalSpace") }
				newState.children = lastActionResult.data

				setPersoCategories(newState)
				setPersoFetched(true)
			}

			// When searching for categories, add the results to the searchResults object.
			if (lastActionResult?.message === "successSearchCat") {
				setSearchResults(lastActionResult.data)
				setSearchLoader(false)
			}

			// Don't forget to clear the lastActionResult when we're done !
			dispatch({ type: "pass/setLAR" })
		}
	}, [lastActionResult])

	useEffect(() => {
		if (favCats.length > 0 && backwardCompApi("1.4.2")) {
			setFavList(constructArrayForSlider(favCats, 2))
		}
	}, [favCats])

	// Once every categories (personnals and shared) have been fetched, hide the loader
	useEffect(() => {
		if (persoFetched && sharedFetched) {
			setLoader(false)
		}
	}, [persoFetched, sharedFetched])

	// Whenever the step goes back to 1, remove the search string. We do that to avoid displaying
	// the categories components on the favorite categories part of the component.
	useEffect(() => {
		if (step === 1) {
			setDebouncedSearchString("")
			setSearchString("")
			setDisplayTree(false)
		}
	}, [step])

	useEffect(() => {
		initialRender.current = false
	}, [])

	// ======== FUNCTIONS AND HANDLERS ========
	// SEARCH MECANISM
	// For the search, we're going to use a debounced function.
	const search = (string) => {
		setDebouncedSearchString(string)
		setSearchLoader(true)
		dispatch({
			type: "LP_GET_CATEGORY_SEARCH_SAGA",
			payload: { search: string, runFromCatMap: true },
		})

		// If a string has been input in the search bar, display the category tree component.
		if (string !== "") {
			setStep(2)
			return setDisplayTree(true)
		}

		return null
	}

	const debouncedSearch = useMemo(
		() => _.debounce((string) => search(string), 500),
		[],
	)

	const handleSearchChange = (value) => {
		setSearchString(value)
		return debouncedSearch(value)
	}

	const handleSelectCat = (newCategory, currentCategory) => {
		if (newCategory.id === currentCategory?.id) {
			_setNewRep(undefined)
		} else {
			_setNewRep(newCategory)
		}
	}

	// Construct an array suitable for the slider. It takes an offset as an argument : it is the number of elements we want to display simultaneously in the slider.
	function constructArrayForSlider(array, offset) {
		return array.reduce(
			(prev, next) => {
				const current = [...prev]

				// Check if we are starting to populate a new slide.
				if (prev[prev.length - 1].length === offset) {
					current.push([next])
					return current
				} else {
					current[current.length - 1].push(next)
					return current
				}
			},
			[[]],
		)
	}

	// Hide all sub-categories and de-select the selected category
	const handleHideAll = (event) => {
		event.preventDefault()
		return setDisplayAll(false)
	}

	// The lookForCategory function iterate through the category tree to search for
	// a given category and return an array containing each parent's id.
	const lookForCategory = (id, categoryTree, idArray = []) => {
		const resultArray = [...idArray]

		// If we are in the searched category, simply return the id array.
		if (id === categoryTree?.id) {
			return [...resultArray, id]

			// If this is not the category we're looking for but it has children,
			// run the function once again for each children.
		} else if (categoryTree?.children) {
			let newCallResult

			for (let i = 0; i < categoryTree.children.length; i++) {
				const item = categoryTree.children[i]
				const newSearch = lookForCategory(id, item, resultArray)

				// If the children of the children returns something it means it's
				// part of the branch that ends with the right category. Add the
				// current children id to the result and return the array.
				// We must also exit the loop, any further category won't contain
				// the result.
				if (newSearch) {
					// If the current category is the shared root, it will have no ID, put "sharedRoot" instead
					if (categoryTree.id === undefined)
						categoryTree.id = "sharedRoot"
					newCallResult = [categoryTree.id, ...newSearch]
					i = categoryTree.children.length
				}
			}
			// After doing the whole loop, return the newCallResult.
			return newCallResult

			// If this is not the searched category and it doesn't have children, return
			// nothing, we are dropping this branch.
		} else {
			return
		}
	}

	// ======== RENDERS ========
	const renderFav = () => {
		return (
			<section
				className={
					actionType === "movePass"
						? "move-pass-section full"
						: "full"
				}
			>
				<div className="whiteBackground">
					<button
						className="changeStep"
						onClick={() => {
							setDisplayTree(true)
							return setStep(2)
						}}
					>
						<p>
							<Recursive />
							{t("pass.movePass.showAll")}
						</p>
						<Folder />
					</button>
				</div>

				{backwardCompApi("1.4.2") && (
					<Slider
						popupRef={popupRef}
						data={favList}
						isCurrentCat
						id="favSlider"
						title={t("pass.movePass.favCat")}
						clickHandler={handleSelectCat}
						currentSelection={_newRep}
					/>
				)}

				<Slider
					popupRef={popupRef}
					data={recentList}
					isCurrentCat
					id="recentSlider"
					title={t("pass.movePass.recentCat")}
					clickHandler={handleSelectCat}
					currentSelection={_newRep}
				/>
			</section>
		)
	}

	return (
		<>
			{props.mapping ? (
				<Mapping mappingProps={mappingProps} />
			) : (
				<div className={`SelectCategory${loader ? " loading" : ""}`}>
					{loader ? (
						<LoaderBars />
					) : (
						<div className="SelectCategory-container">
							<div
								className="contentWrapper"
								ref={widthController}
							>
								{/* ============== SEARCH BAR ============== */}
								<div className="searchContainer">
									<Search />
									<ClassicInput
										placeholder={t("pass.movePass.search")}
										type="text"
										className="is-borderless"
										value={searchString}
										change={handleSearchChange}
										clear={() => {
											setDebouncedSearchString("")
											return setSearchString("")
										}}
										hideLimit
										noTitle
										customClass={"is-borderless"}
									/>
								</div>

								{displayTree ? (
									<CatTree
										newRep={_newRep}
										setNewRep={_setNewRep}
										searchObject={searchObject}
										globalPerso={globalPerso}
										globalShared={globalShared}
										controller={widthController}
										displayStateObject={displayStateObject}
									/>
								) : (
									renderFav()
								)}
							</div>

							<div className="buttons">
								{displayTree &&
									debouncedSearchString.length === 0 && (
										<Button
											type="secondary"
											label={t("pass.movePass.foldAll")}
											onClick={handleHideAll}
										/>
									)}
								{_newRep && (
									<Button
										type="primary"
										label={`${t("moveTo")} ${
											_newRep.name === "mLayout.shared"
												? t("mLayout.shared")
												: _newRep.name
										}`}
										onClick={() =>
											props.validateSelection(
												_newRep,
												persoCategories,
												lookForCategory,
											)
										}
										Icon={
											<ArrowTurnDown className="icon" />
										}
									/>
								)}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	)
}

export default SelectCategory

SelectCategory.propTypes = {
	step: PropTypes.number,
	setStep: PropTypes.func,
	validateSelection: PropTypes.func,
	popupRef: PropTypes.any,
	mappingProps: PropTypes.shape({
		close: PropTypes.func,
		type: PropTypes.string,
		setNewRep: PropTypes.func,
		sharedStateObject: PropTypes.object,
		persoStateObject: PropTypes.object,
		userType: PropTypes.string,
		userProtected: PropTypes.number,
		newRep: PropTypes.object,
		label: PropTypes.string,
		actionBtnLabel: PropTypes.string,
		secondBtnLabel: PropTypes.string,
	}),
	mapping: PropTypes.bool,
	active: PropTypes.string,
}

// Props description
//
// step -> A number representing where, in the SelectCategory, the user is currently. For now, it is used to determine whether
//			the ThinPop must have its header on top or on the left
// setStep -> A function to set a state that will inform the parent component on which section the user is (for styling purpose)

// ! TODO : RF necessary
