import React, { useEffect, useRef, useState } from "react"

import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { toastr } from "react-redux-toastr"
import { useTranslation } from "react-i18next"

import InputIndicator from "../InputIndicator/InputIndicator"
import {
	copyToClip,
	handleError,
	regexEmoji,
} from "../../../actions/generalUtils"
import { InputPassword } from "../PasswordInput/InputPassword"
import { generatePass } from "../Utils"
import PhoneInput from "react-phone-input-2"
import Toggler from "../../toggler/Toggler"

import { MultiListTag } from "../multiListTag/MultiListTag"
import { PasswordStrength } from "../../passwordStrength/PasswordStrength"

import { ReactComponent as Eye } from "../../../assets/icons/Eye.svg"
import { ReactComponent as EyeClose } from "../../../assets/images/eyeClosed.svg"
import { ReactComponent as Refresh } from "../../../assets/images/refresh.svg"
import { ReactComponent as Clone } from "../../../assets/images/clone.svg"
import { ReactComponent as ApiUser } from "../../../assets/images/apiUser.svg"
import { ReactComponent as Group } from "../../../assets/images/group2.svg"
import { ReactComponent as User } from "../../../assets/images/user2.svg"
import { ReactComponent as Warning } from "../../../assets/images/warning.svg"
import { ReactComponent as QuestionIcon } from "../../../assets/images/question.svg"
import { ReactComponent as CheckIcon } from "../../../assets/images/check-v2.svg"
import { ReactComponent as EnterArrow } from "../../../assets/images/arrowEnter.svg"
import { ReactComponent as Close } from "../../../assets/images/close.svg"
import { ReactComponent as FileKey } from "../../../assets/images/file-key.svg"

import LoaderBars from "../../../components/animations/LoaderBars"
import TooltipHelper from "../../../stories/tooltip/TooltipHelper"

export default function ClassicInput(props) {
	const {
		type,
		disabled,
		preventDefault, // anciennement onkeydownKeyboard
		autoFocus,
		value,
		hasMin,
		setMin,
		placeholder,
		hideTitle = false,
		multi,
		changeList,
		keyDown,
		change,
		hideCharLimit,
		hideLimit, // A vérifier si vraiment utile,
		passAndFilesExport,
		noGenButton,
		noGen, // A vérifier s'il y a un cas ou il y a le noGenButton MAIS pourtant, il y a la generation ?
		generateIcon,
		CustomGenerateIcon,
		errorState,
		errorMsg,
		isErrorBottom,
		validLength,
		setValidLength,
		limit,
		hardLimit,
		purposeGroups,
		purposeContacts,
		purposeUsers,
		noTitle,
		list,
		mailList,
		removeFromList,
		passwordStrength,
		strengthInTitle,
		msg,
		copySuccessMsg,
		canCopyToClip,
		loadMsg,
		loadState,
		helperlLabel,
		helperValue,
		mandatory,
		mandatoryCustom,
		titleButtonCallback,
		titleButtonText,
		activeProduct,
		title,
		titlePosition,
		doNotShowNotFoundMsg,
		noHelper,
		purposeNums,
		telAutocompletePositionOver,
		forceShowTitle,
		setCheckEmailValidate,
		hideAction,
		genHasBorders,
		reducedSpecialCharList,
		customClass,
		addInside,
		mandatoryText,
		inAddFolder,
		phoneCountry,
		setPhoneCountry,
		validate,
		validateEvent,
		maxLength,
		noMandatoryIcon,
		titleElement,
		inputAndActionsAligned,
		location,
		focusedBorder,
		forceShowTextRight,
		textRight,
		inputAndActionsAlignedInside,
		switchWayOfUse,
		generateWithPP,
		dataPasswordActive,
		sshMode,
	} = props

	const dispatch = useDispatch()
	const textInputRef = useRef(null)
	const [t] = useTranslation()

	const auth = useSelector((state) => state.auth)
	const lar = useSelector((state) => state.action.lastActionResult)
	const larPass = useSelector((state) => state.pass.lastActionResult)
	const token = useSelector((state) => state.auth.token?.token)
	const xLsT = useSelector((state) => state.auth.xLsToken)
	const organizationId =
		auth.token?.userSession.organizationIds[auth.selectedOrg]
	const userType = useSelector(
		(state) => state.auth.token?.userSession.userType,
	)
	const currentPassCategory = useSelector((state) => state.pass.data)
	const currentFilesFolder = useSelector((state) => state.files.data)
	const pagePosition = useSelector((state) => state.action.type)
	const mailError = useSelector((state) => state.transfer.lastActionResult)
	const sshFileTemporary = useSelector(
		(state) => state.pass.sshFileTemporary.file,
	)

	const titleRef = useRef()
	// General
	const [focused, setFocused] = useState(false)
	const [hideAutoComplete, setHideAutoComplete] = useState(false)
	const [timeoutHandleBlur, setTimeoutHandleBlur] = useState()
	const [timeoutHandle, setTimeoutHandle] = useState()
	const [groupLoader, setGroupLoader] = useState(false)
	const [useApi, setUseApi] = useState(false)
	const [passVisibi, setPassV] = useState(false)
	const [userLoader, setUserLoader] = useState(false)
	const [purposes, setPurposes] = useState([])
	const [groups, setGroups] = useState([])

	const [useLoader, setUseLoader] = useState(false)
	const [val, setVal] = useState("")

	let passGenMajLocal = localStorage.getItem("passGenMaj")
	let passGenMinLocal = localStorage.getItem("passGenMin")
	let passGenNumLocal = localStorage.getItem("passGenNum")
	let passGenSpecLocal = localStorage.getItem("passGenSpec")

	// Password input
	const [useMaj, setUseMaj] = useState(
		passGenMajLocal !== "undefined" && !!passGenMajLocal
			? JSON.parse(passGenMajLocal)
			: true,
	)
	const [useMin, setUseMin] = useState(
		passGenMinLocal !== "undefined" && !!passGenMinLocal
			? JSON.parse(passGenMinLocal)
			: true,
	)
	const [useNum, setUseNum] = useState(
		passGenNumLocal !== "undefined" && !!passGenNumLocal
			? JSON.parse(passGenNumLocal)
			: true,
	)
	const [useSpec, setUseSpec] = useState(
		passGenSpecLocal !== "undefined" && !!passGenSpecLocal
			? JSON.parse(passGenSpecLocal)
			: true,
	)
	const [useLength, setUseLength] = useState(
		localStorage.getItem("passGenLength")
			? localStorage.getItem("passGenLength")
			: 12,
	)

	// Phone input
	const [tempoHideTelInput, setTempoHideTelInput] = useState(false)
	const [smsQuota, setSmsQuota] = useState()
	const [oldSearchVal, setOldSearchVal] = useState("")

	const maxLimit = limit
	let today = new Date()
	today.setDate(today.getDate() + 1)
	today = today.toISOString().split("T")[0]

	function multipleCallbackOne(d) {
		setPurposes(d._embedded?.items)
		responseLoader(1)
	}

	function multipleCallbackTwo(d) {
		setGroups(d._embedded?.items)
		responseLoader(2)
	}

	function responseLoader(nbr) {
		switch (nbr) {
			case 1:
				return setUserLoader(true)
			case 2:
				return setGroupLoader(true)
			default:
				return
		}
	}

	useEffect(() => {
		if (larPass?.message === "successPP" && generateWithPP !== undefined) {
			setUseLength(larPass?.data.maxNbChar)
			setUseMin(larPass?.data.hasLowercaseLetter)
			setUseMaj(larPass?.data.hasCapitalLetter)
			setUseNum(larPass?.data.hasNumber)
			setUseSpec(larPass?.data.hasSpecialChar)
		} else {
			setUseLength(12)
			setUseMin(true)
			setUseMaj(true)
			setUseNum(true)
			setUseSpec(true)
		}
	}, [larPass, generateWithPP])

	useEffect(() => {
		if (lar?.message === "quotaRetrieved" && lar.data) {
			setSmsQuota(lar.data)
		}
	}, [lar])

	useEffect(() => {
		if (groupLoader && userLoader) setUseLoader(false)
	}, [userLoader, groupLoader])

	useEffect(() => {
		if (textInputRef.current) {
			textInputRef.current.focus()
		}
	}, [sshMode])

	function keyPressed(e) {
		if (e.key === "Enter") {
			e.preventDefault()
			setUseApi(false)

			if (multi && value?.length > 0) {
				if (type === "tel") {
					handleChangeTel()
				} else {
					changeList(value)
				}

				setHideAutoComplete(true)

				setGroups([])
			} else if (keyDown) {
				keyDown(e)
			}
		}
	}

	function handleChange(e) {
		clearTimeout(timeoutHandleBlur)

		let tempo = e.target?.value?.length >= 0 ? e.target.value : e

		if (hardLimit && limit) {
			if (tempo.length - value.length === 1) {
				if (limit <= 0) return
			} else if (tempo.length - value.length > 1) {
				if (limit - (tempo.length - value.length) < 0) return
			}
		}

		if (regexEmoji(tempo)) return

		if (timeoutHandle) clearTimeout(timeoutHandle)

		setTimeoutHandle(
			setTimeout(
				() => handleSearch(tempo, setGroupLoader, setHideAutoComplete),
				500,
			),
		)

		setVal(tempo)
		change(tempo)
	}

	function callOne(value, usersCall = false) {
		let limit = 3
		let categoryId
		let idKey

		// Determine which product the user is currently on and get the current position's id.
		// It will be needed if we are searching for a category/folder user.
		if (currentPassCategory?.length === 1) {
			idKey = "categoryId"
			categoryId = currentPassCategory[0].id
		} else if (currentFilesFolder?.length === 1) {
			idKey = "folderId"
			categoryId = currentFilesFolder[0].id
		}

		if (value.length === 0) {
			setPurposes([])
			setOldSearchVal("")

			return setUseApi(false)
		} else if (value.length < limit) {
			return setOldSearchVal("")

			/* SUITE :
                On filtre manuellement si :
                - taille >! à la limite
                - résultats < 20
                - nouvelle val plus longue et inclus l'ancienne val
            */
		} else if (
			oldSearchVal &&
			value.length >= limit &&
			purposes.length < 50 &&
			oldSearchVal?.length <= value.length &&
			value.includes(oldSearchVal)
		) {
			//console.log("Manual filter users")
			return
		} else {
			//console.log("CALLING ", usersCall ? "Users" : "Contacts")
			setUserLoader(false)
			let url = usersCall
				? localStorage.getItem("apiUrl") +
				  "users/" +
				  organizationId +
				  "/organization?search=" +
				  value +
				  "&limit=50&" +
				  idKey +
				  "=" +
				  categoryId
				: localStorage.getItem("apiUrl") +
				  "contacts?search=" +
				  value +
				  "&limit=50"

			//console.log(url)

			callAPI(url, (d) => multipleCallbackOne(d))
			return setOldSearchVal(value)
		}
	}

	function handleSearch(v, setGroupLoader) {
		setHideAutoComplete(false)
		let limit = 3
		//setVal(v)
		//let v = val

		//console.log("SEARCH")

		if (purposeGroups === undefined) setGroupLoader(true)

		if (purposeContacts) callOne(v)

		if (purposeUsers) callOne(v, true)

		if (purposeGroups) {
			if (v.length === 0) {
				setGroups([])
			} else if (groups.length === 0 && v.length >= limit) {
				//console.log("CALLING GROUPS")
				setGroupLoader(false)
				let url
				if (userType === "isUser") {
					if (pagePosition === "editFolderUsers") {
						url =
							localStorage.getItem("apiUrl") +
							"groups?organizationId=" +
							organizationId +
							"&folderId=" +
							currentFilesFolder[0].categoryId +
							"&limit=100000"
					} else if (pagePosition === "editCategoryUsers") {
						url =
							localStorage.getItem("apiUrl") +
							"groups?organizationId=" +
							organizationId +
							"&categoryId=" +
							currentPassCategory[0].id +
							"&limit=100000"
					}
				} else {
					url =
						localStorage.getItem("apiUrl") +
						"groups?organizationId=" +
						organizationId +
						"&limit=100000"
				}
				callAPI(url, (d) => multipleCallbackTwo(d))
			} // else manual filter
		}
	}

	function callAPI(url, callback) {
		let responseStatus
		let error = ""

		setUseLoader(true)
		setUseApi(true)

		return fetch(url, {
			method: "GET",
			headers: {
				"Accept-Language": localStorage.getItem("i18nextLng"),
				Authorization: "Bearer " + token,
				"X-Ls-Token": xLsT,
				"Content-Type": "application/json",
			},
		})
			.then((res) => {
				responseStatus = res.ok
				return res
			})
			.then((res) => res.json())
			.then((res) => {
				if (!responseStatus) {
					setUseLoader(false)
					error = handleError(res, false)
					switch (error) {
						case "sessionExpired":
							return dispatch({ type: "auth/showPadlock" })
						case "sessionDestroyed":
							return dispatch({ type: "auth/sessionExpired" })
						default:
							return undefined
					}
				} else {
					setUseLoader(false)
					callback(res)
				}
			})
			.catch((e) => {
				setUseLoader(false)
				toastr.error(t("forServices.error"), t("forServices.netIssue"))
				throw e
			})
	}

	const isFocusInput = (index) => {
		return index === 0
	}

	function renderInput() {
		if (type === "tel") {
			if (multi) {
				if (tempoHideTelInput) return
				if (auth.canSendSms === false) return

				if (smsQuota && smsQuota.quotaReach) {
					return (
						<div className="noMoreSmsQuota">{t("noSmsQuota")}</div>
					)
				}
			}

			return (
				<PhoneInput
					inputProps={{
						autoFocus: autoFocus,
						lockself: "lockself",
					}}
					onKeyDown={(e) => keyPressed(e)}
					onFocus={() => {
						setFocused(true)
						setHideAutoComplete(false)
					}}
					onBlur={() => {
						setFocused(false)
						setTimeout(() => setHideAutoComplete(true), 2000)
					}}
					country={phoneCountry}
					value={value}
					onChange={(e, countryData) => {
						setPhoneCountry(countryData?.countryCode)
						setValidLength &&
							setValidLength(
								countryData.countryCode === "fr"
									? countryData.format.split(" ").join("")
											.length - 1
									: false,
							)
						handleChange(e)
					}}
				/>
			)
		} else if (type === "sshKey-file") {
			return (
				<div className="inputContainer container-file">
					{Object.keys(sshFileTemporary).length !== 0 ||
					dataPasswordActive.files ? (
						<>
							<FileKey />
							<TooltipHelper
								content={
									sshFileTemporary.name ||
									dataPasswordActive.files[0].name
								}
								hasEllipsis
							>
								<div className="content-text-file">
									{sshFileTemporary.name ||
										dataPasswordActive.files[0].name}
								</div>
							</TooltipHelper>
						</>
					) : null}
				</div>
			)
		} else {
			return (
				<input
					className={`${
						["password"].includes(type)
							? "passFont"
							: ["passphraseProtectedKey"].includes(type)
							? "passProtectedKey"
							: ""
					} ${type === "sshKey" ? "inputMoreHeight" : ""}`}
					data-lockself="lockself"
					ref={isFocusInput(props.targetInput) ? textInputRef : null}
					disabled={disabled}
					onPaste={() => clearTimeout(timeoutHandleBlur)}
					onKeyDown={
						preventDefault
							? (e) => e.preventDefault(e)
							: (e) => {
									keyPressed(e)
							  }
					}
					autoFocus={isFocusInput(props.targetInput)}
					onFocus={() => {
						setFocused(true)
						setHideAutoComplete(false)
					}}
					onBlur={() => {
						setFocused(false)
						setTimeoutHandleBlur(
							setTimeout(() => setHideAutoComplete(true), 2000),
						)
					}}
					value={value}
					{...(hasMin
						? { min: today }
						: setMin
						? { min: setMin }
						: null)}
					onChange={(e) => handleChange(e)}
					{...(!type
						? { type: "text" }
						: ["password", "passphraseProtectedKey"].includes(type)
						? passVisibi
							? { type: "text" }
							: { type: "password" }
						: { type: type })}
					placeholder={t(placeholder)}
					maxLength={maxLength}
				/>
			)
		}
	}

	function renderInputContainer() {
		return (
			<div
				className={`inputContainer ${
					type === "tel" ? "hasBackgroundColorOnlyOnField" : ""
				} ${
					location === "pass" && focused && !errorMsg && focusedBorder
						? "focusedBorder"
						: `${errorMsg && "focusedError"}`
				} ${type === "sshKey" ? "inputMoreHeight" : ""} ${
					type === "passphraseProtectedKey" ? "customInput" : ""
				}`}
			>
				{[
					"password",
					"text",
					"totp",
					"tags",
					"passphraseProtectedKey",
				].includes(type) &&
					location === "pass" && (
						<InputIndicator
							onContainer
							hideCharLimit={hideCharLimit}
							hideLimit={hideLimit}
							type={type}
							focused={focused}
							value={value}
							limit={finalLimit}
							maxLimit={maxLimit}
							location={location}
						/>
					)}
				{renderInput()}
				<div className="inputActions">
					{(["password", "passphraseProtectedKey"].includes(type) ||
						inputAndActionsAlignedInside) && (
						<>
							<span
								className={`${
									passAndFilesExport
										? "action export"
										: "action"
								} ${generateIcon ? "input-with-icon" : ""} ${
									inputAndActionsAlignedInside
										? "input-with-icon-position"
										: ""
								}`}
								onClick={() => setPassV(!passVisibi)}
							>
								{passVisibi ? <EyeClose /> : <Eye />}
							</span>
							{inputAndActionsAlignedInside &&
								renderInputActionsContainer()}
						</>
					)}
					{type === "sshKey-file" && renderInputActionsContainer()}
				</div>
				{(purposeContacts || purposeUsers || purposeGroups) &&
					!hideAutoComplete && (
						<div className="autoCompleteFrame">
							<div className="autoComplete">
								{useLoader ? (
									!doNotShowNotFoundMsg ? (
										<LoaderBars mini />
									) : null
								) : (
									renderGrpPps()
								)}
							</div>
						</div>
					)}
			</div>
		)
	}

	function renderInputActionsContainer() {
		return (
			<div
				className={`${
					props.action === "edit" && props.type === "urlIp"
						? ""
						: "inputActionsContainer"
				} ${
					(titleRef?.current?.innerHTML || forceShowTitle || title) &&
					!hideTitle
						? "hasTitle"
						: ""
				}`}
			>
				{(type === "totp" || type === "password") && canCopyToClip && (
					<div
						className={`${
							passAndFilesExport
								? "action export copy inputWrapActions"
								: "action inputWrapActions"
						}`}
						onClick={() => {
							if (value) {
								copyToClip(value, copySuccessMsg)
							}
						}}
					>
						<Clone className="clone-icon" />
					</div>
				)}
				{type === "sshKey-file" && (
					<div className="inputWrapActions iconPositionClose">
						<div
							className="backgroud-icon"
							onClick={() => {
								props.action === "edit"
									? (props.setRemoveSshKey(true),
									  props.setTextAreaValue(false))
									: props.setIsFileContentEmpty(true)

								dispatch({
									type: "pass/setSshFileTemporary",
									payload: {},
								})
							}}
						>
							<Close className="close" />
						</div>
					</div>
				)}
				{type === "password" && !noGenButton && (
					<div
						className={`genBtn inputWrapActions ${
							inputAndActionsAlignedInside ? "iconPosition" : ""
						} ${type !== "password" ? "addSpace" : ""}`}
						onMouseDown={() => generatePassword()}
					>
						<span className="action">
							{type === "password" &&
								!noGen &&
								(generateIcon ? (
									CustomGenerateIcon ? (
										CustomGenerateIcon
									) : (
										<Refresh className="refresh-icon" />
									)
								) : (
									t("pass.aPass.generate")
								))}
						</span>
					</div>
				)}
				{((multi && type !== "tel") ||
					(type === "tel" &&
						multi &&
						auth.canSendSms !== false &&
						!smsQuota?.quotaReach)) && (
					<div
						className={`inputWrapActions ${
							useLoader && purposeGroups ? "loading" : ""
						}`}
						onClick={() => {
							if (!useLoader) {
								acResultClicked(value)
							}
						}}
					>
						{(type === "url" || type === "tags") &&
						location === "pass" ? (
							<div
								className={`backgroud-icon ${
									focused ? "blue" : ""
								}`}
							>
								<EnterArrow />
							</div>
						) : type !== "urlIp" ? (
							t("add")
						) : null}
					</div>
				)}
			</div>
		)
	}

	let finalLimit
	if (typeof limit === "number" && limit > 0) {
		finalLimit = limit - val?.length
	}

	function generatePassword() {
		return change(generatePass(useLength, useMaj, useMin, useSpec, useNum))
	}

	function acResultClicked(val) {
		if (value.length === 0) return

		change(val)

		if (type === "tel") {
			handleChangeTel()
		} else if (typeof changeList === "function") {
			changeList(val)
		}

		checkToReset(true)
		setUseApi(false)
	}

	function agResultClicked(val) {
		props.addGroup(val)
		checkToReset(true)
		setUseApi(false)
	}

	function handleChangeTel() {
		if (value.length < 5) return

		if (validLength) {
			if (validLength !== value.length) {
				return toastr.warning(
					t("forServices.error"),
					t("transfer.aDeposit.phoneError"),
				)
			}
		}

		if (value[0] != "+") {
			changeList("+" + value)
		} else {
			changeList(value)
		}

		setTempoHideTelInput(true)
		setTimeout(() => setTempoHideTelInput(false), 50)
	}

	function checkToReset(needCheck = false) {
		if (value?.length === 0 || val?.length === 0 || needCheck) {
			if (purposes.length > 0) setPurposes([])
			if (groups.length > 0) setGroups([])
		}
	}

	function changeWrongMail(item, i) {
		if (typeof item === "string") {
			change(item)
		} else {
			change(item?.name)
		}
		removeFromList(i)

		textInputRef.current.focus()
	}

	function checkIfArrayIsUnique(myArray) {
		return myArray.length === new Set(myArray).size
	}

	// Voir pour mettre cette fonction dans utils
	function renderGrpPps() {
		let list = []
		let listgrp = []
		let names = []

		purposes.map((item, i) => {
			if (
				item?.phoneNumber?.toUpperCase().includes(val?.toUpperCase()) ||
				item.email?.toUpperCase().includes(val?.toUpperCase()) ||
				item.firstName?.toUpperCase().includes(val?.toUpperCase()) ||
				item.lastName.toUpperCase().includes(val?.toUpperCase())
			) {
				let icon
				let p = ""

				if (item.status === 6 || item.status === 5) {
					icon = <ApiUser />
				} else if (purposeGroups) icon = <User />

				if (item.firstName?.length > 0) {
					p += item.firstName
					if (item.lastName?.length > 0) {
						p += " " + item.lastName + " "
					}
				}

				if (purposeNums && item.phoneNumber?.length > 0) {
					//console.log(item)
					return list.push(
						<span
							className="autoComplete-child"
							key={i}
							onClick={() => acResultClicked(item.phoneNumber)}
						>
							{p.length > 0 ? p : item.email}
							<b className="m">{item.phoneNumber}</b>
						</span>,
					)
				} else if (!purposeNums) {
					return list.push(
						<span
							className="autoComplete-child"
							key={i}
							onClick={() => acResultClicked(item.email)}
						>
							{icon} {p}{" "}
							<b className={p ? "m" : ""}>{item.email}</b>
						</span>,
					)
				} else {
					return undefined
				}
			} else {
				return undefined
			}
		})

		groups.map((item, i) => {
			if (item.name?.toUpperCase().includes(val?.toUpperCase())) {
				//console.log(item.name);
				names.push(item.name)
				return listgrp.push(
					<span
						className="autoComplete-child"
						key={"g" + i}
						onClick={() => agResultClicked(item)}
					>
						<Group /> <b>{item.name}</b>
					</span>,
				)
			} else {
				return undefined
			}
		})

		if (!checkIfArrayIsUnique(names)) {
			list = list.slice(0, 3)
			list.push(
				<span className="autoComplete-child warning" key="warning">
					<Warning /> <b>{t("inputDupliGroups")}</b>
				</span>,
			)
		}

		if (list.length > 0 || listgrp.length > 0) {
			return [...listgrp.slice(0, 3), ...list.slice(0, 5)]
		} else if (useApi === true) {
			//setTimeout(() => setHideAutoComplete(true), 2000)
			if (!doNotShowNotFoundMsg) {
				if (purposeUsers) {
					return (
						<span className="autoComplete-child">
							<b>{t("noMatchingUser")}</b>
						</span>
					)
				} else {
					return (
						<span className="autoComplete-child">
							<b>{t("noMatch")}</b>
						</span>
					)
				}
			} else {
				return null
			}
		} else {
			return null
		}
	}

	useEffect(() => {
		if (mailError?.message === "wrongEmail" && setCheckEmailValidate) {
			if (focused && list.length > 0) {
				setCheckEmailValidate(true)
			}
		}
	}, [focused, mailError])

	const getPasswordProp = (generateWithPP, propName, propValue) => {
		if (
			generateWithPP !== undefined &&
			generateWithPP[propName] !== undefined
		) {
			return generateWithPP[propName]
		} else {
			return propValue
		}
	}

	return (
		<div
			className={`inputFrame ${focused || noTitle ? "focused" : ""} ${
				errorState ? "error" : ""
			} ${passwordStrength ? "password" : ""} ${
				forceShowTitle ? "forceShowTitle" : ""
			} ${customClass ? customClass : ""} ${
				location === "pass" && focused && focusedBorder
					? "focusedBorder"
					: ""
			}`}
		>
			<div
				className={`${
					!["urlIp", "port"].includes(type)
						? "inputWrap"
						: `inputWrap sameLine ${
								type === "port" ? "withMargin" : ""
						  }`
				} ${!isErrorBottom && errorMsg ? "errorTop" : ""} ${
					strengthInTitle ? "strengthInTitle" : ""
				} ${
					telAutocompletePositionOver
						? "telAutocompletePositionOver"
						: ""
				} ${addInside ? "addInside" : ""}`}
			>
				<div className="inputWrapContainer">
					<div className="inputTitle inputIndicators" ref={titleRef}>
						{((activeProduct !== "locktransfer" &&
							(title?.length > 0 || titleElement)) ||
							(activeProduct === "locktransfer" &&
								title?.length > 0)) && (
							<h4
								className={`inputHeading ${
									titlePosition ? "spacing" : ""
								}`}
							>
								{titleElement ? titleElement : t(title)}{" "}
								{mandatory && noHelper
									? noMandatoryIcon
										? ""
										: "*"
									: ""}
								{mandatory && !noHelper && (
									<TooltipHelper
										hasIcon
										rightPos
										content={mandatoryText}
									>
										<div
											className={`${
												mandatoryCustom && "customColor"
											}`}
										>
											*
										</div>
									</TooltipHelper>
								)}
								{helperValue && !noHelper && (
									<div className="inputMultiHelper">
										{helperlLabel && (
											<span>{helperlLabel}</span>
										)}
										<TooltipHelper
											rightPos
											content={helperValue}
											hasIcon
											Icon={QuestionIcon}
											location={location}
										/>
									</div>
								)}
							</h4>
						)}
						<div
							className={`${
								forceShowTextRight ? "forceShowtextRight" : ""
							}`}
							onClick={() => props.onClick()}
						>
							{forceShowTextRight &&
								switchWayOfUse &&
								textRight?.[0]}
						</div>

						{!isErrorBottom && errorState && (
							<InputIndicator
								hideCharLimit
								hideLimit={hideLimit}
								type={type}
								focused={focused}
								value={value}
								errorState={errorState}
								errorMsg={errorMsg}
								limit={finalLimit}
							/>
						)}
						{strengthInTitle && type === "password" && (
							<PasswordStrength
								strength={passwordStrength}
								type="pass"
							/>
						)}
						{titleButtonCallback && (
							<button
								className="inputTitleButton"
								onClick={titleButtonCallback}
							>
								{titleButtonText}
							</button>
						)}
					</div>

					{inputAndActionsAligned ? (
						<div className="inputAndActionContainer">
							{renderInputContainer()}
						</div>
					) : (
						renderInputContainer()
					)}

					{!["urlIp", "port", "sshKey"].includes(type) && (
						<InputIndicator
							hideCharLimit
							hideLimit
							type={type}
							focused={focused}
							value={value}
							errorState={isErrorBottom ? errorState : false}
							errorMsg={isErrorBottom ? errorMsg : undefined}
							limit={finalLimit}
						/>
					)}
					{(!strengthInTitle ||
						(!hideCharLimit && !hideLimit && type !== "date")) && (
						<div
							className={`inputIndicators ${
								isErrorBottom ? "errorBottom" : ""
							}`}
						>
							{!strengthInTitle &&
								type === "password" &&
								passwordStrength &&
								passwordStrength !== null && (
									<PasswordStrength
										strength={passwordStrength}
										type="pass"
									/>
								)}
						</div>
					)}
					{props.type === "sshKey-file" && (
						<div className="sshKey-switch">
							<Toggler
								value={props.passphraseProtectedKey}
								text={t(
									"pass.ssh.aPassSsh.passphraseToggleText",
								)}
								customText
								onSelect={() =>
									props.setPassphraseProtectedKey(
										!props.passphraseProtectedKey,
									)
								}
							/>
						</div>
					)}
				</div>
				{validate && (
					<div className="validateContainer">
						<CheckIcon className="icon" onClick={validateEvent} />
					</div>
				)}
				{!hideAction &&
					!inputAndActionsAligned &&
					!inputAndActionsAlignedInside &&
					renderInputActionsContainer()}
			</div>
			{loadMsg && loadState && (
				<span className="loadMsg">
					<LoaderBars mini /> <span>{t(loadMsg)}</span>
				</span>
			)}
			{msg && <span className="msg">{t(msg)}</span>}
			{type === "password" && !noGen && (
				<InputPassword
					change={change}
					type="password"
					setUseLength={setUseLength}
					useLength={useLength}
					useMaj={getPasswordProp(
						generateWithPP,
						"hasCapitalLetter",
						useMaj,
					)}
					setUseMaj={setUseMaj}
					useSpec={getPasswordProp(
						generateWithPP,
						"hasSpecialChar",
						useSpec,
					)}
					setUseSpec={setUseSpec}
					useMin={getPasswordProp(
						generateWithPP,
						"hasLowercaseLetter",
						useMin,
					)}
					setUseMin={setUseMin}
					useNum={getPasswordProp(
						generateWithPP,
						"hasNumber",
						useNum,
					)}
					setUseNum={setUseNum}
					hasBorders={genHasBorders}
					reducedSpecialCharList={reducedSpecialCharList}
					inAddFolder={inAddFolder}
					generateWithPP={generateWithPP}
				/>
			)}
			{multi && list?.length > 0 && (
				<div className="multiList">
					{list?.map((item, i) => (
						<MultiListTag
							index={i}
							key={i}
							item={item}
							changeWrongMail={changeWrongMail}
							removeFromList={removeFromList}
							mailList={mailList}
						/>
					))}
				</div>
			)}
		</div>
	)
}

ClassicInput.propTypes = {
	activeProduct: PropTypes.string,
	type: PropTypes.string,
	multi: PropTypes.bool,
	value: PropTypes.string,
	generateWithPP: PropTypes.object,
	setCheckEmailValidate: PropTypes.func,
	changeList: PropTypes.func,
	keyDown: PropTypes.func,
	change: PropTypes.func,
	addGroup: PropTypes.func,
	purposeGroups: PropTypes.bool,
	purposeContacts: PropTypes.bool,
	purposeUsers: PropTypes.bool,
	purposeNums: PropTypes.bool,
	doNotShowNotFoundMsg: PropTypes.bool,
	limit: PropTypes.number,
	hardLimit: PropTypes.bool,
	disabled: PropTypes.bool,
	preventDefault: PropTypes.bool,
	autoFocus: PropTypes.bool,
	hasMin: PropTypes.bool,
	setMin: PropTypes.func,
	placeholder: PropTypes.string,
	needMinLen: PropTypes.any,
	needMaxLen: PropTypes.any,
	removeFromList: PropTypes.func,
	passwordStrength: PropTypes.any,
	errorState: PropTypes.bool,
	noTitle: PropTypes.bool,
	revert: PropTypes.bool,
	titlePosition: PropTypes.bool,
	title: PropTypes.string,
	helper: PropTypes.any,
	helperFixed: PropTypes.bool,
	mandatory: PropTypes.bool,
	mandatoryCustom: PropTypes.bool,
	noHelper: PropTypes.bool,
	mandatoryText: PropTypes.string,
	strengthInTitle: PropTypes.bool,
	titleButtonCallback: PropTypes.any,
	titleButtonText: PropTypes.string,
	multiRecip: PropTypes.bool,
	msg: PropTypes.string,
	inputWrapClassName: PropTypes.string,
	telAutocompletePositionOver: PropTypes.bool,
	passAndFilesExport: PropTypes.bool,
	generateIcon: PropTypes.bool,
	canCopyToClip: PropTypes.bool,
	loadMsg: PropTypes.any,
	loadState: PropTypes.bool,
	noGenButton: PropTypes.any,
	noGen: PropTypes.bool,
	CustomGenerateIcon: PropTypes.any,
	mailErrorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	hideCharLimit: PropTypes.bool,
	hideLimit: PropTypes.bool,
	noTitleInGenMenu: PropTypes.bool,
	reducedSpecialCharList: PropTypes.bool,
	list: PropTypes.any,
	mailList: PropTypes.bool,
	forceShowTitle: PropTypes.bool,
	customClass: PropTypes.string,
	errorMsg: PropTypes.string,
	copySuccessMsg: PropTypes.string,
	setValidLength: PropTypes.func,
	validLength: PropTypes.number,
	isErrorBottom: PropTypes.bool,
	helperlLabel: PropTypes.string,
	helperValue: PropTypes.string,
	hideAction: PropTypes.bool,
	genHasBorders: PropTypes.bool,
	addInside: PropTypes.bool,
	inAddFolder: PropTypes.bool,
	validate: PropTypes.bool,
	validateEvent: PropTypes.func,
	phoneCountry: PropTypes.string,
	setPhoneCountry: PropTypes.func,
	maxLength: PropTypes.number,
	noMandatoryIcon: PropTypes.bool,
	titleElement: PropTypes.node,
	inputAndActionsAligned: PropTypes.bool,
	location: PropTypes.string,
	textInTitle: PropTypes.bool,
	focusedBorder: PropTypes.bool,
	forceShowTextRight: PropTypes.bool,
	textRight: PropTypes.array,
	inputAndActionsAlignedInside: PropTypes.bool,
	onClick: PropTypes.func,
	switchWayOfUse: PropTypes.bool,
	passphraseProtectedKey: PropTypes.bool,
	setPassphraseProtectedKey: PropTypes.func,
	setIsFileContentEmpty: PropTypes.func,
	dataPasswordActive: PropTypes.object,
	setRemoveSshKey: PropTypes.func,
	setTextAreaValue: PropTypes.bool,
	action: PropTypes.string,
	targetInput: PropTypes.number,
	sshMode: PropTypes.bool,
	hideTitle: PropTypes.bool,
}
