/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import TooltipHelper from "../../stories/tooltip/TooltipHelper"
import Toggler from "../../stories/toggler/Toggler"
import { Button } from "../../stories/buttons/Button"
import { CheckBox } from "../../stories/inputs/checkbox/Checkbox"

import { ReactComponent as Place } from "../../assets/images/place.svg"
import { ReactComponent as Folder } from "../../assets/images/folder.svg"
import { ReactComponent as Close } from "../../assets/images/close.svg"
import { backwardCompApi, checkUserRight } from "../../actions/generalUtils"

export default function Mapping(propsMap) {
	const props = propsMap.mappingProps
	const onStateChange = propsMap.onStateChange

	const [t] = useTranslation()
	const dispatch = useDispatch()
	const initialRender = useRef(true)
	const active = props?.active

	const larPass = useSelector((state) => state?.pass?.lastActionResult)
	const larFiles = useSelector((state) => state?.files?.lastActionResult)
	const activePass = useSelector((state) => state?.pass?.active)
	const activeFiles = useSelector((state) => state?.files?.active)
	const dataLockpassPage = useSelector((state) => state.pass?.data?.[0])

	const isFile = useSelector((state) => state.files.isFile)
	const user = props?.userSessionFromExtension
		? props?.userSessionFromExtension
		: useSelector((state) => state?.auth?.token?.userSession)

	const myEmail = props?.userSessionFromExtension
		? user?.userEmail
		: useSelector((state) => state.auth.token.userSession.userEmail)
	const userEmail = props?.userSessionFromExtension
		? user?.userEmail
		: useSelector((state) => state.auth.token.userSession.userEmail)

	const [hideUnauthorizedCats, setHideUnauthorizedCats] = useState(true)
	const [allCatsAuthorized, setAllCatsAuthorized] = useState(true)
	const [showPerso, setShowPerso] = useState(false)

	const [persoTree, setPersoTree] = useState()
	const [tree, setTree] = useState()
	const [_newRep, _setNewRep] = useState()
	const [newRepId, setNewRepId] = useState()
	const [updateNewState, setUpdateNewState] = useState({})
	const [allActiveParentIds, setAllActiveParentIds] = useState()
	const [userProtected, setUserProtected] = useState()

	const [userRights, setUserRights] = useState()
	const [rights, setRights] = useState()
	const [userRightsInSharedSpace, setUserRightsInSharedSpace] = useState()

	const userType = props?.userSessionFromExtension
		? user?.userType
		: useSelector((state) => state.auth.token.userSession.userType)
	const haveShared = props?.userSessionFromExtension
		? user?.userFormulas.permanentTransferContentAdmin
		: useSelector(
				(state) =>
					state.auth.token.userSession.userFormulas
						.permanentTransferContentAdmin,
		  )

	let aFolder = props.data

	// THIS MUST BE CHECKED.
	// To prevent unnecessary execution, the allActiveParentIds has been put in the following useEffect, along with the use of initialRender.current.
	// The state.pass.active state isn't supposed to update, since when this component renders, the user is in a popup and can't select passwords anymore.
	// Even though I don't think this optimization will change the code's behavior, please take some time to think and test it;
	useEffect(() => {
		if (initialRender.current) {
			// Start by getting every selected passwords parent category's id.
			let newArray = []
			Object.keys(activePass ?? activeFiles).forEach((key) =>
				newArray.push(
					activePass[key].categoryId ?? activeFiles[key].categoryId,
				),
			)
			setAllActiveParentIds(newArray)
		}
	}, [initialRender, activePass, activeFiles])

	// Check the localStorage to determine whether the unauthorized categories must be hidden.
	useEffect(() => {
		let lsParam = localStorage.getItem("LShideUnauthorizedCats")
		if (lsParam && JSON.parse(lsParam) === false)
			setHideUnauthorizedCats(false)
	}, [])

	useEffect(() => {
		if (typeof props.newRep?.id !== undefined) _setNewRep(props.newRep)
	}, [props.newRep])

	useEffect(() => {
		if (_newRep !== undefined) {
			setNewRepId(
				_newRep.val &&
					JSON.parse(_newRep.val) &&
					JSON.parse(_newRep.val).id,
			)
		}
	}, [_newRep])

	// Get the categories during the first render of the component, only if we haven't already fetched data.
	useEffect(() => {
		if (
			!props.persoStateObject?.persoData &&
			!props.sharedStateObject?.sharedData &&
			initialRender.current &&
			active === "lockPass"
		) {
			dispatch({
				type: "LP_GET_PERSO_CATEGORIES_SAGA",
				payload: { setInLar: true },
			})
			dispatch({
				type: "LP_GET_CATEGORIES_SAGA",
				payload: { setInLar: true },
			})
		}
	}, [dispatch, props, initialRender, active])

	// When we have finished fetching data, update the component's state.
	useEffect(() => {
		if (
			dataLockpassPage?.homepageLPView ||
			dataLockpassPage?.name === "mLayout.shared" ||
			dataLockpassPage?.name === "mLayout.personnalSpace"
		) {
			if (
				props.userType === "isAdmin" ||
				props.userType === "isModerator"
			) {
				setUserProtected(2)
			} else setUserProtected(0)
		} else setUserProtected(props.userProtected)
	}, [props])

	useEffect(() => {
		if (active === "lockFiles") {
			dispatch({ type: "LF_GET_TREE_SAGA", payload: { setInLar: true } })
			dispatch({
				type: "LF_GET_PERSO_TREE_SAGA",
				payload: { setInLar: true },
			})
		}
	}, [dispatch, active])

	useEffect(() => {
		if (
			props.actionBtnLabel === "pass.catsMap.confirmCat" ||
			active !== "lockFiles"
		) {
			return
		}

		if (
			larFiles?.message === "successTree" &&
			(propsMap.type === "addFolder" ||
				propsMap.type === "uploadChangeDestination" ||
				props.type === "file")
		) {
			setTree(larFiles.data)
			setRights(larFiles.rights)
		} else if (
			(propsMap.type !== "addFolder" ||
				propsMap.type !== "uploadChangeDestination") &&
			larFiles?.message === "successTree" &&
			(["isAdmin", "isModerator"].includes(userType) || userRights)
		) {
			setUserRightsInSharedSpace(larFiles.rights)
			setTree(larFiles.data)
		}

		if (larFiles?.message === "successPersoTree") {
			setPersoTree(larFiles.data)
			_setNewRep({
				name: t("mLayout.personnalSpace"),
				val: JSON.stringify({ id: -1, mainId: 0 }),
			})
			setUpdateNewState({
				name: t("mLayout.personnalSpace"),
				val: JSON.stringify({ id: -1, mainId: 0 }),
			})
		}
	}, [t, larFiles, active])

	useEffect(() => {
		if (active !== "lockPass") {
			return
		}

		if (larPass?.message === "success") {
			props.sharedStateObject?.setSharedData(larPass.data)
		}

		if (larPass?.message === "successPC") {
			props.persoStateObject?.setPersoData(larPass.data)
			setShowPerso(true)
		}
	}, [larPass, active])

	// Track the initial render
	useEffect(() => {
		initialRender.current = false
	}, [initialRender])

	useEffect(() => {
		if (active !== "lockFiles") {
			return
		}

		checkUserRight(aFolder, userEmail, setUserRights)
	}, [aFolder, active])

	useEffect(() => {
		if (
			propsMap.type === "addFolder" ||
			propsMap.type === "uploadChangeDestination"
		) {
			if (persoTree && tree) {
				let pList = [
					{
						name: t("mLayout.personnalSpace"),
						val: JSON.stringify({ id: 0, mainId: 0 }),
					},
					...flatter(persoTree),
				]
				let sList = [...flatter(tree)]
				let f = [...pList, ...sList]

				f.map((item) => {
					if (
						item?.id === props.data.id &&
						item.isInCategory !== false &&
						updateNewState?.val &&
						haveRights(item?.id) &&
						JSON.parse(updateNewState.val).id !== item.id
					)
						return setUpdateNewState({
							name: item.name,
							val: JSON.stringify({
								id: item.id,
								mainId: item.mainId,
								isInCategory: item.isInCategory,
							}),
						})
					else return undefined
				})
			}
		}
	}, [persoTree, tree])

	useEffect(() => {
		if (
			propsMap.type === "addFolder" ||
			propsMap.type === "uploadChangeDestination"
		)
			onStateChange(updateNewState)
	}, [updateNewState])

	const updateStateInMapping = () => {
		onStateChange(updateNewState)
	}

	function flatter(data) {
		let a = []
		data.map((item) => {
			let b = []
			if (item.children) {
				if (item.isInCategory !== false) {
					b.push(item)
				}
				b.push(...flatter(item.children))
			} else if (item.isInCategory !== false) b.push(item)

			return (a = [...a, ...b])
		})
		return a
	}

	function haveRights(id) {
		let r

		Object.keys(rights).map((item) => {
			let u = parseInt(item)

			if (u === id) {
				if (rights[u] !== 0) return (r = true)
				else return (r = false)
			}
			return
		})
		return r
	}

	function isExcludedCatID(target) {
		if (allActiveParentIds.includes(target)) return true
		else return false
	}

	function save() {
		props.setNewRep(_newRep)
		if (props.saveCallback) props.saveCallback(_newRep)
		if (props.close && !props.dontCloseOnSave) props.close()
		return
	}

	function changeHideParamState() {
		if (hideUnauthorizedCats)
			localStorage.setItem("LShideUnauthorizedCats", false)
		else localStorage.removeItem("LShideUnauthorizedCats")
		return setHideUnauthorizedCats((v) => !v)
	}

	function combinedMapper(data, parentDisabled = false, isPersoTree = false) {
		let result = []

		if (!Array.isArray(data)) return

		let byAlpha = [...data]
		byAlpha?.sort((a, b) => (a.name > b.name ? 1 : -1))
		let anyCatUnauthorized = false

		byAlpha?.map((item) => {
			let mapResult = []

			if (props.type === "category") {
				if (item.name === "Root Perso") {
					return
				}

				// Prevent the user from selecting a category which contains at least one of the selected passwords (you can't move something toward where it already is).
				// really usefull in search results when we can select 2 passwords from different categories.
				let disabled = false
				if (allActiveParentIds.includes(item.id)) disabled = true

				// Hide or disable unauthorized categories for basic users
				let hideCat = false
				if (props?.sharedStateObject?.sharedData?.ids && !isPersoTree) {
					if (
						!props?.sharedStateObject?.sharedData?.ids.includes(
							item.id,
						)
					) {
						if (hideUnauthorizedCats) hideCat = true
						else disabled = true
						anyCatUnauthorized = true
					} else if (
						userProtected === 0.5 &&
						props.newRep.id !== item.id &&
						!props?.persoStateObject?.persoData?.find(
							(data) => data.id === item.id,
						)
					) {
						disabled = true
					}
				}

				if (item.children) {
					result.push(
						<div
							className="rep"
							key={item.id}
							id={_newRep.id === item.id ? "selected" : ""}
						>
							{!hideCat && (
								<CheckBox
									isInExtension={
										!!props?.userSessionFromExtension
									}
									type="radio"
									disabled={disabled}
									isChecked={_newRep.id === item.id}
									onChange={() => _setNewRep(item)}
									Icon={<Folder />}
									smallLabel={item.name}
									isExcludedCatID={isExcludedCatID(item.id)}
								/>
							)}

							<div className="subReps">
								{combinedMapper(
									item.children,
									parentDisabled,
									isPersoTree,
								)}
							</div>
						</div>,
					)
				} else if (!hideCat) {
					result.push(
						<div
							className="rep"
							key={item.id}
							id={_newRep.id === item.id ? "selected" : ""}
						>
							<CheckBox
								isInExtension={
									!!props?.userSessionFromExtension
								}
								type="radio"
								disabled={disabled}
								isChecked={_newRep.id === item.id}
								onChange={() => _setNewRep(item)}
								Icon={<Folder />}
								smallLabel={item.name}
								isExcludedCatID={isExcludedCatID(item.id)}
							/>
						</div>,
					)
				}
				return mapResult.push(result)
			} else if (propsMap.type === "addFolder") {
				if (item.name === "Root Perso") {
					return
				}

				if (item.children) {
					result.push(
						<div
							className="rep"
							key={item.id}
							id={
								JSON.parse(updateNewState?.val)?.id === item.id
									? "selected"
									: ""
							}
						>
							<CheckBox
								isInExtension={
									!!props?.userSessionFromExtension
								}
								type="radio"
								disabled={
									!isPersoTree ? !haveRights(item.id) : false
								}
								isChecked={
									JSON.parse(updateNewState?.val)?.id ===
									item.id
								}
								onChange={() => {
									setUpdateNewState({
										name: item.name,
										val: JSON.stringify({
											id: item.id,
											mainId: item.mainId,
											isInCategory: item.isInCategory,
										}),
									})
									updateStateInMapping()
								}}
								Icon={<Folder />}
								smallLabel={item.name}
							/>
							<div className="subReps">
								{combinedMapper(
									item.children,
									parentDisabled,
									isPersoTree,
								)}
							</div>
						</div>,
					)
				} else {
					result.push(
						<div
							className="rep"
							key={item.id}
							id={
								JSON.parse(updateNewState?.val)?.id === item.id
									? "selected"
									: ""
							}
						>
							<CheckBox
								isInExtension={
									!!props?.userSessionFromExtension
								}
								type="radio"
								disabled={
									!isPersoTree ? !haveRights(item.id) : false
								}
								isChecked={
									JSON.parse(updateNewState?.val)?.id ===
									item.id
								}
								onChange={() => {
									setUpdateNewState({
										name: item.name,
										val: JSON.stringify({
											id: item.id,
											mainId: item.mainId,
											isInCategory: item.isInCategory,
										}),
									})
									updateStateInMapping()
								}}
								Icon={<Folder />}
								smallLabel={item.name}
							/>
						</div>,
					)
				}
				return result.push(mapResult)
			} else if (propsMap.type === "uploadChangeDestination") {
				if (item.name === "Root Perso") {
					return
				}

				if (
					item.name === "Root Perso" &&
					item.id === 0 &&
					item.parentId === -1
				) {
				} else if (item.children) {
					result.push(
						<div
							className="rep"
							key={item.id}
							id={
								JSON.parse(updateNewState?.val)?.id === item.id
									? "selected"
									: ""
							}
						>
							<CheckBox
								isInExtension={
									!!props?.userSessionFromExtension
								}
								type="radio"
								disabled={
									!isPersoTree
										? rights?.[item?.id] < 0 ||
										  (!rights?.[item?.id] &&
												rights?.[item?.id] !== 0)
										: false
								}
								isChecked={
									JSON.parse(updateNewState?.val)?.id ===
									item.id
								}
								onChange={() => {
									setUpdateNewState({
										name: item.name,
										val: JSON.stringify({
											id: item.id,
											mainId: item.mainId,
											isInCategory: item.isInCategory,
										}),
									})
									updateStateInMapping()
								}}
								Icon={<Folder />}
								smallLabel={item.name}
							/>
							<div className="subReps">
								{combinedMapper(
									item.children,
									parentDisabled,
									isPersoTree,
								)}
							</div>
						</div>,
					)
				} else {
					result.push(
						<div
							className="rep"
							key={item.id}
							id={
								JSON.parse(updateNewState?.val)?.id === item.id
									? "selected"
									: ""
							}
						>
							<CheckBox
								isInExtension={
									!!props?.userSessionFromExtension
								}
								type="radio"
								disabled={
									!isPersoTree
										? rights?.[item?.id] < 0 ||
										  (!rights?.[item?.id] &&
												rights?.[item?.id] !== 0)
										: false
								}
								isChecked={
									JSON.parse(updateNewState?.val)?.id ===
									item.id
								}
								onChange={() => {
									setUpdateNewState({
										name: item.name,
										val: JSON.stringify({
											id: item.id,
											mainId: item.mainId,
											isInCategory: item.isInCategory,
										}),
									})
									updateStateInMapping()
								}}
								Icon={<Folder />}
								smallLabel={item.name}
							/>
						</div>,
					)
				}
				return result.push(mapResult)
			} else {
				if (item.name === "Root Perso") {
					return
				}

				let disabled = false

				if (!isPersoTree) {
					if (props.type === "folder") {
						if (item.mainId === 0) {
							if (
								parentDisabled ||
								aFolder.id === item.id ||
								aFolder.parentId === item.id
							) {
								disabled = true
							} else if (
								item.parentId === aFolder.parentId &&
								item.id !== aFolder.id
							) {
								disabled = false
							}

							if (
								item.parentId === aFolder.parentId &&
								item.id !== aFolder.id
							) {
								disabled = false
							}
						} else if (userRightsInSharedSpace?.[item?.id]) {
							if (
								parentDisabled ||
								aFolder.id === item.id ||
								aFolder.parentId === item.id
							) {
								disabled = true
							} else if (
								item.parentId === aFolder.parentId &&
								item.id !== aFolder.id
							) {
								disabled = false
							}

							if (
								item.parentId === aFolder.parentId &&
								item.id !== aFolder.id
							) {
								disabled = false
							}

							if (
								parentDisabled &&
								item.parentId !== aFolder?.id &&
								item.id !== aFolder?.id &&
								userRightsInSharedSpace?.[item?.id]
							) {
								disabled = false
							}
						} else {
							disabled = true
						}
					} else {
						disabled =
							rights?.[item?.id] < 0 ||
							(!rights?.[item?.id] && rights?.[item?.id] !== 0)
					}
				} else if (props.type === "folder") {
					disabled = item?.id === aFolder?.id
				}

				if (
					item.name === "Root Perso" &&
					item.id === 0 &&
					item.parentId === -1
				) {
					return
				} else if (item.children) {
					result.push(
						<div
							className="rep"
							key={item.id}
							id={
								_newRep?.val
									? JSON.parse(_newRep?.val)?.id === item.id
										? "selected"
										: ""
									: ""
							}
						>
							<CheckBox
								isInExtension={
									!!props?.userSessionFromExtension
								}
								type="radio"
								disabled={disabled}
								isChecked={newRepId === item.id}
								onChange={() =>
									_setNewRep({
										name: item.name,
										val: JSON.stringify({
											id: item.id,
											mainId: item.mainId,
											isInCategory: item.isInCategory,
										}),
									})
								}
								Icon={<Folder />}
								smallLabel={item.name}
							/>
							<div className="subReps">
								{combinedMapper(
									item.children,
									disabled,
									isPersoTree,
								)}
							</div>
						</div>,
					)
				} else {
					result.push(
						<div
							className="rep"
							key={item.id}
							id={
								_newRep?.val
									? JSON.parse(_newRep?.val)?.id === item.id
										? "selected"
										: ""
									: ""
							}
						>
							<CheckBox
								isInExtension={
									!!props?.userSessionFromExtension
								}
								type="radio"
								disabled={disabled}
								isChecked={newRepId === item.id}
								onChange={() =>
									_setNewRep({
										name: item.name,
										val: JSON.stringify({
											id: item.id,
											mainId: item.mainId,
											isInCategory: item.isInCategory,
										}),
									})
								}
								Icon={<Folder />}
								smallLabel={item.name}
							/>
						</div>,
					)
				}
				return result.push(mapResult)
			}
		})

		if (
			props.sharedStateObject?.sharedData?.ids &&
			anyCatUnauthorized &&
			allCatsAuthorized &&
			!isPersoTree
		) {
			setAllCatsAuthorized(false)
		}

		return result
	}

	function rootSharedChecked() {
		setUpdateNewState({
			name: t("mLayout.shared"),
			val: JSON.stringify({ id: 0, mainId: -1 }),
		})

		if (isFile) {
			dispatch({
				type: "action/openMiniAction",
				payload: {
					type: "mini_isFileRootShared",
					mini: true,
					data: setUpdateNewState,
				},
			})
		}
	}

	function generateCombinedTree() {
		const persoTreeData = props.persoStateObject?.persoData
		const sharedTreeData = props.sharedStateObject?.sharedData?.tree

		/* -------------- REMOVE UNWANTED (NOT ACCESSIBLE) FOLDERS/CATS ------------- */
		let sharedTreeUpdatedData = []

		function hasNoAccessibleChild(branch, ids = [], folderRights = {}) {
			let hasNoAccess = true

			function checkChildren(child) {
				child?.children?.forEach((element) => {
					if (element?.children?.length > 0) {
						checkChildren(element)
					}

					if (
						ids?.includes(element?.id) ||
						folderRights?.[element?.id] >= 0
					) {
						hasNoAccess = false
					}
				})
			}

			if (branch?.children?.length > 0) {
				checkChildren(branch)
			} else if (
				ids?.includes(branch?.id) ||
				folderRights?.[branch?.id] >= 0
			) {
				hasNoAccess = false
			}

			return hasNoAccess
		}

		function getAllAccessibleChildren(branches) {
			return branches
				?.map((branch) => {
					if (
						!hasNoAccessibleChild(
							branch,
							props.sharedStateObject?.sharedData?.ids,
							props?.type === "folder"
								? userRightsInSharedSpace
								: rights,
						)
					) {
						const clonedBranch = { ...branch }

						if (clonedBranch?.children?.length > 0) {
							clonedBranch.children = getAllAccessibleChildren(
								clonedBranch?.children,
							)
						}

						return clonedBranch
					} else {
						return null
					}
				})
				?.filter((branch) => !!branch)
		}

		function getAllAccessibleBranches(tree) {
			tree?.forEach((branch) => {
				if (
					!hasNoAccessibleChild(
						branch,
						props.sharedStateObject?.sharedData?.ids,
						props?.type === "folder"
							? userRightsInSharedSpace
							: rights,
					)
				) {
					const clonedBranch = { ...branch }

					if (clonedBranch?.children?.length > 0) {
						clonedBranch.children = getAllAccessibleChildren(
							clonedBranch?.children,
						)
					}

					sharedTreeUpdatedData?.push(clonedBranch)
				}
			})
		}

		getAllAccessibleBranches(active === "lockPass" ? sharedTreeData : tree)
		/* ------------ END REMOVE UNWANTED (NOT ACCESSIBLE) FOLDERS/CATS ----------- */

		const _persoTree = combinedMapper(persoTreeData, false, true)
		const _sharedTree = combinedMapper(sharedTreeUpdatedData)

		let domTree = []
		let persoDomTree = []

		persoDomTree = combinedMapper(persoTree ?? [], false, true)
		domTree = combinedMapper(sharedTreeUpdatedData ?? [])

		setTimeout(() => {
			if (document.getElementById("selected"))
				document
					.getElementById("selected")
					.scrollIntoView({ behavior: "smooth", block: "nearest" })
		}, 1000)

		let userRight = false

		props?.data?.users?.map((user) => {
			if (user.email !== myEmail) return

			if (
				(backwardCompApi("1.19.4") ? user.protected : user.right) ===
					1 ||
				(backwardCompApi("1.19.4") ? user.protected : user.right) === 2
			) {
				userRight = true
			}
		})

		return (
			<React.Fragment>
				{props.type === "category" ? (
					<>
						{(showPerso ||
							props.persoStateObject?.persoData.length > 0) && (
							<div className="baseRep">
								<CheckBox
									isInExtension={
										!!props?.userSessionFromExtension
									}
									disabled={isExcludedCatID(0)}
									type="radio"
									isChecked={
										_newRep.id === 0 && !_newRep.parentId
									}
									onChange={() =>
										_setNewRep({
											name: t("mLayout.personnalSpace"),
											id: 0,
										})
									}
									Icon={<Place />}
									label={t("mLayout.personnalSpace")}
								/>

								{props.persoStateObject?.persoData?.length ? (
									<div className="reps">{_persoTree}</div>
								) : null}
							</div>
						)}
						{_sharedTree && (
							<div className="baseRep">
								{props.changeFolder && userProtected >= 1 ? (
									<CheckBox
										isInExtension={
											!!props?.userSessionFromExtension
										}
										type="radio"
										isChecked={
											_newRep.id === 0 &&
											_newRep.parentId === -1
										}
										onChange={() =>
											_setNewRep({
												name: t("mLayout.shared"),
												id: 0,
												mainId: -1,
												parentId: -1,
											})
										}
										Icon={<Place />}
										label={t("mLayout.shared")}
									/>
								) : (
									<CheckBox
										isInExtension={
											!!props?.userSessionFromExtension
										}
										type="radio"
										style="hidden"
										Icon={<Place />}
										label={t("mLayout.shared")}
									/>
								)}
								<div className="reps">{_sharedTree}</div>
							</div>
						)}
					</>
				) : propsMap.type === "addFolder" &&
				  updateNewState &&
				  tree &&
				  persoTree ? (
					<>
						<div className="baseRep">
							<CheckBox
								isInExtension={
									!!props?.userSessionFromExtension
								}
								type="radio"
								isChecked={
									JSON.parse(updateNewState?.val).id === -1
								}
								onChange={() => {
									setUpdateNewState({
										name: t("mLayout.personnalSpace"),
										val: JSON.stringify({
											id: -1,
											mainId: 0,
										}),
									})
									updateStateInMapping()
								}}
								Icon={<Place />}
								label={t("mLayout.personnalSpace")}
							/>
							<div className="reps">{persoDomTree}</div>
						</div>

						<div className="baseRep">
							<CheckBox
								isInExtension={
									!!props?.userSessionFromExtension
								}
								type="radio"
								isChecked={
									JSON.parse(updateNewState?.val).id === 0
								}
								onChange={() => {
									setUpdateNewState({
										name: t("mLayout.shared"),
										val: JSON.stringify({
											id: 0,
											mainId: -1,
										}),
									})
									updateStateInMapping()
								}}
								Icon={<Place />}
								label={t("mLayout.shared")}
								style={haveShared ? "visible" : "hidden"}
							/>
							<div className="reps">{domTree}</div>
						</div>
					</>
				) : propsMap.type === "uploadChangeDestination" &&
				  updateNewState &&
				  tree &&
				  persoTree ? (
					<>
						<div className="baseRep">
							<CheckBox
								isInExtension={
									!!props?.userSessionFromExtension
								}
								type="radio"
								isChecked={
									JSON.parse(updateNewState?.val).id === -1
								}
								onChange={() => {
									setUpdateNewState({
										name: t("mLayout.personnalSpace"),
										val: JSON.stringify({
											id: -1,
											mainId: 0,
										}),
									})
									updateStateInMapping()
								}}
								Icon={<Place />}
								label={t("mLayout.personnalSpace")}
							/>
							<div className="reps">{persoDomTree}</div>
						</div>
						{(tree.length > 0 ||
							["isAdmin", "isModerator"].includes(userType)) && (
							<div className="baseRep">
								<CheckBox
									isInExtension={
										!!props?.userSessionFromExtension
									}
									type="radio"
									isChecked={
										JSON.parse(updateNewState?.val).id === 0
									}
									onChange={() => {
										rootSharedChecked(),
											updateStateInMapping()
									}}
									Icon={<Place />}
									label={t("mLayout.shared")}
									style={haveShared ? "visible" : "hidden"}
								/>
								<div className="reps">{domTree}</div>
							</div>
						)}
					</>
				) : (
					<>
						{persoTree?.length > 0 && (
							<div className="baseRep">
								{props.type ? (
									<CheckBox
										isInExtension={
											!!props?.userSessionFromExtension
										}
										type="radio"
										disabled={
											aFolder.id === -1 ||
											(props.type === "folder" &&
												props.data.parentId === -1)
										}
										isChecked={newRepId === -1}
										onChange={() =>
											_setNewRep({
												name: t(
													"mLayout.personnalSpace",
												),
												val: JSON.stringify({
													id: -1,
													mainId: 0,
												}),
											})
										}
										Icon={<Place />}
										label={t("mLayout.personnalSpace")}
									/>
								) : (
									<CheckBox
										isInExtension={
											!!props?.userSessionFromExtension
										}
										type="radio"
										disabled
										style="hidden"
										Icon={<Place />}
										label={t("mLayout.personnalSpace")}
									/>
								)}
								<div className="reps">{persoDomTree}</div>
							</div>
						)}

						{(((persoTree?.length <= 0 || props.type) &&
							["isAdmin", "isModerator"].includes(userType)) ||
							userRights) && (
							<div className="baseRep">
								{props.type === "folder" && userRight ? (
									<CheckBox
										isInExtension={
											!!props?.userSessionFromExtension
										}
										type="radio"
										disabled={
											aFolder.id === 0 ||
											aFolder.mainId === aFolder.id ||
											![
												"isAdmin",
												"isModerator",
											].includes(userType)
										}
										isChecked={newRepId === 0}
										onChange={() =>
											_setNewRep({
												name: t("mLayout.shared"),
												val: JSON.stringify({
													id: 0,
													mainId: -1,
												}),
											})
										}
										Icon={<Place />}
										label={t("mLayout.shared")}
									/>
								) : (
									<CheckBox
										isInExtension={
											!!props?.userSessionFromExtension
										}
										type="radio"
										disabled
										style="hidden"
										Icon={<Place />}
										label={t("mLayout.shared")}
									/>
								)}
								<div className="reps">{domTree}</div>
							</div>
						)}
					</>
				)}
			</React.Fragment>
		)
	}

	return (
		<>
			<div
				className={
					props.type === "category"
						? "withPadding CatMap"
						: "noPaddingButOverflow"
				}
			>
				{_newRep && (
					<>
						{props.type === "category" && (
							<h5 className="simpleWord">
								{props.labelHelper && (
									<TooltipHelper
										leftPos
										content={t(props.labelHelper)}
										isInExtension={
											!!props?.userSessionFromExtension
										}
									/>
								)}
								{t(props.label)}
							</h5>
						)}

						{props.type !== "category" &&
							propsMap.type !== "addFolder" &&
							propsMap.type !== "uploadChangeDestination" && (
								<h5 className="simpleWord">
									{t("files.move.moveTo")}
								</h5>
							)}

						{_newRep && (
							<div
								className={`${
									props.type === "file" ||
									props.type === "folder" ||
									props.type === "category"
										? "repsFrame"
										: ""
								}`}
							>
								{props.type === "category"
									? props.persoStateObject?.persoData &&
									  props.sharedStateObject?.sharedData &&
									  generateCombinedTree()
									: propsMap.type === "addFolder"
									? _newRep &&
									  tree &&
									  persoTree &&
									  generateCombinedTree()
									: _newRep &&
									  (tree || persoTree) &&
									  generateCombinedTree()}
							</div>
						)}

						{!allCatsAuthorized && (
							<div className="line">
								<Toggler
									value={hideUnauthorizedCats}
									onSelect={changeHideParamState}
									text={t("pass.aPass.hideUnauthorizedCats")}
								/>
							</div>
						)}
					</>
				)}
			</div>

			<div className="buttons">
				{props.type === "category" && (
					<>
						<Button
							onClick={() => save()}
							type="primary"
							label={t(props.actionBtnLabel)}
							disabled={typeof _newRep?.id === "undefined"}
						/>

						{props.secondBtnLabel && (
							<Button
								onClick={() => props.close()}
								type="secondary"
								label={t(props.secondBtnLabel)}
							/>
						)}
					</>
				)}

				{props.type !== "category" &&
					propsMap.type !== "addFolder" &&
					typeof props.type !== "undefined" && (
						<>
							<Button
								onClick={() => save()}
								type="primary"
								label={t("move")}
								disabled={
									props.type === "file"
										? aFolder.id === -1 && newRepId === -1
										: aFolder.id !== -1 &&
										  newRepId === -1 &&
										  props.data.parentId !== 0
								}
							/>
							<Button
								type="secondary"
								label={t("cancel")}
								Icon={<Close className="icon" />}
								isIconRight
								iconRelative
								onClick={() => {
									dispatch({ type: "action/closeAction" })
									dispatch({ type: "files/setLAR" })
								}}
							/>
						</>
					)}
			</div>
		</>
	)
}

Mapping.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		parentId: PropTypes.number,
		mainId: PropTypes.number,
		users: PropTypes.array,
		type: PropTypes.string,
	}),
	newRep: PropTypes.oneOfType([
		PropTypes.shape({
			id: PropTypes.number,
		}),
		PropTypes.bool,
	]),
	close: PropTypes.func,
	type: PropTypes.string,
	setNewRep: PropTypes.func,
	saveCallback: PropTypes.func,
	dontCloseOnSave: PropTypes.func,
	sharedStateObject: PropTypes.object,
	persoStateObject: PropTypes.object,
	userType: PropTypes.string,
	userProtected: PropTypes.number,
	changeFolder: PropTypes.bool,
	labelHelper: PropTypes.string,
	label: PropTypes.string,
	actionBtnLabel: PropTypes.string,
	secondBtnLabel: PropTypes.string,
	active: PropTypes.string,
	userSessionFromExtension: PropTypes.object,
}

// ! TODO : RF necessary
