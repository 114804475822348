import { createSlice } from "@reduxjs/toolkit"

const base = [
	{
		name: "params.mainT",
		static: true,
		noSelect: true,
		forceBoardHelp: true,
		repName: "mLayout.categories",
		fileName: "mLayout.files",
	},
]

const paramsSlice = createSlice({
	name: "params",
	initialState: {
		active: [],
		loading: false,
		lastActionResult: null,
		isModerator: null,
	},
	reducers: {
		init: (state) => {
			state.data = base
			state.history = []
			state.active = {}
			state.searchMode = false
			state.searchVal = ""
		},
		toggleLoading: (state, action) => {
			if (action.payload) state.loading = action.payload
			else state.loading = !state.loading
		},
		setLAR: (state, action) => {
			if (!action.payload) state.lastActionResult = null
			else state.lastActionResult = action.payload
		},
		launchPass: (state, action) => {
			if (action.payload.launchPass) state.launchPass = true
			else state.launchPass = null
		},
	},
})

export const { init } = paramsSlice.actions

export default paramsSlice.reducer
