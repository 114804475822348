import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"

import EditForm from "./EditForm"
import CreatePass from "../pages/CreatePass.jsx"
import DOMToast from "../components/DomToast.jsx"
import { NEWPWD, DEFAULTNEWPWD } from "../tools/VariablesName"

import {
	callBackground,
	getNameFromDomain2,
	capitalize,
} from "../tools/extensionUtils"

import { ReactComponent as ArrowT } from "../../assets/images/chevronRight.svg"
import { ReactComponent as Close } from "../../assets/images/close.svg"
import { ReactComponent as Logo } from "../../assets/logo2.svg"

const FormFrameMemo = React.memo(function FormFrame({
	idsToKeep,
	clearIds,
	editMode,
	credToEdit,
	loginUrl,
}) {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const extState = useSelector((state) => state.extension)

	//const [ids,setIds] = useState([])
	const [loading, setLoading] = useState(false)
	const [successSave, setSuccessSave] = useState(false)
	const [successEdit, setSuccessEdit] = useState(false)

	const [errorState, setErrorState] = useState(false)
	const [errorMsg, setErrorMsg] = useState("")

	const [domain, setDomain] = useState("") // it may not be used anymore, we use the loginUrl props instead
	const [name, setName] = useState("")
	const [newPwd, setNewPwd] = useState(DEFAULTNEWPWD)
	const [pwdProps, setPwdProps] = useState("")
	// const [setIdsTimeout,idsTimeout] = useState()
	const [_idsToKeep, _setIdsToKeep] = useState(
		idsToKeep ? idsToKeep : undefined,
	)

	const openedMap = extState.followIds.frameMaxxed
	//const animShowRef = useRef(false)

	const pageOrigin =
		window.location !== window.parent.location
			? document.referrer.replace(/\/$/, "")
			: document.location.origin

	// FOR TEST
	/*useEffect(() => {
		setTimeout(() => {
			setLoading(true)
		}, 2000)
	}, [])

	useEffect(() => {
		if (loading) {
			setTimeout(() => {
				setLoading(false)
				setSuccessSave(true)
			}, 2000)
		}
	}, [loading])*/

	useEffect(() => {
		if (successSave) {
			setTimeout(() => {
				setSuccessSave(false)
				clearIds()
			}, 2000)
		}
	}, [successSave, clearIds])

	useEffect(() => {
		//console.log("%cMOUNT FORM FRAME", "background-color: cyan")
		setDefaultVals()
		//setSuccessEdit(true)

		// return () => clearTimeout(idsTimeout)
	}, [])

	useEffect(() => {
		setDefaultVals()
	}, [loginUrl])

	useEffect(() => {
		if (name) {
			changePwdData(NEWPWD.NAME, name)
		}
	}, [name])

	useEffect(() => {
		if (idsToKeep[0]) {
			changePwdData(NEWPWD.USERNAME, idsToKeep[0])
		}
		if (idsToKeep[1]) {
			setPwdProps(idsToKeep[1])
		}
	}, [idsToKeep])

	useEffect(() => {
		if (errorState) {
			setTimeout(() => {
				setErrorState(false)
				setErrorMsg("")
			}, 3000)
		}
	}, [errorState])

	useEffect(() => {
		if (idsToKeep?.length !== _idsToKeep?.length) _setIdsToKeep(idsToKeep)
	}, [idsToKeep, _idsToKeep])

	function changePwdData(key, value) {
		setNewPwd((prev) => ({
			...prev,
			[key]: value,
		}))
	}

	function _clearIds(propedCallBg) {
		_setIdsToKeep()
		return setTimeout(() => clearIds(propedCallBg), 300)
	}

	function setDefaultVals() {
		const _domain = loginUrl ? loginUrl : document.location.origin
		changePwdData(NEWPWD.URL, loginUrl)
		let nameVal = getNameFromDomain2(document.location.origin)
		setName(capitalize(nameVal))
		return setDomain(_domain)
	}

	function blockThisSite() {
		/* eslint-disable no-undef */
		try {
			if (chrome.storage) {
				return chrome.storage.sync.get([pageOrigin], (storageRes) => {
					chrome.storage.sync.set({
						[pageOrigin]: {
							...storageRes?.[pageOrigin],
							blockFollowIds: true,
						},
					})
					return _clearIds()
				})
			} else return
		} catch (e) {
			console.warn("[LS] - Error on saving site exclusion -> ", e)
		}
	}

	function saveIds(payloadToUse = null, usePassword = null) {
		function cb(res) {
			setLoading(false)
			//console.log("Ret from posting new id", res)

			if (res?.id) {
				return setSuccessSave(true)
			} else {
				setErrorState(true)
				return setErrorMsg(res.message)
			}
		}

		function editCb(res) {
			setLoading(false)
			//console.log("Ret from editing new id", res)

			if (res?.id) {
				_clearIds()
				return setSuccessEdit(true)
			} else {
				setErrorState(true)
				return setErrorMsg(res.message)
			}
		}

		let ids = {
			username: idsToKeep[0],
			password: idsToKeep[1],
		}

		let defVals = {
			name,
			domain,
			categoryId: 0,
			opt1: "",
			opt2: "",
			opt3: "",
			description: "",
			isAlert: false,
		}

		setLoading(true)

		if (editMode) {
			return callBackground(
				{
					message: "LSputCredential",
					action: {
						newPass:
							typeof usePassword === "string"
								? usePassword
								: idsToKeep[1],
						id: credToEdit.id,
					},
				},
				editCb,
			)
		} else
			return callBackground(
				{
					message: "LSpostCredential",
					action: { ...ids, ...defVals, ...payloadToUse },
				},
				cb,
			)
	}

	let showDOMtoast = errorState || loading || successSave || successEdit

	const domToastMemo = useMemo(() => {
		return !showDOMtoast ? null : (
			<DOMToast
				loading={loading}
				successState={successSave || successEdit}
				errorState={errorState}
				text={
					successSave
						? t("popup.create.success")
						: successEdit
						? t("popup.edit.success")
						: errorMsg
						? errorMsg
						: ""
				}
			/>
		)
	}, [
		showDOMtoast,
		errorMsg,
		errorState,
		loading,
		successEdit,
		successSave,
		t,
	])

	// If retrieved actual credential to edit
	let credData = credToEdit

	//console.log("_idsToKeep ",_idsToKeep)
	//console.log("idsToKeep ",idsToKeep)
	//if (!idsToKeep?.length) return null

	return (
		<div
			id="followIdsFrame"
			style={{
				visibility: "hidden",
				width: "100%",
				boxSizing: "border-box",
				padding: idsToKeep?.length ? "" : 0,
			}}
			className={`${openedMap ? "maxxed" : ""} ${
				showDOMtoast ? "domToastViewFrame" : ""
			} ${_idsToKeep?.length ? "show" : ""}`}
		>
			<div className={`closeFollowFrame ${showDOMtoast ? "hide" : ""}`}>
				<div onClick={blockThisSite} className="secondary">
					<Close />
					<span>{t("popup.followIds.neverOnThisSite")}</span>
				</div>

				<div onClick={_clearIds} className="main">
					<ArrowT className="r270" />
					<span>{t("popup.followIds.close")}</span>
				</div>

				<div className="shadow" />
			</div>

			{domToastMemo}

			<div id="followIds" className={showDOMtoast ? "hide" : ""}>
				<Logo />

				{!openedMap && (
					<div className="followIdsTitle">
						<span>
							{t(
								`popup.${
									editMode ? "edit" : "create"
								}.autoAskDesc`,
							)}
						</span>
						<h3>
							{t(
								`popup.${
									editMode ? "edit" : "create"
								}.autoAskTitle`,
							)}
						</h3>
					</div>
				)}

				{editMode ? (
					<EditForm
						loadFavIcon={idsToKeep?.length ? true : false}
						credData={credData}
						name={name}
						domain={loginUrl}
						ids={idsToKeep}
						saveIds={saveIds}
					/>
				) : (
					<CreatePass
						activeTabUrl={domain}
						loadFavIcon={idsToKeep?.length ? true : false}
						hideResetBtn
						noHeader
						openedMap={openedMap}
						toggleMapCB={(v) => {
							// Setting iframe width by hand -> because of rerender of parent comp using redux state val
							//let frame = document.getElementById("followIdsFrameTarget")
							//frame.style.width = (v ? "33rem" : "24rem")
							return dispatch({
								type: "extension/setFrameMaxxed",
								payload: v,
							})
						}}
						submit={saveIds}
						useData={{
							name,
							domain: loginUrl,
							username: idsToKeep[0],
							password: idsToKeep[1],
						}}
						newPwd={newPwd}
						setNewPwd={setNewPwd}
						pwdProps={pwdProps}
					/>
				)}
			</div>
		</div>
	)
})

export default FormFrameMemo

FormFrameMemo.propTypes = {
	idsToKeep: PropTypes.array,
	clearIds: PropTypes.func,
	editMode: PropTypes.bool,
	credToEdit: PropTypes.object,
	loginUrl: PropTypes.string,
}
