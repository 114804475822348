import { createSlice } from "@reduxjs/toolkit"

const extensionSlice = createSlice({
	name: "extension",
	initialState: {
		loading: false,
		filteredResults: null,
		activeDomainPasswords: [],
		credentialDetails: null,
		data: [],
		history: [],
		active: {},
		searchMode: false,
		searchVal: "",
		searchResults: [],
		searchCatResults: [],
		followIds: {
			frameMaxxed: false,
		},
	},
	reducers: {
		init: (state) => {
			state.history = []
			state.active = {}
			state.searchMode = false
			state.searchVal = ""
			state.loading = false
		},
		setFrameMaxxed: (state, action) => {
			// Frame Maxxed is used for the widder state of popup (when showing categories map)
			const newState = { ...state.followIds }

			if (action.payload) newState.frameMaxxed = action.payload
			else newState.frameMaxxed = false

			state.followIds = newState
		},
		setLoading: (state, action) => {
			const newState = { ...state }

			if (action.payload) newState.loading = true
			else newState.loading = false

			return newState
		},
		setActiveDomainPasswords: (state, action) => {
			const newState = { ...state }

			if (action.payload) newState.activeDomainPasswords = action.payload
			else newState.activeDomainPasswords = []

			return newState
		},
		setCredentialDetails: (state, action) => {
			//console.log("settingg credential details", action)
			const newState = { ...state }

			if (action.payload) newState.credentialDetails = action.payload
			else newState.credentialDetails = null

			return newState
		},
		toggleLoading: (state, action) => {
			if (!action.payload) state.loading = false
			else state.loading = action.payload
		},
		addElements: (state, action) => {
			// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api

			let newFiles = action.payload._embedded?.items
				? action.payload._embedded?.items
				: action.payload.files
				? action.payload.files
				: []

			state.data = newFiles
			state.active = {}
			state.pagin = {
				limit: action.payload.limit,
				page: action.payload.page,
				pages: action.payload.pages,
				total: action.payload.total,
			}
		},
		initSearch: (state, action) => {
			let a = []
			state.data = a

			state.searchMode = true
			state.searchVal = action.payload.search
			state.searchResults = []
			state.searchCatResults = []
			state.active = {}
			state.history = []
		},
		setSearchVal: (state, action) => {
			state.searchVal = action.payload
		},
		setPasswordUsageLog: (state, action) => {
			//console.log("settingg credential details", action)
			const newState = { ...state }

			if (action.payload) {
				newState.passwordUsageLog = action.payload
			} else {
				newState.passwordUsageLog = null
			}
			return newState
		},
	},
})

export const { a = {} } = extensionSlice.actions

export default extensionSlice.reducer
