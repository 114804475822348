import React, { useEffect, useState, useRef, useMemo } from "react"

import { useTranslation } from "react-i18next"
import _ from "lodash"

import Whitemark from "./tools/WhitemarkExt"
import ShowIdsFrame from "./ShowIdsFrame"
import FollowIds from "./followIds"

import {
	callBackground,
	getCommonAncestors,
	considerAsPwdField,
	considerAsVisibleIframe,
	considerAsUsernameField,
	allAncestorsFullyVisible,
	allAncestorsDisplayed,
	getObjectHash,
	getStorageSync,
	getDomain,
} from "./tools/extensionUtils"

import defaultFieldsForWebsites from "./tools/defaultFieldsForWebsites.json"
import allowedUrlsForWebsites from "./tools/allowedUrlsForWebsites.json"
import excludeFieldsForWebsites from "./tools/excludeFieldsForWebsites.json"

import { ReactComponent as LockLogo } from "../assets/miniLogo.svg"

const maxInputFieldsToDetect = 100

export default function Autocomplete() {
	/* eslint-disable no-undef */
	const [t] = useTranslation()

	const [showIds, setShowIds] = useState(false)
	const [showIdsStyle, setShowIdsStyle] = useState({})
	const [targetedInput, setTargetedInput] = useState()

	const [inputsToComplete, setInputsToComplete] = useState([])
	const [inputLockers, setInputLockers] = useState([])
	const [styleLink, setStyleLink] = useState()

	const [isEnabled, setIsEnabled] = useState(null)

	const inputsToCompleteRef = useRef([])
	const inputLockersRef = useRef([])
	const showIdsRef = useRef()
	const targetedInputRef = useRef()
	const showIdsLoading = useRef()

	const detectInterval = useRef()
	const posInterval = useRef()
	const hibernating = useRef(false)

	let prodEnv = process.env.NODE_ENV === "production"

	const pageOrigin =
		window?.location !== window?.parent?.location
			? document.referrer.replace(/\/$/, "")
			: document?.location.origin

	//const debouncedCallBackground = useMemo(() => _.debounce(callBackground, 500), [])

	useEffect(() => {
		//console.log("-> isEnabled useEffect ----- > isEnabled ----> ", isEnabled)
		// console.log("_____ MOUNTING _____")

		if (prodEnv) getStyleLink()

		watchForBgRequests()

		getStorageSync("injection", setIsEnabled, true)
	}, [])

	useEffect(() => {
		if (window.location.origin === "https://business.apple.com") {
			const elementToUpdate = document?.querySelector("#auth-container")

			if (!!elementToUpdate) {
				elementToUpdate.style.position = "relative"
				elementToUpdate.style.zIndex = "1000"
			}
		}

		if (document.referrer === "https://business.apple.com/") {
			setShowIdsStyle((currentStyle) => ({
				...currentStyle,
				minHeight: "175px",
				useMinHeight: "true",
			}))
		}
	}, [])

	useEffect(() => {
		if (!isEnabled) return
		window.addEventListener("keydown", handleKeyDown)
		window.addEventListener("click", handleClick)

		detectInterval.current = setInterval(findInputsToComplete, 2000)

		// At mount get first list of present iframeS and send it to background for injection.
		// These iframes are filtered by considerAsVisibleIframe() to remove some pixels
		// and web-utility iframes without any display for user -> so no field to complete inside
		let ogIFrames = [
			...document.getElementsByTagName("iframe"),
			...document.getElementsByTagName("frame"),
		]
		let iFramesToUse = []
		ogIFrames.forEach((frame) => {
			//console.log("if test for visible iframes")
			//console.log(frame)

			if (!considerAsVisibleIframe(frame)) return
			iFramesToUse.push(frame.src)
		})

		if (iFramesToUse.length) {
			//console.log("%cNew iframes found !", "background-color: teal; color: black;")
			callBackground({ message: "newIframeDetected", iFramesToUse })
		}

		// Observe for mutation on page
		// If new iframe is injected -> inject our script inside
		let mutationObserver = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				// console.log(mutation, "mutation")
				let list = [...mutation.addedNodes]
				list.map((item) => {
					if (["IFRAME", "FRAME"].includes(item.tagName)) {
						// console.log(item)
						return treatNewIframe(item)
					} else {
						item.childNodes.forEach((child) => {
							if (["IFRAME", "FRAME"].includes(child.tagName))
								// console.log(item)
								treatNewIframe(child)
						})
						return null
					}
				})
			})
		})

		// console.log(document.documentElement, "document.documentElement")

		mutationObserver.observe(document.documentElement, {
			childList: true,
			subtree: true,
		})

		return () => {
			mutationObserver.disconnect()
			clearInterval(detectInterval.current)
			clearInterval(posInterval.current)
			window.removeEventListener("keydown", handleKeyDown)
			window.removeEventListener("click", handleClick)
		}
	}, [isEnabled])

	useEffect(() => {
		inputLockersRef.current = inputLockers
	}, [inputLockers])

	useEffect(() => {
		showIdsRef.current = showIds
	}, [showIds])

	useEffect(() => {
		if (!isEnabled) return

		inputsToCompleteRef.current = inputsToComplete
		clearInterval(posInterval.current)
		posInterval.current = setInterval(posCheck, 500)

		// Clear input lockers if there is no more detected inputs on page
		if (!inputsToComplete.length && inputLockers?.length)
			setInputLockers([])

		// If number of detected inputs changes on page signal it to background
		if (!hibernating.current) {
			if (!inputsToComplete?.length) {
				callBackground({ message: "LSnoInputsInFrame" })
			} else {
				callBackground({ message: "LSsomeInputsInFrame" })
			}
		}
	}, [inputsToComplete, isEnabled])

	useEffect(() => {
		targetedInputRef.current = targetedInput
		if (targetedInput) posCheck()
	}, [targetedInput])

	const debouncedToggleShowIds = useMemo(
		() => _.throttle(toggleShowIds, 200),
		[],
	)

	if (!isEnabled) return <></>

	document.addEventListener(
		"contextmenu",
		function (e) {
			let target = e.activeElement

			//console.log(target)

			if (target.tagName === "INPUT") {
				//return localchrome.Storage.setItem("LStargetedInput", target)
				return chrome.storage.sync.set({
					LStargetedInputHash: getObjectHash(target),
				}) //getCombinedAttributes(target))
			} else return

			/*if (e.activeElement.tagName == "INPUT" || e.activeElement.tagName == "A" || e.activeElement.tagName == "BUTTON" ||
	        (event && event.target.tagName == "INPUT" || event && event.target.tagName == "A" || event && event.target.tagName == "BUTTON")) { // check pour firefox et chrome
	        var elem = "";
	        if (event)
	            elem = event.target.cloneNode(true);
	        else {
	            elem = e.activeElement.cloneNode(true);
	        }
	        elem.removeAttribute("style");
	        elem.removeAttribute("lockself");
	        elem.removeAttribute("class");
	        elem.removeAttribute("autocomplete");
	        elem.removeAttribute("onclick");
	        localchrome.Storage.setItem("LSinput", elem.outerHTML);
	    }
	    var inputs = document.getElementsByTagName('input');
	    var i = 0;
	    var inputToFind = localchrome.Storage.getItem("LSinput");
	    while (inputs[i]) {
	        if (inputs[i].outerHTML == inputToFind) {
	            break;
	        }
	        i++;
	    }*/
		}.bind(null, document),
	)

	function showIdsLoadingStatusChanged(newStatus) {
		return (showIdsLoading.current = newStatus)
	}

	function handleKeyDown(e) {
		if (e.key === "Escape" && showIdsRef.current) return toggleShowIds()
		else return
	}

	function handleClick() {
		if (prodEnv && showIdsRef.current) toggleShowIds()
	}

	function getStyleLink() {
		if (!prodEnv) return
		let responseStatus
		/* eslint-disable no-undef */
		return fetch(chrome?.runtime?.getURL("asset-manifest.json"), {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		})
			.then((res) => {
				responseStatus = res.ok
				return res
			})
			.then((res) => res.json())
			.then((_data) => {
				if (responseStatus) {
					let styleLink = chrome?.runtime
						?.getURL(_data.files["main.css"])
						.replace("./", "")
					return setStyleLink(styleLink)
				}
			})
	}

	function watchForBgRequests() {
		/* eslint-disable no-undef */
		try {
			if (chrome.runtime) {
				chrome.runtime.onMessage.addListener(
					(request, sender, sendResponse) => {
						// console.log(
						// 	"In auto-complete onMessage listener : ",
						// 	request,
						// 	sender,
						// 	sendResponse,
						// 	window.location.origin,
						// )

						if (sender.id !== chrome.runtime.id) {
							return console.warn(
								"[LS] Unauthorized sender trying to access your page data. Details of sender : ",
								sender,
							)
						}

						// Log from BG utility
						if (request.message === "LSlog") {
							//console.log('%c   LockSelf', `font-size: 27px; color: #39499B; text-shadow: 1px 1px #fff; font-weight: bold; background:url(${chrome.runtime.getURL("/warnSign.svg")}) no-repeat;`)
							//'%c  ', `font-size: 40px; background:url(${chrome.runtime.getURL("/logo.svg")}) no-repeat;`
							return console.warn(
								request.data.message,
								request.data.data,
							)
						}

						// Send back input vals (used in browser popup ext part)
						if (request.message === "LSgetInputsInPage") {
							let values = []
							inputsToCompleteRef.current.forEach((input) => {
								values.push(input.value)
							})
							return sendResponse({ values })
						}

						if (request.message === "LSgetActiveDomain") {
							return sendResponse({
								domain: getDomain(),
								url: window.location.href,
							})
						}

						if (request.message === "LScanWeFill") {
							let hasPwdConsideredField = false

							inputsToCompleteRef.current?.forEach((item) => {
								if (considerAsPwdField(item))
									return (hasPwdConsideredField = true)
								else return
							})

							return sendResponse({
								hasFields: inputsToCompleteRef.current?.length
									? true
									: false,
								hasPwdField: hasPwdConsideredField,
							})
						}

						if (request.message === "LSlocalstorage") {
							switch (request.method) {
								case "SET":
									localStorage.setItem(
										request.key,
										request.data,
									)
									break
								case "GET":
									localStorage.getItem(request.key)
									break
								case "DEL":
									localStorage.removeItem(request.key)
									break
								default:
									return
							}
							const value = localStorage.getItem(request.key)

							return sendResponse({ value })
						}

						// Send back translations for context menu initialisation
						if (request.message === "LSgetTranslationsForContext") {
							return sendResponse({
								addInput: t("popup.context.add"),
								removeInput: t("popup.context.remove"),
								resetForPage: t("popup.context.reset"),
								disableAutodetect: t(
									"popup.context.disableAutodetect",
								),
								enableAutodetect: t(
									"popup.context.enableAutodetect",
								),
								enableSite: t("popup.context.enableOnSite"),
								disableSite: t("popup.context.disableOnSite"),
							})
						}

						// From context menu -> use input in LS as auto-completed
						if (request.message === "LSaddInput") {
							chrome.storage.sync.get(
								[pageOrigin, "LStargetedInputHash"],
								(storageRes) => {
									let res = storageRes?.[pageOrigin]

									let newInputsToUse = res?.LSuseInputs
										? [...res.LSuseInputs]
										: []
									let newInputsToAvoid = res?.LSavoidInputs
										? [...res.LSavoidInputs]
										: []
									let target = storageRes.LStargetedInputHash

									//console.log("IN ADD INPUT : ",target,newInputsToUse)

									if (storageRes.LStargetedInputHash) {
										if (!newInputsToUse.includes(target))
											newInputsToUse.push(target)

										//console.log("___________",target)
										//console.log("___________",newInputsToAvoid)
										newInputsToAvoid =
											newInputsToAvoid.filter(
												(x) => x !== target,
											)
										//console.log("___________",newInputsToAvoid)

										chrome.storage.sync.set({
											[pageOrigin]: {
												...storageRes?.[pageOrigin],
												LSuseInputs: newInputsToUse,
												LSavoidInputs: newInputsToAvoid,
											},
										})

										chrome.storage.sync.remove(
											"LStargetedInputHash",
										)

										return findInputsToComplete()
									} else return
								},
							)
						}

						// From context menu -> save input to avoid in auto-completion in LS
						if (request.message === "LSavoidInput") {
							chrome.storage.sync.get(
								[pageOrigin, "LStargetedInputHash"],
								(storageRes) => {
									//console.log(storageRes)
									let res = storageRes?.[pageOrigin]

									let newInputsToUse = res?.LSuseInputs
										? [...res.LSuseInputs]
										: []
									let newInputsToAvoid = res?.LSavoidInputs
										? [...res.LSavoidInputs]
										: []
									let target = storageRes.LStargetedInputHash

									//console.log("IN AVOID INPUT : ",target,newInputsToAvoid)

									if (storageRes.LStargetedInputHash) {
										if (
											!newInputsToAvoid.includes(
												target,
											) &&
											res?.autoDetection !== false
										) {
											// If autodection is disabled just remove target input from toUse object
											newInputsToAvoid.push(target)
										}
										newInputsToUse = newInputsToUse.filter(
											(x) => x !== target,
										)

										chrome.storage.sync.set({
											[pageOrigin]: {
												...storageRes?.[pageOrigin],
												LSuseInputs: newInputsToUse,
												LSavoidInputs: newInputsToAvoid,
											},
										})

										chrome.storage.sync.remove(
											"LStargetedInputHash",
										)

										return findInputsToComplete()
									} else return
								},
							)
						}

						// On tab focus activate back detectInterval script
						// -> it will enable posInterval script on first iteration
						if (request.message === "LSwakeUpDetectionScripts") {
							hibernating.current = false
							if (!isEnabled) return
							posInterval.current = setInterval(posCheck, 500)
							return (detectInterval.current = setInterval(
								findInputsToComplete,
								2000,
							))
						}

						// Pause posInterval and detectInterval scripts if tab is not focused
						if (request.message === "LShibernateDetectionScripts") {
							hibernating.current = targetedInputRef
							clearInterval(posInterval.current)
							return clearInterval(detectInterval.current)
						}

						return
					},
				)
			}
		} catch (e) {}
	}

	// If its an iframe, check with considerAsVisibleIframe then send to background for injection
	function treatNewIframe(item) {
		// console.log(
		// 	"%cIframe was injected in DOM. ABOVE -------",
		// 	"background-color: blue; color: #fff;",
		// )
		if (
			considerAsVisibleIframe(item) ||
			window.location.href ===
				"https://s2i-frontbpoprd.pbgate-cs.net/caiportal/index2.html"
		) {
			// console.log(
			// 	"%cIframe was injected in DOM.",
			// 	"background-color: blue; color: #fff;",
			// )

			let iframeSrc = item.src

			if (
				!iframeSrc &&
				window.location.href ===
					"https://s2i-frontbpoprd.pbgate-cs.net/caiportal/index2.html"
			) {
				iframeSrc =
					"https://s2i-frontbpoprd.pbgate-cs.net/caiportal/s2imain.html"
			}

			return callBackground({
				message: "newIframeDetected",
				iFramesToUse: [iframeSrc],
			})
		} else return
	}

	// function obtainFormatedUrl() { return document.location }

	function toggleShowIds(item, e = undefined) {
		// console.log("%c____ toggleShowIds ____", "background-color:orange;")
		//console.log("showIdsRef.current : ", showIdsRef.current)

		if (e?.target) {
			e.stopPropagation()
			e.preventDefault()
		}

		if (showIdsLoading.current) return

		if (showIdsRef.current) {
			// console.log(
			// 	"%c____ Clearing targeted input ____",
			// 	"background-color:violet;",
			// )
			setTargetedInput()
			setTimeout(
				() =>
					setShowIdsStyle((currentStyle) => {
						if (currentStyle?.useMinHeight === "true") {
							return { ...currentStyle }
						} else {
							return null
						}
					}),
				250,
			)
			return setShowIds(false)
		} else if (item) {
			setTargetedInput(item)
			showIdsRef.current = true

			return setShowIds(true)
		} else return
	}

	function compareAndSetState(newInputs) {
		//Compare state and new state with isSameNode -> if new inputs detected -> set new state
		let shouldUpdateState = false
		if (inputsToCompleteRef.current?.length !== newInputs?.length) {
			shouldUpdateState = true
		} else {
			// IF still same length, check if node are the sames
			newInputs.forEach((item, i) => {
				if (
					!inputsToCompleteRef.current[i] ||
					!item.isSameNode(inputsToCompleteRef.current[i])
				)
					shouldUpdateState = true
			})
		}

		// console.log("________ COMPARE AND SET STATE ______________")

		if (shouldUpdateState) {
			/* Commenting on focus popin
			newInputs.forEach(newInput => {

				// If we have a focused field open showIds on it
				if (document.activeElement === newInput && !(newInput.isSameNode(targetedInputRef.current))) toggleShowIds(newInput)

				newInput.addEventListener('focus', (e) => {
					//e.target.style.background = 'pink'
					if (!showIdsRef.current) return toggleShowIds(newInput,e)
						else return setTargetedInput(newInput)
				})
			})
			*/
			return setInputsToComplete(newInputs)
		} else return
	}

	function checkInputsVisibility(useInputs) {
		// Before setting, in state, inputs to use for locks display,  we check their real visibility
		if (useInputs?.length < 1) return compareAndSetState([])
		let visibleInputs = []

		let rootAncestor = getCommonAncestors([...useInputs])
		rootAncestor = rootAncestor ? rootAncestor : useInputs[0].parentNode
		//console.log("Common ancestor", rootAncestor)

		let a = allAncestorsFullyVisible(rootAncestor, true)
		//console.log("Is ancestor visible : ", a)
		if (!a) return compareAndSetState([])

		let observerOptions = {
			root: rootAncestor,
			rootMargin: "0px",
			treshold: 0.1,
		}

		let observer = new IntersectionObserver((entries, _observer) => {
			//console.log("Observing : ",entries)

			entries.forEach((entry, i) => {
				//console.log(entry)
				if (entry.intersectionRatio > 0.1) {
					//console.log("[OK] - Input is fully visible")
					if (
						!excludeFieldsForWebsites?.[window.location.origin] ||
						((!useInputs[i]?.id ||
							!useInputs[i]?.id
								?.toLowerCase()
								?.includes(
									excludeFieldsForWebsites?.[
										window.location.origin
									]?.fieldId,
								)) &&
							(!useInputs[i]?.name ||
								!useInputs[i]?.name
									?.toLowerCase()
									?.includes(
										excludeFieldsForWebsites?.[
											window.location.origin
										]?.fieldName,
									)))
					) {
						visibleInputs.push(useInputs[i])
					}
					_observer.disconnect(entry.target) // Disconnect observer
				}
			}) // If they are visible -> we keep them
			//console.log("Visible ones :", visibleInputs)

			if (visibleInputs.length) {
				return compareAndSetState(visibleInputs)
			} else {
				// If ancestor is not visible for observer we check each input on himself from body point of view

				let fallbackObserver = new IntersectionObserver(
					(entries, _observer) => {
						//console.log("---> IN Fallback observer", entries)

						entries.forEach((entry, i) => {
							//console.log(entry)
							if (entry.intersectionRatio > 0.1) {
								//console.log("[OK] - Input is fully visible when fallback")
								if (
									!excludeFieldsForWebsites?.[
										window.location.origin
									] ||
									((!useInputs[i]?.id ||
										!useInputs[i]?.id
											?.toLowerCase()
											?.includes(
												excludeFieldsForWebsites?.[
													window.location.origin
												]?.fieldId,
											)) &&
										(!useInputs[i]?.name ||
											!useInputs[i]?.name
												?.toLowerCase()
												?.includes(
													excludeFieldsForWebsites?.[
														window.location.origin
													]?.fieldName,
												)))
								) {
									visibleInputs.push(useInputs[i])
									_observer.disconnect(entry.target) // Disconnect observer
								}
							}
						}) // If they are visible -> we keep them
						return compareAndSetState(visibleInputs)
					},
					{ ...observerOptions, root: null },
				)

				return useInputs.forEach((input) => {
					return fallbackObserver.observe(input)
				})
			}
		}, observerOptions)

		return useInputs.forEach((input) => {
			// setting observer on each selected input parent
			return observer.observe(input) //.parentElement)
		})
	}

	function posCheck() {
		if (
			!inputsToCompleteRef.current?.length &&
			!targetedInput &&
			!targetedInputRef?.current
		)
			return

		// console.log("________ POS CHECK __________")
		// console.log("inputsToComplete : ", inputsToComplete)
		// console.log("showIds : ", showIds)
		// console.log("showIdsRef : ", showIdsRef.current)
		// console.log("targetedInput : ", targetedInput)
		// console.log("targetedInputRef.current : ", targetedInputRef.current)

		let _inputsToComplete = inputsToCompleteRef.current

		if (_inputsToComplete.length) {
			let _inputLockers = []

			let height = 0

			_inputsToComplete.forEach((item, i) => {
				if (allAncestorsFullyVisible(item)) {
					let pos = item.getBoundingClientRect()
					// let coords = getCoords(item)
					let top = pos.top
					let left = pos.right - pos.height
					if (i === 0) height = pos.height

					// Here to equilibrate sizes of locks when input heights are unequal
					let verticalSizeDif =
						pos.height < height ? height - pos.height : 0

					return _inputLockers.push(
						<div
							key={i}
							onClick={(e) => debouncedToggleShowIds(item, e)}
							//className="LSautoCompleteLock"
							style={{
								top: top - verticalSizeDif / 2,
								left:
									document.referrer ===
									"https://business.apple.com/"
										? left - 40
										: left,
								height: pos.height + verticalSizeDif,
								width: height,
								position: "absolute",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								right: "1rem",
								cursor: "pointer",
								zIndex: 2147483642,
							}}
						>
							{/*<LockLogo style={{ height: "auto", maxHeight: "95%", width: "70%", minWidth: "17px", maxWidth: "24px" }} />*/}
							<LockLogo
								style={{
									height: "auto",
									width: "50%",
									minWidth: "10px",
									maxWidth: "15px",
								}}
							/>
						</div>,
					)
				}
			})

			if (
				JSON.stringify(inputLockersRef.current) !==
				JSON.stringify(_inputLockers)
			) {
				/* console.log("-> Update lock(s) position(s)") */
				setInputLockers(_inputLockers)
			}
		}

		if (showIdsRef.current && targetedInputRef.current) {
			// Show ids frame css properties
			// Width : MIN 280 MAX 450
			// Height : MIN 300 MAX 420

			let pos = targetedInputRef.current.getBoundingClientRect()
			let top = pos.top + pos.height
			let left = pos.left - 16

			// Give the max place it leaves under input, will be limited by min-max-height
			let height = window.innerHeight - pos.bottom - 16
			let newStyle = {
				top,
				left,
				width: pos.width + 32,
				height,
				opacity: 1,
			}

			if (JSON.stringify(showIdsStyle) !== JSON.stringify(newStyle)) {
				// console.log(showIdsStyle, "showIdsStyle in 819")
				if (!!showIdsStyle && "useMinHeight" in showIdsStyle) {
					setShowIdsStyle({
						...newStyle,
						useMinHeight: showIdsStyle?.useMinHeight,
						minHeight: showIdsStyle?.minHeight,
					})
				} else {
					setShowIdsStyle(newStyle)
				}
			}
		}

		return
	}

	function findInputsToCompleteUtil(res) {
		//console.log("FindInputsToCompleteUtil ", Date.now())

		if (
			allowedUrlsForWebsites?.[window.location.origin] &&
			allowedUrlsForWebsites?.[window.location.origin]?.urls?.length >
				0 &&
			!allowedUrlsForWebsites?.[window.location.origin]?.urls?.find(
				(url) => window.location.href?.includes(url),
			)
		) {
			clearInterval(posInterval.current)
			return clearInterval(detectInterval.current)
		}

		let foundSelectedInputToUse = []
		let userSelectedInputs = res?.LSuseInputs ? [...res?.LSuseInputs] : []
		let userAvoidedInputs = res?.LSavoidInputs
			? [...res?.LSavoidInputs]
			: []

		let inputs = document.querySelectorAll("input") // All inputs from document
		let inputsCopy = []
		let ogInputsArray = [...inputs]

		let hiddentPasswordFields = []
		let useInputs = []
		let passNum = -1

		// Recursively get all inputs from shadow root element and add them to inputs list
		const getInputsFromShadowFrames = (rootElement) => {
			for (let el of rootElement.querySelectorAll("*")) {
				if (el?.shadowRoot) {
					let inputsInShadow = [
						...el.shadowRoot.querySelectorAll("input"),
					]
					if (inputsInShadow.length)
						ogInputsArray = [...ogInputsArray, ...inputsInShadow]

					getInputsFromShadowFrames(el.shadowRoot)
				}
			}
		}

		if (ogInputsArray.length <= maxInputFieldsToDetect) {
			// Do not search in shadowFrames if there is already a lot if inputs
			getInputsFromShadowFrames(document)
		}

		if (ogInputsArray?.length < 1) {
			// STOP HERE if no inputs found at all
			return compareAndSetState([])
		} else if (ogInputsArray.length > maxInputFieldsToDetect) {
			ogInputsArray = ogInputsArray.slice(0, maxInputFieldsToDetect)
		}

		//if (userSelectedInputs?.length) console.log("USE : ", userSelectedInputs)
		//if (userAvoidedInputs?.length) console.log("AVOID : ", userAvoidedInputs)

		//console.log("ogInputsArray -> ", ogInputsArray)

		ogInputsArray.forEach((item) => {
			let itemPrint = getObjectHash(item)
			//console.log(item)
			//console.log("Item print : ", itemPrint)
			//console.log(getCombinedAttributes(item))
			//console.log("Is item in use : ", userSelectedInputs?.includes(itemPrint))
			//console.log("Is item in avoided : ", userAvoidedInputs?.includes(itemPrint))

			if (userSelectedInputs?.includes(itemPrint))
				foundSelectedInputToUse.push(item)

			// Filter proprietary and user choosen fields
			if (
				item.getAttribute("lockself") !== "lockself" &&
				!userAvoidedInputs.includes(itemPrint)
			) {
				let itemDimensions = item.getBoundingClientRect()

				if (item.maxLength === 1) {
					// Do not take one char inputs
					return
				}
				if (item.placeholder === "○") {
					// Uber eats merchant case exception -> pin code
					return
				}
				if (
					itemDimensions.width > 0 &&
					itemDimensions.height > 0 &&
					itemDimensions.width < itemDimensions.height
				) {
					// Do not take inputs if they are squares (it often a pin entry field)
					return
				}

				// Remove all 0px sized inputs
				if (itemDimensions.width !== 0 && itemDimensions.height !== 0) {
					inputsCopy.push(item)
				}
			}
		})

		if (foundSelectedInputToUse?.length || res?.autoDetection === false) {
			return compareAndSetState(foundSelectedInputToUse)
		}

		// console.log("%c------------- Find Inputs To Complete -----------","background-color:yellow;color:black;margin-top:15px;")
		// console.log("After first exclusion of inputs : ", inputsCopy)

		if (inputsCopy.length > 0) {
			// On passe sur tous les inputs et on vérifie si on trouve un champs type password
			inputsCopy.forEach((item, i) => {
				// console.log('forEach -> inputsCopy')
				//console.log("in first map : ", item, allAncestorsDisplayed(item))
				if (
					!useInputs.length &&
					(item.type === "password" ||
						item.name ===
							"password") /*|| considerAsPwdField(item)*/
				) {
					if (allAncestorsDisplayed(item)) {
						passNum = i
						return useInputs.push(item)
					} else {
						// When encountering a password field width non displayed parents
						// -> save for potentially treat it in a fallback later
						return hiddentPasswordFields.push(item)
					}
				}
			})

			//console.log("Visibles : ", useInputs)
			//console.log("Hiddens : ", hiddentPasswordFields)

			function setTreated(tar) {
				// Remove autocomplete on detected fields
				//tar.setAttribute('lockself', 'autocomplete')
				tar.setAttribute("autocomplete", "off")
			}

			if (passNum >= 0) {
				// Si on a trouvé un champs mdp

				//console.log("%c- Found password field -","background-color:green;")

				// On repasse alors sur tous les elements situés avant le mot de passe
				// dès qu'on en trouve un correct -> on stop et on passe à l'affichage

				inputsCopy = inputsCopy.slice(0, passNum)

				if (
					(defaultFieldsForWebsites?.[window.location.href] &&
						defaultFieldsForWebsites?.[window.location.href]?.fields
							?.length > 0) ||
					(defaultFieldsForWebsites?.[
						window.location.href.split("?")?.[0]
					] &&
						defaultFieldsForWebsites?.[
							window.location.href.split("?")?.[0]
						]?.fields?.length > 0)
				) {
					const defaultFieldToCheck = inputsCopy
						.reverse()
						.find((input) =>
							defaultFieldsForWebsites?.[
								window.location.href
							]?.fields.includes(input.name),
						)

					if (
						defaultFieldToCheck &&
						!allAncestorsDisplayed(defaultFieldToCheck)
					) {
						useInputs.push(defaultFieldToCheck)
					}
				}

				inputsCopy.reverse().forEach((item) => {
					if (useInputs.length > 1) return

					//console.log("maxlength", item.maxlength)

					if (
						item.type === "hidden" ||
						item.type === "date" ||
						item.type === "submit" ||
						item.type === "radio" ||
						item.type === "checkbox" ||
						item.type === "button" ||
						!allAncestorsDisplayed(item)
					) {
						return
					} else return useInputs.push(item)
				})

				useInputs = useInputs.reverse()
			} else {
				// If no password fields founded
				// Take again all inputs and try to understand if we want to use them
				//console.log("%c- No displayed password fields -","background-color:orange;color:black;")
				let newInputsCopy = [...inputsCopy]
				let treatedCopy = []

				//console.log("NIC -> ", newInputsCopy, inputsCopy, inputs)

				// Vanilla 1st treatment
				// If element is email/text/tel type and is displayed, keep him for next step
				newInputsCopy.map((item) => {
					if (item.maxLength === 1) return null
					if (!item.getAttribute("type") && newInputsCopy.length > 1)
						return null
					// Exception for some bizarre practices when inputs are used on pages for non-inputs components
					// console.log("input", item.type, " visible : ", allAncestorsDisplayed(item))

					if (
						(item.type === "email" ||
							item.type === "text" ||
							item.type === "tel") &&
						allAncestorsDisplayed(item)
					)
						treatedCopy.push(item)
					return null
				})

				//console.log("Treated copy : ", treatedCopy)

				// The next step, choose in filtered inputs
				// Commenting this method cause was detecting too much fields
				/*if (treatedCopy.length === 1) {
					if (considerAsUsernameField(treatedCopy[0]) || considerAsPwdField(treatedCopy[0])) {
						useInputs.push(treatedCopy[0]) // If we have only one text/email/tel field in page -> use it
					} else return checkInputsVisibility([]) // Send it to this func to clear state of field(s) to use

				} else if (treatedCopy.length > 1 && hiddentPasswordFields.length) {
					// For some cases we have a lot of fields (even hidded ones), I consider that if we have
					// at least one password field in page, we must purpose some auto completion
					useInputs.push(treatedCopy[0])
				}*/

				if (!useInputs?.length) {
					let foundId = false
					let foundPass = false

					// TODO : uncomment and complete code below for default fields to check in forms with no password field
					// console.log("defaultFieldsForWebsites", defaultFieldsForWebsites)
					// console.log("window.location.href", window.location.href)

					// if (defaultFieldsForWebsites?.[window.location.href] && defaultFieldsForWebsites?.[window.location.href]?.fields?.length > 0) {
					// 	const defaultFieldToCheck = treatedCopy.find(input => defaultFieldsForWebsites?.[window.location.href]?.fields?.includes(input.name))

					// 	console.log("defaultFieldToCheck", defaultFieldToCheck)

					// 	if (defaultFieldToCheck && !allAncestorsDisplayed(defaultFieldToCheck)) {
					// 		foundId = true
					// 		useInputs.push(defaultFieldToCheck)
					// 	}
					// }

					treatedCopy.forEach((elem) => {
						if (!foundId) {
							if (considerAsUsernameField(elem)) {
								foundId = true
								return useInputs.push(elem)
							}
						}
						if (!foundPass) {
							if (considerAsPwdField(elem)) {
								foundPass = true
								return useInputs.push(elem)
							}
						}
						return
					})
				}

				if (!useInputs?.length && treatedCopy?.length === 1) {
					let _elem = treatedCopy[0]

					//console.log("is aria-required ? -> ", _elem.attributes["aria-required"]?.value === "true")
					//console.log("user ? -> ", considerAsUsernameField(_elem, false, true))
					//console.log("pwd ? -> ", considerAsPwdField(_elem, false, true))

					// TODO : uncomment and complete code below for default fields to check in forms with no password field
					// let foundId = false
					// if (defaultFieldsForWebsites?.[window.location.href] && defaultFieldsForWebsites?.[window.location.href]?.fields?.length > 0) {
					// 	const isDefaultFieldToCheck = defaultFieldsForWebsites?.[window.location.href]?.fields?.includes(_elem.name)

					// 	if (isDefaultFieldToCheck && !allAncestorsDisplayed(_elem) && considerAsUsernameField(_elem, false, true) &&  _elem.attributes?.["aria-required"]?.value === "true") {
					// 		foundId = true
					// 		useInputs.push(_elem)
					// 	}
					// }

					// if (
					// 	((considerAsUsernameField(_elem, false, true) && !foundId)
					// 	|| considerAsPwdField(_elem, false, true))
					// 	&& _elem.attributes?.["aria-required"]?.value === "true"
					// ) {
					// 	//console.log("GOOOOOOOOOOOOOD")
					// 	useInputs.push(_elem)
					// }

					if (
						(considerAsUsernameField(_elem, false, true) ||
							considerAsPwdField(_elem, false, true)) &&
						_elem.attributes?.["aria-required"]?.value === "true"
					) {
						//console.log("GOOOOOOOOOOOOOD")
						useInputs.push(_elem)
					}
				}
			} // else end

			useInputs.forEach((item, i) => {
				setTreated(item, i)
			})
		}

		// console.log("FindInputsToComplete - final before check visibility : ", useInputs)
		return checkInputsVisibility(useInputs)
	}

	function findInputsToComplete() {
		// Initial function for input detection
		// console.log('%cJe suis peut etre la', 'background-color: cyan; color: black;')

		try {
			// console.log('%cJe suis peut etre la aussi', 'background-color: pink; color: black;')

			chrome.storage.sync.get([pageOrigin], (res) => {
				return findInputsToCompleteUtil(res?.[pageOrigin])
			})
		} catch (e) {
			// console.log('%cOu peut etre ici', 'background-color: orange; color: black;')
			// local dev fallback
			return findInputsToCompleteUtil({})
		}
	}

	// console.log(inputLockers, "inputLockers")

	return (
		<Whitemark extMode>
			<div
				id="LSautoCompleteLockFrame"
				style={{ position: "absolute", top: 0, left: 0 }}
			>
				{inputLockers}
			</div>

			<FollowIds
				inputsToComplete={inputsToComplete}
				styleLink={styleLink}
			/>

			<ShowIdsFrame
				styleLink={styleLink}
				showIdsStyle={showIdsStyle}
				inputsToComplete={inputsToComplete}
				targetedInput={targetedInput}
				showIds={showIds}
				toggleShowIds={toggleShowIds}
				cbLoading={showIdsLoadingStatusChanged}
			/>
		</Whitemark>
	)
}

/*<iframe height={300} width={300} src={"chrome-extension://padncgmgnkndpnoikipkpblbhdmcabfg/index.html#/popup/showIds"}/>*/

// No more useful functions ?

//function watchForPurposeIds() {
//	/* eslint-disable no-undef */
//	if (chrome.runtime) {
//		chrome.runtime.onMessage.addListener((request, sender, sendResponse) => {
//			// Log from BG utility
//			console.log(request)
//			if (request.message === "LSlog") {
//				if (request.execOnUrl && document.location.href !== request.execOnUrl) return
//				return console.warn(request.data.message, request.data.data)
//			} else return
//		})
//	}
//}

/*function obtainFormatedUrl() {
	let url = document.location
	if (!(nurl = /^.*\:\/\/(.*)\//.exec(url))) return ""

	if ((nurl = /^(.*)\:\/\/([0-9]{0,3})\.([0-9]{0,3})\.([0-9]{0,3})\.([0-9]{0,3})(\/|\:)(.*?)$/.exec(url)))
		return nurl[2] + '.' + nurl[3] + '.' + nurl[4] + '.' + nurl[5]

	test = document.location.host ? ((document.location.host.match(/([^.]+)\.\w{2,3}(?:\.\w{2})?$/) || [])[0]) : false
	if (test) return test

	if ((nurl = /(\/|\.)([a-zA-Z0-9^_-]{0,30})\.([a-zA-Z0-9^_:]{0,10})\/(.*?)$/.exec(url))) return nurl[2] + '.' + nurl[3]
		else return document.location.host + ""
}*/

/*function getOffset(obj) {
	let left = 0
	let top = 0

	if (obj.offsetParent) {
		console.log("obj srcoll top", obj.scrollTop)

		left = obj.offsetLeft + obj.scrollLeft
		top = obj.offsetTop + obj.scrollTop

		while (obj = obj.offsetParent) {
			left += obj.offsetLeft + obj.scrollLeft
			top += obj.offsetTop + obj.scrollTop
		}
	}
	return { left, top }
}*/

/*function isManualExcludeInput(input) {
	let LSDelInput = localchrome.Storage.getItem("LSDelInput")
	let clonedInput = input.cloneNode(true)

	clonedInput.removeAttribute("class")
	clonedInput.removeAttribute("autocomplete")

	//si le champs à traiter est le champs à exclure
	if (clonedInput.outerHTML == LSDelInput) return 1
		else return 0
}*/
