import React from "react"
import PropTypes from "prop-types"
import { validateEmail } from "../../../actions/generalUtils"
import { ReactComponent as Close } from "../../../assets/images/close.svg"

export function MultiListTag(props) {
	const { index, item, changeWrongMail, removeFromList, mailList } = props

	return (
		<div className="multiList">
			<div
				className={`multiList-container ${
					!validateEmail(item) && mailList && "wrongMailMultiList"
				} `}
			>
				<span
					className="multiList-title"
					onClick={() =>
						!validateEmail(item) && changeWrongMail(item, index)
					}
				>
					{item.name ? item.name : item}
				</span>
				<Close onClick={() => removeFromList(index)} />
			</div>
		</div>
	)
}

MultiListTag.propTypes = {
	mailList: PropTypes.array,
	removeFromList: PropTypes.func,
	changeWrongMail: PropTypes.func,
	item: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	index: PropTypes.number,
}
