import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

export default function InputIndicator(props) {
	const {
		hideCharLimit,
		hideLimit,
		type,
		limit,
		value,
		focused,
		error,
		errorMsg,
		errorState,
		onContainer,
		location,
		maxLimit,
	} = props
	const [t] = useTranslation()
	const inputType = [
		"password",
		"text",
		"totp",
		"tags",
		"passphraseProtectedKey",
	].includes(type)

	return (
		<div
			className={`containerMailErrorMessage ${
				onContainer && location === "pass" && inputType
					? "onContainerPosition"
					: ""
			}`}
		>
			{!hideCharLimit &&
				!hideLimit &&
				focused &&
				type !== "date" &&
				(limit < 0 ? (
					<div
						className={`${
							focused || inputType
								? inputType
									? "charIndicPassword"
									: "charIndic"
								: "charIndicFocus"
						}`}
					>
						{value &&
							`${props.value?.length ?? 0} ${
								!inputType ? t("beforeLastChars") : ""
							} `}
						{(limit || limit === 0) &&
							`${inputType ? "/" : "-"} ${
								!inputType ? t("afterTooMuchChars") : ""
							} ${maxLimit}`}
					</div>
				) : (
					<div
						className={`${
							focused || inputType
								? inputType
									? "charIndicPassword"
									: "charIndic"
								: "charIndicFocus"
						}`}
					>
						{focused &&
							typeof value !== undefined &&
							`${value?.length ?? 0} ${
								!inputType ? t("beforeLastChars") : ""
							} `}
						{focused &&
							(limit || limit === 0) &&
							`${inputType ? "/" : "-"} ${maxLimit} ${
								!inputType ? t("lastChars") : ""
							}`}
					</div>
				))}

			{errorMsg && errorState && !error ? (
				<div className={`errorMsg ${focused && "focused"}`}>
					{t(errorMsg)}
				</div>
			) : (
				!!errorMsg && (
					<div className={`errorMsg addPassErrMsg`}>
						{t(errorMsg)}
					</div>
				)
			)}
		</div>
	)
}

InputIndicator.propTypes = {
	hideCharLimit: PropTypes.bool,
	hideLimit: PropTypes.bool,
	type: PropTypes.string,
	limit: PropTypes.number,
	value: PropTypes.string,
	focused: PropTypes.bool,
	error: PropTypes.bool,
	errorMsg: PropTypes.string,
	errorState: PropTypes.bool,
	onContainer: PropTypes.bool,
	location: PropTypes.string,
	maxLimit: PropTypes.number,
}
