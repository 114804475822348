import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
//import CustomFrame from "../components/controllers/CustomFrame"

import {
	copyToClip,
	getIcons,
	getValidUrl,
	utilPPname,
} from "../../actions/generalUtils"
import { callBackground, passRespectPolicy } from "../tools/extensionUtils"

import { ReactComponent as Clone } from "../../assets/images/clone.svg"
import { ReactComponent as Place } from "../../assets/images/place.svg"
import { ReactComponent as Key } from "../../assets/images/key.svg"
import { ReactComponent as User } from "../../assets/images/user2.svg"
import { ReactComponent as Eye } from "../../assets/images/eye.svg"
import { ReactComponent as EyeClosed } from "../../assets/images/eyeClosed.svg"
import { ReactComponent as ErrorSign } from "../../assets/images/errorSign.svg"
import { Button } from "../../stories/buttons/Button"

export default function EditForm(p) {
	const [t] = useTranslation()

	const [faviconStep, setFaviconStep] = useState(1)
	const [password, setPassword] = useState("")
	const [passwordInputType, setPasswordInputType] = useState("password")

	let _passRespectPolicy = false
	if (password?.length) {
		if (p.credData?.pp)
			_passRespectPolicy = passRespectPolicy(password, p.credData.pp)
		else _passRespectPolicy = true
	}

	useEffect(() => {
		//console.log(p)
		if (p.ids?.[1]) setPassword(p.ids[1])
	}, [p])

	// Create the password when clicking on the submit button
	const handleSubmit = (event) => {
		event.preventDefault()

		if (!_passRespectPolicy) return
		else {
			callBackground(
				{ message: "LSpostPassLogs", credentialId: p.credData.id },
				() => {},
			)
			return p.saveIds({}, password)
		}

		/*if (passwordInputType === "password") {
			// First step -> we show password to user for validation
			return setPasswordInputType("text")
		} else {
			// Then we save it thru props function
			if (!_passRespectPolicy) return

			return p.saveIds({}, password)
		}*/

		/*// Define the mandatory keys in the payload. For now, category will be set exclusively on Personal space.
		const payload = {
			name : credentialName,
			domain : url,
			categoryId,
			username : login,
			password,
			isAlert : false,
			description : "",
			opt1 : "",
			opt2 : "",
			opt3 : ""
		}
		// If we are diplaying the optional fields, add the description and optionals to the payload.
		if (displayOptionals) {
			payload.description = description;
			payload.opt1 = optional1;
			payload.opt2 = optional2;
			payload.opt3 = optional3;
		}
		return dispatch({ type : "POPUP_CREATION_PASS_SAGA", payload })*/
	}

	// Hide/show the password if the user click on the show/add password
	const handleDisplayClick = (e) => {
		e.preventDefault()
		if (passwordInputType === "password")
			return setPasswordInputType("text")
		else return setPasswordInputType("password")
	}

	let cd = p.credData

	if (!cd) return null

	return (
		<div id="CreatePass" className="popupPage">
			<form>
				<div className="formContainer">
					<div className="passWithCatView">
						<span>
							<Place />
							<b>{cd.categoryName}</b>
						</span>

						<div className="passWithCatView-container">
							<div className="favIcon">
								{p.loadFavIcon
									? getIcons(
											{ name: p.name, domain: p.domain },
											getValidUrl,
											faviconStep,
											setFaviconStep,
									  )
									: null}
							</div>

							<div className="passBody">
								<b className="overflowCtrl twoLinesMax">
									{p.name}
								</b>
								<span className="overflowCtrl">
									<User />
									{p.ids[0]}
								</span>
							</div>
						</div>

						<small>{p.domain}</small>
					</div>

					<div className="inputAndLabel withLMargin">
						<label>
							{t("popup.edit.newPassword") +
								(p.credData.pp
									? ` (${utilPPname(p.credData.pp, t, true)})`
									: "")}
						</label>

						<div className="inputContainer">
							<div className="withButtonsInput">
								<input
									data-lockself="lockself"
									type={passwordInputType}
									placeholder={t("popup.create.password")}
									value={password}
									onChange={(event) =>
										setPassword(event.target.value)
									}
								/>
								<div className="inputGradient"></div>
							</div>

							<div className="buttonsContainer">
								<button onClick={handleDisplayClick}>
									{passwordInputType === "password" ? (
										<EyeClosed />
									) : (
										<Eye />
									)}
								</button>
								<button
									onClick={(event) => {
										event.preventDefault()
										return copyToClip(
											password,
											"popup.clipboard.copyPassword",
											true,
											"popup.clipboard.copyPasswordTitle",
										)
									}}
								>
									<Clone />
								</button>
							</div>
						</div>

						{!_passRespectPolicy && password?.length > 0 && (
							<div className="errorContainer">
								<ErrorSign />
								<b>{t("popup.passDoNotRespectPolicy")}</b>
							</div>
						)}
					</div>
				</div>

				<div className="buttons">
					<Button
						onClick={handleSubmit}
						type="submit"
						disabled={
							!_passRespectPolicy && passwordInputType === "text"
						}
						Icon={<Key />}
						label={t("popup.edit.saveBtn")}
					/>
				</div>
			</form>
		</div>
	)
}
