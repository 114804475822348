import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

import argon2Browser from "argon2-browser"

import { toastr } from "react-redux-toastr"
import I18n from "../i18n"
import { v4 as uuidv4 } from "uuid"

import { ReactComponent as Archive } from "../assets/icons/archive.svg"
import { ReactComponent as ArchiveDM } from "../assets/icons/archiveDM.svg"
import { ReactComponent as Excel } from "../assets/icons/excel.svg"
import { ReactComponent as ExcelDM } from "../assets/icons/excelDM.svg"
import { ReactComponent as Word } from "../assets/icons/word.svg"
import { ReactComponent as WordDM } from "../assets/icons/wordDM.svg"
import { ReactComponent as Code } from "../assets/icons/code.svg"
import { ReactComponent as CodeDM } from "../assets/icons/codeDM.svg"
import { ReactComponent as Audio } from "../assets/icons/audio.svg"
import { ReactComponent as AudioDM } from "../assets/icons/audioDM.svg"
import { ReactComponent as Pdf } from "../assets/icons/pdf.svg"
import { ReactComponent as PdfDM } from "../assets/icons/pdfDM.svg"
import { ReactComponent as Image } from "../assets/icons/image.svg"
import { ReactComponent as ImageDM } from "../assets/icons/imageDM.svg"
import { ReactComponent as Video } from "../assets/icons/video.svg"
import { ReactComponent as VideoDM } from "../assets/icons/videoDM.svg"
import { ReactComponent as Ppt } from "../assets/icons/powerpoint.svg"
import { ReactComponent as PptDM } from "../assets/icons/powerpointDM.svg"
import { ReactComponent as File2 } from "../assets/icons/file2.svg"
import { ReactComponent as File2DM } from "../assets/icons/file2DM.svg"
import { ReactComponent as Tableur } from "../assets/icons/tableur.svg"
import { ReactComponent as TableurDM } from "../assets/icons/tableurDM.svg"
import { ReactComponent as Text } from "../assets/icons/text.svg"
import { ReactComponent as TextDM } from "../assets/icons/textDM.svg"
import { ReactComponent as Empty } from "../assets/icons/empty.svg"
import { ReactComponent as EmptyDM } from "../assets/icons/emptyDM.svg"
import { ReactComponent as ErrorIcon } from "../assets/images/error.svg"
import { ReactComponent as WarnIcon } from "../assets/images/warn.svg"
import { ReactComponent as SuccessIcon } from "../assets/images/success.svg"
import { t } from "i18next"
import store from "../store"

const toastrOptions = {
	timeOut: 2000,
	closeOnToastrClick: true,
	showCloseButton: false,
	position: "top-center",
}

// Add a new saga in the redux store history.
export function addLCS(action) {
	try {
		let previousSagas = JSON.parse(localStorage.getItem("lastCalledSaga"))
		let result = []

		if (previousSagas?.length > 0) {
			result = [...previousSagas, action]
		} else {
			result.push(action)
		}

		localStorage.setItem("lastCalledSaga", JSON.stringify(result))
	} catch (e) {
		/*console.log(e)*/
	}
}

export function removeLCS(action) {
	try {
		let a = JSON.parse(localStorage.getItem("lastCalledSaga"))
		let b = []

		if (a) {
			a.map((item) => {
				if (item.type !== action.type) b.push(item)
			})
		}

		localStorage.setItem("lastCalledSaga", JSON.stringify(b))
	} catch (e) {
		/*console.log(e)*/
	}
}

export function utilPPname(d, t, noTitle = false) {
	let n = ""
	let first = true

	if (d.hasCapitalLetter) {
		first = false
		n += t("pass.aCat.maj")
	}

	if (d.hasLowercaseLetter) {
		if (first) {
			n += t("pass.aCat.min")
		} else {
			n += " - " + t("pass.aCat.min")
		}
		first = false
	}

	if (d.hasNumber) {
		if (first) {
			n += t("pass.aCat.num")
		} else {
			n += " - " + t("pass.aCat.num")
		}
		first = false
	}

	if (d.hasSpecialChar) {
		if (first) {
			n += t("pass.aCat.spec")
		} else {
			n += " - " + t("pass.aCat.spec")
		}
		first = false
	}

	if (noTitle) {
		return `${d.minNbChar} ${t("pass.aCat.to")} ${d.maxNbChar} ${t(
			"pass.aCat.chars",
		)} ${n ? "| " + n : ""}`
	} else {
		return `${d.name} | ${d.minNbChar} ${t("pass.aCat.to")} ${
			d.maxNbChar
		} ${t("pass.aCat.chars")} ${n ? "| " + n : ""}`
	}
}

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window
	return { width, height }
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions(),
	)

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions())
		}

		window.addEventListener("resize", handleResize)

		return () => window.removeEventListener("resize", handleResize)
	}, [])

	return windowDimensions
}

export function handleError(res, showToaster = true, message = false) {
	if (
		res?.code === 403 &&
		(res?.message.includes("Session -") || res?.message.includes("MFA"))
	) {
		toastr.warning(
			I18n.t("forServices.error"),
			res.message.replace("Session -", ""),
		)
		return "sessionExpired"
	} else if (res?.code === 403 && res?.message.includes("Session stop -")) {
		toastr.warning(
			I18n.t("forServices.error"),
			res.message.replace("Session stop -", ""),
			{ timeOut: false },
		)
		return "sessionDestroyed"
	} else if (
		res?.code === 401 &&
		(res?.message.includes("Session -") || res?.message.includes("JWT"))
	) {
		toastr.warning(
			I18n.t("forServices.error"),
			res.message.replace("Session -", ""),
			{ timeOut: false },
		)
		return "sessionDestroyed"
	} else if (
		res?.code === 401 &&
		res?.message.includes("Unable to load an user")
	) {
		toastr.warning(I18n.t("forServices.error"), res.message, {
			timeOut: false,
		})
		return "sessionDestroyed"
	} else if (res?.code === 400 && res?.message.includes("X-LS-Token")) {
		localStorage.removeItem("xLsToken")
		localStorage.removeItem("token")
		localStorage.removeItem("apiVersion")
		localStorage.removeItem("canSendSms")

		return (window.location = "/")
	} else if (res?.status === 500 && res?.msg === "userNotFound") {
		return toastr.error(
			I18n.t("forServices.error"),
			I18n.t("manage.userNotFound"),
		)
	} else {
		try {
			let a = JSON.parse(res.message)
			return toastr.error(
				a[0].propertyPath
					? a[0].propertyPath.charAt(0).toUpperCase() +
							a[0].propertyPath.slice(1)
					: I18n.t("forServices.error"),
				a[0].message,
			)
		} catch (e) {
			if (showToaster) {
				// If the message argument was specified, we want to add a message to the error, and
				// the toastr must be written differently with a custom component.
				if (message) {
					return toastr.error("", "", {
						component: (
							<div
								dangerouslySetInnerHTML={{
									__html: `<b>${message}</b><br />${res.message}`,
								}}
							/>
						),
					})
				}

				return toastr.error(I18n.t("forServices.error"), res.message)
			} else {
				return
			}
		}
	}
}

// Clear every keys in localStorage except for a few exceptions.
export function handleNetworkError() {
	const localStorageKeys = Object.keys(localStorage)
	const dataToKeep = {
		apiUrl: true,
		userEmail: true,
		userFavDura: true,
		darkMode: true,
		i18nextLng: true,
		LPrecentCats: true,
		sortBy: true,
		sortType: true,
	}

	for (let i = 0; i < localStorageKeys.length; i++) {
		if (dataToKeep[localStorageKeys[i]] === undefined) {
			localStorage.removeItem(localStorageKeys[i])
		}
	}
	return
}

// Copy the given value (val argument) into the clipboard and display a toast notification.
// The new toast system has been implemented. It requires the useToast and successTitle arguments.
// Since only a few components are working with the new system for now, useToast and successTitle are optional.
// A condition has been added to implement this feature without breaking the previous components.
export function copyToClip(
	val,
	successMsg = "forServices.copySucced",
	useToast,
	successTitle,
) {
	try {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(val)
		} else {
			window.clipboardData.setData("text/plain", val)
		}

		// Check for the useToast argument to determine which function to use.
		if (useToast) {
			// New toast notifications
			toast(I18n.t(successTitle), I18n.t(successMsg), "success")
		} else {
			// Old toast notifications
			toastr.success(I18n.t(successMsg))
		}
	} catch (e) {
		toastr.warning(I18n.t("forServices.copyFailed"))
	}
}

/*export function normalizePhoneNums(phones) {
	try {
		if (phones.length > 1) {
			if (!Array.isArray(phones)) phones = phones.split(";")
			let tmpPhone = ""
			let match = ""
			for (let i in phones) {
				phones[i] = phones[i].replace(/ /g, '')
				if (/\+([0-9]{11})/.exec(phones[i])) {
					tmpPhone += (phones.length > 0 ? "" : "") + phones[i] + ";"
				} else if (match === /0([0-9]{9})/.exec(phones[i])) {
					tmpPhone += (phones.length === 1 ? "" : "") + "+33" + match[1] + ";"
				}
			}
			return tmpPhone
		} else return phones
	} catch(e) {
		//console.log(e)
		return phones
	}
}*/

export function fixNanBytesFileSize(size) {
	if (size === "NAN bytes") {
		return "0 bytes"
	} else {
		return size
	}
}

export function mouseStyleHandler(dragging = false) {
	let follower, mouseX, mouseY, positionElement

	follower = document.getElementById("mouseFollower")

	mouseX = (event) => {
		return event.clientX
	}
	mouseY = (event) => {
		return event.clientY
	}

	positionElement = (event) => {
		let mouse
		mouse = {
			x: mouseX(event),
			y: mouseY(event),
		}
		if (follower?.style) {
			follower.style.top = mouse.y + 15 + "px"
			return (follower.style.left = mouse.x + 10 + "px")
		} else {
			return
		}
	}

	if (dragging) {
		window.ondragover = (event) => {
			let _event
			_event = event
			return setTimeout(() => {
				return positionElement(_event)
			}, 5)
		}
	} else {
		window.onmousemove = (event) => {
			let _event
			_event = event
			return setTimeout(() => {
				return positionElement(_event)
			}, 5)
		}
	}
}

// allows to cut the lists at each semicolon in the inputs of lockTransfert
export function splitListInput(textInput, callBack) {
	const match = textInput.split(";").filter((e) => e)

	if (textInput.indexOf(";")) {
		return (list) =>
			callBack([...list.filter((e) => String(e).trim()), ...match])
	} else {
		return (list) =>
			callBack([...list.filter((e) => String(e).trim()), textInput])
	}
}

// takes a mail list as a parameter will sort it between those that are valid and those that are not
export function sortOrderMailValidate(list) {
	const regex = /^[\w-zÀ-ÖØ-öø-ÿ-+\.]+@([\w--zÀ-ÖØ-öø-ÿ-]+\.)+[\w]{2,10}$/
	const goodList = []
	const wrongList = []

	list.forEach((address) => {
		if (regex.test(address)) {
			goodList.push(address)
		} else {
			wrongList.push(address)
		}
	})
	return wrongList.concat(goodList)
}

export function validateEmail(emailAddress) {
	const regex = /^[\w-zÀ-ÖØ-öø-ÿ+\.]+@([\w-]+\.)+[\w-]{2,}$/
	return regex.test(emailAddress)
}

export function regexEmoji(value) {
	const emojiRegex =
		/[\u{1F300}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{FE00}-\u{FEFF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}]/u
	return emojiRegex.test(value)
}

export function humanFileSize(bytes, si = false, dp = 1) {
	const thresh = si ? 1000 : 1024

	if (Math.abs(bytes) < thresh) return bytes + " b"

	const units = ["Ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"]
	/*const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']*/

	let u = -1
	const r = 10 ** dp

	do {
		bytes /= thresh
		++u
	} while (
		Math.round(Math.abs(bytes) * r) / r >= thresh &&
		u < units.length - 1
	)

	return bytes.toFixed(dp) + " " + units[u]
}

export function moOrGo(v) {
	if (!v) return null
	if (v >= 1000) {
		return v / 1000 + " Go"
	} else {
		return v + " Mo"
	}
}

export function getFileExtension(filename) {
	return /[.]/.exec(filename)
		? /[^.]+$/.exec(filename)?.[0].toLowerCase()
		: null
}

export function getFileNameNoExt(filename) {
	let ext = getFileExtension(filename)
	if (ext) {
		return filename.slice(0, -ext.length - 1)
	} else {
		return filename
	}
}

export function checkFileType(
	filename,
	isIcon = false,
	isDM = false,
	directExt = false,
) {
	let ext = directExt ? filename : getFileExtension(filename)
	switch (ext) {
		case "zip":
		case "rar":
		case "7z":
		case "tar":
		case "gz":
		case "icon-file-archive":
			return isIcon ? (
				isDM ? (
					<ArchiveDM />
				) : (
					<Archive />
				)
			) : (
				"icon-file-archive"
			)
		case "xlsx":
		case "xls":
		case "xlsm":
		case "xlsb":
		case "xltx":
		case "icon-file-excel":
			return isIcon ? isDM ? <ExcelDM /> : <Excel /> : "icon-file-excel"
		case "xml":
		case "csv":
		case "numbers":
		case "icon-file-tableur":
			return isIcon ? (
				isDM ? (
					<TableurDM />
				) : (
					<Tableur />
				)
			) : (
				"icon-file-tableur"
			)
		case "doc":
		case "docx":
		case "icon-file-word":
			return isIcon ? isDM ? <WordDM /> : <Word /> : "icon-file-word"
		case "odt":
		case "txt":
		case "pages":
		case "icon-file-text":
			return isIcon ? isDM ? <TextDM /> : <Text /> : "icon-file-text"
		case "js":
		case "php":
		case "c":
		case "py":
		case "rb":
		case "html":
		case "go":
		case "css":
		case "scss":
		case "config":
		case "icon-file-code":
			return isIcon ? isDM ? <CodeDM /> : <Code /> : "icon-file-code"
		case "riff":
		case "wav":
		case "bwf":
		case "ogg":
		case "aiff":
		case "caf":
		case "raw":
		case "mp3":
		case "wma":
		case "icon-file-audio":
			return isIcon ? isDM ? <AudioDM /> : <Audio /> : "icon-file-audio"
		case "pdf":
		case "icon-file-pdf":
			return isIcon ? isDM ? <PdfDM /> : <Pdf /> : "icon-file-pdf"
		case "bmp":
		case "tiff":
		case "gif":
		case "jpeg":
		case "png":
		case "jpg":
		case "ico":
		case "icon-file-image-o":
			return isIcon ? isDM ? <ImageDM /> : <Image /> : "icon-file-image-o"
		case "avi":
		case "mov":
		case "mpg":
		case "mp4":
		case "mkv":
		case "divx":
		case "icon-file-video":
			return isIcon ? isDM ? <VideoDM /> : <Video /> : "icon-file-video"
		case "pptx":
		case "pptm":
		case "ppt":
		case "key":
		case "icon-file-powerpoint":
			return isIcon ? isDM ? <PptDM /> : <Ppt /> : "icon-file-powerpoint"
		case "empty":
		case 0:
		case "icon-file-empty":
			return isIcon ? isDM ? <EmptyDM /> : <Empty /> : "icon-file-empty"
		default:
			return isIcon ? isDM ? <File2DM /> : <File2 /> : "icon-file"
	}
}

export function displayMVIcons(
	darkMode = false,
	list = [],
	supName = "",
	justMVP,
) {
	let arr = []
	//console.log(supName)

	if (supName?.length) arr.push(checkFileType(supName))

	if (list.length >= 1) {
		list.map((item) => {
			arr.push(checkFileType(item.name))
		})
	}

	let counts = []
	let exts = []

	arr.map((item) => {
		if (exts.includes(item)) {
			return
		} else {
			return exts.push(item)
		}
	})

	exts.map((item) => {
		counts.push({ ext: item, count: arr.filter((x) => x === item).length })
	})

	let sortedArr = counts.sort((a, b) =>
		a.count > b.count ? -1 : b.count > a.count ? 1 : 0,
	)

	if (justMVP) {
		if (sortedArr.length >= 1) {
			return checkFileType(sortedArr[0].ext, true, darkMode, true)
		} else {
			return checkFileType("empty", true, darkMode, true)
		}
	} else {
		if (sortedArr.length === 1) {
			return checkFileType(sortedArr[0].ext, true, darkMode, true)
		} else if (sortedArr.length === 2) {
			return (
				<div className="icon-container">
					{checkFileType(sortedArr[0].ext, true, darkMode, true)}
					{checkFileType(sortedArr[1].ext, true, darkMode, true)}
				</div>
			)
		} else if (sortedArr.length > 2) {
			return (
				<div className="icon-container">
					{checkFileType(sortedArr[0].ext, true, darkMode, true)}
					{checkFileType(sortedArr[1].ext, true, darkMode, true)}
					<b className="hasMore">{sortedArr.length > 2 ? "+" : ""}</b>
				</div>
			)
		} else {
			return (
				<div className="icon-container">
					{checkFileType("empty", true, darkMode, true)}
				</div>
			)
		}
	}
}

export function isInViewport(element) {
	if (typeof element === "string") element = document.getElementById(element)
	if (!element) return undefined
	const rect = element.getBoundingClientRect()

	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <=
			(window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <=
			(window.innerWidth || document.documentElement.clientWidth)
	)
}

export function AutoPaginated(props) {
	const [loading, _setLoading] = useState(false)
	const loadingRef = useRef(loading)
	const setLoading = (data) => {
		loadingRef.current = data
		_setLoading(data)
	}
	const [page, setPage] = useState()

	function onScroll() {
		let isAtEnd
		if (document.querySelector(".autoPaginatedLoader")) {
			isAtEnd = isInViewport(
				document.querySelector(".autoPaginatedLoader"),
			)
		}
		if (isAtEnd && !props.loading && !loadingRef.current) {
			setLoading(true)
			props.setLoading(true)
			props.load()
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", () => onScroll())
		return () => window.removeEventListener("scroll", onScroll)
	}, [])

	useEffect(() => {
		if (page !== props.page) {
			setPage(props.page)
			onScroll()
			setLoading(props.loading)
		}
	}, [props.page])

	useEffect(() => {
		setLoading(props.loading)
	}, [props.loading])

	return (
		<div className="autoPaginatedLoader">
			{props.loading && <span className="rippleLoader" />}
		</div>
	)
}

/*export function useElemOnScreen(options) {
	const containerRef = useRef(null)
	const [isVisible, setIsVisible] = useState(false)

	const callbackFunction = entries => {
		const [entry] = entries
		setIsVisible(entry.isIntersecting)
	}

	useEffect(() => {
		const observer = new IntersectionObserver(callbackFunction, options)
		if (containerRef.current) observer.oberve(containerRef.current)

		return () => {
			if (containerRef.current) observer.unobserve(containerRef.current)
		}
	}, [containerRef, options])
}*/

export function getFilesDataTransferItems(dataTransferItems) {
	let allFiles = []
	let rootFiles = []
	let allFolders = []
	let totalSize = 0

	function traverseFileTreePromise(
		item,
		path = "",
		files,
		folder,
		firstLoop,
	) {
		return new Promise((resolve) => {
			if (!item || item.name === ".DS_Store") {
				return resolve(null)
			} else {
				if (item.isFile) {
					return item.file((file) => {
						files.push(file)
						allFiles.push(file)
						totalSize += file.size
						if (firstLoop) rootFiles.push(file)
						resolve(file)
					})
				} else if (item.isDirectory) {
					let dirReader = item.createReader()

					dirReader.readEntries((entries) => {
						let entriesPromises = []
						let reps = []
						let files = []

						folder.push({ name: item.name, files, reps })
						allFolders.push({ name: item.name })
						if (entries) {
							for (let entr of entries) {
								entriesPromises.push(
									traverseFileTreePromise(
										entr,
										path || "" + item.name + "/",
										files,
										reps,
										false,
									),
								)
							}
						}
						resolve(Promise.all(entriesPromises))
					})
				}
			}
		})
	}

	let files = []
	let folder = []
	return new Promise((resolve) => {
		let entriesPromises = []
		for (let it of dataTransferItems) {
			entriesPromises.push(
				traverseFileTreePromise(
					it.webkitGetAsEntry(),
					null,
					files,
					folder,
					true,
				),
			)
		}

		Promise.all(entriesPromises).then(() => {
			resolve({ folder, allFiles, rootFiles, allFolders, totalSize })
		})
	})
}

export function versionAddDecimals(v) {
	if (!v) {
		return
	}

	const nbDeciVersion = 2

	let changes = new Array()
	let correctVersion = v
	let index = 0

	while (index !== -1) {
		const search = v.indexOf(".", index + 1)

		// for all the points except the first and the last
		if (search !== -1 && index !== 0) {
			const lengthNum = search - index - 1
			for (let i = 0; i < nbDeciVersion - lengthNum; i++) {
				changes.push(index + 1)
			}
		}

		// for the last dot
		if (search === -1) {
			const lastDot = v.length - index - 1
			for (let i = 0; i < nbDeciVersion - lastDot; i++) {
				changes.push(index + 1)
			}
		}
		index = search
	}

	changes
		.slice(0)
		.reverse()
		.map((e) => {
			return (correctVersion =
				correctVersion.slice(0, e) + "0" + correctVersion.slice(e))
		})
	return correctVersion
}

// Transform the api version string into a number so that it can be compared to a specific value.
export function treatVersion(v) {
	return parseInt(v?.replaceAll(".", ""))
}

export function definedRight(v) {
	// User/Group -> 0 -> Telecharger
	// User/Group Gestionnaire -> 0.5 -> Telecharger / Deplacer / Supprimer
	// Moderateur -> 1 -> Telecharger / Deplacer / Supprimer
	// Administrateur -> 2 -> Telecharger / Deplacer / Supprimer

	switch (v) {
		case 0:
			return 0
		case 0.5:
			return 0.5
		case 1:
			return 1
		case 2:
			return 2
	}
}

export function definedView(v) {
	const apiVersion = backwardCompApi("1.10.0")

	// avant 1.10 a 3.11 :
	// -1 -> caché
	// 0 -> droit de lecture
	// 1 -> droit de lecture, d'écriture et suppression
	//
	// apres 1.10 a 3.11 :
	// -1 -> caché
	// 0 -> droit de lecture
	// 1 -> droit de lecture, d'écriture
	// 2 -> droit de lecture, d'écriture et de suppression
	if (apiVersion) {
		switch (v) {
			case -1:
				return -1
			case 0:
				return 0
			case 1:
				return 1
			case 2:
				return 2
		}
	} else if (apiVersion) {
		switch (v) {
			case -1:
				return -1
			case 0:
				return 0
			case 1:
				return 2
			case 2:
				return 2
		}
	}
}

export function isCurrentUserModoOrAdmin(user = null) {
	const storeData = store.getState()
	const userType = storeData?.auth?.token?.userSession?.userType
	const isAdmin = storeData?.auth?.token?.userSession?.isAdmin
	const isSuperAdmin = storeData?.auth?.token?.userSession?.isSuperAdmin

	if (!!user) {
		if (typeof user !== "object") {
			return false
		}

		if ("protected" in user) {
			return [1, 2]?.includes(user?.protected)
		} else if ("right" in user) {
			return [1, 2]?.includes(user?.right)
		} else {
			return false
		}
	}

	return !!userType
		? ["isAdmin", "isModerator", "isSuperAdmin"]?.includes(userType)
		: isAdmin || isSuperAdmin
}

export function getUserRights(userOrArray, product, data = null) {
	const storeStateData = store.getState()
	const userEmail = storeStateData?.auth?.token?.userSession?.userEmail
	const clonedData = !!data ? { ...data } : null
	let currentUser
	let userRightsObject = {}

	if (Array.isArray(userOrArray)) {
		if (!!userEmail) {
			const currentUserFromArray = userOrArray?.find(
				(user) => user?.email === userEmail,
			)
			const currentUserGroupsFromArray = userOrArray?.filter(
				(userItem) => {
					if (userItem?.email === "Group" && userItem?.isInGroup) {
						return true
					} else {
						return false
					}
				},
			)

			if (currentUserGroupsFromArray?.length > 0) {
				const currentUserGroup = currentUserGroupsFromArray?.reduce(
					(previousValue, currentValue) => {
						return currentValue?.protected >=
							previousValue?.protected
							? currentValue
							: previousValue
					},
					currentUserGroupsFromArray?.[0],
				)

				if (
					(currentUserFromArray?.protected === 0.5 &&
						currentUserGroup?.protected === 3) ||
					currentUserFromArray?.protected === 1 ||
					currentUserFromArray?.protected === 2
				) {
					currentUser = currentUserFromArray
				} else {
					currentUser = currentUserGroup
				}
			} else {
				currentUser = currentUserFromArray
			}
		} else {
			return null
		}
	} else {
		currentUser = userOrArray
	}

	userRightsObject.user = currentUser

	const clonedUser = { ...currentUser }
	const canCreateKeys = {
		lockpass: {
			repository: "canCreateCategory",
			content: "canCreatePassword",
			context: "passwordOnly",
		},
		lockfiles: {
			repository: "canCreateFolder",
			content: "canCreateFile",
			context: "fileOnly",
		},
	}

	// retro compat on user key naming
	if (!backwardCompApi("1.19.4")) {
		clonedUser.protected = clonedUser?.right || 0

		if ("right" in clonedUser) {
			delete clonedUser.right
		}
	}

	// if current user is admin or modo he has all the rights
	// check comment above function for more informations
	if (isCurrentUserModoOrAdmin() || isCurrentUserModoOrAdmin(clonedUser)) {
		if (["lockpass", "lockfiles"].includes(product)) {
			userRightsObject = {
				...userRightsObject,
				[canCreateKeys[product].content]: true,
				[canCreateKeys[product].repository]: true,
			}
		}

		return {
			...userRightsObject,
			canSee: true,
			canRead: true,
			canUpdate: true,
			canDelete: true,
			hasAllRights: true,
			protected: clonedUser?.protected,
		}
	}

	// retro compat on data.view max value
	if (!backwardCompApi("1.10.0") && !!clonedData && "view" in clonedData) {
		clonedData.view = clonedData?.view === 1 ? 2 : clonedData?.view
	}

	if (!!clonedData && "view" in clonedData) {
		// check comment above function for more informations
		return {
			canSee: clonedData?.view >= 0,
			canRead: clonedData?.view >= 0,
			canUpdate: clonedData?.view >= 1,
			canDelete: clonedData?.view >= 2,
			protected: clonedData?.protected,
			hasAllRights: clonedData?.view >= 2,
			context: canCreateKeys[product].context,
		}
	}

	// if user/group is manager then it has all the rights
	if ([0.5, 4].includes(clonedUser?.protected) || clonedUser?.isManager) {
		if (["lockpass", "lockfiles"].includes(product)) {
			userRightsObject = {
				...userRightsObject,
				[canCreateKeys[product].content]: true,
				[canCreateKeys[product].repository]: true,
			}
		}

		// check comment above function for more informations
		return {
			...userRightsObject,
			canSee: true,
			canRead: true,
			canUpdate: true,
			canDelete: true,
			hasAllRights: true,
			protected: clonedUser?.protected,
		}
	}

	// the values below are for all basic users and groups
	if (["lockpass", "lockfiles"].includes(product)) {
		userRightsObject = {
			...userRightsObject,
			[canCreateKeys[product].content]: true,
			[canCreateKeys[product].repository]: false,
		}
	}

	// check comment above function for more informations
	return {
		...userRightsObject,
		canSee: true,
		canRead: true,
		canUpdate: false,
		canDelete: false,
		hasAllRights: false,
		protected: clonedUser?.protected,
	}
}

/* --------------------------- END GET_USER_RIGHTS -------------------------- */

export function backwardCompApi(v) {
	const apiVersion = treatVersion(
		versionAddDecimals(
			window.localStorage.getItem("apiVersion")
				? window.localStorage.getItem("apiVersion")
				: "1.1.1",
		),
	)
	const versionLimit = treatVersion(versionAddDecimals(v))

	if (apiVersion >= versionLimit) {
		return true
	} else {
		return false
	}
}

// Takes a time value (in ms) and convert it into a delay.
// This comes handy when we must display the remaining time to the user.
export function msToText(time) {
	if (time === null) return

	let result = ""
	let remainingTime = time
	let hour
	let minute
	let second

	// The nearestSecondToDisplay function takes a number and give the closest number finishing by 0 or 5.
	const nearestSecondToDisplay = (number) => {
		const smallerValue = Math.floor(number / 5) * 5
		const greaterValue = smallerValue + 5

		const inferiorOffset = number - smallerValue
		const superiorOffset = greaterValue - number

		if (inferiorOffset < superiorOffset) {
			return smallerValue
		} else {
			return greaterValue
		}
	}

	// If the time is inferior to a second, return a specific string.
	if (time < 1000) return "0 s"

	const lesserThanDay = (value) => {
		if (value < 86400000) {
			return true
		} else {
			return false
		}
	}

	const lesserThanHour = (value) => {
		if (value < 3600000) {
			return true
		} else {
			return false
		}
	}

	const lesserThanMinute = (value) => {
		if (value < 60000) {
			return true
		} else {
			return false
		}
	}

	const lesserThanSecond = (value) => {
		if (value < 1000) {
			return true
		} else {
			return false
		}
	}

	if (lesserThanDay(remainingTime)) {
		if (!lesserThanHour(remainingTime)) {
			hour = Math.floor(remainingTime / 3600000)
			remainingTime -= hour * 3600000
			result += hour + " " + t("time.hourMin")
		}

		if (!lesserThanMinute(remainingTime)) {
			minute = Math.floor(remainingTime / 60000)
			// If there is more than 2 minutes remaining, we don't display the seconds an therefore must increment the minute value.
			if (time >= 120000) minute += 1

			remainingTime -= minute * 60000
			if (hour) {
				result += " " + minute + " " + t("time.minuteMin")
			} else {
				result += minute + " " + t("time.minuteMin")
			}

			// If there is more than 2 minutes remaining, return the result and don't show seconds.
			if (time >= 120000) return result
		}

		if (!lesserThanSecond(remainingTime)) {
			second = Math.floor(remainingTime / 1000)
			remainingTime -= second * 1000
			if (hour || minute) {
				result +=
					" " +
					nearestSecondToDisplay(second) +
					" " +
					t("time.secondMin")
			} else {
				result +=
					nearestSecondToDisplay(second) + " " + t("time.secondMin")
			}
		}
	} else {
		result += t("time.tooLong")
	}

	return result
}

// Open a download window to allow the user to dowload a file given as an argument.
// Require, as arguments, the file to download (you’ll have to create the blob before calling this function) and its name.
export function downloadFiles(file, fileName) {
	let url = URL.createObjectURL(file)
	let link = document.createElement("a")
	link.id = "downloadLink"
	link.setAttribute("href", url)
	link.setAttribute("download", fileName)
	document.body.appendChild(link)

	link.click()
	link.remove()
	return
}

export function toast(title, desc, type) {
	switch (type) {
		case "error":
			toastr.error(title, desc, { ...toastrOptions, icon: <ErrorIcon /> })
			break
		case "warn":
			toastr.warning(title, desc, {
				...toastrOptions,
				icon: <WarnIcon />,
			})
			break
		default:
			toastr.success(title, desc, {
				...toastrOptions,
				icon: <SuccessIcon />,
			})
			break //: “success”
		//default : toastr.info(title,desc,toastrOptions); break;
	}
}

export function getValidUrl(url = "") {
	try {
		let newUrl = window.decodeURIComponent(url)
		newUrl = newUrl.trim().replace(/\s/g, "")

		if (/^(:\/\/)/.test(newUrl)) {
			newUrl = `http${newUrl}`
		} else if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
			newUrl = `http://${newUrl}`
		}

		return newUrl
	} catch (e) {
		return ""
	}
}

export function getIcons(
	passwordData,
	getValidUrl,
	faviconStep,
	setFaviconStep,
) {
	let firstLetter = passwordData.name.slice(0, 1)

	if (!firstLetter) firstLetter = "?"

	let linkUrl = ""
	if (passwordData.domain) linkUrl = getValidUrl(passwordData.domain)

	if (linkUrl) {
		try {
			let hostname = new URL(linkUrl).hostname

			// Step 1 = add /favicon.icon to the end of url
			// Step 2 = If the favicon doesn't load with /favicon added at the end of url
			// it probably means that the url has a sub-domain in it, so try to remove it with getMainDomain
			// Step 3 = If it still doesn't work then use the initial.
			if (faviconStep === 3) {
				return <img src="" alt={firstLetter} className="badIcon" />
			} else {
				return (
					<img
						className="icon"
						src={`https://${hostname}/favicon.ico`}
						alt={firstLetter}
						onError={(event) => {
							setFaviconStep((faviconStep) => faviconStep + 2)
							event.target.classList.add("badIcon")
						}}
					/>
				)
			}
		} catch (e) {
			return <div className="noIcon badIcon">{firstLetter}</div>
		}
	} else {
		return <div className="noIcon badIcon">{firstLetter}</div>
	}
}

function validate(validations, values) {
	const errors = validations
		.map((validation) => validation(values))
		.filter((validation) => typeof validation === "object")
	return {
		isValid: errors.length === 0,
		errors: errors.reduce((errors, error) => ({ ...errors, ...error }), {}),
	}
}

export function isRequired(value) {
	return value != null && value.trim().length > 0
}

export function minSize(value, size) {
	return value != null && value.trim().length > size
}

export function maxSize(value, size) {
	return value != null && value.trim().length < size
}

export function charAllow(value) {
	const res = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\.-]+$/.exec(value)
	const valid = !!res
	return valid
}

// Check if the value of a form field is the same as the value of another field when the user is editing a profile.
export function checkFormSameValue(val, val2, val3, val4, val5) {
	let removeStringSpace = /\s+/g
	let trimName = val.replace(removeStringSpace, "").toLowerCase()
	let concatValues = val2
		.concat(" ", val3)
		.replace(removeStringSpace, "")
		.toLowerCase()

	if (val4 !== val2) {
		return val4.trim() === val2.trim()
	} else if (val5 !== val3) {
		return val5.trim() === val3.trim()
	} else {
		return trimName === concatValues
	}
}

// Construct an array suitable for the slider. It takes an offset as an argument : it is the number of elements we want to display simultaneously in the slider.
export function constructArrayForSlider(array, offset) {
	return array.reduce(
		(prev, next) => {
			const current = [...prev]

			// Check if we are starting to populate a new slide.
			if (prev[prev.length - 1].length === offset) {
				current.push([next])
				return current
			} else {
				current[current.length - 1].push(next)
				return current
			}
		},
		[[]],
	)
}

// Add the start of a base64 file for images.
export function rebuildBase64(partial64, type, format) {
	return `data:${type}/${format};base64,${partial64}`
}

// Determine whether we're looking for a prod, dev, staging or local url : the Api version.
export function getApiUrl() {
	const host = window.location.host
	let resultUrl

	if (/localhost/.test(host) || /api.develop.lockself/.test(host)) {
		resultUrl = process.env.REACT_APP_API_DEV_URL
	} else if (/api.staging.lockself/.test(host)) {
		resultUrl = process.env.REACT_APP_API_STAGING_URL
	} else if (/127.0.0.1/.test(host)) {
		resultUrl = process.env.REACT_APP_API_LOCAL_URL
	} else {
		resultUrl = process.env.REACT_APP_API_URL
	}

	return resultUrl
}

// Determine whether we're looking for a prod, dev, staging or local url : the License version.
export function getLicenseUrl() {
	const host = window.location.host
	const storedApiUrl = localStorage.getItem("apiUrl")

	let urlToTest = host

	if (storedApiUrl) urlToTest = storedApiUrl

	let resultUrl

	// As you can see, there's no localhost for this function, this is not a mistake;
	// accounts created on api.develop.lockself are binded to the license's production controler.
	// We only want to work on license.staging.lockself when we're working with api.staging.lockself.
	if (/127.0.0.1/.test(urlToTest)) {
		resultUrl = process.env.REACT_APP_LICENSE_LOCAL_URL
	} else if (
		/api.staging.lockself/.test(urlToTest) ||
		/api.develop.lockself/.test(urlToTest) ||
		/localhost/.test(urlToTest)
	) {
		resultUrl = process.env.REACT_APP_LICENSE_STAGING_URL
	} else if (/apibo.develop.lockself.com/.test(urlToTest)) {
		resultUrl = process.env.REACT_APP_LICENSE_BTEST_URL
	} else {
		resultUrl = process.env.REACT_APP_LICENSE_URL
	}
	return resultUrl
}

// Determine whether we're looking for a prod, dev, staging or local url : the external version.
export function getExternalUrl() {
	const host = window.location.host

	let resultUrl

	if (/localhost/.test(host)) {
		resultUrl = process.env.REACT_APP_EXTERNAL_DEV_URL
	} else if (/api.staging.lockself/.test(host)) {
		resultUrl = process.env.REACT_APP_EXTERNAL_STAGING_URL
	} else if (/127.0.0.1/.test(host)) {
		resultUrl = process.env.REACT_APP_EXTERNAL_LOCAL_URL
	} else {
		resultUrl = process.env.REACT_APP_EXTERNAL_URL
	}

	return resultUrl
}

// will add a space every 3 digits 1000 => 1 000
export function numberWithSpaces(number) {
	if (number === null || number === undefined) return 0

	let parts = number.toString().split(".")
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
	return parts.join(".")
}

// this function generate a random string with the length and the type of characters you want to use
export function generatePassword(policy) {
	const charsMaj = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
	const charsMin = "abcdefghijklmnopqrstuvwxyz"
	const charsNum = "0123456789"
	const charsSpec = "!@#$%^&*()_+~`|}{[]:;?><,./-="

	let minGeneratePassForPDM = policy.minNbChar <= 0 ? 1 : policy.minNbChar
	let chars = ""
	let password = ""

	if (policy.hasCapitalLetter) chars += charsMaj
	if (policy.hasLowercaseLetter) chars += charsMin
	if (policy.hasNumber) chars += charsNum
	if (policy.hasSpecialChar) chars += charsSpec
	if (
		!policy.hasCapitalLetter &&
		!policy.hasLowercaseLetter &&
		!policy.hasNumber &&
		!policy.hasSpecialChar &&
		minGeneratePassForPDM
	) {
		chars += charsMin
	}

	for (let i = 0; i < minGeneratePassForPDM; i++) {
		password += chars.charAt(Math.floor(Math.random() * chars.length))
	}
	return password
}

// this function check every character in a password and classify by type (number, uppercase, lowercase & special character)
export function categorizeCharacters(password) {
	const numbers = []
	const uppercaseLetters = []
	const lowercaseLetters = []
	const specialCharacters = []
	// the special characters we need to check
	const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

	//loop to classify
	for (let i = 0; i < password.length; i++) {
		const character = password[i]

		if (isNaN(character)) {
			if (
				character === character.toUpperCase() &&
				!specialChars.test(character)
			) {
				uppercaseLetters.push(character)
			} else if (
				character === character.toLowerCase() &&
				!specialChars.test(character)
			) {
				lowercaseLetters.push(character)
			} else {
				specialCharacters.push(character)
			}
		} else {
			numbers.push(character)
		}
	}

	// return an object of array
	return { numbers, uppercaseLetters, lowercaseLetters, specialCharacters }
}

// function to calculate the strength of a password
export function calculPasswordForce(password) {
	if (!password) return
	let combinaison = 0
	let bits = 0
	let force = ""

	//complexity of characters by types
	const lowercaseChars = 26
	const uppercaseChars = 26
	const numberChars = 10
	const specialChars = 30

	//classify characters by types
	const characterCategories = categorizeCharacters(password)

	//check number of different characters that can be used in the password
	if (characterCategories.numbers.length > 0) {
		combinaison += numberChars
	}
	if (characterCategories.lowercaseLetters.length > 0) {
		combinaison += lowercaseChars
	}
	if (characterCategories.uppercaseLetters.length > 0) {
		combinaison += uppercaseChars
	}
	if (characterCategories.specialCharacters.length > 0) {
		combinaison += specialChars
	}
	// The number of bits is calculated using the following formula:
	// bits = log2(characters^length)
	// Where character is the number of different characters that can be used in the password
	// Length is the password length
	// bits = Math.round(Math.log2(Math.pow(combinaison, password.length)))
	// Math.pow can return "infinity" so we decide to log2 the conbinaison then multiply with the length

	let log = Math.log2(combinaison)
	bits = Math.round(log * password.length)
	// We do the same calculation as the ANSII

	// if bits is lower than 80 : the password is easy to hack
	// if bits is between 80 and 100 : the password can be hacked
	// if it's higher than 100 : the password can't be hacked (it can, but it will take days and days)
	if (bits <= 80) {
		force = "low"
	} else if (bits > 80 && bits < 100) {
		force = "medium"
	} else if (bits > 100) {
		force = "high"
	} else {
		force = null
	}

	return force
}

export function booleanString2Boolean(booleanString) {
	if (!booleanString || booleanString === "false") return false
	return true
}

export function convertBytes(bytes) {
	const sizes = ["octets", "Ko", "Mo", "Go", "To"]
	const i = Math.floor(Math.log(bytes) / Math.log(1024))

	if (bytes === 0) {
		return "0 octets"
	} else if (i >= sizes.length) {
		const number = (bytes / 1024 ** sizes.length).toFixed(1)
		return `${number} ${sizes[sizes.length - 1]}`
	} else {
		const number = (bytes / 1024 ** i).toFixed(1)
		return `${number} ${sizes[i]}`
	}
}

export function detectb64(wLogo) {
	if (wLogo.includes("base64")) {
		return wLogo
	} else {
		return `data:image/svg+xml;base64,${wLogo}`
	}
}

// The sleep function allows another function to be put on hold for a set amount of time.
// To use it, you can either put it in an async function and use await, or chain what
// your function must do in a then() callback that chain after the sleep.
export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

export function checkPinComplexity(pin) {
	const pinTab = pin.split("")

	if (pinTab.every((pin) => pin === pinTab[0])) {
		return false
	} else {
		for (let i = 1; i < 6; i++) {
			if (
				pinTab[i] - pinTab[i - 1] !== 1 &&
				pinTab[i] - pinTab[i - 1] !== -1
			) {
				return true
			}
		}
	}

	return false
}

export function checkUserRight(data, userEmail, setHaveRights) {
	// If the user's is at Lockfiles root, there will be no user stored in data, we must check their existence.
	if (data && data?.users) {
		let currentUser = data.users.filter(
			(item) => item.email === userEmail || item.isInGroup,
		)[0]

		if (currentUser !== undefined) {
			// The user can be a normal user, in which case the user email must match, or part of a group, in which case the group will have the key isInGroup as true.
			const filteredUsers = data.users.filter(
				(item) => item.email === userEmail || item.isInGroup,
			)
			// If the current user has only a status of user OR group, things are simple, the filter just returned one result.
			if (filteredUsers.length === 1) {
				currentUser = filteredUsers[0]
			} else {
				// The the user has a user status AND is part of a group, we extract info only if he have some rights
				filteredUsers.forEach((item) => {
					if (backwardCompApi("1.19.4")) {
						if (item.isManager || item.protected > 0) {
							currentUser = item
						}
					} else {
						if (item.isManager || item.right > 0) {
							currentUser = item
						}
					}
				})
			}
		}

		if (backwardCompApi("1.19.4")) {
			if (
				currentUser &&
				((currentUser.protected && currentUser.protected !== 0) ||
					currentUser.isManager)
			) {
				setHaveRights(true)
			} else {
				setHaveRights(false)
			}
		} else {
			if (
				currentUser &&
				((currentUser.right && currentUser.right !== 0) ||
					currentUser.isManager)
			) {
				setHaveRights(true)
			} else {
				setHaveRights(false)
			}
		}
	} else {
		return setHaveRights(false)
	}
}

export function arrayToCsv(data) {
	const header = [
		"Name",
		"Url",
		"Username",
		"Password",
		"Category/SubCategory",
		"Tags",
		"Description",
		"Opt1",
		"Opt2",
		"Opt3",
		"Totp",
	]

	const csv = data.map((row) => {
		const contentArray = row.content.split(";") // Split the content string into an array

		return contentArray
			.map((item) => {
				return item // Return the item as is
			})
			.join(";")
	})

	csv.unshift(header.join(";"))
	return csv.join("\n")
}

// This array will hold all the callback functions that are subscribed to time updates.
export let subscribers = []

// This function allows other parts of the code to subscribe to time updates.
// It takes a callback function as a parameter, which will be called whenever a time update is published.
export function subscribeToTimeUpdates(callback) {
	// The callback is added to the list of subscribers.
	subscribers.push(callback)

	// The function returns another function that allows the caller to unsubscribe.
	// This is a common pattern in JavaScript for event listeners or other types of subscriptions.
	return function unsubscribe() {
		// Find the index of the callback in the subscribers array.
		const index = subscribers.indexOf(callback)
		// If the callback was found in the subscribers array, remove it.
		if (index !== -1) {
			subscribers.splice(index, 1)
		}
	}
}

// This function is used to publish time updates to all subscribers.
export function publishTimeUpdate(time) {
	// For each callback in the subscribers array, call the callback with the new time.
	for (let callback of subscribers) {
		callback(time)
	}
}

export function secondsToTimeFormat(seconds) {
	const min = Math.floor(seconds / 60)
	const sec = Math.round(seconds % 60)
	return `${min}min ${sec}sec`
}

export function decodeBase64(encodedArray) {
	if (!Array.isArray(encodedArray)) {
		throw new Error("Expected an array of encoded strings.")
	}

	return encodedArray.map((encodedString) => {
		try {
			// Decoding base64 ⇢ UTF-8
			return decodeURIComponent(
				atob(encodedString)
					.split("")
					.map((c) => {
						return (
							"%" +
							("00" + c.charCodeAt(0).toString(16)).slice(-2)
						)
					})
					.join(""),
			)
		} catch (error) {
			console.error(
				`Invalid Base64 string: ${encodedString} \n ${error.message}`,
			)
			return null // ou vous pouvez retourner la chaîne originale ou une autre valeur par défaut
		}
	})
}

// Remove sub-domain from url to get favicon.
export function getMainDomain(domain) {
	const indexFirstDot = domain.lastIndexOf(".")
	const indexSecondDot = domain.lastIndexOf(".", indexFirstDot - 1)

	if (indexSecondDot == -1) {
		return domain
	}

	return domain.substring(indexSecondDot + 1)
}

export function checkPhonesLength(phoneNumberList, phoneValidLength) {
	if (phoneNumberList.length <= 0) {
		return
	}
	phoneNumberList.map((phoneNumber) => {
		if (phoneNumber.replace("+", "").length !== phoneValidLength) {
			return
		}
	})

	return true
}

export async function requestLoop(apiRequestFunction, apiRequestPayload) {
	let taskCompletion = "pending"
	let data = {}

	while (!["completed", "failed"].includes(taskCompletion)) {
		const taskData = await apiRequestFunction(apiRequestPayload)

		switch (taskData) {
			case "sessionExpired":
			case "sessionDestroyed":
			case true:
				data = taskData
				taskCompletion = "failed"
			default: {
				async function wait() {
					await sleep(500)
				}

				if (!taskData?.uuid && !taskData?.eventName) {
					taskCompletion = "completed"
					data = taskData
				} else {
					await wait()
				}
			}
		}
	}

	return data
}

export function mergeObjects(initialObject, objectToMerge) {
	const newTreeData = objectToMerge?.tree?.[0]

	if (!!newTreeData) {
		newTreeData?.forEach((newBranch) => {
			if (newBranch?.parentId !== 0) {
				const mainBranch = initialObject?.tree?.find(
					(mainBranch) => mainBranch?.id === newBranch?.mainId,
				)
				const mainBranchIndex = initialObject?.tree?.findIndex(
					(branch) => branch?.id === mainBranch?.id,
				)

				function updateMainBranchChildren(childBranches) {
					return childBranches?.map((childBranch) => {
						if (childBranch?.parentId === newBranch?.parentId) {
							childBranch.children = [
								...childBranch.children,
								newBranch,
							]
						} else {
							childBranch.children = updateMainBranchChildren(
								childBranch.children,
							)
						}

						return childBranch?.children
					})
				}

				if (mainBranch?.children?.length > 0) {
					initialObject.tree[mainBranchIndex].children =
						updateMainBranchChildren(mainBranch?.children)
				}
			} else {
				initialObject.tree = [...initialObject?.tree, newBranch]
			}
		})
	}

	return initialObject
}

export async function requestLoopTreeStructure(
	apiRequestFunction,
	apiRequestPayload,
) {
	let taskCompletion = "pending"
	let pagination = 1
	let data = {}

	while (!["completed", "failed"].includes(taskCompletion)) {
		const taskData = await apiRequestFunction({
			...apiRequestPayload,
			pagination,
		})

		switch (taskData) {
			case "sessionExpired":
			case "sessionDestroyed":
			case true:
				data = taskData
				taskCompletion = "failed"
			default: {
				async function wait() {
					await sleep(500)
				}

				if (!!data?.tree) {
					data = mergeObjects(data, taskData)
				} else {
					data = taskData
				}

				if (pagination === taskData?.pagination) {
					taskCompletion = "completed"
				} else {
					pagination += 1

					await wait()
				}
			}
		}
	}

	return data
}

AutoPaginated.propTypes = {
	loading: PropTypes.bool,
	setLoading: PropTypes.func,
	load: PropTypes.func,
	page: PropTypes.number,
}

/* ------------------------------ CUSTOM HOOKS ------------------------------ */

export function useForm(initialState = {}, validations = []) {
	const { isValid: initialIsValid, errors: initialErrors } = validate(
		validations,
		initialState,
	)
	const [touched, setTouched] = useState({})
	const [values, setValues] = useState(initialState)
	const [errors, setErrors] = useState(initialErrors)
	const [isValid, setValid] = useState(initialIsValid)

	useEffect(() => {
		if (touched !== {}) {
			setTouched({})
		}
	}, [])

	const changeHandler = ({ value, name }) => {
		const newValues = { ...values, [name]: value.trimStart() }
		const { isValid, errors } = validate(validations, newValues)
		setValues(newValues)
		setErrors(errors)
		setValid(isValid)
		setTouched({ ...touched, [name]: true })
	}

	return { values, errors, touched, isValid, changeHandler }
}

export function useInViewport(tar, parTar) {
	const [inViewport, setInViewport] = useState(isInViewport(tar))

	function handleVP() {
		return setInViewport(isInViewport(tar))
	}

	useEffect(() => {
		document.getElementById(parTar).addEventListener("scroll", handleVP)
		return () => {
			document
				.getElementById(parTar)
				?.removeEventListener("scroll", handleVP)
		}
	}, [])

	return inViewport
}

export function useInterval(callback, delay) {
	const savedCallback = useRef()

	// Remember the latest function.
	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current()
		}

		if (delay !== null) {
			let id = setInterval(tick, delay)
			return () => clearInterval(id)
		}
	}, [delay])
}

export function useObserver(callback, element) {
	const current = element && element.current
	const observer = useRef(null)

	useEffect(() => {
		// If we are already observing element
		if (observer && observer.current && current) {
			observer.current.unobserve(current)
		}
		const resizeObserverOrPolyfill = ResizeObserver
		observer.current = new resizeObserverOrPolyfill(callback)
		observe()

		return () => {
			if (observer && observer.current && element && element.current) {
				observer.current.unobserve(element.current)
			}
		}
	}, [current])

	const observe = () => {
		if (element && element.current && observer.current) {
			observer.current.observe(element.current)
		}
	}
}

export function useHover() {
	const [isHovering, setIsHovering] = React.useState(false)

	const handleMouseOver = React.useCallback(() => setIsHovering(true), [])
	const handleMouseOut = React.useCallback(() => setIsHovering(false), [])

	const nodeRef = React.useRef()

	const callbackRef = React.useCallback(
		(node) => {
			if (nodeRef.current) {
				nodeRef.current.removeEventListener(
					"mouseenter",
					handleMouseOver,
				)
				nodeRef.current.removeEventListener(
					"mouseleave",
					handleMouseOut,
				)
			}

			nodeRef.current = node

			if (nodeRef.current) {
				nodeRef.current.addEventListener("mouseenter", handleMouseOver)
				nodeRef.current.addEventListener("mouseleave", handleMouseOut)
			}
		},
		[handleMouseOver, handleMouseOut],
	)

	return [callbackRef, isHovering]
}

// The next 2 functions are used for time estimation. They are used to generate an ID that depends on the file size and name.
export function readFileContent(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = (event) => resolve(event.target.result)
		reader.onerror = (error) => {
			console.warn(error)
			reject(error)
		}
		reader.readAsArrayBuffer(file)
	})
}

// Generate a specific ID with a given file. This function guaranty a specific file will constantly have a fixed ID
// without having to pass it though functions calls and dispatches.
// The calculated ID depends on the following things : file name, file content and last modification date (to prevent dupplicate files having the same ID)
// This function isn't used anywhere in the webapp for now, I'm keeping it just in case.
// It was scraped in favor of the generateFileHashWithoutContent function, which doesn't read files content.
// As generateFileHash is waaaaaay slower than generateFileHashWithoutContent since it doesn't need to read files content, the tradeoff is that it has an easier time differentiating similar files.
export async function generateFileHash(file, prefix = "") {
	const fileContent = await readFileContent(file)
	const hashBuffer = await crypto.subtle.digest("SHA-256", fileContent)
	const hashArray = Array.from(new Uint8Array(hashBuffer))
	let hashHex = hashArray
		.map((byte) => byte.toString(16).padStart(2, "0"))
		.join("")
	hashHex += `${prefix}-${file.name}${file.lastModified}`
	return hashHex
}

export function generateFileHashWithoutContent(file) {
	return `${file.name}_${file.lastModified}`
}

export function generateFolderDataString(folder) {
	let combinedFilesHash = folder.name
	let files = folder.files
	if (folder.rootFiles) files = folder.rootFiles

	for (let i = 0; i < files?.length; i++) {
		combinedFilesHash += `|${generateFileHashWithoutContent(
			folder.files[i],
		)}`
	}

	for (let i = 0; i < folder.reps?.length; i++) {
		combinedFilesHash += `/${folder.reps[i].name}/${generateFolderHash(
			folder.reps[i].name,
		)}`
	}

	return combinedFilesHash
}

export function generateFolderHash(folder) {
	const folderDataString = generateFolderDataString(folder)
	const dataBuffer = new TextEncoder().encode(folderDataString)
	const dataHashArray = Array.from(new Uint8Array(dataBuffer))
	return dataHashArray
		.map((byte) => byte.toString(16).padStart(2, "0"))
		.join("")
}

export async function generateHashesFromTree(tree) {
	const result = []
	let newHash

	for (let i = 0; i < tree.files?.length; i++) {
		newHash = await generateFileHashWithoutContent(tree.files[i])
		result.push({ file: tree.files[i], id: newHash })
	}

	for (let i = 0; i < tree.reps?.length; i++) {
		newHash = generateFolderHash(tree.reps[i])
		result.push({ file: tree.reps[i], id: newHash })
		const newHashes = await generateHashesFromTree(tree.reps[i])
		result.push(...newHashes)
	}

	return result
}

// Extract the current page from the URL.
// Its purpose is for sagas that can be triggered from different pages,
// like getLicenseInfo.
export function getCurrentPage(returnType) {
	try {
		let result = false
		const location = window.location.href
		const regex = /(?<=#\/)(.*?)(?=\/)|(?<=#\/)(.*?)(?=$)/
		const search = location.match(regex)

		if (search.length > 0) result = search[0]

		if (returnType === "sliceLocation") {
			const containsLock = result.match("Lock")

			if (containsLock) {
				result = result.substring(4)
			}

			result = result.toLowerCase()
		}

		return result
	} catch (error) {
		console.warn("error", error)
	}
}

// Get an object containing password policy data and store them in the localStorage.
export function storePasswordPolicy(policyData, localStateSetter = () => {}) {
	localStateSetter(policyData)
	window.localStorage.setItem("passGenMaj", policyData?.hasCapitalLetter)
	window.localStorage.setItem("passGenMin", policyData?.hasLowercaseLetter)
	window.localStorage.setItem("passGenNum", policyData?.hasNumber)
	window.localStorage.setItem("passGenSpec", policyData?.hasSpecialChar)
	window.localStorage.setItem("passGenLength", policyData?.maxNbChar)
	return
}

export const uploadFileSsh = (file, dispatch, type = false) => {
	return new Promise((resolve) => {
		let chunkSize = 20000000
		let chunkIndex = 0
		let totalChunkCount = Math.ceil(file.size / chunkSize)
		let fileId = uuidv4()

		const sendChunk = (chunkIndex) => {
			return new Promise((resolveChunk) => {
				let offset = chunkIndex * chunkSize
				let chunk = file.slice(offset, offset + chunkSize)
				let reader = new FileReader()

				reader.onload = function (event) {
					let content = event.target.result

					const payload = {
						content: content,
						totalSize: file.size,
						name: file.name,
						chunkIndex: chunkIndex,
						totalChunkCount: totalChunkCount,
						uuid: fileId,
					}

					if (type) {
						resolveChunk(payload)
					} else {
						dispatch({
							type: "pass/setSshFileTemporary",
							payload: { ...payload },
						})
					}
				}
				reader.readAsText(chunk)
			})
		}

		const sendAllChunks = async () => {
			while (chunkIndex < totalChunkCount) {
				await sendChunk(chunkIndex)
				chunkIndex++
			}
			resolve()
		}

		sendAllChunks()
	})
}

export function getAuthorizedChildren(tree, authorizedChildrenIds) {
	const findAuthorizedChildren = (branches) => {
		return branches
			?.map((branch) => {
				const authorizedChildren =
					branch?.children?.length > 0
						? findAuthorizedChildren(branch?.children)
						: []

				if (
					authorizedChildrenIds?.includes(branch?.id) ||
					authorizedChildren?.length > 0
				) {
					return {
						...branch,
						children: authorizedChildren,
					}
				} else {
					return null
				}
			})
			?.filter((branch) => !!branch)
	}

	return findAuthorizedChildren(tree)
}

export async function argon2Utils(
	password,
	salt,
	memory,
	iterations,
	length,
	parallelism,
	type,
) {
	const res = await argon2Browser
		.hash({
			// required
			pass: Buffer.from(new Uint8Array(password)),
			salt: Buffer.from(new Uint8Array(salt)),
			// optional
			time: iterations, // the number of iterations
			mem: memory, // used memory, in KiB
			hashLen: length, // desired hash length
			parallelism: parallelism, // desired parallelism (it won't be computed in parallel, however)
			//secret: new Uint8Array([...]), // optional secret data - not sure of how to use this?
			//ad: new Uint8Array([...]), // optional associated data - not sure of how to use this?
			type: type, // Argon2d, Argon2i, Argon2id
		})
		.catch((err) => {
			//err.message; // error message as string, if available
			//err.code; // numeric error code
			throw err
		})

	return res.hash
}
