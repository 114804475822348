import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import NumInput from "../../../components/inputs/NumInput"
import TooltipHelper from "../../../stories/tooltip/TooltipHelper"
import { ReactComponent as Generate } from "../../../assets/images/Generate.svg"

export function InputPassword(props) {
	const {
		activeProduct,
		noTitleInGenMenu,
		reducedSpecialCharList,
		generateWithPP,
		useLength,
		setUseLength,
		useMaj,
		setUseMaj,
		useMin,
		setUseMin,
		useNum,
		setUseNum,
		useSpec,
		setUseSpec,
		hasBorders,
		inAddFolder,
	} = props

	const [t] = useTranslation()

	function changeGenParam(target /*,val*/) {
		let needMajs
		let needMins
		let needNums
		let needSpecs

		if (generateWithPP) {
			let valGenPP = generateWithPP

			if (valGenPP.hasCapitalLetter) needMajs = true
			if (valGenPP.hasLowercaseLetter) needMins = true
			if (valGenPP.hasNumber) needNums = true
			if (valGenPP.hasSpecialChar) needSpecs = true
			//if (p.minNbChar) needMinLen = p.minNbChar
			//if (p.maxNbChar) needMaxLen = p.maxNbChar
		}

		switch (target) {
			case "Majs":
				if (generateWithPP) return
				else if (
					(needMajs && !useMaj) ||
					(!useMin && !useNum && !useSpec)
				)
					return setUseMaj(true)
				else
					return (
						setUseMaj((v) => !v),
						localStorage.setItem(
							"passGenMaj",
							JSON.stringify(!useMaj),
						)
					)
				break

			case "Mins":
				if (props.generateWithPP) return
				else if (
					(needMins && !useMin) ||
					(!useMaj && !useNum && !useSpec)
				)
					return setUseMin(true)
				else
					return (
						setUseMin((v) => !v),
						localStorage.setItem(
							"passGenMin",
							JSON.stringify(!useMin),
						)
					)
				break

			case "Nums":
				if (props.generateWithPP) return
				else if (
					(needNums && !useNum) ||
					(!useMin && !useMaj && !useSpec)
				)
					return setUseNum(true)
				else
					return (
						setUseNum((v) => !v),
						localStorage.setItem(
							"passGenNum",
							JSON.stringify(!useNum),
						)
					)
				break

			case "Specs":
				if (props.generateWithPP) return
				else if (
					(needSpecs && !useSpec) ||
					(!useMin && !useNum && !useMaj)
				)
					return setUseSpec(true)
				else
					return (
						setUseSpec((v) => !v),
						localStorage.setItem(
							"passGenSpec",
							JSON.stringify(!useNum),
						)
					)
				break

			/*case "Len" :
                if ((needMinLen || needMaxLen) && (val < needMinLen || val > needMaxLen)) return
                    else return setUseLength(val)
                break;*/
			default:
				return
		}
	}

	return (
		<div>
			<div
				className={`genMenu ${
					activeProduct === "locktransfer" ? "inAddFolder" : ""
				} ${hasBorders ? "hasBorders" : ""} ${
					inAddFolder ? "inAddFolder" : ""
				}`}
			>
				{!noTitleInGenMenu && activeProduct !== "locktransfer" && (
					<div className="title-container">
						<Generate />
						<h3 className="title">
							{t("pass.aPass.generateTitle")}{" "}
							{generateWithPP &&
								"- " + t("pass.pp").toLowerCase()}
						</h3>
						<h3 className="desc-ppName">
							<TooltipHelper
								content={generateWithPP?.name}
								hasEllipsis
							>
								<div className="ppName">
									{generateWithPP?.name}
								</div>
							</TooltipHelper>
						</h3>
					</div>
				)}
				<div
					className={`genOptionsList ${
						generateWithPP ? "customGap" : ""
					}`}
				>
					<span
						className={`genOptionList-item ${
							useMaj && !generateWithPP
								? "active"
								: generateWithPP && useMaj
								? "activeWithPP"
								: generateWithPP && !useMaj
								? "noPP"
								: ""
						}`}
						onClick={() => changeGenParam("Majs")}
					>
						A-Z
					</span>
					<span
						className={`genOptionList-item ${
							useMin && !generateWithPP
								? "active"
								: generateWithPP && useMin
								? "activeWithPP"
								: generateWithPP && !useMin
								? "noPP"
								: ""
						}`}
						onClick={() => {
							changeGenParam("Mins")
						}}
					>
						a-z
					</span>
					<span
						className={`genOptionList-item ${
							useNum && !generateWithPP
								? "active"
								: generateWithPP && useNum
								? "activeWithPP"
								: generateWithPP && !useNum
								? "noPP"
								: ""
						}`}
						onClick={() => changeGenParam("Nums")}
					>
						0-9
					</span>
					<span
						className={`genOptionList-item ${
							useSpec && !generateWithPP
								? "active"
								: generateWithPP && useSpec
								? "activeWithPP"
								: generateWithPP && !useSpec
								? "noPP"
								: ""
						}`}
						onClick={() => changeGenParam("Specs")}
					>
						{reducedSpecialCharList
							? `!@#$`
							: `~!@#$%^&*()-_=+[]{};:,.<>/?|`}
					</span>{" "}
					{/* Initial label : `~!@#$%^&*()-_=+[]{};:,.<>/?|` */}
					<NumInput
						value={useLength}
						change={(v) => (
							setUseLength(v),
							localStorage.setItem("passGenLength", v)
						)}
						overNull
						setUseMaj={setUseMaj}
						setUseLength={setUseLength}
						setUseMin={setUseMin}
						setUseNum={setUseNum}
						setUseSpec={setUseSpec}
						generateWithPP={generateWithPP}
						onPopUpPass
					/>
				</div>
			</div>
		</div>
	)
}

InputPassword.propTypes = {
	activeProduct: PropTypes.string,
	noTitleInGenMenu: PropTypes.bool,
	useMaj: PropTypes.bool,
	setUseMaj: PropTypes.func,
	useMin: PropTypes.bool,
	setUseMin: PropTypes.func,
	useNum: PropTypes.bool,
	setUseNum: PropTypes.func,
	useSpec: PropTypes.bool,
	setUseSpec: PropTypes.func,
	reducedSpecialCharList: PropTypes.bool,
	setUseLength: PropTypes.func,
	useLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	generateWithPP: PropTypes.object,
	change: PropTypes.func,
	noGenButton: PropTypes.bool,
	noGen: PropTypes.bool,
	generateIcon: PropTypes.bool,
	CustomGenerateIcon: PropTypes.element,
	destroyGeneratorButton: PropTypes.bool,
	type: PropTypes.string,
	hasBorders: PropTypes.bool,
	inAddFolder: PropTypes.bool,
}
