import {
	call,
	put,
	take,
	actionChannel,
	takeLeading,
	takeEvery,
	select,
	all,
} from "redux-saga/effects"
import * as Api from "./services"
import { toastr } from "react-redux-toastr"
import I18n from "../../i18n"
import {
	addLCS,
	removeLCS,
	downloadFiles,
	requestLoop,
	backwardCompApi,
} from "../../actions/generalUtils"

export function* getAllGroups() {
	return yield call(getGroups, { payload: { getAllGroups: true } })
}

export function* getAllAdGroups() {
	return yield call(getGroups, {
		payload: { getAllGroups: true, getAD: true },
	})
}

function populateOrgData(orgData) {
	const newOrg = []

	orgData?.forEach((org) => {
		const newChildren = []

		if (org.children && org.children.length > 0) {
			const childrenResult = populateOrgData(org.children)
			newChildren.push(...childrenResult)
		}

		newOrg.push({
			...org,
			children: newChildren,
			rootLoadData: "M_GET_DATA_SAGA",
			loadData: "M_GET_DATA_PERMALINKS_SAGA",
			showHelp: "orgs",
			boardHelp: "orgs",
			repName: "manage.subOrgs",
		})
	})

	return newOrg
}

export function* getGroups(action) {
	try {
		addLCS(action)

		yield put({ type: "manage/setLAR" })

		if (
			(!action?.type === "M_GET_DATA_SAGA" &&
				!action.payload?.justRefresh &&
				!action.payload?.offset) ||
			(action.payload?.getAllGroups && !action.payload?.setInLar)
		) {
			yield put({
				type: "manage/toggleCompassLoading",
				payload: true,
			})
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)
		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		let payload = { token, xLsT, ...action.payload, organizationId }

		if (action.payload?.getAllGroups) payload.limit = 100000

		const data = yield call(Api.getGroups, payload)

		if (
			(!action?.type === "M_GET_DATA_SAGA" &&
				!action.payload?.justRefresh &&
				!action.payload?.offset) ||
			(action.payload?.getAllGroups && !action.payload?.setInLar)
		) {
			yield put({ type: "manage/toggleCompassLoading" })
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				const filteredItems = data._embedded.items.filter(
					(item) => item.organisationId === organizationId,
				)

				if (action.payload?.setInLar) {
					return yield put({
						type: "manage/setLAR",
						payload: {
							message: "successGroups",
							data,
							filteredItems,
						},
					})
				}

				if (action.payload?.initCall) {
					let reps = data._embedded.items

					if (action.payload?.getAD) {
						if (data) {
							if (data.total > 10) {
								reps.push({
									name: I18n.t("manage.getAllGroups"),
									loadData: "M_GET_ALL_AD_GROUPS_SAGA",
									loadMoreItem: true,
								})
							}
							return yield put({
								type: "manage/addReps",
								payload: {
									data: data._embedded.items,
									...action.payload,
									stateTarget: 2,
								},
							})
						} else return yield put({ type: "manage/hideADGroups" })
					} else {
						if (data.total > 10 && !action.payload?.search) {
							reps.push({
								name: I18n.t("manage.getAllGroups"),
								loadData: "M_GET_ALL_GROUPS_SAGA",
								loadMoreItem: true,
							})
						}

						return yield put({
							type: "manage/addReps",
							payload: {
								data: data._embedded.items,
								...action.payload,
								stateTarget: 1,
							},
						})
					}
				}

				if (action.payload?.getAllGroups) {
					yield put({ type: "manage/toggleLoading" })

					if (action.payload?.getAD) {
						yield put({
							type: "manage/updateData",
							payload: { children: [], stateTarget: 2 },
						})
						return yield put({
							type: "manage/addReps",
							payload: {
								data: data._embedded.items,
								stateTarget: 2,
							},
						})
					} else {
						yield put({
							type: "manage/updateData",
							payload: { children: [], stateTarget: 1 },
						})

						return yield put({
							type: "manage/addReps",
							payload: {
								data: filteredItems,
								stateTarget: 1,
							},
						})
					}
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getGroupUsers(action) {
	try {
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		yield put({ type: "manage/toggleLoading", payload: true })
		yield put({ type: "manage/toggleSlideLoading", payload: true })

		yield put({ type: "manage/toggleCompassLoading", payload: true })

		let payload = { token, xLsT, groupId: action.payload.categoryId }
		const data = yield call(Api.getGroupUsers, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				if (action.payload.justReturn) return data
				else
					yield put({
						type: "manage/updateData",
						payload: { files: data },
					})

				yield put({ type: "manage/toggleLoading" })
				yield put({ type: "manage/toggleCompassLoading" })
				yield put({ type: "manage/toggleSlideLoading" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* postGroup(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		//yield put({ type: "manage/setLAR"})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)
		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		let payload = {
			token,
			xLsT,
			data: { ...action.payload, organizationId },
		}

		const data = yield call(Api.postGroup, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("manage.addGroup.success"))
				let groups = yield select(
					(state) => state.manage.data?.[1]?.reps,
				)

				if (groups?.length === 10) {
					let reps = [
						{
							name: I18n.t("manage.getAllGroups"),
							loadData: "M_GET_ALL_GROUPS_SAGA",
							loadMoreItem: true,
						},
					]
					yield put({
						type: "manage/addReps",
						payload: { data: reps, stateTarget: 1, addRep: true },
					})
				} else if (groups?.length < 10) {
					yield put({
						type: "manage/addReps",
						payload: { data: [data], stateTarget: 1, addRep: true },
					})
				} else if (groups?.length > 10) {
					yield put({
						type: "M_GET_GROUPS_SAGA",
						payload: { initCall: true, limit: 16 },
					})
				}

				return yield put({ type: "action/closeAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putGroup(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		//yield put({ type: "manage/setLAR"})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.putGroup, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("editSuccess"))

				yield put({
					type: "manage/updateData",
					payload: { name: data.name },
				})

				yield put({
					type: "M_GET_ALL_GROUPS_SAGA",
					payload: { noCompassLoading: true },
				})

				return yield put({ type: "action/closeAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteGroup(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		//yield put({ type: "manage/setLAR"})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, id: action.payload }

		const data = yield call(Api.deleteGroup, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("manage.removeGroup.success"))

				yield put({ type: "action/setLAR", payload: "navigateBack" })
				return yield put({ type: "action/closeMiniAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* addUserToGroup(action) {
	try {
		addLCS(action)

		if (!action.payload.noAfter)
			yield put({
				type: "action/setActionLoader",
				payload: {
					msg: I18n.t("manage.addToGroup.loader"),
					transparency: true,
				},
			})
		//yield put({ type: "manage/setLAR"})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = {
			token,
			xLsT,
			data: {
				groupId: action.payload.groupId,
				email: action.payload.email,
			},
		}
		let data = {}

		if (backwardCompApi("1.18.3")) {
			data = yield call(Api.addUserToGroupV2, payload)

			switch (data) {
				case "sessionExpired":
					if (!action.payload.noAfter)
						yield put({
							type: "action/setActionLoader",
						})

					return yield put({ type: "auth/showPadlock" })
				case "sessionDestroyed":
					if (!action.payload.noAfter)
						yield put({
							type: "action/setActionLoader",
						})

					return yield put({ type: "auth/sessionExpired" })
				case true:
					if (!action.payload.noAfter)
						yield put({
							type: "action/setActionLoader",
						})

					return removeLCS(action)
				default:
					data = yield call(requestLoop, Api.addUserToGroupV2, {
						...payload,
						data: {
							...payload?.data,
							task: data?.uuid,
						},
					})
					break
			}
		} else {
			data = yield call(Api.addUserToGroup, payload)
		}

		if (!action.payload.noAfter)
			yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				if (!action.payload.noAfter)
					yield put({
						type: "action/setActionLoader",
					})

				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				if (!action.payload.noAfter)
					yield put({
						type: "action/setActionLoader",
					})

				return yield put({ type: "auth/sessionExpired" })
			case true:
				if (!action.payload.noAfter)
					yield put({
						type: "action/setActionLoader",
					})

				return removeLCS(action)
			default: {
				removeLCS(action)

				//console.log(data)
				let state = yield select((state) => state.manage.data?.[0])

				if (!action.payload.noAfter) {
					// Reload de l'info
					if (state?.comp === "Group")
						yield put({
							type: "M_GET_GROUP_USERS_SAGA",
							payload: { categoryId: state.id },
						})
				}

				toastr.success(I18n.t("manage.toGroup.success"))
				return yield put({ type: "action/closeAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* addMultiUsersToGroup(action) {
	try {
		yield put({ type: "manage/setLAR" })
		//console.log(action.payload)

		let i = 0
		let responses = []

		yield put({
			type: "action/setActionLoader",
			payload: {
				msg: I18n.t("manage.addToGroup.loader"),
				transparency: true,
			},
		})

		while (i < action.payload.emails.length) {
			let payload = {
				groupId: action.payload.groupId,
				email: action.payload.emails[i],
			}
			let a = yield call(addUserToGroup, {
				payload: { ...payload, noAfter: true },
			})
			responses.push(a)
			i++
		}

		yield put({ type: "action/setActionLoader" })

		// On regarde si l'ajout a été fait avec succés pour chaque utilisateur
		let perfect = true
		responses?.map((item) => {
			if (item === undefined) return (perfect = false)
			else return undefined
		})

		// Reload de l'info à la fin
		let state = yield select((state) => state.manage.data?.[0])
		if (state?.comp === "Group")
			yield put({
				type: "M_GET_GROUP_USERS_SAGA",
				payload: { categoryId: state.id },
			})

		if (perfect) {
			toastr.success(I18n.t("manage.toGroup.successForMulti"))
			return yield put({ type: "action/closeAction" })
		} else return
	} catch (error) {
		//console.log(error)
	}
}

export function* removeUserFromGroup(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: {
				msg: I18n.t("manage.removeFromGroup.loader"),
				transparency: true,
			},
		})
		//yield put({ type: "manage/setLAR"})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.removeUserFromGroup, payload)
		yield put({ type: "action/setActionLoader" })
		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("manage.removeFromGroup.success"))
				yield put({ type: "manage/unselectAll" })

				if (action?.requiresRedirectInWindowHistoryState) {
					let state = yield select((state) => state.manage.data?.[0])
					yield put({
						type: "M_GET_GROUP_USERS_SAGA",
						payload: { categoryId: state.id },
					})
				}

				yield put({ type: "action/setLAR", payload: "navigateBack" })
				return yield put({ type: "action/closeMiniAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function findRep(a) {
	let cat = a.data.find((x) => x.id === a.action.payload.categoryId)

	if (cat) {
		return cat
	} else {
		let result

		a.data?.map((item) => {
			// Si on est dans la bonne racine
			if (item.mainId === a.action.payload.mainId) {
				let elem = item.children // On prends tous les enfants de la racine
				let el = elem.find((x) => x.id === a.action.payload.categoryId)

				if (el) {
					//Si l'élément voulu est trouvé en 1er degré de parenté de la racine
					return (result = el)
				} else {
					//Sinon on suit les étapes de l'historique pour arriver jusqu'à l'élement
					let h = a.history.slice(1)
					let hTree = a.data
					let continueMap = true

					h?.map((hItem) => {
						if (continueMap)
							hTree = hTree.find(
								(x) => x.id === hItem.categoryId,
							).children

						if (hTree[0].categoryId === a.action.payload.categoryId)
							return (continueMap = false)
						else return undefined
					})

					if (a.action.payload.categoryId)
						return (result = hTree.find(
							(x) => x.id === a.action.payload.categoryId,
						))
					else return (result = hTree[0])
				}
			} else return undefined
		})
		return result
	}
}

export function* getOrgs(action) {
	try {
		addLCS(action)
		// console.log(action)
		yield put({ type: "manage/setLAR" })

		if (
			!action.type === "M_GET_DATA_SAGA" &&
			!action.payload?.justRefresh &&
			!action.payload?.offset
		)
			yield put({
				manage: "manage/toggleLoading",
				payload: true,
			})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)
		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		let payload = { token, xLsT, ...action.payload, organizationId }

		const data = yield call(Api.getOrgs, payload)

		if (
			!action.type === "M_GET_DATA_SAGA" &&
			!action.payload?.justRefresh &&
			!action.payload?.offset
		)
			yield put({ manage: "manage/toggleLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (action.payload.justRefresh) {
					yield put({ type: "manage/saveBranch", payload: data })

					if (!backwardCompApi("1.20.16")) {
						yield put({
							type: "manage/updateData",
							payload: { children: [], stateTarget: 0 },
						})
					}

					return yield put({
						type: "manage/addReps",
						payload: {
							data: populateOrgData(data.children),
							stateTarget: action.payload.stateTarget ?? 0,
							doNotKeep: true,
							isArbo:
								action.payload.stateTarget !== undefined
									? true
									: false,
						},
					})
				}

				if (action.payload.initCall) {
					let reps = [...data.children]
					delete data.children
					if (action.payload.initCall) delete action.payload.initCall
					yield put({
						type: "manage/addReps",
						payload: {
							data: populateOrgData(reps),
							stateTarget: 0,
						},
					})
					return yield put({
						type: "manage/updateData",
						payload: { ...data, ...action.payload, stateTarget: 0 },
					})
				}

				if (action.payload.setInLar)
					return yield put({
						type: "manage/setLAR",
						payload: { message: "success", data },
					})

				// On update la data avec l'info fraîche arrivée depuis l'api ( get orgs )
				if (action.payload.categoryId !== 0) {
					let history = yield select((state) => state.manage.history)
					let newData = yield call(findRep, {
						action,
						data: data.children,
						history,
					})

					let copyData = { ...newData }
					if (copyData.children) {
						delete copyData.children
						yield put({
							type: "manage/addReps",
							payload: {
								data: populateOrgData(newData.children),
							},
						})
					}

					if (copyData)
						yield put({
							type: "manage/updateData",
							payload: { ...copyData },
						})
				}

				// Puis on ajoute les répertoires en fonction de la demande
				if (action.payload.findPos) {
					let history = yield select((state) => state.manage.history)
					let reps = yield call(findRep, {
						action,
						data: data.children,
						history,
					})

					if (reps?.children)
						yield put({
							type: "manage/addReps",
							payload: { data: reps.children, ...action.payload },
						})
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* postOrg(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, data: action.payload }

		const data = yield call(Api.postOrg, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (data === "badEncode") {
					toastr.error(I18n.t("manage.userNotFound"))
				} else {
					toastr.success(I18n.t("manage.addOrg.success"))
					yield put({ type: "action/closeAction" })
				}

				let s = yield select((state) => state.manage.data[0])
				let actualRepId = yield select(
					(state) => state.manage.data[0].id,
				)

				yield put({
					type: "M_GET_ORGS_SAGA",
					payload: {
						categoryId: s.id,
						justRefresh: true,
						stateTarget:
							action.payload.organizationId === s.id
								? undefined
								: 0,
					},
				})

				if (payload.data?.organizationId === s.id) {
					yield put({
						type: "M_GET_ORG_USERS_SAGA",
						payload: {
							justRefresh: true,
							categoryId: actualRepId,
							recountPagin: true,
						},
					})
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putOrg(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.putOrg, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("editSuccess"))

				yield put({
					type: "manage/updateData",
					payload: { name: data.name },
				})

				yield put({
					type: "M_GET_ORGS_SAGA",
					payload: {
						categoryId: data.id,
						justRefresh: true,
						stateTarget: 0,
						isArbo: true,
					},
				})

				return yield put({ type: "action/closeAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getOrgUsers(action) {
	try {
		addLCS(action)

		if (!action.payload?.justRefresh && !action.payload?.offset) {
			yield put({
				type: "manage/toggleLoading",
				payload: true,
			})
		} else if (action.payload.addOrgaPopup) {
			yield put({
				type: "action/setActionLoader",
				payload: { transparency: true },
			})
		}

		if (action.payload.offset) {
			yield put({ type: "manage/paginLoading", payload: true })
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId
		if (action.payload.categoryId) {
			organizationId = action.payload.categoryId
		} else {
			organizationId =
				auth.token.userSession.organizationIds[auth.selectedOrg]
		}

		let payload = {
			token,
			xLsT,
			organizationId,
			search: action.payload.search,
			offset: action.payload.offset,
			limit: action.payload.limit,
			limitToOrga: action.payload.search ? true : undefined,
		}
		const ogData = yield call(Api.getOrgUsers, payload)

		if (action.payload.offset) yield put({ type: "manage/paginLoading" })
		if (!action.payload?.justRefresh && !action.payload?.offset)
			yield put({ type: "manage/toggleLoading" })

		switch (ogData) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let data = { ...ogData }

				if (action.payload.checkAdminStatus) {
					// Récup statut user
					let h = yield select((state) => state.manage.history)
					let superiorAdmin = false
					h?.map((item) => {
						if (item.isAdmin) superiorAdmin = true
					})

					let myId = yield select(
						(state) => state.auth.token.userSession.userId,
					)
					let iam = data?._embedded?.items?.find((x) => x.id === myId)

					yield put({
						type: "manage/updateData",
						payload: {
							isAdmin: iam ? iam.isAdmin : false,
							superiorAdmin,
						},
					})
				}

				let pageSize = yield select((state) => state.manage.pageSize)

				if (action.payload.recountPagin || !action.payload.offset) {
					//|| action.payload.search) {
					data.pages = Math.ceil(ogData.total / pageSize)
					data.page = Math.ceil(action.payload.limit / pageSize)
					data.limit = pageSize
				}

				let isLoading = yield select((state) => state.manage.loading)
				if (isLoading) yield put({ type: "manage/toggleLoading" })

				if (action.payload.justReturn) return data
				if (action.payload.removeCR) yield put({ type: "manage/setCR" })
				if (action.payload.initCall)
					return yield put({
						type: "manage/addElements",
						payload: { ...data, ...action.payload },
					})

				let ps = yield select((state) => state.manage)

				if (action.payload.search && !action.payload.addOrgaPopup) {
					yield put({
						type: "manage/addElements",
						payload: {
							files: data._embedded.items,
							doNotKeep: !action.payload.offset,
							total: data.total,
							pages: data.pages,
							page: data.page,
						},
					})
					if (!ps.searchMode && !action.payload.addOrgaPopup) {
						yield put({
							type: "manage/initSearch",
							payload: { ...action.payload },
						})
					}
				} else if (action.payload.addOrgaPopup) {
					yield put({
						type: "manage/modalSearch",
						payload: {
							modalSearchResult: data._embedded.items,
						},
					})
				} else {
					let s = yield select((state) => state.manage.data[0])
					if (s.id && action.payload.categoryId !== s.id) return

					yield put({
						type: "manage/addElements",
						payload: {
							...action.payload,
							...data,
							doNotKeep: action.payload.justRefresh
								? true
								: false,
						},
					})
				}
				if (action.payload.addOrgaPopup)
					yield put({ type: "action/setActionLoader" })
				return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteOrga(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, id: action.payload }
		const data = yield call(Api.deleteOrga, payload)

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case "deleted": {
				removeLCS(action)

				toastr.success(I18n.t("manage.delOrg.success"))
				yield put({ type: "action/closeAction" })
				yield put({ type: "manage/saveBranch", payload: {} })
				yield put({ type: "action/setLAR", payload: "navigateBack" })
				break
			}
			default: {
				removeLCS(action)
				return yield put({ type: "action/closeAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getData(action) {
	try {
		let ogP = { ...action.payload }

		let s = yield select((state) => state.manage)
		let h = [...(yield select((state) => state.manage.history))]
		//let superiorAdmin = s.data[0].isAdmin
		let loading = s.loading

		if (s.callRunning) return
		if (s.pageSize && !ogP.limit) ogP.limit = s.pageSize * 2

		yield put({ type: "____________________________" })
		yield put({ type: "manage/setCR", payload: true })

		if (ogP.saveLimitAs2Size)
			yield put({ type: "manage/savePageSize", payload: ogP.limit / 2 })

		if (!ogP.offset) {
			if (!loading && ogP.initCall)
				yield put({ type: "manage/toggleLoading", payload: true })
		}

		if (ogP.search) {
			// Recherche
			yield put({ type: "manage/setCR" })
			yield put({ type: "manage/initSearch", payload: { ...ogP } })
			yield put({
				type: "M_GET_GROUPS_SAGA",
				payload: { ...ogP, initCall: true, getAllGroups: true },
			})
			return yield put({
				type: "M_GET_ORG_USERS_SAGA",
				payload: {
					...ogP,
					removeCR: true,
					recountPagin: action.payload.offset ? false : true,
				},
			}) //recountPagin: !s.searchMode } })
		}
		if (ogP.offset) {
			// Pagination auto | Pas dans les groupes -> on appelle toujours les ORG_USERS
			yield put({ type: "manage/setCR" })
			return yield put({
				type: "M_GET_ORG_USERS_SAGA",
				payload: { ...ogP, removeCR: true },
			})
		}

		// Sinon c'est qu'on rentre dans un dans un dossier
		if (!ogP.initCall && !ogP.justRefresh) {
			if (!ogP.historyState)
				yield put({ type: "manage/historyEnter", payload: { ...ogP } })

			yield put({ type: "manage/enterInFolder", payload: { ...ogP } })
		}

		if (h.length === 0 && ogP.productTarget >= 1) {
			// Si on est à la racine + qu'on ne rentre pas dans une sous-orga -> on rentre donc dans un groupe
			yield put({ type: "M_GET_GROUP_USERS_SAGA", payload: { ...ogP } })
		} else {
			// On rentre dans une sous-orga
			if (action.payload.initCall) {
				yield put({
					type: "M_GET_GROUPS_SAGA",
					payload: { ...ogP, getAD: false },
				})
				yield put({
					type: "M_GET_GROUPS_SAGA",
					payload: { ...ogP, getAD: true },
				})
			}

			yield put({
				type: "M_GET_ORG_USERS_SAGA",
				payload: {
					...ogP,
					checkAdminStatus: ogP.offset ? false : true,
				},
			})

			if (ogP.page) delete ogP.page
			if (ogP.limit) delete ogP.limit
			if (ogP.saveLimitAs2Size) delete ogP.saveLimitAs2Size

			yield put({
				type: "M_GET_ORGS_SAGA",
				payload: { ...ogP, ...(!ogP ? { findPos: true } : {}) },
			})
		}

		yield put({ type: "manage/setCR" })
		return yield put({ type: "____________________________" })
	} catch (error) {
		//console.log(error)
	}
}

export function* getUser(action) {
	try {
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, id: action.payload }

		const data = yield call(Api.getUser, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				yield put({ type: "auth/saveUserType", payload: data })
				return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

// Download the report of a given user.
export function* getUserReport(action) {
	try {
		// Typical saga setup : activate the loader, get the authentication data, setup the payload and make the request.
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, data: { userId: action.payload.userId } }
		const data = yield call(Api.getUserReport, payload)

		// Disable the loader when the request has ended
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				// When we have the data, set the name we want to give to our file and trigger the download process
				const fileName =
					I18n.t("manage.report") + "-" + action.payload.userName
				return downloadFiles(data, fileName)
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* postUser(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		//yield put({ type: "manage/setLAR"})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, data: action.payload }

		const data = yield call(Api.postUser, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({ type: "action/closeAction" })
				toastr.success(I18n.t("manage.addUser.success"))

				yield put({ type: "LICENSE_GET_INDICATORS_SAGA" })

				let actualRepId = yield select(
					(state) => state.manage.data[0].id,
				)
				let pagin = yield select((state) => state.manage.pagin)

				yield put({
					type: "M_GET_ORG_USERS_SAGA",
					payload: {
						justRefresh: true,
						categoryId: actualRepId,
						limit: pagin.page * pagin.limit,
						recountPagin: true,
					},
				})

				return yield put({ type: "action/closeAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* postApiUser(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, data: action.payload.data }

		const data = yield call(Api.postApiUser, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({ type: "action/showTokens", payload: data })
				toastr.success(I18n.t("manage.addUser.success"))

				yield put({ type: "LICENSE_GET_INDICATORS_SAGA" })

				let actualRepId = yield select(
					(state) => state.manage.data[0].id,
				)
				let pagin = yield select((state) => state.manage.pagin)

				return yield put({
					type: "M_GET_ORG_USERS_SAGA",
					payload: {
						justRefresh: true,
						categoryId: actualRepId,
						limit: pagin.page * pagin.limit,
						recountPagin: true,
					},
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putApiUser(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.putApiUser, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({ type: "action/closeAction" })

				if (
					action.payload.data.organizationId ===
					action.payload.data.moveOrganizationId
				) {
					yield put({
						type: "action/setLAR",
						payload: "navigateBack",
					})

					if (action.payload?.updateUserPrestaStatus) {
						window.location.reload()

						return
					}

					yield put({
						type: "manage/updateActive",
						payload: { data, id: action.payload.id },
					})

					toastr.success(I18n.t("editSuccess"))
					return yield put({ type: "manage/unselectAll" })
				} else if (action.payload.data.fullReload) {
					yield put({ type: "manage/setUploadLoading" })
					yield put({ type: "manage/toggleLoading", payload: true })

					toastr.success(I18n.t("manage.move.success"))

					let actualRepId = yield select(
						(state) => state.manage.data[0].id,
					)
					let pagin = yield select((state) => state.manage.pagin)

					yield put({
						type: "M_GET_ORG_USERS_SAGA",
						payload: {
							justRefresh: true,
							categoryId: actualRepId,
							limit: pagin.page * pagin.limit,
							recountPagin: true,
						},
					})

					return yield put({ type: "manage/toggleLoading" })
				}

				return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putUser(action) {
	try {
		addLCS(action)

		if (action?.requiresRedirectInWindowHistoryState) {
			yield put({ type: "manage/setCR", payload: true })
		}

		if ("multiTotal" in action.payload && "multiPart" in action.payload)
			yield put({
				type: "manage/setUploadLoading",
				payload: {
					actualPart: action.payload.multiPart,
					totalParts: action.payload.multiTotal,
					customMsg: `${I18n.t("fileLoad")} ${action.payload.name}`,
				},
			})
		else {
			yield put({
				type: "action/setActionLoader",
				payload: { transparency: true },
			})
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.putUser, payload)

		if (
			!action.payload.hasOwnProperty("multiTotal") &&
			!action.payload.hasOwnProperty("multiPart")
		)
			yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				if (action?.requiresRedirectInWindowHistoryState) {
					yield put({ type: "manage/setCR" })
				}

				return removeLCS(action)
			default: {
				removeLCS(action)

				if (
					(!action.payload.hasOwnProperty("multiTotal") &&
						!action.payload.hasOwnProperty("multiPart")) ||
					action.payload.fullReload
				) {
					yield put({ type: "action/closeAction" })

					if (action.payload.fullReload) {
						yield put({ type: "manage/setUploadLoading" })
						yield put({
							type: "manage/toggleLoading",
							payload: true,
						})

						toastr.success(I18n.t("manage.move.success"))

						const actualRepId = yield select(
							(state) => state.manage.data[0].id,
						)
						const manageState = yield select(
							(state) => state.manage,
						)

						yield put({
							type: "action/setLAR",
							payload: "navigateBack",
						})

						if (
							action?.requiresRedirectInWindowHistoryState ||
							manageState.searchMode
						) {
							if (manageState.searchMode) {
								yield put({
									type: "M_GET_ORG_USERS_SAGA",
									payload: {
										justRefresh: true,
										search: manageState.searchVal,
										limit:
											manageState.pagin.page *
											manageState.pageSize,
										recountPagin: true,
									},
								})
							} else {
								yield put({
									type: "M_GET_ORG_USERS_SAGA",
									payload: {
										justRefresh: true,
										categoryId: actualRepId,
										limit:
											manageState.pagin.page *
											manageState.pageSize,
										recountPagin: true,
									},
								})
							}

							// wait for manage/addElements action to be called
							yield take("manage/addElements")
							yield put({ type: "manage/setCR" })
						}

						toastr.success(I18n.t("manage.move.success"))

						yield put({ type: "manage/unselectAll" })
						return yield put({ type: "manage/toggleLoading" })
					} else {
						if (
							action.payload.data.organizationId ===
							action.payload.data.moveOrganizationId
						) {
							toastr.success(I18n.t("editSuccess"))
							return yield put({
								type: "manage/updateActive",
								payload: { data, id: action.payload.id },
							})
						}
					}
				} else return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putUserStatus(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }

		let data
		if (action.payload.activate)
			data = yield call(Api.reActivateUser, payload)
		else data = yield call(Api.deactivateUser, payload)

		// Only stop the loader if there was an error on the first request or if we don't need to reset the expiration date.
		if (
			data === "sessionExpired" ||
			data === "sessionDestroyed" ||
			data === true ||
			!action.payload.removeExpiration
		) {
			yield put({ type: "action/setActionLoader" })
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				// If the payload specified we must reset the expiration date, we need to make another api call.
				if (action.payload.removeExpiration) {
					const newPayload = {
						token,
						xLsT,
						...action.payload.putUserPayload,
					}
					let newRequest

					if (action.payload.apiUser) {
						newRequest = yield call(Api.putApiUser, newPayload)
					} else {
						newRequest = yield call(Api.putUser, newPayload)
					}

					data.expirationDate = newRequest.expirationDate

					// Put the status back to an activated user (the value depends wether we have a normal user or
					// an api user)
					if (action.payload.apiUser) {
						data.status = 5
					} else {
						data.status = 1
					}

					yield put({ type: "action/setActionLoader" })
				}

				if (action.payload.activate)
					toastr.success(I18n.t("manage.reActivSuccess"))
				else toastr.success(I18n.t("manage.deactivSuccess"))

				return yield put({
					type: "manage/updateActive",
					payload: { data, id: action.payload.id },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putUserModerator(action) {
	try {
		addLCS(action)

		if (action.payload.data.status) {
			yield put({
				type: "action/setActionLoader",
				payload: {
					msg: I18n.t("manage.promotedAsModeratorLoader"),
					transparency: true,
				},
			})
		} else {
			yield put({
				type: "action/setActionLoader",
				payload: {
					msg: I18n.t("manage.downgradedAsUserLoader"),
					transparency: true,
				},
			})
		}

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.putUserModerator, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (action.payload.data.status)
					toastr.success(I18n.t("manage.promotedAsModerator"))
				else toastr.success(I18n.t("manage.downgradedAsUser"))

				yield put({ type: "action/closeMiniAction" })
				return yield put({
					type: "manage/updateActive",
					payload: { data, id: action.payload.id },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putUserAdmin(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.putUserAdmin, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({ type: "action/closeMiniAction" })

				const superiorAdmin = yield select(
					(state) => state.manage.data[0].superiorAdmin,
				)
				//let isSuperAdmin = yield select(state => state.auth.token.userSession.isSuperAdmin)

				if (superiorAdmin) {
					toastr.success(I18n.t("manage.promotedAsAdmin"))

					yield put({
						type: "action/setActionLoader",
						payload: true,
					})

					const actualRepId = yield select(
						(state) => state.manage.data[0].id,
					)
					const pagin = yield select((state) => state.manage.pagin)
					yield put({
						type: "M_GET_ORG_USERS_SAGA",
						payload: {
							justRefresh: true,
							categoryId: actualRepId,
							limit: pagin.page * pagin.limit,
							recountPagin: true,
						},
					})

					return yield put({ type: "action/setActionLoader" })
				} else {
					// JE PASSE UN MODO ADMIN A MA PLACE
					localStorage.setItem("warning", "Success")
					return yield put({ type: "auth/sessionExpired" })
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteUsers(action) {
	try {
		addLCS(action)

		yield put({ type: "manage/setCR", payload: true })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = {
			token,
			xLsT,
			ids: action.payload.ids,
			apiUser: action.payload.apiUser,
		}

		const data = yield call(Api.deleteUsers, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({ type: "manage/setCR" })
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({ type: "action/closeMiniAction" })
				yield put({ type: "manage/unselectAll" })
				yield put({ type: "LICENSE_GET_INDICATORS_SAGA" })

				toastr.success(I18n.t("manage.deleteUser.success"))

				const actualRepId = yield select(
					(state) => state.manage.data[0].id,
				)
				const manageState = yield select((state) => state.manage)

				yield put({ type: "action/setLAR", payload: "navigateBack" })

				if (action?.requiresRedirectInWindowHistoryState) {
					if (manageState.searchMode) {
						yield put({
							type: "M_GET_ORG_USERS_SAGA",
							payload: {
								justRefresh: true,
								search: manageState.searchVal,
								limit:
									manageState.pagin.page *
									manageState.pageSize,
								recountPagin: true,
							},
						})
					} else {
						yield put({
							type: "M_GET_ORG_USERS_SAGA",
							payload: {
								justRefresh: true,
								categoryId: actualRepId,
								limit:
									manageState.pagin.page *
									manageState.pageSize,
								recountPagin: true,
							},
						})
					}

					// wait for manage/addElements action to be called
					yield take("manage/addElements")
				}

				yield put({ type: "manage/setCR" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* resendActivUser(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, data: action.payload }

		const data = yield call(Api.resendActivUser, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("manage.activMessageResended"))
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getSortUser(action) {
	try {
		// Typical saga setup : activate the loader, get the authentication data, setup the payload and make the request.
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = {
			token,
			xLsT,
			data: {
				userId: action.payload.userId,
				orgId: action.payload.orgId,
				limit: action.payload.paginationLimit,
			},
		}

		if (action.payload.resetOffset) payload.offset = 2

		const data = yield call(Api.getSortUser, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				if (action.payload.resetOffset) {
					const newLimit = action.payload.saveLimitAs2Size
						? data.limit / 2
						: data.limit
					yield put({
						type: "manage/updatePagin",
						payload: {
							page: 2,
							pages: data.pages * 2,
							limit: newLimit,
							total: data.total,
						},
					})
				}
				yield put({
					type: "manage/updateData",
					payload: { files: data._embedded.items },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* setSsoUserPassword(action) {
	try {
		// Register the saga call.
		addLCS(action)

		// Launch the loader and setup the request.
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payloadData = { ...action.payload }

		delete payloadData.userIndex
		delete payloadData.userId

		const payload = {
			token,
			xLsT,
			userId: action.payload.userId,
			data: payloadData,
		}

		const data = yield call(Api.setSsoUserPassword, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				// Remove the saga from history and display the success toaster.
				removeLCS(action)
				toastr.success(I18n.t("manage.ssoPwdPopup.success"))
				const actualRepId = yield select(
					(state) => state.manage.data[0].id,
				)
				const manageState = yield select((state) => state.manage)

				// Refresh the data after the saga has ended.
				yield put({
					type: "manage/updateActive",
					payload: { data: { ...data, hasPassword: true } },
				})

				// Finally, close the popup
				yield put({ type: "action/closeMiniAction" })
				return yield put({
					type: "M_GET_ORG_USERS_SAGA",
					payload: {
						justRefresh: true,
						categoryId: actualRepId,
						limit: manageState.pagin.page * manageState.pageSize,
						recountPagin: true,
					},
				})
			}
		}
	} catch (error) {
		/* console.log("setSsoUserPassword error catched !", error) */
	}
}

export function* removeSsoUserPassword(action) {
	try {
		// Register the saga call.
		addLCS(action)

		// Launch the loader and setup the request.
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, userId: action.payload.userId }

		const data = yield call(Api.removeSsoUserPassword, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				// Remove the saga from history and display the success toaster.
				removeLCS(action)
				toastr.success(I18n.t("manage.ssoPwdRemovalSuccess"))

				const actualRepId = yield select(
					(state) => state.manage.data[0].id,
				)
				const manageState = yield select((state) => state.manage)

				// Refresh the data after the saga has ended.
				// console.log("data", data)
				yield put({
					type: "manage/updateActive",
					payload: { data: { ...data, hasPassword: false } },
				})

				// Finally, close the popup
				yield put({ type: "action/closeMiniAction" })
				return yield put({
					type: "M_GET_ORG_USERS_SAGA",
					payload: {
						justRefresh: true,
						categoryId: actualRepId,
						limit: manageState.pagin.page * manageState.pageSize,
						recountPagin: true,
					},
				})
			}
		}
	} catch (error) {
		/* console.log("setSsoUserPassword error catched !", error) */
	}
}

export function* getDataPermalinks(action) {
	yield put({ type: "manage/setCR", payload: true })

	/* -------------------------------- VARIABLES ------------------------------- */
	const xLsT = yield select((state) => state.auth.xLsToken)
	const token = yield select((state) => state.auth.token.token)
	const auth = yield select((state) => state.auth)
	const branchInfo = yield select((state) => state.manage?.branchInfo)
	const currentUserId = yield select(
		(state) => state.auth.token.userSession.userId,
	)
	const organizationId =
		auth.token.userSession.organizationIds[auth.selectedOrg]
	const { payload } = action
	const requestPayloadBase = {
		token,
		xLsT,
		organizationId,
	}
	let subOrga = null
	let historyData = []
	let cleanHistoryData = []
	/* ------------------------------ END VARIABLES ----------------------------- */

	/* -------------------------------- FUNCTIONS ------------------------------- */
	const findSubOrga = (
		orgaData,
		currentSubOrgaId,
		currentSubOrgaParentId = null,
	) => {
		orgaData.forEach((data) => {
			if (data?.id === currentSubOrgaId) {
				subOrga = !currentSubOrgaParentId
					? data
					: {
							...data,
							parentId: currentSubOrgaParentId,
							boardHelp: "orgs",
					  }
			} else if (data?.children?.length > 0) {
				findSubOrga(data?.children, currentSubOrgaId, data?.id)
			}
		})
	}

	const createHistory = (
		branchData,
		currentOrgaId,
		currentOrgaParentId,
		currentBranchParentId = null,
	) => {
		branchData.forEach((branch) => {
			if (branch?.id !== currentOrgaId) {
				historyData.push(
					!currentBranchParentId
						? { ...branch, boardHelp: "orgs" }
						: {
								...branch,
								boardHelp: "orgs",
								parentId: currentBranchParentId,
						  },
				)
			}

			if (branch?.children?.length > 0) {
				return createHistory(
					branch?.children,
					currentOrgaId,
					currentOrgaParentId,
					branch?.id,
				)
			}
		})
	}

	const cleanHistory = (history, currentOrganizationId) => {
		history.forEach((data) => {
			if (data?.id === currentOrganizationId) {
				cleanHistoryData.push(data)

				return cleanHistory(history, data?.parentId)
			}
		})
	}
	/* ------------------------------ END FUNCTIONS ----------------------------- */

	/* ---------------------------- ACTIVATE LOADERS ---------------------------- */
	let loading = yield select((state) => state.manage.loading)

	if (!loading) yield put({ type: "manage/toggleLoading", payload: true })

	/* -------------------------- END ACTIVATE LOADERS -------------------------- */

	/* ------ SET SUPERIORADMIN/ISADMIN VALUE (NECESSARY FOR PAGES OTHER THAN ROOT) ----- */
	const orgUsersData = yield call(Api.getOrgUsers, {
		...requestPayloadBase,
		...payload,
		checkAdminStatus: true,
	})

	// VERIFY API CALL RESULT
	if (orgUsersData === "sessionExpired") {
		return yield put({ type: "auth/showPadlock" })
	} else if (orgUsersData === "sessionDestroyed") {
		return yield put({ type: "auth/sessionExpired" })
	} else if (orgUsersData === true) {
		return yield put({ type: "manage/setErrorNotFound", payload: true })
	}

	const currentUserItem = orgUsersData?._embedded?.items?.find(
		(user) => user?.id === currentUserId,
	)
	const isAdminOrSuperiorAdmin = currentUserItem
		? currentUserItem.isAdmin
		: false
	/* --- END : SET SUPERIORADMIN VALUE (NECESSARY FOR PAGES OTHER THAN ROOT) -- */

	if (payload?.initCall || payload.getArbo) {
		if (payload?.saveLimitAs2Size)
			yield put({
				type: "manage/savePageSize",
				payload: payload?.limit / 2,
			})

		const [ADGroupsData, groupsData, orgsData] = yield all([
			call(Api.getGroups, {
				...requestPayloadBase,
				...payload,
				limit: 16,
				getAD: true,
				preventGroupFetch: action.payload.preventGroupFetch,
			}),
			call(Api.getGroups, {
				...requestPayloadBase,
				...payload,
				limit: 16,
				getAD: false,
				preventGroupFetch: action.payload.preventGroupFetch,
			}),
			call(Api.getOrgs, {
				...requestPayloadBase,
				...payload,
			}),
		])

		/* ------------------------- VERIFY API CALL RESULT ------------------------- */
		if (ADGroupsData === "sessionExpired") {
			return yield put({ type: "auth/showPadlock" })
		} else if (ADGroupsData === "sessionDestroyed") {
			return yield put({ type: "auth/sessionExpired" })
		} else if (ADGroupsData === true) {
			return yield put({ type: "manage/setErrorNotFound", payload: true })
		}

		if (groupsData === "sessionExpired") {
			return yield put({ type: "auth/showPadlock" })
		} else if (groupsData === "sessionDestroyed") {
			return yield put({ type: "auth/sessionExpired" })
		} else if (groupsData === true) {
			return yield put({ type: "manage/setErrorNotFound", payload: true })
		}

		if (orgsData === "sessionExpired") {
			return yield put({ type: "auth/showPadlock" })
		} else if (orgsData === "sessionDestroyed") {
			return yield put({ type: "auth/sessionExpired" })
		} else if (orgsData === true) {
			return yield put({ type: "manage/setErrorNotFound", payload: true })
		}
		/* ----------------------- END VERIFY API CALL RESULT ----------------------- */

		yield put({ type: "saveBranch", payload: orgsData })

		if (ADGroupsData) {
			const reps = ADGroupsData._embedded.items
				? ADGroupsData._embedded.items?.map((item) => ({
						...item,
						isAD: true,
				  }))
				: []

			if (reps?.length > 10) {
				reps.push({
					name: I18n.t("manage.getAllGroups"),
					loadData: "M_GET_ALL_AD_GROUPS_SAGA",
					loadMoreItem: true,
				})
			}

			yield put({
				type: "manage/addReps",
				payload: {
					data: reps,
					...action.payload,
					stateTarget: 2,
					isArbo: payload.getArbo,
				},
			})
		} else {
			yield put({ type: "manage/hideADGroups" })
		}

		if (groupsData) {
			const reps = groupsData._embedded.items

			if (reps?.length > 10) {
				reps.push({
					name: I18n.t("manage.getAllGroups"),
					loadData: "M_GET_ALL_GROUPS_SAGA",
					loadMoreItem: true,
				})
			}

			yield put({
				type: "manage/addReps",
				payload: {
					data: reps,
					...action.payload,
					stateTarget: 1,
					isArbo: payload.getArbo,
				},
			})
		}

		const pageSize = yield select((state) => state.manage.pageSize)

		orgUsersData.pages = Math.ceil(orgUsersData.total / pageSize)
		orgUsersData.page = Math.ceil(payload.limit / pageSize)
		orgUsersData.limit = pageSize

		if (payload?.userId) {
			const userData = yield call(Api.getUser, {
				...requestPayloadBase,
				id: payload?.userId,
			})

			if (userData === "sessionExpired") {
				return yield put({ type: "auth/showPadlock" })
			} else if (userData === "sessionDestroyed") {
				return yield put({ type: "auth/sessionExpired" })
			} else if (userData === true) {
				return yield put({
					type: "manage/setErrorNotFound",
					payload: true,
				})
			}

			const currentUserInOrganisation =
				orgUsersData?._embedded?.items?.find((user) => {
					return userData?.id === user?.id
				})

			yield put({
				type: "manage/updateActive",
				payload: {
					addUser: true,
					...userData,
					...(!!currentUserInOrganisation
						? currentUserInOrganisation
						: {}),
					elemType: "User",
					elemKey: userData?.id,
				},
			})
		}

		if (!payload.getArbo) {
			yield put({
				type: "manage/addElements",
				payload: { ...orgUsersData, ...payload },
			})

			yield put({
				type: "manage/updateData",
				payload: {
					isAdmin: isAdminOrSuperiorAdmin,
					superiorAdmin: false,
				},
			})
		}

		const reps = [...orgsData.children]
		const newReps = populateOrgData(reps)

		delete orgsData.children
		delete action.payload.initCall

		yield put({
			type: "manage/addReps",
			payload: { data: reps, stateTarget: 0 },
			payload: { data: newReps, stateTarget: 0, isArbo: payload.getArbo },
		})

		if (!payload.getArbo) {
			yield put({
				type: "manage/updateData",
				payload: { ...orgsData, ...payload, stateTarget: 0 },
			})
		}
	}
	if (payload?.subOrgaId) {
		if (payload?.limit) {
			yield put({
				type: "manage/savePageSize",
				payload: payload?.limit / 2,
			})
		}

		/* -------------------------------- API CALLS ------------------------------- */
		let orgsData = null

		if (!branchInfo || !branchInfo?.id) {
			orgsData = yield call(Api.getOrgs, {
				...requestPayloadBase,
				...payload,
			})

			// VERIFY API CALL RESULT
			if (orgsData === "sessionExpired") {
				return yield put({ type: "auth/showPadlock" })
			} else if (orgsData === "sessionDestroyed") {
				return yield put({ type: "auth/sessionExpired" })
			} else if (orgsData === true) {
				return yield put({
					type: "manage/setErrorNotFound",
					payload: true,
				})
			}

			yield put({ type: "manage/saveBranch", payload: orgsData })
		} else {
			orgsData = branchInfo
		}

		const orgUsersData = yield call(Api.getOrgUsers, {
			...requestPayloadBase,
			...payload,
			organizationId: payload?.subOrgaId,
		})

		// VERIFY API CALL RESULT
		if (orgUsersData === "sessionExpired") {
			return yield put({ type: "auth/showPadlock" })
		} else if (orgUsersData === "sessionDestroyed") {
			return yield put({ type: "auth/sessionExpired" })
		} else if (orgUsersData === true) {
			return yield put({ type: "manage/setErrorNotFound", payload: true })
		}
		/* ------------------------------ END API CALLS ----------------------------- */

		const currentUserItem = orgUsersData?._embedded?.items?.find(
			(user) => user?.id === currentUserId,
		)
		const isAdmin = currentUserItem ? currentUserItem.isAdmin : false

		findSubOrga([orgsData], payload?.subOrgaId)
		createHistory([orgsData], payload?.subOrgaId, subOrga?.parentId)
		cleanHistory(historyData, subOrga?.parentId)

		yield put({
			type: "manage/updateData",
			payload: {
				...subOrga,
				...payload,
				historyData: cleanHistoryData.reverse(),
				reset: true,
				isAdmin,
				superiorAdmin: isAdminOrSuperiorAdmin,
				stateTarget: 0,
			},
		})

		const pageSize = yield select((state) => state.manage.pageSize)

		orgUsersData.pages = Math.ceil(orgUsersData.total / pageSize)
		orgUsersData.page = Math.ceil(payload.limit / pageSize)
		orgUsersData.limit = pageSize

		if (payload?.userId && Array.isArray(orgUsersData?._embedded?.items)) {
			if (
				!orgUsersData?._embedded?.items.find(
					(user) => user?.id === parseInt(payload?.userId),
				)
			) {
				return yield put({
					type: "manage/setErrorNotFound",
					payload: true,
				})
			}

			if (payload?.userId) {
				const userData = yield call(Api.getUser, {
					...requestPayloadBase,
					id: payload?.userId,
				})

				if (userData === "sessionExpired") {
					return yield put({ type: "auth/showPadlock" })
				} else if (userData === "sessionDestroyed") {
					return yield put({ type: "auth/sessionExpired" })
				} else if (userData === true) {
					return yield put({
						type: "manage/setErrorNotFound",
						payload: true,
					})
				}

				const currentUserInOrganisation =
					orgUsersData?._embedded?.items?.find((user) => {
						return userData?.id === user?.id
					})

				yield put({
					type: "manage/updateActive",
					payload: {
						addUser: true,
						...userData,
						...(!!currentUserInOrganisation
							? currentUserInOrganisation
							: {}),
						elemType: "User",
						elemKey: userData?.id,
					},
				})
			}
		}

		yield put({
			type: "manage/addElements",
			payload: { ...orgUsersData, ...payload },
		})
	} else if (payload?.groupId || payload?.groupADId) {
		if (payload?.limit) {
			yield put({
				type: "manage/savePageSize",
				payload: payload?.limit / 2,
			})
		}

		const isAD = payload?.groupId
			? false
			: payload?.groupADId
			? true
			: false
		const stateTarget = isAD ? 2 : 1
		let currentGroup = null
		let data = null

		const requestsData = yield all([
			call(Api.getGroups, {
				...requestPayloadBase,
				...payload,
				getAD: payload?.groupId
					? false
					: payload?.groupADId
					? true
					: false,
			}),
			call(Api.getGroupUsers, {
				token,
				xLsT,
				groupId: payload?.groupId
					? payload?.groupId
					: payload?.groupADId,
			}),
		])

		const groupsData = requestsData?.[0]
		data = requestsData?.[1]

		/* ------------------------- VERIFY API CALL RESULT ------------------------- */
		if (groupsData === "sessionExpired") {
			return yield put({ type: "auth/showPadlock" })
		} else if (groupsData === "sessionDestroyed") {
			return yield put({ type: "auth/sessionExpired" })
		} else if (groupsData === true) {
			return yield put({ type: "manage/setErrorNotFound", payload: true })
		}

		if (data === "sessionExpired") {
			return yield put({ type: "auth/showPadlock" })
		} else if (data === "sessionDestroyed") {
			return yield put({ type: "auth/sessionExpired" })
		} else if (data === true) {
			return yield put({ type: "manage/setErrorNotFound", payload: true })
		}
		/* ----------------------- END VERIFY API CALL RESULT ----------------------- */

		if (groupsData) {
			currentGroup = groupsData._embedded.items.find(
				(item) =>
					item?.id ===
					payload?.[
						payload?.groupId
							? "groupId"
							: payload?.groupADId
							? "groupADId"
							: "groupId"
					],
			)
		}

		const pageSize = yield select((state) => state.manage.pageSize)

		data.pages = Math.ceil(data.total / pageSize)
		data.page = Math.ceil(payload.limit / pageSize)
		data.limit = pageSize

		if (payload?.userId && Array.isArray(data?._embedded?.items)) {
			if (
				!data?._embedded?.items.find(
					(user) => user?.id === parseInt(payload?.userId),
				)
			) {
				return yield put({
					type: "manage/setErrorNotFound",
					payload: true,
				})
			}
		}

		yield put({
			type: "manage/updateData",
			payload: {
				...currentGroup,
				stateTarget,
				comp: "Group",
				files: data,
				reset: true,
				isAdmin: isAdminOrSuperiorAdmin,
			},
		})
	}

	/* --------------------------- DEACTIVATE LOADERS --------------------------- */
	loading = yield select((state) => state.manage.loading)

	if (loading) yield put({ type: "manage/toggleLoading" })
	/* ------------------------- END DEACTIVATE LOADERS ------------------------- */

	yield put({ type: "manage/setCR" })
}

export function* removeMfaFromManage(action) {
	try {
		yield put({ type: "manage/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { ...action.payload, token, xLsT }

		const data = yield call(Api.removeMfaByAdmin, payload)

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				toastr.success(
					I18n.t("manage.removeMfaMiniPop.operationSuccessTitle"),
					I18n.t("manage.removeMfaMiniPop.operationSuccess"),
				)

				yield put({
					type: "manage/removeMfaFromUser",
					payload: { userId: action.payload.userId },
				})

				yield put({ type: "action/closeMiniAction" })
			}
		}
	} catch (error) {
		console.warn(error)
	}
}

export default function* manageSagas() {
	yield takeLeading("M_GET_ALL_GROUPS_SAGA", getAllGroups)
	yield takeLeading("M_GET_ALL_AD_GROUPS_SAGA", getAllAdGroups)

	yield takeEvery("M_GET_GROUPS_SAGA", getGroups)
	yield takeLeading("M_GET_GROUP_USERS_SAGA", getGroupUsers)
	yield takeLeading("M_POST_GROUP_SAGA", postGroup)
	yield takeLeading("M_PUT_GROUP_SAGA", putGroup)
	yield takeLeading("M_DELETE_GROUP_SAGA", deleteGroup)

	yield takeLeading("M_ADD_USER_TO_GROUP_SAGA", addUserToGroup)
	yield takeLeading("M_ADD_MULTI_USERS_TO_GROUP_SAGA", addMultiUsersToGroup)
	yield takeLeading("M_REMOVE_USER_FROM_GROUP_SAGA", removeUserFromGroup)

	yield takeLeading("M_GET_ORGS_SAGA", getOrgs)
	yield takeLeading("M_POST_ORG_SAGA", postOrg)
	yield takeLeading("M_PUT_ORG_SAGA", putOrg)
	yield takeLeading("M_GET_ORG_USERS_SAGA", getOrgUsers)
	yield takeLeading("M_DELETE_ORG_SAGA", deleteOrga)

	yield takeLeading("M_GET_DATA_SAGA", getData)

	yield takeLeading("M_GET_USER_SAGA", getUser)
	yield takeLeading("M_GET_USER_REPORT", getUserReport)
	yield takeLeading("M_POST_USER_SAGA", postUser)
	yield takeLeading("M_POST_API_USER_SAGA", postApiUser)
	yield takeLeading("M_PUT_API_USER_SAGA", putApiUser)
	yield takeLeading("M_PUT_USER_SAGA", putUser)
	yield takeLeading("M_PUT_USER_STATUS_SAGA", putUserStatus)
	yield takeLeading("M_PUT_USER_MODERATOR_SAGA", putUserModerator)
	yield takeLeading("M_PUT_USER_ADMIN_SAGA", putUserAdmin)
	yield takeLeading("M_DELETE_USERS_SAGA", deleteUsers)
	yield takeLeading("M_RESEND_ACTIV_USER_SAGA", resendActivUser)
	yield takeLeading("M_PUT_SSO_USER_PASSWORD", setSsoUserPassword)
	yield takeLeading("M_REMOVE_SSO_USER_PASSWORD_SAGA", removeSsoUserPassword)
	yield takeLeading("OPTIONS_MFA_MANAGE_REMOVAL", removeMfaFromManage)

	yield takeLeading("M_SORT_USER_SAGA", getSortUser)

	/* -------------------------- MANAGEMENT PERMALINKS ------------------------- */
	yield takeLeading("M_GET_DATA_PERMALINKS_SAGA", getDataPermalinks)
	/* ------------------------ END MANAGEMENT PERMALINKS ----------------------- */

	const channel = yield actionChannel("M_WITH_BUFFER_SAGA")

	while (true) {
		const payload = yield take(channel)

		switch (payload.payload.channelMod) {
			case "putUser": {
				yield call(putUser, payload)
				break
			}
			default:
				return null
		}
	}
}
