/*
	CatTree
	This component displays the user's categories and allows him to select one.
	The user can first select a favorite or recently used category and, when clicking a button,
	can display a tree that can deploy progressively, when the user click on an existing category.

	Props
	newRep
	setNewRep
	successCallback
	closeCallback
	globalPerso
	globalShared
	controller

*/

import React, { useEffect, useState, useRef, useMemo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import LoaderBars from "../../animations/LoaderBars"
import Category from "./Category"
import SearchedCat from "./SearchedCat"
import WidthController from "../../controllers/WidthController"

import * as Api from "../../../pages/pass/services"

import { ReactComponent as SecureFiles } from "../../../assets/helpers/secureFiles.svg"

import PropTypes from "prop-types"

const CatTree = (props) => {
	const {
		newRep,
		setNewRep,
		globalPerso,
		globalShared,
		controller,
		displayStateObject,
	} = props
	const {
		searchLoader,
		setSearchLoader,
		setSearchString,
		debouncedSearchString,
		setDebouncedSearchString,
		searchResults,
	} = props.searchObject

	const dispatch = useDispatch()
	const [t] = useTranslation()

	const token = useSelector((state) => state.auth.token.token)
	const xLsT = useSelector((state) => state.auth.xLsToken)
	const lastActionResult = useSelector((state) => state.pass.lastActionResult)

	const initialRender = useRef(true)
	const controlledElement = useRef()
	const searchListContainer = useRef()

	const [localPersoCategories, setLocalPersoCategories] = useState()
	const [localSharedCategories, setLocalSharedCategories] = useState()
	const [targetPath, setTargetPath] = useState()
	const [hoveringTarget, setHoveringTarget] = useState(false)
	const [fetchingBranch, setFetchingBranch] = useState(false)

	const selectedStateObject = { newRep, setNewRep }
	const targetPathStateObject = { targetPath, setTargetPath }
	const fetchingBranchStateObject = { fetchingBranch, setFetchingBranch }

	// ======== MEMOIZATION ========
	// The folloing useMemo and useCallback are here to allow the component to either work with global states for categories or local.
	// With global states, the categories are stored in a parent component and passed as the globalPerso and globalShared props.
	// If these propps are not specified, the component work with states that are defined in this file.
	const persoCategories = useMemo(() => {
		if (globalPerso) {
			return globalPerso.persoCategories
		} else {
			return localPersoCategories
		}
	}, [globalPerso?.persoCategories, localPersoCategories])

	const setPersoCategories = useCallback(
		(newState) => {
			if (globalPerso) {
				return globalPerso.setPersoCategories(newState)
			} else {
				return setLocalPersoCategories(newState)
			}
		},
		[globalPerso],
	)

	const sharedCategories = useMemo(() => {
		if (globalShared) {
			return globalShared.sharedCategories
		} else {
			return localSharedCategories
		}
	}, [globalShared?.sharedCategories, localSharedCategories])

	const setSharedCategories = useCallback(
		(newState) => {
			if (globalShared) {
				return globalShared.setSharedCategories(newState)
			} else {
				return setLocalSharedCategories(newState)
			}
		},
		[globalShared],
	)

	// Memoize the category trees states to avoid useless calculation.
	const persoStateObject = useMemo(() => {
		return { persoCategories, setPersoCategories }
	}, [persoCategories, setPersoCategories])
	const sharedStateObject = useMemo(() => {
		return { sharedCategories, setSharedCategories }
	}, [sharedCategories, setSharedCategories])

	// ======== USEEFFECTS ========
	// When first rendering, and if we don't have any categories (we can have them if we're using global
	// states), get the root categories.
	useEffect(() => {
		if (initialRender.current && (!persoCategories || !sharedCategories)) {
			dispatch({
				type: "LP_GET_ROOT_CATEGORIES_SAGA",
				payload: { runFromInput: true },
			})
			dispatch({
				type: "LP_GET_PERSO_CATEGORIES_SAGA",
				payload: { setInLar: true },
			})
		}
	}, [initialRender])

	// Listen for sagas result
	useEffect(() => {
		// When the GET_ROOT_CATEGORIES saga has ended, add the root categories to the sharedCategories
		// object (only happens twice : during first render and after initial sagas call)
		if (lastActionResult?.message === "rootCategoriesFetched") {
			const newState = { name: "Shared space" }
			newState.children = lastActionResult.copyData
			setSharedCategories(newState)
		}

		// When the GET_PERSO_CATEGORIES saga has ended, add the personnal categories tree to the
		// persoCategories object (only happens twice : during first render and after initial sagas call)
		if (lastActionResult?.message === "successPC") {
			const newState = { id: 0, name: "Personal space" }
			newState.children = lastActionResult.data

			setPersoCategories(newState)
		}

		// Don't forget to clear the lastActionResult when we're done !
		dispatch({ type: "pass/setLAR" })
	}, [lastActionResult])

	// Track the initial render
	useEffect(() => {
		initialRender.current = false
	}, [initialRender])

	// ======== FUNCTIONS AND HANDLERS ========
	// Fetch a root category branch if the data isn't already present.
	// WARNING : Exception to the saga => service => lar rule here !
	// The fetch is done in the component, not via a saga, because the user may have to display
	// a lot of root categories and I don't want to have multiple useless useEffects executions
	// to catch the fetch response whenever we get a single branch.
	const fetchBranch = async (
		branch,
		categoryTree,
		categoryTreeCallback,
		loaderCallback,
	) => {
		const payload = { token, xLsT, mainId: branch.mainId }
		const newState = JSON.parse(JSON.stringify(categoryTree))

		const response = await Api.getBranch(payload)

		const categoryChildren = response.tree[0].children

		// Add the result to the sharedCategories state, if there are children, and
		// register the fetch in the category object (if the user goes back and want to
		// do a search, the CatTree will need this).
		for (let i = 0; i < newState.children.length; i++) {
			const item = newState.children[i]

			if (item.id === branch.id) {
				if (categoryChildren) {
					newState.children[i].children = [...categoryChildren]
				}
				newState.children[i].fetched = true
				categoryTreeCallback(newState)
				loaderCallback(false)
				return newState
			}
		}
	}

	// When telling the application we want to see where a searched category is, in the tree, trigger
	// this function.
	const handleSelectTarget = async (targetCategory) => {
		setSearchLoader(true)

		// The lookForCategory function iterate through the category tree to search for
		// a given category and return an array containing each parent's id.
		const lookForCategory = (id, categoryTree, idArray = []) => {
			const resultArray = [...idArray]

			// If we are in the searched category, simply return the id array.
			if (id === categoryTree?.id) {
				return [...resultArray, id]

				// If this is not the category we're looking for but it has children,
				// run the function once again for each children.
			} else if (categoryTree?.children) {
				let newCallResult

				for (let i = 0; i < categoryTree.children.length; i++) {
					const item = categoryTree.children[i]
					const newSearch = lookForCategory(id, item, resultArray)

					// If the children of the children returns something it means it's
					// part of the branch that ends with the right category. Add the
					// current children id to the result and return the array.
					// We must also exit the loop, any further category won't contain
					// the result.
					if (newSearch) {
						// If the current category is the shared root, it will have no ID, put "sharedRoot" instead
						if (categoryTree.id === undefined)
							categoryTree.id = "sharedRoot"
						newCallResult = [categoryTree.id, ...newSearch]
						i = categoryTree.children.length
					}
				}

				// After doing the whole loop, return the newCallResult.
				return newCallResult

				// If this is not the searched category and it doesn't have children, return
				// nothing, we are dropping this branch.
			} else {
				return
			}
		}

		// Start by getting the root type.
		if (targetCategory.mainId === 0) {
			// If the category is in personal space, just get it with lookForCategory.
			const newTargetPath = lookForCategory(
				targetCategory.id,
				persoCategories,
			)
			setTargetPath(newTargetPath)
		} else {
			// If the category is in shared space, we must start by getting the correct branch.
			let mainCategory

			for (let i = 0; i < sharedCategories?.children?.length; i++) {
				const item = sharedCategories.children[i]

				if (item.id === targetCategory.mainId) {
					mainCategory = item
					i = sharedCategories.children.length
				}
			}

			// Now check if the main category has been fetched, we only want to execute the
			// service if necessary.
			if (!mainCategory.fetched) {
				const response = await fetchBranch(
					mainCategory,
					sharedCategories,
					setSharedCategories,
					() => {},
				)

				const newTargetPath = lookForCategory(
					targetCategory.id,
					response,
				)
				setTargetPath(newTargetPath)
			} else {
				const newTargetPath = lookForCategory(
					targetCategory.id,
					sharedCategories,
				)
				setTargetPath(newTargetPath)
			}
		}

		// Set every search state to empty strings to render the categories tree.
		setSearchString("")
		setDebouncedSearchString("")

		// Select the target and hide the loader.
		setNewRep(targetCategory)
		setSearchLoader(false)
		return null
	}

	// ======== RENDERS ========
	// Start the rendering of the personal space tree
	const renderPersoTree = () => {
		return (
			<Category
				data={persoCategories}
				key={`perso-root`}
				isRootCat
				displayStateObject={displayStateObject}
				persoStateObject={persoStateObject}
				selectedStateObject={selectedStateObject}
				targetPathStateObject={targetPathStateObject}
				fetchingBranchStateObject={fetchingBranchStateObject}
			/>
		)
	}

	// Start the rendering of the shared space tree
	const renderSharedTree = () => {
		return (
			<Category
				data={sharedCategories}
				key={`shared-root`}
				isRootCat
				displayStateObject={displayStateObject}
				sharedStateObject={sharedStateObject}
				selectedStateObject={selectedStateObject}
				targetPathStateObject={targetPathStateObject}
				fetchingBranchStateObject={fetchingBranchStateObject}
			/>
		)
	}

	// Render a searched category
	const renderSearchCat = (item, index) => {
		return (
			<SearchedCat
				data={item}
				setTargetCat={handleSelectTarget}
				selectedStateObject={selectedStateObject}
				searchedString={debouncedSearchString}
				key={index}
			/>
		)
	}

	return (
		<section className="CatTree" ref={controlledElement}>
			<div className="contentWrapper">
				{debouncedSearchString ? (
					searchLoader ? (
						<div
							className={`loaderContainer${
								hoveringTarget ? " blueBorder" : ""
							}`}
						>
							<LoaderBars />
						</div>
					) : (
						<div
							className={`searchListContainer${
								hoveringTarget ? " blueBorder" : ""
							}`}
							ref={searchListContainer}
						>
							<ul className="searchList">
								{searchResults?.length > 0 ? (
									searchResults?.map(renderSearchCat)
								) : (
									<div className="noResult">
										<div className="noResult-container">
											<SecureFiles />
											<p>
												{t("pass.movePass.noCatFound")}
											</p>
										</div>
									</div>
								)}
							</ul>
						</div>
					)
				) : (
					<div
						className={`categoriesListContainer${
							hoveringTarget ? " blueBorder" : ""
						}`}
					>
						<ul className="categoriesList">
							{persoCategories && renderPersoTree()}
							{sharedCategories && renderSharedTree()}
						</ul>
					</div>
				)}
			</div>
			<WidthController
				controller={controller.current}
				controlled={controlledElement.current}
				setHoverState={setHoveringTarget}
			/>
		</section>
	)
}

export default CatTree

CatTree.propTypes = {
	newRep: PropTypes.any,
	setNewRep: PropTypes.func,
	closeCallback: PropTypes.func,
	globalPerso: PropTypes.any,
	globalShared: PropTypes.any,
	controller: PropTypes.any,
	displayStateObject: PropTypes.object,
	searchObject: PropTypes.any,
}
