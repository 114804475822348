import { toastr } from "react-redux-toastr"
import I18n from "../../i18n"
import {
	backwardCompApi,
	decodeBase64,
	handleError,
	publishTimeUpdate,
	secondsToTimeFormat,
	/*, decodeBase64*/
} from "../../actions/generalUtils"

function headers(payload) {
	let a = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-Ls-Token": payload.xLsT,
	}

	if (!payload.formData) a = { ...a, "Content-Type": "application/json" }

	return a
}

function formQuery(d) {
	let q = ""
	let first = true

	if (d.ids) {
		first = false
		q += "?ids=" + d.ids
	}

	if (typeof d.organizationId !== "undefined") {
		if (first) q += "?organizationId=" + d.organizationId
		else q += "&organizationId=" + d.organizationId
		first = false
	}

	return q
}

export const changePass = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "users/password"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const changePin = (payload) => {
	////console.log(payload)

	let responseStatus
	const url = localStorage.getItem("apiUrl") + "users/pin"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const checkPin = (payload) => {
	let responseStatus
	const url = localStorage.getItem("apiUrl") + "users/pin/validate"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify({ pinCode: payload.data.pinCode }),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const changeName = (payload) => {
	let responseStatus
	const url = localStorage.getItem("apiUrl") + "users/" + payload.userId

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? res.json() : res))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const postPP = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "passwordpolicies"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const editPP = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "passwordpolicies/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const delPP = (payload) => {
	////console.log(payload.ids)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "passwordpolicies" + formQuery(payload)

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const importPass = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "credentials/import"

	const data = new FormData()
	data.append("file", payload.data.file)
	data.append("organizationId", payload.data.organizationId)
	data.append("format", payload.data.format)
	data.append("currentChunk", payload.data.currentChunk)
	data.append("totalChunkCount", payload.data.totalChunkCount)
	data.append("firstUpload", payload.data.firstUpload)

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body: data,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus
				? res?.code === 422
					? "badEncode"
					: handleError(res)
				: res,
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const importUser = (payload) => {
	////console.log(payload.ids)
	//console.log(payload);

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "users/import"

	const data = new FormData()
	data.append("file", payload.data.file)
	data.append("organizationId", payload.data.organizationId)
	data.append("firstUpload", payload.data.firstUpload)

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body: data,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const importGroupsUsers = (payload) => {
	////console.log(payload.ids)
	//console.log(payload);

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "groups/import"

	const data = new FormData()
	data.append("file", payload.data.file)
	data.append("organizationId", payload.data.organizationId)

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body: data,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const importArborescence = (payload) => {
	////console.log(payload.ids)
	//console.log(payload);

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "files/folder/import"

	const data = new FormData()
	data.append("file", payload.data.file)
	data.append("organizationId", payload.data.organizationId)
	data.append("firstUpload", payload.data.firstUpload)

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body: data,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const importOrg = (payload) => {
	////console.log(payload.ids)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "organizations/import"

	const data = new FormData()
	data.append("file", payload.data.file)
	data.append("organizationId", payload.data.organizationId)
	data.append("format", payload.data.format)

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body: data,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const importCat = (payload) => {
	////console.log(payload.ids)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "categories/import"

	const data = new FormData()
	data.append("file", payload.data.file)
	data.append("organizationId", payload.data.organizationId)
	data.append("firstUpload", payload.data.firstUpload)

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body: data,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const exportPass = (payload) => {
	////console.log(payload.ids)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "credentials/export/perso"

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

// This function fetches a page of data.
// It takes an offset as a parameter, which is used to determine which page to fetch.
export const exportPassPersoOpti = (payload) => {
	let offset = 1
	let total = 0
	const decoder = new TextDecoder()
	let data = []
	const lineRegex = /(\d+ ------ ([^;]*;){10}[^;]*;)/g

	let totalPages = 0
	let firstPageProcessingTime = 0
	let lastProcessingTime = 0

	function fetchData(offset) {
		const startFetchTime = Date.now()
		let url =
			localStorage.getItem("apiUrl") +
			"credentials/export/perso?offset=" +
			offset

		return fetch(url, {
			method: "GET",
			headers: headers(payload),
		})
			.catch((e) => {
				toastr.error(
					I18n.t("forServices.error"),
					I18n.t("forServices.netIssue"),
				)
				throw e
			})
			.then((response) => {
				const reader = response.body.getReader()
				const lignePagination = 2500

				return reader.read().then(function processText({
					done,
					value,
				}) {
					if (done) {
						const processingTimeCurrentPage =
							(Date.now() - startFetchTime) / 1000
						if (offset === 1) {
							firstPageProcessingTime = processingTimeCurrentPage
						}
						if (offset === totalPages - 1) {
							lastProcessingTime = processingTimeCurrentPage
						}
						if (offset === totalPages) {
							return data
						}
						if (offset === 1) {
							const remainingLines = total - lignePagination
							const remainingTimeEstimated =
								(firstPageProcessingTime / lignePagination) *
								remainingLines
							publishTimeUpdate(
								secondsToTimeFormat(remainingTimeEstimated),
							)
						}
						if (offset === totalPages - 1) {
							const remainingLines = total % lignePagination
							const remainingTimeEstimated =
								(lastProcessingTime / lignePagination) *
								remainingLines
							publishTimeUpdate(
								secondsToTimeFormat(remainingTimeEstimated),
							)
						}
						offset++
						return fetchData(offset)
					}

					const chunk = decoder.decode(value)

					const totalMatch = chunk.match(/---total:(\d+)/)
					if (totalMatch) {
						total = Number(totalMatch[1])
						totalPages = Math.ceil(total / lignePagination)
						if (total === 0) {
							return { error: "No password in personal space" }
						}
					}

					const lines = chunk.match(lineRegex) || []

					if (!lines.length) {
						console.error(
							"Aucune ligne n'a été trouvée avec le regex donné.",
						)
					}

					for (let i = 0; i < lines.length; i++) {
						const line = lines[i]
						const parts = line.split(" ------ ")
						if (parts.length === 2) {
							const lineNumber = parts[0]
							let contentParts = parts[1].slice(0, -1).split(";")
							try {
								contentParts = decodeBase64(contentParts)
							} catch (error) {
								console.error(
									`Erreur lors du décodage Base64 à la ligne ${lineNumber}: ${error.message}`,
								)
							}
							let content = contentParts.join(";")
							data.push({ lineNumber, content })
						} else {
							console.error(
								`Erreur à la ligne ${
									i + 1
								}: La ligne ne contient pas le format attendu.`,
							)
						}
					}

					return reader.read().then(processText)
				})
			})
	}

	return fetchData(offset)
}

export const exportAllPass = (payload) => {
	//console.log(payload.ids)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"credentials/export" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? res.blob() : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const exportPassAndFiles = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "download/generate/token"

	function downloadLink(link, res) {
		window.location.assign(link)
		return res
	}

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then(
			(res) => (
				!responseStatus
					? handleError(res)
					: downloadLink(res.link, res),
				res
			),
		)
}

export const exportUsersOrga = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		`user/export?organizationId=${payload.id}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? res.blob() : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getUserKey = (payload) => {
	////console.log(payload.ids)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "pubkey"

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus
				? res?.code === 404
					? "noKey"
					: handleError(res)
				: res,
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const addUserKey = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "pubkeys"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const delUserKey = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "pubkey?ids=" + payload.id

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getSMTP = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "whitemarks/smtp"

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const saveSMTP = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "whitemarks/smtps"

	if (backwardCompApi("1.18.5")) {
		url = localStorage.getItem("apiUrl") + "v2/whitemark/smtp"
	}

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const changeSMTP = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "whitemarks/smtp"

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const testSMTP = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "whitemarks/smtp/email"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => {
			if (res.status === 422) {
				return res.json()
			} else if (responseStatus) {
				return res
			} else {
				return handleError(res)
			}
		})
}

export const csvError = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "util/csv/errors"
	let body = JSON.stringify(payload)

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.blob())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const setupMFA = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "user/otp"

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => {
			// If the response is a 409, it means the user already have a secret key, which only happens if he refreshed on the setupMFA popup.
			// In that case, we must return a specific message, because the saga will have to call the cancelMFASetup instead of executing the follow up.
			if (res.code === 409) {
				return "mustRemoveSecretKey"
			}

			return !responseStatus ? handleError(res) : res
		}) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const confirmMFA = (payload) => {
	let responseStatus
	let url = `${localStorage.getItem("apiUrl")}user/verify/otp?secureCode=${
		payload.mfaCode
	}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus ? handleError(res) : { operationSucceeded: res },
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getMFARecoveryCodes = (payload) => {
	let responseStatus
	let url = `${localStorage.getItem("apiUrl")}user/otp/recovery_code`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const cancelMFASetup = (payload) => {
	let url = `${localStorage.getItem("apiUrl")}configuration/otp`
	let requestSuccess = false

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)

			throw e
		})
		.then((res) => {
			if (res.ok) {
				requestSuccess = true
				return "mfa deleted"
			}

			try {
				return res.json()
			} catch (error) {
				return true
			}
		})
		.then((res) => {
			if (!requestSuccess) {
				return handleError(res)
			}

			return res // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
		})
}

export const removeMFA = (payload) => {
	let responseStatus
	let url = `${localStorage.getItem("apiUrl")}user/otp?secureCode=${
		payload.code
	}`

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			let result = res

			if (res.status !== 204) result = res.json()
			return result
		})
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
