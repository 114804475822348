import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { ReactComponent as Minus } from "../../assets/icons/newMinus.svg"
import { ReactComponent as Lock } from "../../assets/images/lock.svg"

import { ReactComponent as Plus } from "../../assets/images/bigPlus.svg"

export default function NumInput(props) {
	let [val, setVal] = useState(0)
	const [leftAnimationActive, setLeftAnimationActive] = useState(false)
	const [rightAnimationActive, setRightAnimationActive] = useState(false)
	const [getPP, setGetPP] = useState()

	useEffect(() => {
		if (props.value) {
			setVal(props.value)
		} else {
			setVal(1)
		}
	}, [props.value])

	useEffect(() => {
		if (props.generateWithPP === undefined) {
			setGetPP()
		} else setGetPP(props.generateWithPP)
	}, [props.generateWithPP])

	const handleButtonClick = ($type) => {
		$type === "left"
			? setLeftAnimationActive(true)
			: setRightAnimationActive(true)

		// Pour s'assurer que l'animation se termine, vous pouvez réinitialiser l'état après un certain délai.
		setTimeout(() => {
			setLeftAnimationActive(false)
			setRightAnimationActive(false)
		}, 500) // 2000 millisecondes (2 secondes) ici, ajustez selon la durée de votre animation
	}

	function checkChange(v) {
		try {
			if (props.overNull && v < 1) return props.change(1)

			let n = parseInt(v)
			return props.change(n)
		} catch (e) {
			throw e
		}
	}

	return (
		<div className="numInput">
			<button
				className={`counterBtn minusBtn ${
					props.onPopUpPass && "onPopUpPass"
				} ${rightAnimationActive ? "animateRight" : ""}`}
				onClick={(event) => {
					if (!props.dataPP) {
						event.preventDefault()

						if (props.minimum) {
							if (
								props.minimum &&
								parseInt(val) > props.minimum
							) {
								checkChange(parseInt(val) - 1)
								handleButtonClick("right")
							}

							return
						}

						if (props.value > getPP?.minNbChar) {
							checkChange(props.value - 1)
						} else if (!getPP) {
							checkChange(parseInt(val) - 1)
						}
						handleButtonClick("right")
					}
				}}
			>
				{getPP !== undefined && getPP.minNbChar >= props.value ? (
					<Lock className="icon" />
				) : (
					<Minus className="icon" />
				)}
			</button>

			<input
				className={`counterValue ${props.onPopUpPass && "onPopUpPass"}`}
				value={val}
				onChange={(e) => checkChange(e.target.value)}
				type="number"
				onBlur={() => (props.onBlur ? props.onBlur() : null)}
				min="0"
			/>

			<button
				className={`counterBtn plusBtn ${
					props.onPopUpPass && "onPopUpPass"
				} ${leftAnimationActive ? "animateLeft" : ""}`}
				onClick={(event) => {
					if (!props.dataPP) {
						event.preventDefault()

						if (props.minimum) {
							if (parseInt(val) >= props.minimum) {
								checkChange(parseInt(val) + 1)
								handleButtonClick("left")
							}

							return
						}

						if (props.value < getPP?.maxNbChar) {
							checkChange(props.value + 1)
						} else if (!getPP) {
							checkChange(parseInt(val) + 1)
						}
						handleButtonClick("left")
					}
				}}
			>
				{getPP !== undefined && getPP.maxNbChar <= props.value ? (
					<Lock className="icon" />
				) : (
					<Plus className="icon" />
				)}
			</button>
		</div>
	)
}

NumInput.propTypes = {
	value: PropTypes.any,
	overNull: PropTypes.bool,
	change: PropTypes.func,
	onBlur: PropTypes.func,
	minimum: PropTypes.number,
	generateWithPP: PropTypes.object,
	onPopUpPass: PropTypes.bool,
	dataPP: PropTypes.object,
}
