import React from "react"
import NewSwitch from "../../components/inputs/NewSwitch"

import PropTypes from "prop-types"

export default function Toggler(props) {
	const { size, value, onSelect, text, customText } = props

	return (
		<div className="togglerFrame">
			<NewSwitch
				check={value}
				size={size ?? "medium"}
				onSelect={onSelect}
			/>
			{text && (
				<p
					className={`textContainer ${
						customText ? "customText" : ""
					}`}
				>
					{text}
				</p>
			)}
		</div>
	)
}

Toggler.propTypes = {
	size: PropTypes.oneOf(["small", "medium", "large"]),
	value: PropTypes.bool,
	onSelect: PropTypes.func,
	text: PropTypes.string,
	customText: PropTypes.bool,
}
