import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

export function PasswordStrength(props) {
	const { messageLength, type, noText, customText, strength } = props

	const [t] = useTranslation()
	const [message, setMessage] = useState("")

	useEffect(() => {
		if (strength && messageLength) {
			setMessage("Long")
		}
	}, [messageLength, type, customText])

	return (
		<div className="passwordStrength strength">
			<div className="strength-message">
				<p>
					{customText
						? customText
						: strength && !noText
						? t(
								`${
									type === "pass"
										? "pass.strength"
										: "pass.strengthPDM"
								}${message ? message : ""}.${strength}`,
						  )
						: ""}
				</p>
			</div>
			<div className="intensity-mark">
				<div className={`mark ${strength ? strength : ""}`}></div>
				<div
					className={`mark ${
						strength === "medium" || strength === "high"
							? strength
							: ""
					}`}
				></div>
				<div
					className={`mark ${strength === "high" ? strength : ""}`}
				></div>
			</div>
		</div>
	)
}

PasswordStrength.propTypes = {
	customText: PropTypes.string,
	type: PropTypes.string,
	messageLength: PropTypes.bool,
	noText: PropTypes.bool,
	strength: PropTypes.string,
}
