import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"

import { ReactComponent as ActionArrow } from "../../assets/images/actionArrow.svg"

// WidthController
//
// A component that allows the user, by clicking on it, to change with mouse the width of another element.

const WidthController = (props) => {
	const { controlled, controller, setHoverState, customIcon, customFunc } =
		props
	const [trackMousePosition, setTrackMousePosition] = useState(false)

	// Get the root element font size. This will be used to calculate width later.
	const rootFontSize = window.getComputedStyle(
		document.documentElement,
	).fontSize

	//======== MEMOIZED VALUES ========
	// Whenever the mouse moves, track its position if the user is trying to change the list wideness.
	const trackPosition = useCallback(
		(event) => {
			// Start by checking if the cursor is still in the web page.
			if (event.target.tagName) {
				const controlledRightBoundary =
					controlled.getBoundingClientRect().right
				const newWidthPx =
					event.pageX - controlled.getBoundingClientRect().left
				let newWidthRem = newWidthPx / parseInt(rootFontSize)

				// Ne réduire que si la souris est à l'intérieur de la div contrôlée
				if (event.pageX < controlledRightBoundary) {
					controlled.style.width = `${newWidthRem}rem`
				}
				// Calculate the new width in rem
				newWidthRem = newWidthPx / parseInt(rootFontSize)
				if (customFunc) {
					customFunc()
				}

				return (controlled.style.width = `${newWidthRem}rem`)
			}

			// If we didn't return anything at this point, it means the cursor is outside the
			// application, don't do anything.
			return null
		},
		[controlled, controller],
	)

	// When the user release the mouse, the component must be notified.
	const stopTracking = useCallback(() => {
		setTrackMousePosition(false)
	}, [])

	//======== USEEFFECTS ========
	// Track the user's interactions with the component. If he clicked, track the
	// mouse position. If he released, kill the event listeners.
	useEffect(() => {
		if (trackMousePosition) {
			document.addEventListener("mousemove", trackPosition)
			document.addEventListener("mouseup", stopTracking)
		} else {
			document.removeEventListener("mousemove", trackPosition)
			document.removeEventListener("mouseup", stopTracking)
			setHoverState(false)
		}
	}, [trackMousePosition])

	//======== HANDLERS AND FUNCTIONS ========
	const handleStopHovering = () => {
		if (!trackMousePosition) {
			setHoverState(false)
		}
	}

	//======== RENDERS ========
	return (
		<div
			id="changeWindowDimensions"
			className="blue"
			onMouseDown={() => setTrackMousePosition(true)}
			onMouseEnter={() => setHoverState(true)}
			onMouseLeave={handleStopHovering}
		>
			<div className="wDBlock">
				{customIcon ? (
					customIcon
				) : (
					<>
						<ActionArrow />
						<ActionArrow />
					</>
				)}
			</div>
			{trackMousePosition && (
				<div className="wDBlock displayOnlyMyCursor" />
			)}
		</div>
	)
}

export default WidthController

//======== PROPS DEFINITIONS ========
WidthController.propTypes = {
	controlled: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({
			current: PropTypes.instanceOf(Element),
		}),
	]),
	controller: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({
			current: PropTypes.instanceOf(Element),
		}),
	]),
	setHoverState: PropTypes.func,
	customIcon: PropTypes.any,
	customFunc: PropTypes.func,
}

// Props details
//
// controlled -> Doit être un élément de DOM. L'élément dont on veut faire varier la largeur. Pour passer un élément de DOM en props,
//					créer une référence avec useRef et la passer à l'attribut 'ref' de l'élément que l'on souhaite cibler. Passer
//					ensuite cette même référence à WidthController dans la prop 'controlled'.
// controller -> Doit être un élément de DOM. L'élément duquel l'élément contrôlé doit pouvoir dépasser. Il définit la largeur
//					minimale de l'élément contrôlé.
// setWidthState -> La fonction associé au state qui défini la largeur de l'élément contrôlé. A noter que la largeur sera
//					définie en rem.
// setHoverState -> Une fonction déclenchée lorsque le curseur passe sur WidthController.
