import {
	call,
	put,
	takeLeading,
	select,
	takeLatest,
	takeEvery,
} from "redux-saga/effects"
import i18next from "i18next"

import { addLCS, toast, removeLCS, copyToClip } from "../actions/generalUtils"
import { removeRecentCatEntry } from "../pages/pass/lpUtils.jsx"
import {
	openExt,
	removeLastSawEntry,
	callBackground,
} from "./tools/extensionUtils"

import * as Api from "./services"
import * as LPApi from "../pages/pass/services"
import { authenticator } from "otplib"

const { t } = i18next

// Create a new password from the popup.
function* createPass(action) {
	try {
		// Register the saga call in localStorage
		addLCS(action)

		//console.log("create pass -> action -> ", action)

		// Get the user authentication data and add it to the payload
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = {
			token,
			xLsT,
			data: { isAlert: false, ...action.payload },
		}

		const response = yield call(Api.createPass, payload)

		switch (response) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default:
				//let a = window.location.href.split("/").slice(0,-1).join("/")
				removeLCS(action)

				localStorage.removeItem("extPopSaveVals")
				toast(t("popup.create.success"))

				setTimeout(() => {
					window.close()
				}, 2000)
		}
	} catch (error) {
		console.error("[LS] CreatePass error : ", error)
	}
}

// Get every credentials associated with a given URL
function* getDomainPass(action) {
	try {
		// Register the saga call in localStorage
		//addLCS(action);

		//console.log("SAGA getDomainPass -> action -> ", action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, data: { ...action.payload } }

		yield put({ type: "extension/setLoading", payload: true })

		const response = yield call(Api.getDomainPass, payload)

		switch (response) {
			case "sessionExpired":
				return openExt("loginOnPopup")
			case "sessionDestroyed":
				return openExt("loginOnPopup")
			case true:
				return
			default:
				// Now that we have the passwords, we're going to organize them.
				const organizedPasswords = {}

				for (let i = 0; i < response.length; i++) {
					if (response[i]?.isInTrash) {
						return
					}

					const currentCredential = response[i]
					const currentCategory = currentCredential.categoryName

					// If the current credential's category isn't already in the new list, add it.
					if (!organizedPasswords[currentCategory])
						organizedPasswords[currentCategory] = []

					organizedPasswords[currentCategory].push(currentCredential)
				}

				// Now, put the organized list in the store.
				yield put({
					type: "extension/setActiveDomainPasswords",
					payload: organizedPasswords,
				})

				// Don't forget to disable the loader !
				return yield put({ type: "extension/setLoading" })
		}
	} catch (error) {
		console.error("[LS] GetDomainPass saga error : ", error)
	}
}

function* getPassDetails(action) {
	try {
		// Register the saga call in localStorage
		//addLCS(action);

		// Get every data needed to make the request
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, data: action.payload }

		// Display the loader, then make the request
		yield put({ type: "extension/setLoading", payload: true })

		const response = yield call(Api.getPassDetails, payload)

		switch (response) {
			case "sessionExpired":
				return openExt("loginOnPopup")
			case "sessionDestroyed":
				return openExt("loginOnPopup")
			case true:
				return
			case "NotFound":
				/* eslint-disable no-undef */
				if (chrome?.runtime) {
					window.location.href =
						chrome.runtime.getURL("index.html#/popup")
				}

				removeLastSawEntry(action.payload)

				return yield put({ type: "extension/setLoading" })
			default:
				// Put the response in redux store, then hide the loader.
				yield put({
					type: "extension/setCredentialDetails",
					payload: response,
				})
				return yield put({ type: "extension/setLoading" })
		}
	} catch (error) {
		return console.error("[LS] GetPassDetails saga error : ", error)
	}
}

function* sendPasswordUsageLog(action) {
	try {
		// Register the saga call in localStorage
		//addLCS(action);

		// Get every data needed to make the request
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, data: action.payload }

		// Display the loader, then make the request

		const response = yield call(Api.sendPasswordUsageLog, payload)

		switch (response) {
			case "sessionExpired":
				return openExt("loginOnPopup")
			case "sessionDestroyed":
				return openExt("loginOnPopup")
			case true:
				return
			case "NotFound":
				return
			default:
				// Put the response in redux store, then hide the loader.
				return yield put({
					type: "extension/setPasswordUsageLog",
					payload: response,
				})
		}
	} catch (error) {
		console.error("[LS] SendPasswordUsageLog saga error : ", error)
	}
}

function* decryptThenCopyCredential(action) {
	try {
		// Get every data needed to make the request
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, id: action.payload.credentialId }

		const res = yield call(LPApi.decryptCredit, payload)

		switch (res) {
			case "sessionExpired":
			case "sessionDestroyed":
				return openExt("loginOnPopup")
			case true: {
				// If error clear its entry in recent passes and active state
				//if (action.payload.id) removeRecentPassEntry(action.payload.id)
				return
			}
			default: {
				if (res.view < 0) return

				callBackground(
					{
						message: "LSpostPassLogs",
						credentialId: action.payload.credentialId,
					},
					() => {},
				)

				if (action.payload.isLoginTargeted) {
					return copyToClip(
						res.username,
						"popup.clipboard.copyLogin",
						true,
						"popup.clipboard.copyLoginTitle",
					)
				} else {
					if (action.payload?.isTotp) {
						return copyToClip(
							authenticator.generate(res.password?.totp),
							"popup.clipboard.copyTotp",
							true,
							"popup.clipboard.copyTotpTitle",
						)
					}

					return copyToClip(
						res.password,
						"popup.clipboard.copyPassword",
						true,
						"popup.clipboard.copyPasswordTitle",
					)
				}
			}
		}
	} catch (error) {
		console.error("[LS] SendPasswordUsageLog saga error : ", error)
	}
}

export function* getCredentials(action) {
	try {
		addLCS(action)

		if (!action.payload?.justRefresh && !action.payload?.offset)
			yield put({ type: "extension/toggleLoading", payload: true })
		if (action.payload.offset)
			yield put({ type: "extension/paginLoading", payload: true })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...action.payload }
		let s = yield select((state) => state.extension)

		if (s.searchVal || payload.search) {
			payload.search = s.searchVal
			if (payload.limit) delete payload.limit
			payload = { ...payload, categoryId: undefined }
		}

		const ogData = yield call(Api.getCredentials, payload)

		if (action.payload.offset) yield put({ type: "extension/paginLoading" })
		if (!action.payload?.justRefresh && !action.payload?.offset)
			yield put({ type: "extension/toggleLoading", payload: false })

		switch (ogData) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				// If we had an error, remove this category from the recents use
				removeRecentCatEntry(action.payload.categoryId)

				//If error on loading -> reinit product to root
				// if (action.payload.isTeleporting) {
				// 	const apiVersion = localStorage.getItem("apiVersion")

				yield put({ type: "extension/init" })
				yield put({
					type: "POPUP_GET_DATA_SAGA",
					payload: { categoryId: 0, initCall: true, stateTarget: 1 },
				})

				return removeLCS(action)

			default: {
				removeLCS(action)

				// let data = {...ogData}

				const organizedPasswords = {}

				for (let i = 0; i < ogData.length; i++) {
					if (ogData[i]?.isInTrash) {
						return
					}

					const currentCredential = ogData[i]
					const currentCategory =
						currentCredential.categoryId === 0
							? "Perso"
							: currentCredential.categoryName

					// If the current credential's category isn't already in the new list, add it.
					if (!organizedPasswords[currentCategory])
						organizedPasswords[currentCategory] = []

					organizedPasswords[currentCategory].push(currentCredential)
				}

				if (s.searchVal) {
					yield put({
						type: "extension/addElements",
						payload: {
							files: organizedPasswords,
							doNotKeep: true,
							limit: ogData.length,
							pages: 1,
							page: 1,
							total: ogData.length,
						},
					})

					// if (!s.searchMode) yield put({ type: "extension/initSearch", payload: { ...action.payload } })
				} else {
					let sd = yield select((state) => state.extension.data)

					if (sd.id && action.payload.categoryId !== sd.id) return

					// Quick note here, NEVER put ...data before ...action.payload in the payload declaration; these objects both have a limit key, and it will cause it to be set at an abnormally high value.
					// The consequence is that when a user will create multiple passwords, the limit value of the http request will grow exponentially, resulting after a few requests in an error.
					yield put({
						type: "extension/addElements",
						payload: {
							...action.payload,
							...organizedPasswords,
							doNotKeep: action.payload.justRefresh
								? true
								: false,
						},
					})
				}
				return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getDataFromHomepageLP(action) {
	try {
		const ogP = { ...action.payload }

		// Below is just a redux logs separator
		yield put({ type: "____________________________" })

		// To perso root
		if (ogP.stateTarget === 1) {
			yield put({ type: "pass/removeFirstStateElement" })
			yield put({ type: "pass/removeLastStateElement" })
			yield put({
				type: "pass/updateData",
				payload: { dispatchable: undefined },
			})
			yield put({
				type: "LP_GET_CREDENTIALS_SAGA",
				payload: { categoryId: 0, limit: ogP.limit },
			})

			// To shared root
		} else if (ogP.stateTarget === 2) {
			yield put({ type: "pass/removeFirstStateElement" })
			yield put({ type: "pass/removeFirstStateElement" })
		}

		return yield put({ type: "____________________________" })
	} catch (error) {
		//console.log(error)
	}
}

export function* getData(action) {
	try {
		// console.log("running getData saga", action)
		let ogP = { ...action.payload }

		if (ogP.fromHomepageLP) {
			// Special treatment for navigation dispatched when going from HomepageLP to a root folder (shared or perso)
			return yield call(getDataFromHomepageLP, { payload: ogP })
		}

		let s = yield select((state) => state.pass)
		let slideLoading = s.slideLoading
		let compassLoading = s.compassLoading

		// Push history is for teleportation
		// -> with this param on payload, get branch saga, will recreate history path
		let pushHistory = s.searchMode || !s.history?.length

		if (s.callRunning) return

		if (s.pageSize && !ogP.limit) ogP.limit = s.pageSize * 2

		yield put({ type: "____________________________" })
		yield put({ type: "pass/setCR", payload: true })

		if (ogP.saveLimitAs2Size) {
			yield put({ type: "pass/savePageSize", payload: ogP.limit / 2 })
		}

		if (!ogP.offset) {
			if (!slideLoading && !ogP.initCall)
				yield put({ type: "pass/toggleSlideLoading", payload: true })
			if (!compassLoading)
				yield put({ type: "pass/toggleCompassLoading", payload: true })
		}

		if (ogP.search) {
			yield put({
				type: "LP_GET_CATEGORY_SEARCH_SAGA",
				payload: { ...ogP },
			})
		}

		// Si appel depuis "voir plus" (pagination) || recherche
		if (ogP.offset || ogP.search) {
			return yield put({
				type: "LP_GET_CREDENTIALS_SAGA",
				payload: { ...ogP, removeCR: true },
			})
		}
		// Sinon

		if (!ogP.initCall && !ogP.justRefresh) {
			if (!ogP.historyState && !pushHistory)
				yield put({ type: "pass/historyEnter", payload: { ...ogP } })

			yield put({ type: "pass/enterInFolder", payload: { ...ogP } })
		}

		if (ogP.isInCategory === false) {
			yield put({
				type: "LP_GET_BRANCH_SAGA",
				payload: { ...ogP, pushHistory },
			})
		} else {
			// Appel aux utilisateurs & groupes
			if (
				!ogP.initCall &&
				ogP.categoryId !== 0 &&
				ogP.categoryId !== -1 &&
				ogP.mainId !== 0 &&
				!ogP.search
			) {
				yield put({
					type: "LP_GET_CAT_MEMBERS_SYNC_SAGA",
					payload: {
						...ogP,
						hasNotBranch: action.payload.hasNotBranch,
					},
				})
			}

			// Appel aux mdps
			if (!ogP.initCall && ogP.categoryId !== -1) {
				yield put({
					type: "LP_GET_CREDENTIALS_SAGA",
					payload: { ...ogP },
				})
			}

			if (!ogP.search) {
				if (ogP.mainId === 0 || ogP.initCall) {
					yield put({
						type: "LP_GET_PERSO_CATEGORIES_SAGA",
						payload: { ...ogP },
						stateTarget:
							!ogP.initCall && ogP.categoryId === 0
								? 0
								: undefined,
						findPos:
							ogP.initCall || ogP.categoryId === 0 ? false : true,
					})
				}

				// On initial type call or when going to shared root
				if (ogP.initCall || ogP.categoryId === -1) {
					yield put({ type: "pass/setCR" })
					return yield put({
						type: "LP_GET_ROOT_CATEGORIES_SAGA",
						payload: {
							stateTarget: ogP.categoryId === -1 ? 0 : 2,
						},
					}) //, payload: { limit: 10 } })
				}

				// If we have a mainId in payload and does not going to a root (perso or shared root)
				if (
					typeof ogP.mainId === "number" &&
					ogP.categoryId !== 0 &&
					ogP.categoryId !== -1 /*&& ogP.mainId !== 0*/
				) {
					const history = yield select((state) => state.pass.history)
					const saveBranch = yield select(
						(state) => state.pass.branchInfo,
					)

					yield put({
						type: "LP_GET_BRANCH_SAGA",
						payload: {
							...ogP,
							pushHistory,
							hasNotBranch: action.payload.hasNotBranch
								? false
								: history[0]?.mainId === ogP.mainId
								? saveBranch
								: false,
						},
					})
				}

				if (
					ogP.isInCategory !== false &&
					ogP.categoryId !== 0 &&
					ogP.categoryId !== -1
				) {
					yield put({
						type: "LP_GET_CATEGORY_SAGA",
						payload: { ...ogP },
					})
				}
			}
		}

		yield put({ type: "pass/setCR" })
		return yield put({ type: "____________________________" })
	} catch (error) {
		/* console.log(error) */
	}
}

export default function* popupSagas() {
	yield takeLeading("POPUP_CREATION_PASS_SAGA", createPass)
	yield takeLeading("POPUP_GET_DOMAIN_PASSWORDS", getDomainPass)
	yield takeLatest("POPUP_GET_PASS_DETAILS", getPassDetails)
	yield takeLatest("POPUP_SEND_PASS_USAGE_LOG", sendPasswordUsageLog)
	yield takeLatest(
		"POPUP_DECRYPT_THEN_COPY_CREDENTIAL",
		decryptThenCopyCredential,
	)
	yield takeLatest("POPUP_GET_CREDENTIALS_SAGA", getCredentials)
	yield takeEvery("POPUP_GET_DATA_SAGA", getData)
}
