import {
	call,
	put,
	takeLeading,
	takeLatest,
	takeEvery,
	actionChannel,
	take,
	select,
	all,
} from "redux-saga/effects"
import { toastr } from "react-redux-toastr"
import I18n from "../../i18n"
import { v4 as uuidv4 } from "uuid"

import * as Api from "./services"
import {
	addLCS,
	removeLCS,
	downloadFiles,
	definedView,
	backwardCompApi,
	generateFileHashWithoutContent,
	requestLoop,
	requestLoopTreeStructure,
	storePasswordPolicy,
	getAuthorizedChildren,
} from "../../actions/generalUtils"
import {
	updateRecentPassEntries,
	removeRecentPassEntry,
	removeRecentCatEntry,
} from "../../pages/pass/lpUtils.jsx"

import { passRelatedConfig } from "./slice"

export function* getCredentials(action) {
	try {
		// console.log("running getCredentials saga", action.payload)
		addLCS(action)

		if (
			!action.payload?.justRefresh &&
			!action.payload?.offset &&
			!action.keepLoaders
		) {
			yield put({
				type: "pass/toggleLoading",
				payload: true,
			})
		}
		if (action.payload.offset) {
			yield put({ type: "pass/paginLoading", payload: true })
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...action.payload }
		let isSso = yield select((state) => state.auth.token.userSession.isSso)
		let s = yield select((state) => state.pass)

		if (s.searchVal || payload.search) {
			payload.search = s.searchVal
			if (payload.limit) delete payload.limit
			payload = { ...payload, categoryId: undefined }
		}

		const ogData = yield call(Api.getCredentials, payload)

		if (action.payload.offset) yield put({ type: "pass/paginLoading" })
		if (
			!action.payload?.justRefresh &&
			!action.payload?.offset &&
			!action.keepLoaders
		) {
			yield put({
				type: "pass/toggleLoading",
				payload: false,
			})
		}

		switch (ogData) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				// If we had an error, remove this category from the recents use
				removeRecentCatEntry(action.payload.categoryId)

				//If error on loading -> reinit product to root
				if (action.payload.isTeleporting) {
					yield put({ type: "pass/init" })
					yield put({
						type: "LP_GET_DATA_SAGA",
						payload: {
							categoryId: 0,
							initCall: true,
							stateTarget: 1,
						},
					})
					if (backwardCompApi("1.4.2")) {
						yield put({ type: "LP_GET_FAV" })
					}
				}

				return removeLCS(action)
			default: {
				removeLCS(action)

				let data = { ...ogData }
				let pageSize = yield select((state) => state.pass?.pageSize)

				// Redefine the pagination values, this code is run if the user add a new password.
				if (action.payload.recountPagin || !action.payload.offset) {
					data.pages = Math.ceil(ogData.total / pageSize)
					data.page = Math.ceil(action.payload.limit / pageSize)
					data.limit = pageSize
				}

				if (!backwardCompApi("1.20.16")) {
					if (s.compassLoading && !action.keepLoaders) {
						yield put({ type: "pass/toggleCompassLoading" })
					}
				} else {
					yield put({ type: "pass/toggleCompassLoading" })
				}

				if (
					isSso &&
					data?._embedded?.items?.length === 0 &&
					action.payload.initCall &&
					action.payload.mainId !== 0
				) {
					yield put({ type: "LP_GET_IS_WAITING_SAGA", payload })
				}

				if (action.payload.removeCR) {
					yield put({ type: "pass/setCR" })
				}

				if (
					action?.payload?.fetchingOnPagination &&
					typeof action?.payload?.fetchedOnPagination === "function"
				) {
					action?.payload?.fetchedOnPagination?.()
				}

				if (action.payload.initCall) {
					return yield put({
						type: "pass/addElements",
						payload: { ...data, ...action.payload },
					})
				}

				if (s.searchVal) {
					if (s.slideLoading && !action.keepLoaders) {
						yield put({ type: "pass/toggleSlideLoading" })
					}

					const passDataFiles = backwardCompApi("1.18.0")
						? ogData.filter((pass) => pass?.trashName === null)
						: ogData

					yield put({
						type: "pass/addElements",
						payload: {
							files: passDataFiles,
							doNotKeep: true,
							limit: passDataFiles.length,
							pages: 1,
							page: 1,
							total: passDataFiles.length,
						},
					})

					if (backwardCompApi("1.18.0")) {
						yield put({
							type: "pass/setTrashSearchedFiles",
							payload: ogData.filter(
								(pass) => pass?.trashName !== null,
							),
						})
					}

					if (!s.searchMode) {
						yield put({
							type: "pass/initSearch",
							payload: { ...action.payload },
						})
					}
				} else {
					let sd = yield select((state) => state.pass.data[0])

					if (sd.id && action.payload.categoryId !== sd.id) return
					// Quick note here, NEVER put ...data before ...action.payload in the payload declaration; these objects both have a limit key, and it will cause it to be set at an abnormally high value.
					// The consequence is that when a user will create multiple passwords, the limit value of the http request will grow exponentially, resulting after a few requests in an error.
					yield put({
						type: "pass/addElements",
						payload: {
							...action.payload,
							...data,
							doNotKeep: action.payload.justRefresh,
						},
					})
				}
				return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getIsWaiting(action) {
	try {
		yield put({
			type: "pass/setRunningGetIsWaitingSaga",
			payload: true,
		})
		const data = yield call(Api.getIsWaiting, action.payload)
		yield put({
			type: "pass/setRunningGetIsWaitingSaga",
			payload: false,
		})
		yield put({ type: "pass/toggleLoading" })
		yield put({ type: "pass/setCR" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			default: {
				return yield put({ type: "pass/setIsWaiting", payload: data })
			}
		}
	} catch (error) {
		// console.log(error)
	}
}

export function findRep(a) {
	let cat = a.data.find((x) => x.id === a.action.payload.categoryId)

	if (cat) {
		return cat
	} else {
		let result

		a.data.map((item) => {
			// Si on cherche dans les catégories perso (donc mainId === 0)
			if (a.action.payload.mainId === 0) {
				//Sinon on suit les étapes de l'historique pour arriver jusqu'à l'élement
				let h = a.history.slice(1)
				let hTree = a.data
				let continueMap = true

				h.map((hItem) => {
					if (continueMap) {
						hTree = hTree.find(
							(x) => x.id === hItem.categoryId,
						).children
					}

					if (hTree[0].categoryId === a.action.payload.categoryId) {
						return (continueMap = false)
					} else {
						return undefined
					}
				})

				if (a.action.payload.categoryId) {
					return (result = hTree.find(
						(x) => x.id === a.action.payload.categoryId,
					))
				} else {
					return (result = hTree[0])
				}
			} else if (item.mainId === a.action.payload.mainId) {
				// Si on est dans la bonne racine (hors catégories perso donc)

				if (!item.children && backwardCompApi("1.20.16")) {
					return a.data
				}

				let elem = item.children // On prends tous les enfants de la racine
				let el = elem.find((x) => x.id === a.action.payload.categoryId)

				if (el) {
					//Si l'élément voulu est trouvé en 1er degré de parenté de la racine
					return (result = el)
				} else {
					//Sinon on suit les étapes de l'historique pour arriver jusqu'à l'élement
					let h = a.action.payload.isBackToHistorisedTarget
						? a.history
						: a.history.slice(1)

					if (a.isArbo && backwardCompApi("1.20.16")) {
						h = []
					}

					let hTree = a.data
					let continueMap = true

					h.map((hItem) => {
						if (hItem.categoryId < 1) return

						if (continueMap) {
							hTree = hTree.find(
								(x) => x.id === hItem.categoryId,
							).children
						}

						if (
							hTree[0].categoryId === a.action.payload.categoryId
						) {
							return (continueMap = false)
						} else {
							return undefined
						}
					})

					if (a.action.payload.categoryId) {
						return (result = hTree.find(
							(x) => x.id === a.action.payload.categoryId,
						))
					} else {
						return (result = hTree[0])
					}
				}
			} else {
				return undefined
			}
		})

		return result
	}
}

export function checkIsInCat(data) {
	let newData = []
	data.tree.map((item) => {
		if (backwardCompApi("90000")) {
			let a =
				item[0].view > 0
					? { isInCategory: true }
					: { isInCategory: false }
			newData.push({ ...item[0], ...a })
		} else {
			let a = data.ids.includes(item.id)
				? { isInCategory: true }
				: { isInCategory: false }
			newData.push({ ...item, ...a })
		}
	})
	return newData.sort((a, b) =>
		a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
	)
}

export function* getCategories(action) {
	try {
		//console.log("get shared", action)
		addLCS(action)

		yield put({ type: "pass/setLAR" })
		if (
			!action.type === "LP_GET_DATA_SAGA" &&
			!action.payload?.justRefresh &&
			!action.payload?.offset &&
			!action.payload.setInLar
		) {
			yield put({ type: "pass/toggleLoading", payload: true })
		}
		if (action.payload.setInLar) {
			yield put({
				type: "action/setActionLoader",
				payload: { transparency: true },
			})
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let auth = yield select((state) => state.auth)
		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		let payload = { token, xLsT }
		let data

		if (backwardCompApi("90000")) {
			data = yield call(requestLoopTreeStructure, Api.getRootCatsV2, {
				...payload,
			})
		} else {
			data = yield call(Api.getCategories, {
				...payload,
				organizationId,
			})
		}

		if (
			!action.type === "LP_GET_DATA_SAGA" &&
			!action.payload?.justRefresh &&
			!action.payload?.offset &&
			!action.payload.setInLar
		) {
			yield put({ type: "pass/toggleLoading", payload: false })
		}

		if (action.payload.setInLar) {
			yield put({ type: "action/setActionLoader" })
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let dataCheck = yield call(checkIsInCat, { ...data })

				if (backwardCompApi("90000")) {
					let accessibleCategoriesIds = []
					const getAccessibleCategoriesIds = (tree) => {
						tree?.forEach((branch) => {
							if (branch?.view > 0) {
								accessibleCategoriesIds.push(branch?.id)
							}

							if (branch?.children?.length > 0) {
								getAccessibleCategoriesIds(branch.children)
							}
						})
					}

					getAccessibleCategoriesIds(dataCheck)

					data = {
						...data,
						tree: dataCheck,
						mainIds: data?.ids,
						ids: accessibleCategoriesIds,
					}
				}

				if (action.payload.setInLar) {
					return yield put({
						type: "pass/setLAR",
						payload: {
							message: "success",
							data,
						},
					})
				}

				if (action.payload.justReturn) return data

				let history = yield select((state) => state.pass.history)
				let reps = yield call(findRep, { action, dataCheck, history })

				if (reps?.children) {
					return yield put({
						type: "pass/addReps",
						payload: {
							data: reps.children.sort((a, b) =>
								a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
							),
						},
					})
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getPersoCategories(action) {
	try {
		addLCS(action)
		// console.log(action, "action")
		// console.log("getPersoCategories -> ", action.payload)

		yield put({ type: "pass/setLAR" })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]
		let payload = { token, xLsT, organizationId }
		const d = yield call(Api.getPersoCategories, payload)

		let isArboLoaded = yield select((state) => state.pass.isArboLoaded)
		// console.log("%cFetched personal categories !", "background-color: lime;color:black;font-weight:bolder;padding:10px")

		switch (d) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				let data = d.sort((a, b) =>
					a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
				)

				if (backwardCompApi("1.20.16") && !isArboLoaded?.perso) {
					yield put({
						type: "pass/setArboStatus",
						payload: { space: "perso" },
					})
				}

				if (action.payload.setInLar) {
					return yield put({
						type: "pass/setLAR",
						payload: { message: "successPC", data },
					})
				}

				if (!backwardCompApi("1.20.16")) {
					let compassLoading = yield select(
						(state) => state.pass.compassLoading,
					)
					if (compassLoading && !action.keepLoaders) {
						yield put({ type: "pass/toggleCompassLoading" })
					}
				}

				let s = yield select((state) => state.pass.data[0])

				if (action?.payload?.isFirstPageLoad) {
					yield put({
						type: "pass/addReps",
						payload: {
							data,
							stateTarget: 1,
							doNotKeep: true,
							initArbo: action?.paylaod?.initArbo,
						},
					})

					return
				}

				if (action.payload.categoryId !== s.id) return

				// On update la data avec l'info fraîche arrivée depuis l'api ( get categories )
				if (
					action.payload.categoryId !== 0 &&
					!action?.payload?.initArbo
				) {
					let history = yield select((state) => state.pass.history)
					let newData = yield call(findRep, { action, data, history })

					let copyData = { ...newData }
					if (copyData.children) delete copyData.children

					if (copyData) {
						yield put({
							type: "pass/updateData",
							payload: copyData,
						})
					}
				}

				// Puis on ajoute les répertoires en fonction de la demande
				if (action.findPos) {
					let history = yield select((state) => state.pass.history)
					let reps = yield call(findRep, { action, data, history })
					//console.log(history,reps)
					if (reps?.children) {
						return yield put({
							type: "pass/addReps",
							payload: { data: reps.children, doNotKeep: true },
						})
					}
				} else {
					return yield put({
						type: "pass/addReps",
						payload: {
							data,
							stateTarget:
								typeof action.stateTarget === "number"
									? action.stateTarget
									: 1,
							doNotKeep: true,
						},
					}) // , ...action.payload , st...
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getRootCats(action) {
	try {
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...action.payload }
		let data

		let isArboLoaded = yield select((state) => state.pass.isArboLoaded)

		if (
			!action?.payload.runFromInput &&
			!action.payload.isFirstPageLoad &&
			backwardCompApi("1.20.16")
		) {
			yield put({ type: "pass/setLAR" })
		}

		if (!action?.payload?.runFromInput && !backwardCompApi("1.20.16")) {
			yield put({ type: "pass/toggleCompassLoading", payload: true })
		}

		if (backwardCompApi("90000")) {
			data = yield call(requestLoopTreeStructure, Api.getRootCatsV2, {
				...payload,
			})

			const dataCheck = yield call(checkIsInCat, { ...data })

			let accessibleCategoriesIds = []
			const getAccessibleCategoriesIds = (tree) => {
				tree?.forEach((branch) => {
					if (branch?.view > 0) {
						accessibleCategoriesIds.push(branch?.id)
					}

					if (branch?.children?.length > 0) {
						getAccessibleCategoriesIds(branch.children)
					}
				})
			}

			getAccessibleCategoriesIds(dataCheck)

			data = {
				...data,
				mainIds: data?.ids,
				ids: accessibleCategoriesIds,
			}
		} else {
			data = yield call(Api.getRootCats, payload)
		}

		yield put({ type: "pass/saveCategories", payload: data })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (backwardCompApi("1.20.16") && !isArboLoaded?.share) {
					yield put({
						type: "pass/setArboStatus",
						payload: { space: "share" },
					})
				}

				let dataCheck = yield call(checkIsInCat, { ...data })

				if (backwardCompApi("90000")) {
					data = {
						...data,
						tree: dataCheck,
					}
				}

				let copyData = [...dataCheck]

				let compassLoading = yield select(
					(state) => state.pass.compassLoading,
				)
				let loading = yield select((state) => state.pass.loading)

				if (
					compassLoading &&
					!payload.inSubFolder &&
					!action.keepLoaders
				) {
					yield put({ type: "pass/toggleCompassLoading" })
				}

				if (loading && !payload.inSubFolder && !action.keepLoaders) {
					yield put({ type: "pass/toggleLoading" })
				}
				// If the saga was launch from the CatMap component, the result must be send back
				// to the component with Last Action Result.
				// If this is not the case, use the addReps slice.

				if (action?.payload?.runFromInput) {
					return yield put({
						type: "pass/setLAR",
						payload: {
							message: "rootCategoriesFetched",
							copyData,
						},
					})
				} else {
					yield put({
						type: "pass/addReps",
						payload: {
							stateTarget:
								typeof action.payload.stateTarget === "number"
									? action.payload.stateTarget
									: 2,
							data: copyData,
							doNotKeep: true,
							initArbo: action.payload?.initArbo,
						},
					})

					return
				}
			}
		}
	} catch (error) {
		/* console.log(error) */
	}
}

function checkUtilMap(data, ids) {
	let keep = false

	data.map((item) => {
		if (ids.includes(item.id)) {
			return (keep = true)
		} else if (item.children && !keep) {
			return (keep = checkUtilMap(item.children, ids))
		}
	})
	return keep
}

function checkDeadEnd(tree, ids) {
	let a = tree.children
	let reps = []

	a.map((item) => {
		let keep = false

		if (ids.includes(item.id)) {
			keep = true
		} else if (item.children) keep = checkUtilMap(item.children, ids)

		if (keep) {
			return reps.push(item)
		} else {
			return
		}
	})
	return { ...tree, children: reps }
}

export function* getBranch(action) {
	try {
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }

		let d = action.payload.hasNotBranch

		if (!action.payload.hasNotBranch) {
			d = yield call(Api.getBranch, payload)
			yield put({ type: "pass/saveBranch", payload: d })
		}

		if (!action.payload.noLoader && backwardCompApi("1.20.16")) {
			yield put({
				type: `pass/toggleChildrenLoading`,
				payload: { loading: true, children: action.payload.mainId },
			})
		}

		switch (d) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				const history = yield select((state) => state.pass.history)
				const dataTree = [...d.tree]

				const newData = yield call(findRep, {
					action,
					data: dataTree,
					history,
					isArbo: action.payload.isArbo,
				})

				yield put({
					type: "pass/toggleChildrenLoading",
				})

				if (action.payload.isArbo && backwardCompApi("1.20.16")) {
					yield put({
						type: "pass/addChildren",
						payload: {
							data: getAuthorizedChildren(
								newData?.children,
								d.ids,
							),
							parentId: action.payload.mainId,
						},
					})
				}

				const compassLoading = yield select(
					(state) => state.pass.compassLoading,
				)

				if (compassLoading && !action.payload.isArbo) {
					yield put({ type: "pass/toggleCompassLoading" })
				}

				if (newData?.children) {
					const copyData = checkDeadEnd(newData, d.ids)
					const data = yield call(checkIsInCat, {
						tree: copyData.children,
						ids: d.ids,
					})

					const folderState = yield select(
						(state) => state.pass.data[0],
					)
					if (action.payload.categoryId !== folderState.id) return

					yield put({
						type: "pass/addReps",
						payload: { data: data, doNotKeep: true },
					})
				}

				if (action.payload.pushHistory) {
					let a = [
						{
							name:
								action.payload.mainId === 0
									? "mLayout.personnalSpace"
									: "mLayout.shared",
							mainId: action.payload.mainId,
							// historyState: [],
							categoryId: action.payload.mainId === 0 ? 0 : -1,
							...passRelatedConfig,
						},
					]

					function calcHistory(target, bb) {
						if (target.id === action.payload.categoryId) {
							return (a = bb) // On arrête la fonction si on est arrivé jusqu'au dossier cible
						} else {
							let b = [...bb]

							b.push({
								...target,
								id: undefined,
								categoryId: target.id,
								children: undefined,
								//historyState: JSON.stringify(b),
								...passRelatedConfig,
							})

							if (target.children?.length === 1) {
								calcHistory(target.children[0], b)
							} else if (target.children?.length > 1) {
								target.children.map((item) => {
									calcHistory(item, b)
								})
							} else {
								return
							}
						}
					}

					calcHistory(d.tree[0], a)

					yield put({ type: "pass/updateHistory", payload: a })
				}

				return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getDataFromHomepageLP(action) {
	try {
		const ogP = { ...action.payload }

		// Below is just a redux logs separator
		yield put({ type: "____________________________" })

		// To perso root
		if (ogP.stateTarget === 1) {
			yield put({ type: "pass/removeFirstStateElement" })
			yield put({ type: "pass/removeLastStateElement" })
			yield put({
				type: "pass/updateData",
				payload: { dispatchable: undefined },
			})
			yield put({
				type: "LP_GET_CREDENTIALS_SAGA",
				keepLoaders: ogP?.keepLoaders,
				payload: { categoryId: 0, limit: ogP.limit },
			})

			// To shared root
		} else if (ogP.stateTarget === 2) {
			yield put({ type: "pass/removeFirstStateElement" })
			yield put({ type: "pass/removeFirstStateElement" })
		}

		return yield put({ type: "____________________________" })
	} catch (error) {
		//console.log(error)
	}
}

export function* getData(action) {
	try {
		// console.log("running getData saga", action)
		let ogP = { ...action.payload }

		if (ogP.fromHomepageLP) {
			// Special treatment for navigation dispatched when going from HomepageLP to a root folder (shared or perso)
			return yield call(getDataFromHomepageLP, { payload: ogP })
		}

		if (ogP.isFirstPageLoad) {
			yield call(getPersoCategories, {
				type: "LP_GET_PERSO_CATEGORIES_SAGA",
				payload: { ...ogP },
				stateTarget: 0,
				initCall: ogP.categoryId !== 0,
				categoryId: ogP.categoryId === 0 ? 0 : null,
				isFirstPageLoad: true,
				keepLoaders: true,
			})
		}

		let isArboLoaded = yield select((state) => state.pass.isArboLoaded)
		let s = yield select((state) => state.pass)
		let loading = s.loading
		let slideLoading = s.slideLoading
		let compassLoading = s.compassLoading

		if (loading && ogP.initCall) {
			yield put({ type: "pass/toggleLoading" })
		}

		// Push history is for teleportation
		// -> with this param on payload, get branch saga, will recreate history path
		let pushHistory = s.searchMode || !s.history?.length

		if (s.callRunning && !action.payload?.ignoreCR) return

		if (s.pageSize && !ogP.limit) ogP.limit = s.pageSize * 2

		if (!isArboLoaded) {
			yield put({ type: "pass/setCR", payload: true })
		}

		if (ogP.isTrashRoute) {
			yield call(getTrashPassSaga, {
				type: "LP_GET_TRASH_PASS_SAGA",
				payload: {
					...ogP,
					initCall: action?.payload?.initCall,
					isShared: action?.payload?.isShared,
					limit: 26,
					dateFilter: action?.payload?.dateFilter,
				},
			})

			return yield put({ type: "pass/setCR" })
		}

		if (ogP.saveLimitAs2Size) {
			yield put({ type: "pass/savePageSize", payload: ogP.limit / 2 })
		}

		if (!ogP.offset) {
			if (!slideLoading && !ogP.initCall) {
				yield put({ type: "pass/toggleSlideLoading", payload: true })
			}

			if (!compassLoading && !backwardCompApi("1.20.16")) {
				yield put({ type: "pass/toggleCompassLoading", payload: true })
			}
		}

		if (ogP.search) {
			yield put({
				type: "LP_GET_CATEGORY_SEARCH_SAGA",
				payload: { ...ogP },
			})
		}

		// Si appel depuis "voir plus" (pagination) || recherche
		if (ogP.offset || ogP.search) {
			return yield put({
				type: "LP_GET_CREDENTIALS_SAGA",
				payload: { ...ogP, removeCR: true },
			})
		}
		// Sinon

		if (!ogP.initCall && !ogP.justRefresh) {
			if (!ogP.isBackToHistorisedTarget && !pushHistory) {
				yield put({ type: "pass/historyEnter", payload: { ...ogP } })
			}

			yield put({ type: "pass/enterInFolder", payload: { ...ogP } })
		}

		if (ogP.isInCategory === false) {
			yield put({
				type: "LP_GET_BRANCH_SAGA",
				payload: { ...ogP, pushHistory },
			})
		} else {
			// Appel aux utilisateurs & groupes
			if (
				!ogP.initCall &&
				ogP.categoryId !== 0 &&
				ogP.categoryId !== -1 &&
				ogP.mainId !== 0 &&
				!ogP.search
			) {
				yield put({
					type: "LP_GET_CAT_MEMBERS_SYNC_SAGA",
					payload: {
						...ogP,
						hasNotBranch: action.payload.hasNotBranch,
					},
				})
			}

			// Appel aux mdps
			if (!ogP.initCall && ogP.categoryId !== -1) {
				yield put({
					type: "LP_GET_CREDENTIALS_SAGA",
					payload: { ...ogP },
				})
			}

			if (!ogP.search) {
				if (ogP.mainId === 0 || ogP.initCall) {
					yield call(getPersoCategories, {
						type: "LP_GET_PERSO_CATEGORIES_SAGA",
						payload: { ...ogP },
						keepLoaders: true,
						stateTarget:
							!ogP.initCall && ogP.categoryId === 0
								? 0
								: undefined,
						findPos:
							ogP.initCall || ogP.categoryId === 0 ? false : true,
					})
				}

				// On initial type call or when going to shared root
				if (ogP.initCall || ogP.categoryId === -1) {
					if (!backwardCompApi("1.20.16")) {
						yield put({
							type: "pass/toggleCompassLoading",
							payload: true,
						})
					}

					yield put({
						type: "LP_GET_ROOT_CATEGORIES_SAGA",
						payload: {
							stateTarget: ogP.categoryId === -1 ? 0 : 2,
						},
					}) //, payload: { limit: 10 } })

					if (ogP?.waitForCompletion) {
						yield take("pass/addReps")
					}

					yield put({
						type: "pass/setLAR",
						payload: "successLoadingData",
					})

					return yield put({ type: "pass/setCR" })
				}

				// If we have a mainId in payload and does not going to a root (perso or shared root)
				if (
					typeof ogP.mainId === "number" &&
					ogP.categoryId !== 0 &&
					ogP.categoryId !== -1 /*&& ogP.mainId !== 0*/
				) {
					const history = yield select((state) => state.pass.history)
					const saveBranch = yield select(
						(state) => state.pass.branchInfo,
					)

					yield put({
						type: "LP_GET_BRANCH_SAGA",
						payload: {
							...ogP,
							pushHistory,
							hasNotBranch: action.payload.hasNotBranch
								? false
								: history[0]?.mainId === ogP.mainId
								? saveBranch
								: false,
						},
					})
				}

				if (
					ogP.isInCategory !== false &&
					ogP.categoryId !== 0 &&
					ogP.categoryId !== -1
				) {
					yield put({
						type: "LP_GET_CATEGORY_SAGA",
						payload: { ...ogP },
					})
				}
			}
		}

		yield put({ type: "pass/setLAR", payload: "successLoadingData" })

		return yield put({ type: "pass/setCR" })
	} catch (error) {
		/* console.log(error) */
	}
}

export function* getCategory(action) {
	try {
		addLCS(action)
		//yield put({ type: "pass/setLAR"})

		yield put({ type: "pass/toggleLoading", payload: true })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, id: action.payload.categoryId }

		const data = yield call(Api.getCat, payload)

		if (
			!action.payload?.offset &&
			!action.payload.setInLar &&
			!backwardCompApi("1.20.16")
		) {
			yield put({ type: "pass/toggleLoading", payload: false })
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				//if( action.payload.setInLar ) return yield put({ type: "pass/setLAR", payload: {message: "success", data } })

				let slideLoading = yield select(
					(state) => state.pass.slideLoading,
				)
				if (slideLoading) yield put({ type: "pass/toggleSlideLoading" })

				let s = yield select((state) => state.pass.data[0])
				if (action.payload.categoryId !== s.id) return

				if (action.payload.categoryId !== 0) {
					// On update la data avec l'info fraîche arrivée depuis l'api ( get categories )
					let copyData = { ...data, isInCategory: true }
					delete copyData.children

					yield put({ type: "pass/setCR" })
					return yield put({
						type: "pass/updateData",
						payload: copyData,
					})
				} else {
					return yield put({ type: "pass/setCR" })
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getCatSearch(action) {
	try {
		/* console.log("running getCatSearch saga", action) */
		addLCS(action)

		if (!action?.payload?.runFromCatMap) {
			yield put({ type: "pass/toggleCompassLoading", payload: true })
		}

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, search: action.payload.search }

		const ogData = yield call(Api.getCategoriesSearch, payload)

		switch (ogData) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let data = ogData.sort((a) => (a.mainId === 0 ? -1 : 1))

				yield put({ type: "pass/toggleCompassLoading" })

				// If this saga was called from the CatMap component, we want to send
				// data back to it without touching the navigation data.
				if (action.payload.runFromCatMap) {
					return yield put({
						type: "pass/setLAR",
						payload: { message: "successSearchCat", data },
					})
				}
				return yield put({
					type: "pass/addReps",
					payload: { data, doNotKeep: true },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* teleportFromPassSearch(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, id: action.payload.id }

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		const response = yield call(Api.getCat, payload)
		yield put({ type: "action/setActionLoader" })

		switch (response) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				const getDataPayload = {
					domTarget: 0,
					mainId: response.mainId,
					parentId: response.parentId,
					categoryId: response.id,
					isTeleporting: true,
					isInCategory: true,
					isSearchable: true,
					comp: "Pass",
					loadData: "LP_GET_DATA_SAGA",
					fileName: "pass.passList",
					repName: "mLayout.categories",
					resetReps: true,
				}

				yield put({ type: "LP_GET_DATA_SAGA", payload: getDataPayload })
			}
		}
	} catch (error) {
		// console.log("teleportFromSearch error", error)
	}
}

export function* decryptCredit(action) {
	try {
		addLCS(action)

		yield put({ type: "pass/toggleSlideLoading", payload: true })

		if (action.payload?.isSelecting) {
			yield take("pass/manageSelected")
		}

		if (action.payload?.blockSelection || action.payload?.isSelecting) {
			yield put({ type: "pass/setCR", payload: true })
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...action.payload }

		let data = !action.payload?.hasPasswordData
			? yield call(Api.decryptCredit, payload)
			: action.payload

		const view = definedView(data.view)

		if (data?.files?.length > 0) {
			const files = yield call(Api.getCreditFiles, payload)
			data.files = files
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true: {
				// If error clear its entry in recent passes and active state
				if (action.payload.id) removeRecentPassEntry(action.payload.id)
				yield put({ type: "pass/unselectAll" })

				yield put({ type: "pass/toggleSlideLoading" })

				return removeLCS(action)
			}
			default: {
				if (action.payload.justReturn) return data

				removeLCS(action)

				if (view !== -1) {
					updateRecentPassEntries(data, data.categoryName)
				}

				yield put({
					type: "pass/updateActive",
					payload: { data, ...action.payload },
				})

				if (
					action.payload?.blockSelection ||
					action.payload?.isSelecting
				) {
					yield put({ type: "pass/setCR" })
				}

				yield put({ type: "pass/toggleSlideLoading" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* postCredential(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		const licenseType = yield select(
			(state) => state.auth.token.userSession.licenseType,
		)

		let payload = { token, xLsT }
		let actionPayloadData = { ...action.payload.data }

		if (
			backwardCompApi("1.22.0") &&
			licenseType === 2 &&
			actionPayloadData.file
		) {
			delete actionPayloadData.file
		}

		payload.action = actionPayloadData

		const data = yield call(Api.postCredential, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("pass.aPass.success"))

				if (action.payload.refresh) {
					let pagin = yield select((state) => state.pass.pagin)

					if (pagin?.limit) {
						yield put({
							type: "LP_GET_CREDENTIALS_SAGA",
							payload: {
								justRefresh: true,
								categoryId: data.data.categoryId,
								limit: pagin.page * pagin.limit,
								recountPagin: true,
							},
						})
					}
				}

				return yield put({ type: "action/closeAction" })
			}
		}
	} catch (error) {
		//console.warn(error)
	}
}

export function* putCredit(action) {
	try {
		addLCS(action)

		if (action?.requiresRedirectInWindowHistoryState) {
			yield put({ type: "pass/setCR", payload: true })
		}

		let isMulti = false

		// En cas de déplacement multiple ici on va decrypt chaque credential avant de PUT
		let decryptedData = {}

		if ("multiTotal" in action.payload && "multiPart" in action.payload) {
			isMulti = true
			yield put({
				type: "action/setActionLoader",
				payload: {
					actualPart: action.payload.multiPart,
					totalParts: action.payload.multiTotal,
					customMsg: `${I18n.t("fileLoad")} ${action.payload.name}`,
				},
			})

			let d = yield call(decryptCredit, {
				payload: {
					justReturn: true,
					id: action.payload.id,
				},
			})

			let tags = []
			d.tags.map((t) => tags.push(t.name))

			decryptedData = {
				data: {
					tags: tags.join(";"),
					name: d.name,
					domain: d.domain,
					description: d.description,
					username: d.username,
					password: d.password,
					opt1: d.opt1,
					opt2: d.opt2,
					opt3: d.opt3,
					isAlert: d.isAlert,
					categoryId: action.payload.data.categoryId,
					type: d.type,
				},
			}

			if (d.totp && d.totp !== "") {
				decryptedData.data.totp = d.totp
			}
		} else {
			yield put({
				type: "action/setActionLoader",
				payload: { transparency: true },
			})
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload, ...decryptedData }
		const data = yield call(Api.putCredit, payload)

		if (!isMulti) yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true: {
				//if (action.payload.hasOwnProperty("multiTotal")) toastr.warning(I18n.t("errorToaster"))
				if (action?.requiresRedirectInWindowHistoryState) {
					yield put({ type: "pass/setCR" })
				}

				if (isMulti) {
					if (action.payload.fullReload) {
						yield put({ type: "action/closeAction" })
						yield put({ type: "pass/init" })
						yield put({
							type: "LP_GET_DATA_SAGA",
							payload: {
								categoryId: 0,
								initCall: true,
								stateTarget: 1,
							},
						})

						return removeLCS(action)
					} else {
						return
					}
				} else {
					return
				}
			}
			default: {
				// Si l'action est 2XX
				removeLCS(action)

				let storedRecentPass = []

				const recentPassFromStorage =
					window.localStorage.getItem("LPrecentPass")

				if (
					recentPassFromStorage !== null &&
					recentPassFromStorage !== undefined
				) {
					try {
						storedRecentPass = JSON.parse(recentPassFromStorage)
					} catch (e) {
						console.error(
							"Error parsing JSON from localStorage:",
							e,
						)
						storedRecentPass = []
					}
				} else {
					storedRecentPass = []
				}

				if (
					storedRecentPass === false ||
					storedRecentPass.length === 0
				) {
					storedRecentPass = []
				}

				if (
					storedRecentPass.filter((item) => item.id === payload.id)
						.length > 0
				) {
					const newRecentPass = storedRecentPass.map((item) => {
						if (item.id === payload.id) {
							return {
								categoryName: data.categoryName,
								categoryId: data.categoryId,
								domain: data.domain,
								id: data.id,
								name: data.name,
							}
						}
						return item
					})

					window.localStorage.setItem(
						"LPrecentPass",
						JSON.stringify(newRecentPass),
					)
				}

				const comp = yield select((state) => state.pass.data?.[0]?.comp)
				const searchMode = yield select(
					(state) => state.pass.searchMode,
				)

				if (
					action.payload.idsMultipleRemoveLS ||
					action.payload.idRemoveLS
				) {
					removeRecentPassEntry(
						action.payload.idsMultipleRemoveLS ||
							action.payload.idRemoveLS,
					)
				}

				if (action.payload.noReload) {
					return
				} else if (action.payload.fullReload) {
					if (
						action.payload.fromPersoToShared ||
						action.payload.fromPersoToSharedMulti
					) {
						// When a password is transferred from a personal category to a shared category
						// Toast is showed to warning the user
						toastr.success(
							I18n.t(
								action.payload.fromPersoToShared
									? "pass.movePass.persoToShared"
									: "pass.movePass.persoToSharedMulti",
							),
							{ timeOut: 7000 },
						)
					} else {
						toastr.success(I18n.t("editSuccess"))
					}

					yield put({ type: "action/closeAction" })
					yield put({ type: "action/setActionLoader" })
					yield put({
						type: "action/setLAR",
						payload: "navigateBack",
					})
					yield put({ type: "pass/unselectAll" })

					if (
						action?.requiresRedirectInWindowHistoryState ||
						searchMode
					) {
						const pagin = yield select((state) => state.pass.pagin)
						yield put({
							type: "LP_GET_CREDENTIALS_SAGA",
							payload: {
								justRefresh: true,
								categoryId: action.payload.categoryId,
								ignoreCR: true,
								limit: pagin.page * pagin.limit,
								recountPagin: true,
							},
						})

						// wait for pass/addElements action to be called
						yield take("pass/addElements")
						yield put({ type: "pass/setCR" })
					}

					return
				} else if (comp === "Pass") {
					let fileData = action.payload.data.file
					const licenseType = yield select(
						(state) => state.auth.token.userSession.licenseType,
					)

					if (
						backwardCompApi("1.22.0") &&
						licenseType === 2 &&
						fileData &&
						Object.keys(fileData.file.length > 0)
					) {
						let payloadForLPWithBuffer = {
							file: fileData.file,
							totalSize: fileData.totalSize,
							id: data.id,
							name: fileData.name,
							isLast: fileData.isLast,
							type: data.type,
						}

						yield put({
							type: "LP_WITH_BUFFER_SAGA",
							payload: {
								channelMod: "uploadFile",
								...payloadForLPWithBuffer,
							},
						})
					}

					yield put({
						type: "LP_DECRYPT_CREDIT_SAGA",
						payload: {
							id: data.id,
							ogId: action.payload.id,
							noEmail: true,
						},
					})
				}

				if (
					!isMulti &&
					!action.payload.hasOwnProperty("name") &&
					!action.payload.data.noToaster
				) {
					toastr.success(I18n.t("editSuccess"))
					return yield put({ type: "action/closeAction" })
				} else {
					return
				}
			}
		}
	} catch (error) {
		// console.log(error)
	}
}

export function* grabCredential(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, id: action.payload.id }

		const data = yield call(Api.grabCredential, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (data === "networkError") {
					return
				}
				toastr.success(I18n.t("editSuccess"))

				return yield put({
					type: "LP_DECRYPT_CREDIT_SAGA",
					payload: { ogId: action.payload.id, id: data.id },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* grabAllCredentials(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, id: action.payload }

		const data = yield call(Api.grabAllCredentials, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				if (data === "networkError") {
					return
				}

				toastr.success(I18n.t("editSuccess"))

				return yield put({
					type: "pass/getAllPasswordsOwnership",
				})
			}
		}
	} catch (error) {
		// console.log("error in saga", error)
	}
}

export function* deleteCredit(action) {
	try {
		addLCS(action)

		yield put({ type: "pass/setCR", payload: true })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = {
			token,
			xLsT,
			action: action.payload,
			moveInTrash: action?.moveInTrash,
		}

		const data = yield call(Api.deleteCredit, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({ type: "pass/setCR" })
				return removeLCS(action)
			default: {
				removeLCS(action)

				const pagin = yield select((state) => state.pass.pagin)
				const data = yield select((state) => state.pass.data[0])
				const searchMode = yield select(
					(state) => state.pass.searchMode,
				)
				const categoryId = data.id ? data.id : 0
				const localStorageLastSawPasswordsKey = "LSlastSaw"
				const extensionLastSawPasswords = localStorage.getItem(
					localStorageLastSawPasswordsKey,
				)

				removeRecentPassEntry(action.payload)

				if (
					!!extensionLastSawPasswords &&
					extensionLastSawPasswords !== undefined
				) {
					const passwords = JSON.parse(extensionLastSawPasswords)

					if (passwords?.length > 0) {
						const passwordsToKeep = passwords?.filter(
							(password) => {
								return !action?.payload?.includes(password.id)
							},
						)

						if (passwordsToKeep?.length > 0) {
							localStorage.setItem(
								localStorageLastSawPasswordsKey,
								JSON.stringify(passwordsToKeep),
							)
						} else {
							localStorage.removeItem(
								localStorageLastSawPasswordsKey,
							)
						}
					}
				}

				toastr.success(I18n.t("manage.ssoPwdRemovalSuccess"))

				yield put({ type: "action/closeMiniAction" })
				yield put({ type: "pass/unselectAll" })
				yield put({ type: "action/setLAR", payload: "navigateBack" })

				if (
					action?.requiresRedirectInWindowHistoryState ||
					searchMode
				) {
					if (action?.moveInTrash || !backwardCompApi("1.18.0")) {
						yield put({
							type: "LP_GET_CREDENTIALS_SAGA",
							payload: {
								justRefresh: true,
								categoryId: categoryId,
								limit: pagin.page * pagin.limit,
								recountPagin: true,
							},
						})
						// wait for pass/addElements action to be called
						yield take("pass/addElements")
					} else {
						yield put({
							type: "LP_GET_TRASH_PASS_SAGA",
							payload: {
								limit: 26,
								dateFilter: action?.order,
								initCall: action?.listType,
								isShared: action?.listType,
								hasRights: action?.hasRights,
								search: action?.search,
							},
						})
					}
				}

				if (backwardCompApi("1.18.0")) {
					yield call(getTrashTotal)
				}

				yield put({ type: "pass/setCR" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* addFilesToCredit(action) {
	try {
		const sagaPayload = { ...action.payload }

		yield put({ type: "pass/setLAR" })

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let token = yield select((state) => state.auth.token.token)
		let xLsT = yield select((state) => state.auth.xLsToken)

		let chunkSize = 20000000 // bytes
		let d = { ...action.payload }

		d.chunkIndex = 0
		d.totalChunkCount = Math.ceil(d.file.size / chunkSize)
		d.uuid = uuidv4()

		let data = []

		const fileId = yield call(generateFileHashWithoutContent, d.file)

		while (d.chunkIndex < d.totalChunkCount) {
			let offset = d.chunkIndex * chunkSize
			let blob = d.file.slice(offset, offset + chunkSize)
			d.filePart = new File([blob], d.file.name)

			let servicePayload = {
				token,
				xLsT,
				data: {
					...d,
					firstUpload: sagaPayload.multiPart === 0 ? "true" : "false",
				},
			}
			yield put({ type: "action/startUpload", payload: { fileId } })
			data = yield call(Api.addFilesToCredit, servicePayload)
			yield put({ type: "action/endUpload", payload: { fileId } })
			d.chunkIndex++
		}

		if (action.payload.isLast) {
			yield put({ type: "action/setActionLoader" })
			yield put({ type: "pass/setLoader" })
		}

		switch (data) {
			case "sessionExpired":
				yield put({ type: "action/cancelUploadEstimation" })
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				yield put({ type: "action/cancelUploadEstimation" })
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({ type: "action/cancelUploadEstimation" })
				return
			default: {
				if (action.payload.isLast) {
					yield put({ type: "action/closeAction" })

					toastr.success(I18n.t("pass.editFiles.successUpload"))
					// yield put({ type: "pass/setLAR", payload: {message: "filesAddedToCredit"} })

					yield put({
						type: "LP_DECRYPT_CREDIT_SAGA",
						payload: { id: action.payload.id },
					})
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteCreditFile(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, data: action.payload }

		const data = yield call(Api.deleteCreditFile, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (action.payload.type !== "ssh") {
					toastr.success(I18n.t("pass.editFiles.successRemove"))

					return yield put({
						type: "LP_DECRYPT_CREDIT_SAGA",
						payload: { id: action.payload.id },
					})
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* downloadCreditFile(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		yield put({ type: "pass/setLAR" })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, action: action.payload }

		const data = yield call(Api.downloadCreditFile, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				return yield put({
					type: "pass/setLAR",
					payload: { message: "success" },
				})
			}
		}
	} catch (error) {
		yield put({ type: "action/setActionLoader" })
		//console.log(error)
	}
}

export function* downloadSshFile(action) {
	try {
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, credentialId: action.payload.credentialId }

		const data = yield call(Api.getSshKey, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				const payload = {
					password: data.password,
					sshKey: data.sshKey,
				}

				yield put({
					type: "pass/setSshFileTemporary",
					payload: { ...payload, decrypted: "decrypted" },
				})
			}
		}
	} catch (error) {
		yield put({ type: "action/setActionLoader" })
		//console.log(error)
	}
}

export function* removeTotp(action) {
	try {
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const servicePayload = { xLsT, token, data: { ...action.payload } }

		const data = yield call(Api.removeTotp, servicePayload)

		yield put({ type: "action/setActionLoader" })
		yield put({ type: "action/closeMiniAction" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				yield put({
					type: "LP_DECRYPT_CREDIT_SAGA",
					payload: { id: action.payload.passwordId, noEmail: true },
				})
				const pagin = yield select((state) => state.pass.pagin)

				yield put({
					type: "LP_GET_CREDENTIALS_SAGA",
					payload: {
						justRefresh: true,
						categoryId: action.payload.categoryId,
						ignoreCR: true,
						limit: pagin.page * pagin.limit,
						recountPagin: true,
					},
				})

				return toastr.success(I18n.t("pass.removeOtp.success"))
			}
		}
	} catch (error) {
		console.warn(error)
	}
}

export function* getCategoryReport(action) {
	try {
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = {
			token,
			xLsT,
			data: { categoryId: action.payload.categoryId },
		}
		const data = yield call(Api.getCategoryReport, payload)

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				const fileName =
					I18n.t("manage.report") + "-" + action.payload.fileName
				return downloadFiles(data, fileName)
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* postCategory(action) {
	try {
		addLCS(action)

		yield put({ type: "pass/setCR", payload: true })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organisationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		let payload = {
			token,
			xLsT,
			action: { ...action.payload, organisationId },
		}

		const data = yield call(Api.postCategory, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({ type: "pass/setCR" })
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({
					type: "action/setActionLoader",
					payload: { transparency: true },
				})
				yield put({ type: "action/closeAction" })

				toastr.success(I18n.t("pass.aCat.success"))

				let h = yield select((state) => state.pass.history)
				let s = yield select((state) => state.pass.data[0])

				if (backwardCompApi("1.20.16")) {
					if (data.parentId !== -1) {
						yield put({ type: "pass/setShareCateg" })
						yield put({
							type: "LP_GET_BRANCH_SAGA",
							payload: {
								mainId: data.mainId,
								isArbo: true,
								noLoader: true,
							},
						})
					}
				}

				if (s?.homepageLPView) {
					//parentId 0-> partagé -1->perso
					yield put({ type: "pass/saveBranch", payload: {} })
					yield put({ type: "pass/init" })
					yield put({
						type: s?.loadData,
						payload: {
							categoryId: 0,
							initCall: true,
							page: 2,
							limit: 26,
							saveLimitAs2Size: true,
							ignoreCR: true,
						},
					})
				} else if (
					h.length === 0 &&
					(action.payload.parentId === 0 ||
						action.payload.parentId === -1)
				) {
					//parentId 0-> partagé -1->perso
					yield put({ type: "pass/saveBranch", payload: {} })
					yield put({ type: "pass/init" })
					yield put({
						type: "LP_GET_DATA_PERMALINKS_SAGA",
						payload: {
							stateTarget:
								action.payload.parentId === -1
									? 1
									: action.payload.parentId === 0
									? 2
									: s.subFolderId,
							fromDashboard: true,
							initCall: true,
							page: 2,
							limit: 26,
							saveLimitAs2Size: true,
							initArbo: action.payload.mainId !== s.id,
						},
					})
				} else if (s.id === action.payload.parentId) {
					yield put({ type: "pass/init" })
					yield put({
						type: "LP_GET_DATA_PERMALINKS_SAGA",
						payload: {
							categoryId: s.id,
							limit: 26,
							resetBranchInfo: true,
							isSharedCat: s?.mainId !== 0,
							parentId: s.mainId,
							keepLoaders: true,
							reloadCateg: true,
						},
					})

					yield put({
						type:
							action.payload.mainId === 0
								? "LP_GET_PERSO_CATEGORIES_SAGA"
								: "LP_GET_ROOT_CATEGORIES_SAGA",
						stateTarget: action.payload.mainId === 0 ? 1 : 2,
						payload: {
							stateTarget: action.payload.mainId === 0 ? 1 : 2,
							categoryId: 0,
							isFirstPageLoad: action.payload.parentId !== s.id,
							initArbo: action.payload.parentId !== s.id,
						},
						keepLoaders: action.payload.parentId === s.id,
					})
				} else {
					yield put({
						type:
							action.payload.mainId === 0
								? "LP_GET_PERSO_CATEGORIES_SAGA"
								: "LP_GET_ROOT_CATEGORIES_SAGA",
						stateTarget: action.payload.mainId === 0 ? 1 : 2,
						payload: {
							stateTarget: action.payload.mainId === 0 ? 1 : 2,
							categoryId: 0,
							isFirstPageLoad: action.payload.parentId !== s.id,
							initArbo: action.payload.parentId !== s.id,
						},
						keepLoaders: action.payload.parentId === s.id,
					})
					yield put({ type: "pass/saveBranch", payload: {} })
					yield put({ type: "pass/setCR" })
				}

				return yield put({ type: "action/setActionLoader" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putCategory(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let favCats = yield select((state) => state.pass.favCats)

		let payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.putCategory, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (action.payload.data.dispatchPurpose === "changeStatus") {
					toastr.success(I18n.t("editSuccess"))
				}
				if (
					action.payload.data.dispatchPurpose ===
					"changePasswordPolicy"
				) {
					if (data.passwordPolicyId === 0) {
						toastr.success(I18n.t("params.pp.removeSuccess"))
					}
					if (
						data.passwordPolicyId !== 0 &&
						action.payload.data.initPP === 0
					) {
						toastr.success(I18n.t("params.pp.addCatSuccess"))
					}
					if (
						data.passwordPolicyId !== 0 &&
						action.payload.data.initPP !== 0
					) {
						toastr.success(I18n.t("params.pp.editSuccess"))
					}
				}

				if (
					action.payload.data.dispatchPurpose ===
					"changeExpirationDate"
				) {
					if (data.expirationDate === "0") {
						toastr.success(I18n.t("pass.aCat.expiDeleteSuccess"))
					}
					if (
						data.expirationDate !== "0" &&
						action.payload.data.expiDate === "0"
					) {
						toastr.success(I18n.t("pass.aCat.expiAddSuccess"))
					}
					if (
						data.expirationDate !== "0" &&
						action.payload.data.expiDate !== "0"
					) {
						toastr.success(I18n.t("pass.aCat.expiModifySuccess"))
					}
				}

				if (backwardCompApi("1.20.16")) {
					yield put({
						type: "LP_GET_DATA_SAGA",
						payload: {
							categoryId: data.id,
							initCall: true,
							stateTarget: data.mainId === 0 ? 1 : 2,
						},
					})

					yield put({
						type: "LP_GET_BRANCH_SAGA",
						payload: {
							mainId: data.mainId,
							isArbo: true,
							noLoader: true,
						},
					})

					if (
						favCats.filter((fav) => fav.id === data.id).length > 0
					) {
						yield put({ type: "LP_GET_FAV" })
					}
				}

				yield put({ type: "action/closeAction" })
				yield put({ type: "pass/updateTree", payload: data })
				return yield put({ type: "pass/updateData", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteCategory(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		yield put({ type: "pass/toggleLoading", payload: true })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, action: action.payload }

		const data = yield call(Api.deleteCategory, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (backwardCompApi("1.19.12")) {
					if (action.data.categoriesToRemove) {
						removeRecentCatEntry(action.data.categoriesToRemove)
					}

					// Remove favorite categories that were deleted.
					const previousFavoriteCategories = yield select(
						(state) => state.pass.favCats,
					)
					const newFavoriteCategories = []

					previousFavoriteCategories.forEach((category) => {
						if (
							action.data.categoriesToRemove.filter(
								(idToRemove) => idToRemove === category.id,
							).length === 0
						) {
							newFavoriteCategories.push(category)
						}
					})

					yield put({
						type: "pass/setFavCats",
						payload: newFavoriteCategories,
					})

					if (
						backwardCompApi("1.11.1") &&
						action.data?.recentPassIds?.length > 0
					) {
						removeRecentPassEntry(action.data.recentPassIds)
					}
				}

				if (
					action.data.mainId !== action.payload &&
					backwardCompApi("1.20.16")
				) {
					yield put({
						type: "LP_GET_BRANCH_SAGA",
						payload: {
							mainId: action.data.mainId,
							isArbo: true,
							noLoader: true,
						},
					})
				}

				if (backwardCompApi("1.20.16")) {
					yield put({
						type: "LP_GET_DATA_PERMALINKS_SAGA",
						payload: {
							categoryId: action.data.parentId,
							limit: 26,
							resetBranchInfo: true,
							isSharedCat: action.data?.mainId !== 0,
							parentId: action.data.mainId,
							keepLoaders: true,
							reloadCateg: true,
							initArbo: true,
						},
					})
				}

				if (backwardCompApi("1.20.16") && action.data.mainId === 0) {
					yield put({
						type: "LP_GET_PERSO_CATEGORIES_SAGA",
						stateTarget: 1,
						payload: {
							stateTarget: 1,
							categoryId: action.data.mainId,
							initArbo: true,
						},
					})
				}

				toastr.success(I18n.t("pass.catRemoved"))

				yield put({ type: "pass/saveBranch", payload: {} })
				yield put({ type: "action/setLAR", payload: "navigateBack" })
				return yield put({ type: "action/closeMiniAction" })
			}
		}
	} catch (error) {
		// console.log(error)
	}
}

export function* getCatMembersSync(action) {
	try {
		yield put({ type: "pass/setUsersLoading", payload: true })

		let repUsers = []
		let repGroups = []

		repUsers = yield call(getCatUsers, action)
		repGroups = yield call(getCatGroups, action)

		yield put({ type: "pass/setUsersLoading" })

		if (repGroups.length) repUsers = [...repGroups, ...repUsers]

		if (repUsers.length) {
			let s = yield select((state) => state.pass.data[0])

			if (action.payload.categoryId === s.id) {
				return yield put({
					type: "pass/updateData",
					payload: { repUsers },
				})
			} else {
				return
			}
		} else {
			return
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getCatUsers(action) {
	try {
		//console.log("running getCatUsers saga", action)
		addLCS(action)
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, categoryId: action.payload.categoryId }
		const data = yield call(Api.getCategoryUsers, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true: {
				removeRecentCatEntry(action.payload.categoryId)
				return removeLCS(action)
			}
			default: {
				removeLCS(action)

				return data
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getCatGroups(action) {
	try {
		addLCS(action)
		yield put({ type: "pass/setLAR" })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, categoryId: action.payload.categoryId }
		const data = yield call(Api.getCatGroups, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (action.payload.setInLar) {
					return yield put({
						type: "pass/setLAR",
						payload: { message: "successCG", data },
					})
				} else {
					return data
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* categoryAddGroup(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: {
				msg: I18n.t("pass.editRcpt.catAddGroupLoader"),
				transparency: true,
			},
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, action: action.payload }
		let data = {}

		if (backwardCompApi("1.18.3")) {
			data = yield call(Api.categoryAddGroupV2, payload)

			switch (data) {
				case "sessionExpired":
					yield put({
						type: "action/setActionLoader",
					})

					return yield put({ type: "auth/showPadlock" })
				case "sessionDestroyed":
					yield put({
						type: "action/setActionLoader",
					})

					return yield put({ type: "auth/sessionExpired" })
				case true:
					yield put({
						type: "action/setActionLoader",
					})

					return removeLCS(action)
				default:
					data = yield call(requestLoop, Api.categoryAddGroupV2, {
						...payload,
						action: {
							...payload?.action,
							task: data?.uuid,
						},
					})
					break
			}
		} else {
			data = yield call(Api.categoryAddGroup, payload)
		}

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				yield put({
					type: "action/setActionLoader",
				})

				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				yield put({
					type: "action/setActionLoader",
				})

				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({
					type: "action/setActionLoader",
				})

				return removeLCS(action)
			default: {
				removeLCS(action)
				toastr.success(I18n.t("pass.editRcpt.groupAdded"))

				return yield put({
					type: "LP_GET_CAT_MEMBERS_SYNC_SAGA",
					payload: { ...action.payload },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* categoryAddUser(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: {
				msg: I18n.t("pass.editRcpt.catAddUserLoader"),
				transparency: true,
			},
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, action: action.payload }
		let data = {}

		if (backwardCompApi("1.18.3")) {
			data = yield call(Api.categoryAddUserV2, payload)

			switch (data) {
				case "sessionExpired":
					yield put({
						type: "action/setActionLoader",
					})

					return yield put({ type: "auth/showPadlock" })
				case "sessionDestroyed":
					yield put({
						type: "action/setActionLoader",
					})

					return yield put({ type: "auth/sessionExpired" })
				case true:
					yield put({
						type: "action/setActionLoader",
					})

					return removeLCS(action)
				default:
					data = yield call(requestLoop, Api.categoryAddUserV2, {
						...payload,
						action: {
							...payload?.action,
							task: data?.uuid,
						},
					})
					break
			}
		} else {
			data = yield call(Api.categoryAddUser, payload)
		}

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				yield put({
					type: "action/setActionLoader",
				})

				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				yield put({
					type: "action/setActionLoader",
				})

				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({
					type: "action/setActionLoader",
				})

				return removeLCS(action)
			default: {
				removeLCS(action)
				toastr.success(I18n.t("pass.editRcpt.userAdded"))

				return yield put({
					type: "LP_GET_CAT_MEMBERS_SYNC_SAGA",
					payload: { ...action.payload },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

// Remove one or more groups of users from a given category.
export function* categoryDelGroup(action) {
	try {
		/* console.log("running categoryDelGroup saga", action) */
		addLCS(action)
		yield put({
			type: "action/setActionLoader",
			payload: {
				msg: I18n.t("pass.editRcpt.catDelGroupLoader"),
				transparency: true,
			},
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.categoryDelGroup, payload)

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("pass.editRcpt.groupRemoved"))

				return yield put({
					type: "LP_GET_CAT_MEMBERS_SYNC_SAGA",
					payload: { ...action.payload },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* categoryDelUser(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: {
				msg: I18n.t("pass.editRcpt.catDelUserLoader"),
				transparency: true,
			},
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.categoryDelUser, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("pass.editRcpt.userRemoved"))

				return yield put({
					type: "LP_GET_CAT_MEMBERS_SYNC_SAGA",
					payload: { ...action.payload },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* categoryUserExpi(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, action: action.payload }
		const data = yield call(Api.categoryUserExpi, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (action.payload.expirationDate === action.payload.userExpi) {
					toastr.success(I18n.t("pass.aCat.expiDeleteSuccess"))
				}
				if (
					action.payload.userExpi === "0" &&
					action.payload.expirationDate !== action.payload.userExpi
				) {
					toastr.success(I18n.t("pass.aCat.expiAddSuccess"))
				}
				if (
					action.payload.userExpi !== "0" &&
					action.payload.expirationDate !== action.payload.userExpi
				) {
					toastr.success(I18n.t("pass.aCat.expiModifySuccess"))
				}

				return yield put({
					type: "LP_GET_CAT_MEMBERS_SYNC_SAGA",
					payload: { ...action.payload },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* categoryChangeUserGrade(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.categoryChangeUserGrade, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				removeLCS(action)
				return yield put({
					type: "LP_GET_CAT_MEMBERS_SYNC_SAGA",
					payload: { ...action.payload },
				})
			default: {
				removeLCS(action)

				// Check if the user just demoted himself. If that's the case, close the popup.
				const userEmail = yield select(
					(state) => state.auth.token.userSession.userEmail,
				)

				if (
					action.payload.email === userEmail &&
					!action.payload.data.status
				) {
					let userAlreadySpotted = false
					let stillHasRights = false

					action.payload.usersList.forEach((item) => {
						if (
							(item.email === userEmail || item.isInGroup) &&
							item.protected > 0 &&
							item.protected !== 3
						) {
							if (userAlreadySpotted) {
								return (stillHasRights = true)
							} else {
								return (userAlreadySpotted = true)
							}
						}
					})

					if (!stillHasRights) {
						yield put({ type: "action/closeAction" })
					}
				}

				toastr.success(I18n.t("pass.editRcpt.userRightsChanged"))

				return yield put({
					type: "LP_GET_CAT_MEMBERS_SYNC_SAGA",
					payload: { ...action.payload },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* categoryChangeGroupGrade(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.categoryChangeGroupGrade, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				removeLCS(action)
				return yield put({
					type: "LP_GET_CAT_MEMBERS_SYNC_SAGA",
					payload: { ...action.payload },
				})
			default: {
				removeLCS(action)

				if (action.payload.isInGroup && !action.payload.data.status) {
					// Check if the user just demoted himself group. If that's the case, close the popup.
					const userEmail = yield select(
						(state) => state.auth.token.userSession.userEmail,
					)
					let userAlreadySpotted = false
					let stillHasRights = false

					action.payload.usersList.forEach((item) => {
						if (
							(item.email === userEmail || item.isInGroup) &&
							item.protected > 0 &&
							item.protected !== 3
						) {
							if (userAlreadySpotted) {
								return (stillHasRights = true)
							} else {
								return (userAlreadySpotted = true)
							}
						}
					})

					if (!stillHasRights) {
						yield put({ type: "action/closeAction" })
					}
				}

				toastr.success(I18n.t("pass.editRcpt.userRightsChanged"))

				return yield put({
					type: "LP_GET_CAT_MEMBERS_SYNC_SAGA",
					payload: { ...action.payload },
				})
			}
		}
	} catch (error) {
		//return console.log(error)
	}
}

export function* postTag(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		yield put({ type: "pass/setLAR" })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, action: action.payload }

		const data = yield call(Api.postTag, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({
					type: "pass/setLAR",
					payload: { message: "tagPosted" },
				})
				return yield put({
					type: "LP_DECRYPT_CREDIT_SAGA",
					payload: { id: action.payload.id },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteTag(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		yield put({ type: "pass/setLAR" })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, action: action.payload }

		const data = yield call(Api.deleteTag, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({
					type: "pass/setLAR",
					payload: { message: "tagRemoved" },
				})
				return yield put({
					type: "LP_DECRYPT_CREDIT_SAGA",
					payload: { id: action.payload.id },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getPPs(action) {
	try {
		addLCS(action)

		yield put({ type: "pass/setLAR" })
		yield put({
			type: "params/setLAR",
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]
		let payload = { token, xLsT, organizationId }
		const data = yield call(Api.getPPs, payload)

		const dataIsNok =
			data === "sessionExpired" ||
			data === "sessionDestroyed" ||
			data === true
		if (dataIsNok) {
			yield put({
				type: "pass/setLAR",
				payload: { message: "failedPPFetch" },
			}),
				yield put({
					type: "params/setLAR",
					payload: { message: "successfailedPPFetchPP" },
				})
		}
		//console.log("saga resp", data)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case "noPPs":
				return yield put({
					type: "pass/setLAR",
					payload: { message: "noPP" },
				})
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (action.payload.setInLar) {
					return (
						yield put({
							type: "pass/setLAR",
							payload: { message: "successPP", data },
						}),
						yield put({
							type: "params/setLAR",
							payload: { message: "successPP", data },
						})
					)
				} else {
					return
				} // ???
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getPP(action) {
	try {
		addLCS(action)

		yield put({ type: "pass/setLAR" })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]
		let payload = { token, xLsT, organizationId, id: action.payload.id }

		const data = yield call(Api.getPP, payload)

		const dataIsNok =
			data === "sessionExpired" ||
			data === "sessionDestroyed" ||
			data === true
		if (dataIsNok) {
			yield put({
				type: "pass/setLAR",
				payload: { message: "failedPPFetch" },
			})
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let message = "successPP"
				if (action.payload.message) message = action.payload.message
				storePasswordPolicy(data)

				if (action.payload.setInLar) {
					return yield put({
						type: "pass/setLAR",
						payload: { message, data },
					})
				}
				return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getFavCats(action) {
	try {
		addLCS(action)

		if (action.setInLAR) yield put({ type: "pass/setLAR" })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)

		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]
		const payload = { token, xLsT, organizationId }

		const data = yield call(Api.getFavs, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (action.setInLAR) {
					return yield put({
						type: "pass/setLAR",
						payload: { message: "successGetFavCats", data },
					})
				} else {
					return yield put({ type: "pass/setFavCats", payload: data })
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* postFavCat(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, categoryId: action.payload.id }

		const data = yield call(Api.postFav, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				return yield put({ type: "pass/addFavCat", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteFavCat(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, categoryId: action.payload.id }

		const data = yield call(Api.deleteFav, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				return yield put({
					type: "pass/removeFavCat",
					payload: payload.categoryId,
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* handleAlertCategory(action) {
	try {
		addLCS(action)
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = {
			token,
			xLsT,
			categoryId: action.payload.id,
			isAlert: action.payload.data.isAlert,
		}
		const data = yield call(Api.handleAlertCategories, payload)
		yield put({ type: "action/setActionLoader" })
		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("editSuccess"))
				yield put({ type: "action/closeAction" })
				yield put({
					type: "pass/updateData",
					payload: action.payload.data,
				})
				return yield put({
					type: "pass/categories_alerts",
					payload: data,
				})
			}
		}
	} catch (error) {
		// console.log(error)
	}
}

export function* getGuacamole(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		yield put({ type: "action/setActionLoader", payload: true })

		const payload = { token, xLsT, idCredential: action.payload.id }

		const data = yield call(Api.getGuacamole, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				return yield put({
					type: "pass/setConnection",
					payload: {
						message: "successGuacaConnection",
						data,
					},
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteGuacamole(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, connectionId: action.payload.id }

		const data = yield call(Api.deleteGuacamole, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				return yield put({
					type: "pass/setConnection",
					payload: { message: "deleteGuacaConnection" },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

/* ------------------------ LOCKPASS TRASH FUNCTIONS ------------------------ */
export function* getTrashTotal() {
	const xLsT = yield select((state) => state.auth.xLsToken)
	const token = yield select((state) => state.auth.token.token)

	// START SLIDE LOADER
	const trashButtonLoading = yield select(
		(state) => state.pass?.trash?.buttonLoading,
	)

	if (!trashButtonLoading) {
		yield put({ type: "pass/toggleTrashButtonLoading", payload: true })
	}

	const data = yield call(Api.getTrashTotal, {
		xLsT,
		token,
	})

	if (data === "sessionExpired") {
		return yield put({ type: "auth/showPadlock" })
	} else if (data === "sessionDestroyed") {
		return yield put({ type: "auth/sessionExpired" })
	} else if (data === true) {
		return yield put({
			type: "pass/setErrorNotFound",
			payload: true,
		})
	}

	yield put({ type: "pass/setTrashTotal", payload: data?.total ?? 0 })
	yield put({ type: "pass/toggleTrashButtonLoading" })

	return
}

export function* getTrashPassCard(action) {
	if (!action?.payload?.id) {
		return
	}

	const xLsT = yield select((state) => state.auth.xLsToken)
	const token = yield select((state) => state.auth.token.token)

	// START SLIDE LOADER
	const slideLoading = yield select((state) => state.pass?.slideLoading)

	if (!slideLoading) {
		yield put({ type: "pass/toggleSlideLoading", payload: true })
	}

	const data = yield call(Api.getTrashPassCard, {
		xLsT,
		token,
		id: action.payload?.id,
	})

	if (data === "sessionExpired") {
		return yield put({ type: "auth/showPadlock" })
	} else if (data === "sessionDestroyed") {
		return yield put({ type: "auth/sessionExpired" })
	} else if (data === true) {
		return yield put({
			type: "pass/setErrorNotFound",
			payload: true,
		})
	}

	yield put({
		type: "pass/updateActive",
		payload: { data: { ...data, passType: "Trash" }, ...action.payload },
	})
	yield put({ type: "pass/toggleSlideLoading" })

	return
}

export function* getTrashPassSaga(action) {
	if (!action?.payload) {
		return
	}

	const xLsT = yield select((state) => state.auth.xLsToken)
	const token = yield select((state) => state.auth.token.token)
	let loading = yield select((state) => state.pass.trashLoading)

	if (action?.payload?.page) {
		yield put({ type: "pass/paginLoading", payload: true })
	} else {
		if (!loading) {
			yield put({ type: "pass/toggleTrashLoading", payload: true })
		}
	}

	let data
	if (
		action?.payload?.hasRights ||
		(!action?.payload?.hasRights && action?.payload?.initCall !== "all") ||
		action?.payload.search
	) {
		data = yield call(Api.getTrashPasswords, {
			xLsT,
			token,
			type:
				action?.payload?.initCall === "all"
					? "all"
					: action?.payload?.isShared === "shared"
					? "shared"
					: "personal",
			order: action?.payload?.dateFilter == "recent" ? "desc" : "asc",
			page: action?.payload?.page ?? 1,
			results: 26,
			search: action?.payload?.search,
		})

		if (action?.payload?.search) {
			yield put({ type: "pass/toggleSlideLoading" })

			yield put({
				type: "pass/addTrashListElement",
				payload: {
					...data,
				},
			})

			yield put({
				type: "pass/initSearch",
				payload: { ...action.payload, isTrash: true },
			})

			yield put({ type: "pass/toggleLoading" })
		}
	}

	if (data === "sessionExpired") {
		return yield put({ type: "auth/showPadlock" })
	} else if (data === "sessionDestroyed") {
		return yield put({ type: "auth/sessionExpired" })
	}

	if (action?.payload?.page) {
		yield put({ type: "pass/paginLoading" })
	} else {
		yield put({ type: "pass/toggleTrashLoading", payload: false })
	}

	if (action?.payload?.isPagination) {
		yield put({
			type: "pass/addTrashListElement",
			payload: {
				...action.payload,
				...data,
			},
		})
	} else {
		yield put({
			type: "pass/addTrashListElement",
			payload: { ...data, ...action.payload },
		})

		yield put({
			type: "pass/setTrashFiles",
			payload: data,
		})
	}

	yield put({
		type: "pass/setTrashListType",
		payload:
			action?.payload?.initCall === "all"
				? "all"
				: action?.payload?.isShared === "shared"
				? "shared"
				: "personal",
	})

	yield put({ type: "pass/toggleCompassLoading" })

	yield put({
		type: "pass/setTrashListOrder",
		payload: action?.payload?.dateFilter,
	})
	return
}

export function* restoreTrashPass(action) {
	if (!action?.payload) {
		return
	}

	const xLsT = yield select((state) => state.auth.xLsToken)
	const token = yield select((state) => state.auth.token.token)
	const payload = {
		token,
		xLsT,
		passwordMasterIds: action.payload.passwordMasterIds,
	}

	const searchMode = yield select((state) => state.pass.searchMode)
	const pagin = yield select((state) => state.pass.pagin)

	// START ACTION LOADING
	yield put({
		type: "action/setActionLoader",
		payload: { transparency: true },
	})

	const data = yield call(
		Api.restoreTrashPass,
		!action?.payload?.passCatIsDeleted &&
			!action?.payload?.createCatForRemainingPasswords
			? payload
			: {
					...payload,
					...action.payload,
					passwordMasterIds: action.payload.passwordMasterIds || [],
			  },
	)

	if (data === "sessionExpired") {
		return yield put({ type: "auth/showPadlock" })
	} else if (data === "sessionDestroyed") {
		return yield put({ type: "auth/sessionExpired" })
	} else if (data === true) {
		yield put({ type: "action/setActionLoader" })
		yield put({ type: "action/closeMiniAction" })

		return yield put({
			type: "pass/setErrorNotFound",
			payload: true,
		})
	}

	if (
		data?.categoryErrorsCredentialIds ||
		data?.passwordPolicyErrorsCredentialsIds
	) {
		const active = yield select((state) => state.pass.active)
		const newActiveObject = {}

		Object.keys(active).forEach((passwordId) => {
			const passwordIdAsInt = Number(passwordId)

			if (
				data?.categoryErrorsCredentialIds?.includes(passwordIdAsInt) ||
				data?.passwordPolicyErrorsCredentialsIds?.includes(
					passwordIdAsInt,
				)
			) {
				newActiveObject[passwordIdAsInt] = active?.[passwordIdAsInt]
			}
		})

		yield put({
			type: "pass/updateActive",
			payload: {
				isSetter: true,
				newActiveObject,
			},
		})
		yield put({
			type: "action/setTrash",
			payload: {
				createCatForRemainingPasswords: true,
				ids: data?.categoryErrorsCredentialIds,
			},
		})
	} else {
		yield put({ type: "action/closeMiniAction" })
		yield put({ type: "pass/unselectAll" })
	}

	if (searchMode && !action?.payload?.isTrash) {
		yield put({
			type: "LP_GET_CREDENTIALS_SAGA",
			payload: {
				justRefresh: true,
				limit: pagin?.page * pagin?.limit,
				recountPagin: true,
			},
		})

		// wait for pass/addElements action to be called
		yield take("pass/addElements")
	}

	if (action?.requiresRedirectInWindowHistoryState || searchMode) {
		yield put({
			type: "LP_GET_TRASH_PASS_SAGA",
			payload: {
				limit: 26,
				dateFilter: action?.payload?.order,
				isShared: action?.payload?.isShared && "shared",
				hasRights: action?.payload?.hasRights,
				search: action?.payload?.search,
			},
		})
		yield put({ type: "action/setLAR", payload: "navigateBack" })

		yield put({
			type: "pass/updateActive",
			payload: { isSetter: true, newActiveObject: [] },
		})
	}

	if (!data.categoryErrorsCredentialIds) {
		toastr.success(I18n.t("pass.trash.miniPopRestore.success"))
	}

	return yield put({ type: "action/setActionLoader" })
}

/* ---------------------- END LOCKPASS TRASH FUNCTIONS ---------------------- */

/* ---------------------- LOCKPASS PERMALINKS FUNCTIONS --------------------- */
export function* getDataPermalinks(action) {
	if (Object.keys(action.payload).length > 0) {
		/* -------------------------------- VARIABLES ------------------------------- */
		const { payload } = action
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const isTrashRoute = payload?.isTrashRoute
		let passwordId = payload?.passwordId
		let stateTarget = payload?.stateTarget
		let categoryId = payload?.categoryId
		let passwordData = {}
		let categoryData = {}

		/* -------------------------------- FUNCTIONS ------------------------------- */
		const findCategory = (tree, currentCategoryId) => {
			tree.forEach((data) => {
				if (data.length) {
					data = data[0]
				}
				if (data?.id === currentCategoryId) {
					categoryData = data
				} else if (data?.children?.length > 0) {
					findCategory(data?.children, currentCategoryId)
				}
			})
		}
		/* ------------------------------ END FUNCTIONS ----------------------------- */

		/* ------------------------------ STORE UPDATE ------------------------------ */
		let compassLoading = yield select((state) => state.pass.compassLoading)
		let slideLoading = yield select((state) => state.pass.slideLoading)
		let loading = yield select((state) => state.pass.loading)
		let isArboLoaded = yield select((state) => state.pass.isArboLoaded)
		//let callRunning = yield select((state) => state.pass.callRunning)

		if (!compassLoading && !backwardCompApi("1.20.16") && !isTrashRoute) {
			yield put({ type: "pass/toggleCompassLoading", payload: true })
		}

		if (!slideLoading) {
			yield put({ type: "pass/toggleSlideLoading", payload: true })
		}

		if (!loading || backwardCompApi("1.20.16")) {
			yield put({ type: "pass/toggleLoading", payload: true })
		}

		// if (!callRunning) {
		// 	return
		// }

		yield put({ type: "pass/setCR", payload: true })

		if (payload.pageSize && !payload.limit) {
			payload.limit = payload.pageSize * 2
		}

		yield put({ type: "pass/savePageSize", payload: payload.limit / 2 })
		/* ---------------------------- END STORE UPDATE ---------------------------- */

		/* ----------------------------- HANDLE PASSWORD ---------------------------- */
		if (passwordId) {
			// ! TODO : check backward comp api version
			if (action?.payload?.isFirstPageLoad && backwardCompApi("1.22.0")) {
				return yield put({
					type: "LP_GET_DATA_PERMALINKS_PASSWORD_INITIAL_LOAD_SAGA",
				})
			}

			const masterCredentialsId = yield call(Api.getMasterCredentialsId, {
				xLsT,
				token,
				id: passwordId,
			})

			// VERIFY API CALL RESULT
			if (masterCredentialsId === "sessionExpired") {
				return yield put({ type: "auth/showPadlock" })
			} else if (masterCredentialsId === "sessionDestroyed") {
				return yield put({ type: "auth/sessionExpired" })
			} else if (masterCredentialsId === true) {
				return yield put({
					type: "pass/setErrorNotFound",
					payload: true,
				})
			}

			if (!isTrashRoute) {
				passwordId = masterCredentialsId
			}

			if (isTrashRoute) {
				passwordData = yield call(Api.getTrashPassCard, {
					xLsT,
					token,
					id: passwordId,
				})

				passwordData = {
					...passwordData,
					id: passwordId,
					domain: passwordData.domainName,
					isTrash: true,
				}
			} else {
				passwordData = yield call(Api.decryptCredit, {
					xLsT,
					token,
					id: passwordId,
				})
			}

			// VERIFY API CALL RESULT
			if (passwordData === "sessionExpired") {
				return yield put({ type: "auth/showPadlock" })
			} else if (passwordData === "sessionDestroyed") {
				return yield put({ type: "auth/sessionExpired" })
			} else if (passwordData === true) {
				return yield put({
					type: "pass/setErrorNotFound",
					payload: true,
				})
			}

			if (passwordData.id === undefined) {
				return
			}

			categoryId = passwordData?.categoryId
			stateTarget = categoryId === 0 ? 1 : 2

			passwordData["elemKey"] = passwordData?.id
			passwordData["elemType"] = "Pass"

			yield put({
				type: "LP_GET_DATA_PERMALINKS_PASSWORD_SAGA",
				payload: passwordData,
				isTrash: isTrashRoute,
			})
		}
		/* --------------------------- END HANDLE PASSWORD -------------------------- */

		if (categoryId && ![0, -1].includes(categoryId) && !isTrashRoute) {
			/* -------------------------------- VARIABLES ------------------------------- */
			const data = yield select((state) => state.pass.data)
			const branchInfo = yield select((state) => state.pass?.branchInfo)
			const sharedCategories = yield select(
				(state) => state.pass?.sharedCategories,
			)

			const persoCategories = yield select(
				(state) => state.pass?.persoCategories,
			)
			const auth = yield select((state) => state.auth)
			const organizationId =
				auth.token.userSession.organizationIds[auth.selectedOrg]
			const historyData = []
			const cleanHistoryData = []
			const flattenedTree = []
			let reps = []
			let branchData = null
			let repUsers = null
			let repGroups = null
			let accessibleCategoriesIds = []

			/* -------------------------------- FUNCTIONS ------------------------------- */
			const createHistory = (
				branchData,
				currentCategoryId,
				currentCategoryParentId,
			) => {
				branchData.forEach((branch) => {
					if (branch?.parentId === currentCategoryId) {
						return reps.push({
							...passRelatedConfig,
							static: false,
							...branch,
						})
					} else if (
						branch?.id !== currentCategoryId &&
						branch?.parentId !== currentCategoryParentId
					) {
						historyData.push({
							...passRelatedConfig,
							...branch,
						})
					}

					if (branch?.children?.length > 0) {
						return createHistory(
							branch?.children,
							currentCategoryId,
							currentCategoryParentId,
						)
					}
				})
			}

			const cleanHistory = (history, currentCategoryParentId) => {
				history.forEach((data) => {
					if (
						currentCategoryParentId !== undefined &&
						data?.id !== undefined &&
						(data?.id === currentCategoryParentId ||
							(data?.id === 0 &&
								currentCategoryParentId === -1) ||
							(data?.id === -1 && currentCategoryParentId === 0))
					) {
						cleanHistoryData.push(data)

						return cleanHistory(history, data?.parentId)
					}
				})
			}

			const setFlattenedTree = (tree) => {
				tree.forEach((branch) => {
					flattenedTree.push(branch)

					if (branch?.children?.length) {
						setFlattenedTree(branch?.children)
					}
				})
			}

			const getAccessibleCategoriesIds = (tree) => {
				tree?.forEach((branch) => {
					if (branch?.view > 0) {
						accessibleCategoriesIds.push(branch?.id)
					}

					if (branch?.children?.length > 0) {
						getAccessibleCategoriesIds(branch.children)
					}
				})
			}

			/* -------------------------------- API CALLS ------------------------------- */
			if (!action.payload?.categoryId) {
				action.payload.categoryId = categoryId
			}

			yield put({ type: "pass/setUsersLoading", payload: true })
			yield put({ type: "pass/toggleLoading", payload: true })

			// execute multiple requests at the same time to save performance
			// make branch request/categories request only if branchInfo attribute is not in store
			// or if new category is not in current main branch
			if (!payload?.isSharedCat && !isTrashRoute) {
				categoryData = yield call(Api.getCat, {
					xLsT,
					token,
					id: categoryId,
				})

				// VERIFY API CALL RESULT
				if (categoryData === "sessionExpired") {
					return yield put({ type: "auth/showPadlock" })
				} else if (categoryData === "sessionDestroyed") {
					return yield put({ type: "auth/sessionExpired" })
				} else if (categoryData === true) {
					return yield put({
						type: "pass/setErrorNotFound",
						payload: true,
					})
				}

				if (categoryData.id === undefined) {
					return
				}

				let requestsData = []
				let branchRequest

				if (
					payload?.resetBranchInfo ||
					!branchInfo ||
					!branchInfo?.tree ||
					!branchInfo?.ids ||
					branchInfo?.currentMainId === undefined ||
					branchInfo?.currentMainId !== categoryData?.mainId
				) {
					requestsData = yield all([
						call(getCatUsers, action),
						call(getCatGroups, action),
					])

					if (!persoCategories || payload?.reloadCateg) {
						branchRequest = yield call(Api.getBranch, {
							xLsT,
							token,
							mainId: categoryData.mainId,
						})

						yield put({
							type: "pass/setPersoCateg",
							payload: branchRequest,
						})

						branchData =
							branchRequest.length === 3
								? branchRequest?.[0]
								: branchRequest?.tree
								? branchRequest
								: branchInfo
					}
				} else {
					requestsData = yield all([
						call(getCatUsers, action),
						call(getCatGroups, action),
					])
				}

				if (persoCategories && !payload?.reloadCateg) {
					branchData =
						persoCategories.length === 3
							? persoCategories?.[0]
							: persoCategories?.tree
							? persoCategories
							: branchInfo
				}

				repUsers =
					requestsData.length === 3
						? requestsData?.[1]
						: requestsData?.[0]
				repGroups =
					requestsData.length === 3
						? requestsData?.[2]
						: requestsData?.[1]
			} else {
				let treeCatIds = []

				branchData = branchInfo

				const setTreeCatIds = (branchData) => {
					branchData?.forEach((branch) => {
						if (branch?.id) {
							treeCatIds.push(branch?.id)
						}

						if (branch?.children) {
							setTreeCatIds(branch?.children)
						}
					})
				}

				setTreeCatIds(!branchData?.tree ? [] : branchData?.tree)

				if (
					payload?.resetBranchInfo ||
					!branchInfo ||
					!branchInfo?.tree ||
					!branchInfo?.ids ||
					!branchInfo?.currentMainId ||
					!treeCatIds.includes(parseInt(payload?.categoryId))
				) {
					let rootBranchData

					if (backwardCompApi("90000")) {
						rootBranchData = yield call(
							requestLoopTreeStructure,
							Api.getRootCatsV2,
							{
								xLsT,
								token,
								organizationId,
							},
						)
					} else {
						if (!sharedCategories || payload?.reloadCateg) {
							rootBranchData = yield call(Api.getCategories, {
								xLsT,
								token,
								organizationId,
							})

							if (
								(backwardCompApi("1.20.16") &&
									!sharedCategories) ||
								payload?.reloadCateg
							) {
								yield put({
									type: "pass/setShareCateg",
									payload: rootBranchData,
								})
							}

							// VERIFY API CALL RESULT
							if (rootBranchData === "sessionExpired") {
								return yield put({ type: "auth/showPadlock" })
							} else if (rootBranchData === "sessionDestroyed") {
								return yield put({
									type: "auth/sessionExpired",
								})
							} else if (rootBranchData === true) {
								console.log(
									"[API CALL] - error when calling root branch endpoint",
								)
								return yield put({
									type: "files/setErrorNotFound",
									payload: true,
								})
							}
						} else {
							rootBranchData = sharedCategories
						}
					}

					if (backwardCompApi("90000")) {
						const transformedTree = yield call(
							checkIsInCat,
							rootBranchData,
						)

						getAccessibleCategoriesIds(transformedTree)

						rootBranchData = {
							...rootBranchData,
							tree: transformedTree,
							mainIds: rootBranchData?.ids,
							ids: accessibleCategoriesIds,
						}
					}

					if (rootBranchData?.tree) {
						findCategory(
							rootBranchData?.tree,
							parseInt(payload?.categoryId),
						)

						branchData = {
							...rootBranchData,
							tree: [
								rootBranchData?.tree?.find(
									(branch) =>
										branch?.id === categoryData?.mainId,
								),
							],
						}
					}
				}
			}

			if (
				backwardCompApi("1.20.16") &&
				payload.parentId !== undefined &&
				!isArboLoaded
			) {
				yield call(getPersoCategories, {
					type: "LP_GET_PERSO_CATEGORIES_SAGA",
					stateTarget: stateTarget,
					payload: {
						stateTarget: stateTarget,
						categoryId: stateTarget === 1 ? categoryId : null,
						initArbo: true,
						isFirstPageLoad: true,
						keepLoaders: true,
					},
				})

				yield call(getRootCats, {
					type: "LP_GET_ROOT_CATEGORIES_SAGA",
					stateTarget: stateTarget,
					keepLoaders: true,
					payload: {
						stateTarget: 2,
						categoryId: stateTarget !== 1 ? 0 : null,
						initArbo: true,
					},
				})
			}

			yield put({ type: "pass/setUsersLoading" })

			/* ------------------------- VERIFY API CALL RESULT ------------------------- */
			if (branchData === "sessionExpired") {
				return yield put({ type: "auth/showPadlock" })
			} else if (branchData === "sessionDestroyed") {
				return yield put({ type: "auth/sessionExpired" })
			} else if (branchData === true) {
				console.log("[API CALL] - error when calling branch endpoint")
				return yield put({
					type: "pass/setErrorNotFound",
					payload: true,
				})
			}

			if (repUsers === "sessionExpired") {
				return yield put({ type: "auth/showPadlock" })
			} else if (repUsers === "sessionDestroyed") {
				return yield put({ type: "auth/sessionExpired" })
			} else if (repUsers === true) {
				console.log("[API CALL] - error when calling catUsers endpoint")
				return yield put({
					type: "pass/setErrorNotFound",
					payload: true,
				})
			}

			if (repGroups === "sessionExpired") {
				return yield put({ type: "auth/showPadlock" })
			} else if (repGroups === "sessionDestroyed") {
				return yield put({ type: "auth/sessionExpired" })
			} else if (repGroups === true) {
				console.log(
					"[API CALL] - error when calling catGroups endpoint",
				)
				return yield put({
					type: "pass/setErrorNotFound",
					payload: true,
				})
			}
			/* ----------------------- END VERIFY API CALL RESULT ----------------------- */

			/* --------------------------------- CONTENT -------------------------------- */
			if (!payload?.isSharedCat) {
				createHistory(
					[data[1], ...branchData?.tree],
					parseInt(categoryId),
					categoryData?.parentId,
				)
				cleanHistory(historyData, categoryData?.parentId)
			} else {
				setFlattenedTree([data[2], ...branchData?.tree])

				let inHistory = true

				flattenedTree.forEach((branch) => {
					if (inHistory) {
						if (branch?.id !== parseInt(payload?.categoryId)) {
							historyData.unshift({
								...passRelatedConfig,
								isInCategory: branchData?.ids?.includes(
									branch?.id,
								),
								static: branch?.id === -1 ? true : false,
								name: branch?.name,
								id: branch?.id,
								mainId: branch?.mainId,
								parentId: branch?.parentId,
							})
						} else {
							categoryData = {
								...passRelatedConfig,
								...branch,
								isInCategory: branchData?.ids?.includes(
									branch?.id,
								),
								static: false,
								name: branch?.name,
								id: branch?.id,
								mainId: branch?.mainId,
								parentId: branch?.parentId,
							}

							if (branch?.children?.length > 0) {
								reps = checkDeadEnd(
									branch,
									branchData?.ids,
								)?.children?.map((child) => ({
									...passRelatedConfig,
									...child,
									isInCategory: branchData?.ids?.includes(
										child?.id,
									),
									static: false,
									name: child?.name,
									id: child?.id,
									mainId: child?.mainId,
									parentId: child?.parentId,
								}))
							}

							inHistory = false
						}
					}
				})

				if (!categoryData?.id) {
					return yield put({
						type: "pass/setErrorNotFound",
						payload: true,
					})
				}

				cleanHistory(historyData, categoryData?.parentId)
			}

			let hasAccess = false

			if (payload?.isSharedCat) {
				if (
					(branchData?.ids?.includes(categoryData?.id) &&
						!backwardCompApi("90000")) ||
					(backwardCompApi("90000") &&
						branchData?.ids?.includes(categoryData?.id) &&
						categoryData?.view > 0)
				) {
					const requestsData = yield all([
						call(Api.getCat, {
							xLsT,
							token,
							id: categoryId,
						}),
						call(getCatUsers, action),
						call(getCatGroups, action),
					])

					categoryData =
						requestsData?.length === 3
							? requestsData?.[0]
							: categoryData
					repUsers =
						requestsData?.length === 3
							? requestsData?.[1]
							: requestsData?.[0]
					repGroups =
						requestsData?.length === 3
							? requestsData?.[2]
							: requestsData?.[1]

					// VERIFY API CALL RESULT
					if (categoryData === "sessionExpired") {
						return yield put({ type: "auth/showPadlock" })
					} else if (categoryData === "sessionDestroyed") {
						return yield put({ type: "auth/sessionExpired" })
					} else if (categoryData === true) {
						return yield put({
							type: "pass/setErrorNotFound",
							payload: true,
						})
					}

					if (repUsers === "sessionExpired") {
						return yield put({ type: "auth/showPadlock" })
					} else if (repUsers === "sessionDestroyed") {
						return yield put({ type: "auth/sessionExpired" })
					} else if (repUsers === true) {
						console.log(
							"[API CALL] - error when calling catUsers endpoint",
						)
						return yield put({
							type: "pass/setErrorNotFound",
							payload: true,
						})
					}

					if (repGroups === "sessionExpired") {
						return yield put({ type: "auth/showPadlock" })
					} else if (repGroups === "sessionDestroyed") {
						return yield put({ type: "auth/sessionExpired" })
					} else if (repGroups === true) {
						console.log(
							"[API CALL] - error when calling catGroups endpoint",
						)
						return yield put({
							type: "pass/setErrorNotFound",
							payload: true,
						})
					}

					if (categoryData.id === undefined) {
						return
					}

					categoryData = {
						...passRelatedConfig,
						static: false,
						...categoryData,
						isInCategory: true,
					}

					hasAccess = true
				} else {
					delete categoryData?.repUsers
				}
			}

			yield put({
				type: "pass/updateHistory",
				payload: cleanHistoryData.reverse(),
			})

			const categoryPayload = {
				domTarget: 0,
				categoryId: parseInt(categoryId),
				mainId: categoryData.mainId,
				limit: 26,
				initCall: true,
				productTarget: 0,
			}

			const currentRepData = {
				...categoryData,
				static: false,
				reps: reps?.sort((a, b) =>
					a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
				),
				repUsers: Array.isArray(repUsers)
					? repGroups.length
						? [...repGroups, ...repUsers]
						: repUsers
					: [],
			}

			if (!hasAccess && payload?.isSharedCat) {
				delete currentRepData?.repUsers
				delete currentRepData?.dispatchable
			}

			if (categoryData?.parentId) {
				categoryPayload["parentId"] = categoryData.parentId
			}

			if (categoryData?.isInCategory) {
				categoryPayload["isInCategory"] = categoryData.isInCategory
			}

			yield put({
				type: "pass/updateData",
				payload: {
					data: currentRepData,
					doNotKeep: true,
					reset: true,
				},
			})

			yield put({
				type: "pass/saveBranch",
				payload: {
					...branchData,
					currentMainId: categoryData?.mainId,
				},
			})

			if (hasAccess || !payload?.isSharedCat) {
				yield put({
					type: "LP_GET_CREDENTIALS_SAGA",
					keepLoaders: true,
					payload: categoryPayload,
				})

				yield take("pass/addElements")
			}
		} else if (isTrashRoute) {
			const dateFilter = action?.payload?.dateFilter ?? "recent"

			yield put({
				type: "LP_GET_TRASH_PASS_SAGA",
				payload: {
					initCall: action?.payload.initCall && "all",
					isShared: action?.payload.isShared && "shared",
					dateFilter: dateFilter,
					page: action?.payload?.page ?? 1,
					results: action?.payload?.limit ?? 26,
					hasRights: action?.payload?.hasRights,
				},
			})
		} else {
			if (action.payload.isFirstPageLoad) {
				yield call(getPersoCategories, {
					type: "LP_GET_PERSO_CATEGORIES_SAGA",
					stateTarget: stateTarget,
					payload: {
						stateTarget: stateTarget,
						categoryId: stateTarget === 1 ? categoryId : null,
						initArbo: stateTarget !== 1,
						isFirstPageLoad: true,
					},
				})

				yield call(getRootCats, {
					type: "LP_GET_ROOT_CATEGORIES_SAGA",
					stateTarget: stateTarget,
					payload: {
						stateTarget: 2,
						categoryId: stateTarget !== 1 ? 0 : null,
						initArbo: stateTarget === 1,
						isFirstPageLoad: true,
					},
				})

				if (stateTarget === 1) {
					yield put({ type: "pass/removeFirstStateElement" })
					yield put({ type: "pass/removeLastStateElement" })
				} else {
					yield put({ type: "pass/removeFirstStateElement" })
					yield put({ type: "pass/removeFirstStateElement" })
				}

				if (!payload?.isSharedCat && payload.stateTarget !== 2) {
					yield put({
						type: "LP_GET_CREDENTIALS_SAGA",
						keepLoaders: true,
						payload: { categoryId: 0, limit: 26 },
					})

					yield take("pass/addElements")
				}

				yield put({ type: "pass/toggleSlideLoading" })

				yield put({ type: "pass/toggleCompassLoading" })
				yield put({ type: "pass/setCR" })

				return
			} else {
				yield put({
					type:
						stateTarget === 1 || categoryId === 0
							? "LP_GET_PERSO_CATEGORIES_SAGA"
							: "LP_GET_ROOT_CATEGORIES_SAGA",
					stateTarget: stateTarget,
					payload: {
						stateTarget: stateTarget,
						categoryId: categoryId ?? 0,
						initArbo: action?.payload?.initArbo,
					},
				})
			}
			yield take("pass/addReps")
		}

		if (
			![null, undefined].includes(stateTarget) &&
			!categoryId &&
			!isTrashRoute &&
			!action?.payload?.initArbo
		) {
			yield call(getDataFromHomepageLP, {
				payload: {
					...payload,
					stateTarget,
					keepLoaders: true,
				},
			})

			// wait for pass/addElements action to be called
			if (stateTarget === 1) yield take("pass/addElements")
		}

		slideLoading = yield select((state) => state.pass.slideLoading)
		loading = yield select((state) => state.pass.loading)
		compassLoading = yield select((state) => state.pass.compassLoading)
		const runningGetIsWaitingSaga = yield select(
			(state) => state.pass.runningGetIsWaitingSaga,
		)

		if (slideLoading) yield put({ type: "pass/toggleSlideLoading" })
		if (loading && !runningGetIsWaitingSaga) {
			yield put({ type: "pass/toggleLoading" })
		}
		if (compassLoading && !backwardCompApi("1.20.16"))
			yield put({ type: "pass/toggleCompassLoading" })

		if (!passwordId && !runningGetIsWaitingSaga) {
			yield put({ type: "pass/setCR" })
		}
	}
}

export function* getDataPasswordPermalinks(action) {
	// wait for pass/addElements action to be called
	if (action?.isTrash) {
		yield take("pass/addTrashListElement")
	} else {
		yield take("pass/addElements")
	}

	yield put({ type: "pass/manageSelected", payload: action.payload })

	yield put({
		type: "LP_DECRYPT_CREDIT_SAGA",
		payload: {
			...action.payload,
			hasPasswordData: true,
		},
	})

	// wait for pass/updateActive action to be called
	yield take("pass/updateActive")

	yield put({ type: "pass/setCR" })
}

export function* getDataPasswordInitialLoadPermalinks(action) {
	if (!action?.payload || !backwardCompApi("1.22.0")) {
		return
	}

	const xLsT = yield select((state) => state.auth.xLsToken)
	const token = yield select((state) => state.auth.token.token)
	const { passwordId } = action.payload
	let passwordData

	const masterCredentialsId = yield call(Api.getMasterCredentialsId, {
		xLsT,
		token,
		id: passwordId,
	})

	// VERIFY API CALL RESULT
	if (masterCredentialsId === "sessionExpired") {
		return yield put({ type: "auth/showPadlock" })
	} else if (masterCredentialsId === "sessionDestroyed") {
		return yield put({ type: "auth/sessionExpired" })
	} else if (masterCredentialsId === true) {
		return yield put({
			type: "pass/setErrorNotFound",
			payload: true,
		})
	}

	passwordId = masterCredentialsId
	passwordData = yield call(Api.decryptCredit, {
		xLsT,
		token,
		id: passwordId,
	})

	// VERIFY API CALL RESULT
	if (passwordData === "sessionExpired") {
		return yield put({ type: "auth/showPadlock" })
	} else if (passwordData === "sessionDestroyed") {
		return yield put({ type: "auth/sessionExpired" })
	} else if (passwordData === true) {
		return yield put({
			type: "pass/setErrorNotFound",
			payload: true,
		})
	}

	if (passwordData.id === undefined) {
		return
	}

	categoryId = passwordData?.categoryId
	stateTarget = categoryId === 0 ? 1 : 2

	passwordData["elemKey"] = passwordData?.id
	passwordData["elemType"] = "Pass"

	yield put({ type: "pass/manageSelected", payload: action.payload })

	yield put({
		type: "LP_DECRYPT_CREDIT_SAGA",
		payload: {
			...action.payload,
			hasPasswordData: true,
		},
	})

	// wait for pass/updateActive action to be called
	yield take("pass/updateActive")

	yield put({ type: "pass/setCR" })
}

/* -------------------- END LOCKPASS PERMALINKS FUNCTIONS ------------------- */

export default function* passSagas() {
	yield takeLatest("LP_GET_CREDENTIALS_SAGA", getCredentials)
	yield takeLeading("LP_GET_IS_WAITING_SAGA", getIsWaiting)
	yield takeLatest("LP_GET_CATEGORIES_SAGA", getCategories)
	yield takeLatest("LP_GET_CATEGORY_SAGA", getCategory)
	yield takeLatest("LP_GET_CATEGORY_SEARCH_SAGA", getCatSearch)
	yield takeLatest("LP_SEARCH_TELEPORT", teleportFromPassSearch)
	yield takeLatest("LP_GET_PERSO_CATEGORIES_SAGA", getPersoCategories)
	yield takeLeading("LP_GET_ROOT_CATEGORIES_SAGA", getRootCats)
	yield takeLatest("LP_GET_BRANCH_SAGA", getBranch)
	yield takeEvery("LP_GET_DATA_SAGA", getData)

	yield takeLatest("LP_GET_CAT_MEMBERS_SYNC_SAGA", getCatMembersSync)

	yield takeLeading("LP_DECRYPT_CREDIT_SAGA", decryptCredit)
	yield takeLeading("LP_POST_CREDENTIAL_SAGA", postCredential)
	yield takeLeading("LP_PUT_CREDIT_SAGA", putCredit)
	yield takeLeading("LP_GRAB_CREDIT_SAGA", grabCredential)
	yield takeLeading("LP_GRAB_ALL_CREDIT_SAGA", grabAllCredentials)
	//yield takeLeading("LP_MOVE_CREDIT_SAGA", moveCredit)
	yield takeLeading("LP_DELETE_CREDIT_SAGA", deleteCredit)
	yield takeLeading("LP_UPLOAD_CREDIT_FILES_SAGA", addFilesToCredit)
	yield takeLeading("LP_DELETE_CREDIT_FILE_SAGA", deleteCreditFile)
	yield takeLeading("LP_DOWNLOAD_CREDIT_FILE_SAGA", downloadCreditFile)
	yield takeLeading("LP_GET_SSH_FILE_SAGA", downloadSshFile)
	yield takeLeading("LP_REMOVE_TOTP", removeTotp)

	yield takeLeading("LF_GET_CATEGORY_REPORT", getCategoryReport)
	yield takeLeading("LP_POST_CATEGORY_SAGA", postCategory)
	yield takeLeading("LP_PUT_CATEGORY_SAGA", putCategory)
	yield takeLeading("LP_DELETE_CATEGORY_SAGA", deleteCategory)

	yield takeLeading("LP_CAT_ADD_GROUP_SAGA", categoryAddGroup)
	yield takeLeading("LP_CAT_ADD_USER_SAGA", categoryAddUser)
	yield takeLeading("LP_CAT_DEL_GROUP_SAGA", categoryDelGroup)
	yield takeLeading("LP_CAT_DEL_USER_SAGA", categoryDelUser)
	yield takeLeading("LP_CAT_USER_EXPI_SAGA", categoryUserExpi)
	yield takeLeading("LP_CAT_CHANGE_USER_GRADE_SAGA", categoryChangeUserGrade)
	yield takeLeading(
		"LP_CAT_CHANGE_GROUP_GRADE_SAGA",
		categoryChangeGroupGrade,
	)

	yield takeLeading("LP_POST_CREDIT_TAG_SAGA", postTag)
	yield takeLeading("LP_DELETE_CREDIT_TAG_SAGA", deleteTag)

	yield takeLeading("LP_GET_PPs_SAGA", getPPs)
	yield takeEvery("LP_GET_PP_SAGA", getPP)

	yield takeLeading("LP_GET_FAV", getFavCats)
	yield takeLeading("LP_POST_FAV", postFavCat)
	yield takeLeading("LP_DELETE_FAV", deleteFavCat)

	/* ----------------------------- LOCKPASS GUACAMOLE ----------------------------- */
	yield takeLeading("LP_GET_GUACAMOLE_SAGA", getGuacamole)
	yield takeLeading("LP_DELETE_GUACAMOLE_SAGA", deleteGuacamole)

	/* ----------------------------- LOCKPASS TRASH ----------------------------- */
	yield takeLeading("LP_GET_TRASH_TOTAL", getTrashTotal)
	yield takeLeading("LP_GET_TRASH_PASS_CARD_SAGA", getTrashPassCard)
	yield takeLeading("LP_GET_TRASH_PASS_SAGA", getTrashPassSaga)
	yield takeLeading("LP_RESTORE_TRASH_PASS_SAGA", restoreTrashPass)

	/* --------------------------- END LOCKPASS TRASH --------------------------- */

	yield takeLeading("LP_ALERT_CATEGORIES", handleAlertCategory)

	/* --------------------------- LOCKPASS PERMALINKS -------------------------- */
	yield takeLeading("LP_GET_DATA_PERMALINKS_SAGA", getDataPermalinks)
	yield takeLeading(
		"LP_GET_DATA_PERMALINKS_PASSWORD_SAGA",
		getDataPasswordPermalinks,
	)
	yield takeLeading(
		"LP_GET_DATA_PERMALINKS_PASSWORD_INITIAL_LOAD_SAGA",
		getDataPasswordInitialLoadPermalinks,
	)
	/* ------------------------- END LOCKPASS PERMALINKS ------------------------ */

	const channel = yield actionChannel("LP_WITH_BUFFER_SAGA")

	while (true) {
		const payload = yield take(channel)

		switch (payload.payload.channelMod) {
			case "uploadFile": {
				yield call(addFilesToCredit, payload)
				break
			}
			case "putCredit": {
				yield call(putCredit, payload)
				break
			}
			default:
				return null
		}
	}
}
