/* eslint-disable react/react-in-jsx-scope */
import { backwardCompApi } from "../../actions/generalUtils"

export function updateRecentPassEntries(pass) {
	const entryToUpdate = {
		categoryName: pass.categoryName,
		categoryId: pass.categoryId,
		domain: pass.domain,
		id: pass.id,
		name: pass.name,
	}
	let _recentPass = localStorage.getItem("LPrecentPass")

	if (_recentPass?.length) {
		_recentPass = JSON.parse(_recentPass)

		let updated = false
		_recentPass.forEach((entry, i) => {
			if (entry.id === pass.id) {
				updated = true
				return (_recentPass[i] = entryToUpdate)
			}
		})

		if (updated)
			localStorage.setItem("LPrecentPass", JSON.stringify(_recentPass))
		return
	} else return
}

export function addRecentPassEntry(pass, categoryName) {
	const entryToAdd = {
		categoryName,
		categoryId: pass.categoryId,
		domain: pass.domain,
		id: pass.id,
		name: pass.name,
		mainId: pass?.mainId,
	}
	let _recentPass = localStorage.getItem("LPrecentPass")

	if (!_recentPass?.length) {
		_recentPass = []
	} else {
		_recentPass = JSON.parse(_recentPass)
		_recentPass = _recentPass.filter((x) => x.id !== pass.id)
	}

	if (_recentPass.length >= 4) _recentPass.pop()

	_recentPass.unshift(entryToAdd)
	return localStorage.setItem("LPrecentPass", JSON.stringify(_recentPass))
}

export function removeRecentPassEntry(targetId) {
	let _recentPass = localStorage.getItem("LPrecentPass")

	if (_recentPass?.length) {
		_recentPass = JSON.parse(_recentPass)
		targetId.forEach((id) => {
			_recentPass = _recentPass.filter((x) => x.id !== id)
			_recentPass = _recentPass.filter((x) => x.categoryId !== id)
		})
		return localStorage.setItem("LPrecentPass", JSON.stringify(_recentPass))
	} else return
}

export function addRecentCatEntry(cat) {
	let _recentCats = localStorage.getItem("LPrecentCats")
	const clonedCat = { ...cat }

	if (![undefined, null]?.includes(clonedCat?.reps)) {
		delete clonedCat.reps
	}

	if (!_recentCats?.length) {
		_recentCats = []
	} else {
		_recentCats = JSON.parse(_recentCats)
		_recentCats = _recentCats.filter((x) => x.id !== clonedCat.id)
	}

	if (_recentCats.length >= 15) _recentCats.pop()

	_recentCats.unshift(clonedCat)
	return localStorage.setItem("LPrecentCats", JSON.stringify(_recentCats))
}

export function removeRecentCatEntry(targetId) {
	let _recentCat = localStorage.getItem("LPrecentCats")

	if (_recentCat?.length) {
		_recentCat = JSON.parse(_recentCat)
		if (backwardCompApi("1.11.1")) {
			targetId.forEach((id) => {
				_recentCat = _recentCat.filter((x) => x.id !== id)
			})
		} else {
			_recentCat = _recentCat.filter((x) => x.id !== targetId)
		}
		return localStorage.setItem("LPrecentCats", JSON.stringify(_recentCat))
	} else return
}
