/*
	Category
	A selectable category that is rendered in the CatTree component.
	It allows the user to select it as well as showing/hiding its children categories.
*/

import React, { useEffect, useState, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import * as Api from "../../../pages/pass/services"
import TooltipHelper from "../../../stories/tooltip/TooltipHelper"
import LoaderBars from "../../animations/LoaderBars"

import { ReactComponent as Folder } from "../../../assets/images/folder.svg"
import { ReactComponent as Place } from "../../../assets/images/place.svg"
import { ReactComponent as Recursive } from "../../../assets/images/recursive.svg"
import { ReactComponent as SelectArrow } from "../../../assets/images/selectArrow.svg"
import { ReactComponent as FolderPosition } from "../../../assets/images/folderPosition.svg"
import { ReactComponent as SharedRep } from "../../../assets/images/SharedRep.svg"

const Category = (props) => {
	const {
		data,
		isRootCat,
		isMainCat,
		displayBranch,
		setDisplayBranch,
		persoStateObject,
		sharedStateObject,
		displayStateObject,
		selectedStateObject,
		targetPathStateObject,
		fetchingBranchStateObject,
	} = props

	const { fetchingBranch, setFetchingBranch } = fetchingBranchStateObject

	const [t] = useTranslation()
	const xLsT = useSelector((state) => state.auth.xLsToken)
	const token = useSelector((state) => state.auth.token.token)
	const selectedPasswords = useSelector((state) => state.pass.active)
	const initialRender = useRef(true)

	const [selectable, setSelectable] = useState(true)
	const [displayChildren, setDisplayChildren] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [childrenFetched, setChildrenFetched] = useState()
	const [isSelected, setIsSelected] = useState(false)
	const [partialTarget, setPartialTarget] = useState()
	const [displayBranchEmitter, setDisplayBranchEmitter] = useState(false)
	const [titleHovered, setTitleHovered] = useState(false)

	// ======== USEEFFECTS ========
	// When the component render, check if the category must be fetch via getBranch.
	// The only categories that must fetch data are the mainCat, they have a specific prop.
	// Since the search has been implemented, there are mainCats that can have been fetched
	// during the search. If that's the case, the data object will have a 'fetched' key.
	useEffect(() => {
		if (!isMainCat || data.fetched) {
			setChildrenFetched(true)
		} else {
			setChildrenFetched(false)
		}
	}, [isMainCat])

	// Check when first rendering the component if we want to display the whole branch. If this is the case,
	// display the children. Since the first render determine whether the data has already been fetched,
	// this must be triggered only during second render.
	useEffect(() => {
		if (displayBranch && !initialRender.current) {
			if (sharedStateObject) {
				handleSharedClick()
			} else {
				handlePersoClick()
			}

			setDisplayBranchEmitter(true)
		}

		// Note to myself (as I'm the only one following this rule), even though sharedStateObject is used in this
		// useEffect, it can't be put in the dependency array, as this would cause subcategories to be automatically
		// hidden if the user fetch another branch after having display a branch.
	}, [displayBranch, initialRender.current])

	// What to do with the target prop.
	useEffect(() => {
		// If there is a targetPath, start by comparing its first and last members to the
		// current category ID.
		if (targetPathStateObject?.targetPath) {
			const targetPathFirstId = targetPathStateObject.targetPath[0]
			const targetPathLastId =
				targetPathStateObject.targetPath[
					targetPathStateObject.targetPath.length - 1
				]

			// If the current category is part of the path to the target, it will pass a reduced
			// path to its children. And don't forget to display the children in this case !
			const isSharedRoot = isRootCat && sharedStateObject
			if (
				data.id === targetPathFirstId ||
				(targetPathFirstId === "sharedRoot" && isSharedRoot)
			) {
				const newState = [...targetPathStateObject.targetPath]
				newState.shift()
				setPartialTarget(newState)
				setDisplayChildren(true)
			}

			// If the current category is the last ID of the path to the target, it means
			// we have reached the target ! We can safely remove any data in the partialTarget
			// state to avoid passing useless information to the children.
			if (data.id === targetPathLastId) {
				setIsSelected(true)
				selectedStateObject.setNewRep(data)
				setPartialTarget(undefined)
				setDisplayChildren(false)
			}
		}

		// If the targetPath becomes undefined, it means the user has dropped the target, remove the partialTargetPath state.
		if (targetPathStateObject.targetPath === undefined) {
			setPartialTarget(undefined)
		}
	}, [data, targetPathStateObject.targetPath, selectedStateObject.setNewRep])

	// Check if the category is currently selected.
	useEffect(() => {
		const sharedSpaceSelected =
			data.name === selectedStateObject?.newRep?.name &&
			data?.name === "Shared space"
		const sameId = data.id === selectedStateObject?.newRep?.id

		if (!selectedStateObject.newRep || (!sameId && !sharedSpaceSelected)) {
			setIsSelected(false)
		} else if (!sharedSpaceSelected) {
			setIsSelected(true)
		}
	}, [data, selectedStateObject])

	// Check if the category can be selected. It must not contain any of the selected passwords This is a useLayoutEffect
	// to prevent a flickering effect on the icon.
	// For the shared categories, we must also check whether the user has accessed to them.
	useLayoutEffect(() => {
		let isSelectable = true

		Object.keys(selectedPasswords).forEach((item) => {
			if (selectedPasswords[item].categoryId === data.id) {
				isSelectable = false
			}
		})

		if (isRootCat && sharedStateObject) isSelectable = false

		// if (sharedStateObject && !data.isInCategory) isSelectable = false

		if (!isSelectable) setSelectable(false)
	})

	// Listen for any change in displayAll global state. If there is one, the displayChildren must be updated.
	useEffect(() => {
		if (displayStateObject.displayAll === true) setDisplayChildren(true)
		else if (displayStateObject.displayAll === false)
			setDisplayChildren(false)

		// Once the displayChildren has been set, put the displayAll global state back on undefined.
		// This is needed because we want the "Show/Hide all" buttons to still work after they have been
		// used.
		displayStateObject.setDisplayAll()
	}, [displayStateObject])

	// Track the initial render
	useEffect(() => {
		initialRender.current = false
	}, [initialRender])

	// ======== HANDLERS ========
	// Display the children of a personnal category.
	// Also stop showing the whole branch when the user modify the display.
	const handlePersoClick = (resetBranchDisplayState) => {
		if (resetBranchDisplayState) {
			if (setDisplayBranch) setDisplayBranch(false)
			setDisplayBranchEmitter(false)
		}

		return setDisplayChildren(!displayChildren)
	}

	// Display the children of a shared category
	// Also stop showing the whole branch when the user modify the display.
	const handleSharedClick = (resetBranchDisplayState) => {
		// Start by checking if we've already fetched the branch. If the data hasn't been fetch, we're going
		// to need to run the service.
		if (!childrenFetched) {
			setIsLoading(true)
			setFetchingBranch(true)
			fetchBranch(
				data,
				sharedStateObject.sharedCategories,
				sharedStateObject.setSharedCategories,
				setIsLoading,
			)

			setChildrenFetched(true)
		}

		if (resetBranchDisplayState) {
			if (setDisplayBranch) setDisplayBranch(false)
			setDisplayBranchEmitter(false)
		}
		return setDisplayChildren(!displayChildren)
	}

	// Fetch a root category branch if the data isn't already present.
	// WARNING : Exception to the saga => service => lar rule here !
	// The fetch is done in the component, not via a saga, because the user may have to display
	// a lot of root categories and I don't want to have multiple useless useEffects executions
	// to catch the fetch response whenever we get a single branch.
	const fetchBranch = async (
		branch,
		categoryTree,
		categoryTreeCallback,
		loaderCallback,
	) => {
		const payload = { token, xLsT, mainId: branch.mainId }
		const newState = JSON.parse(JSON.stringify(categoryTree))

		const response = await Api.getBranch(payload)
		setFetchingBranch(false)

		const accessibleCategoriesIds = response.ids

		// Special annoying case here. A user can have access to a category and one of its sub-sub-category without having access to the
		// intermediate sub category, and this sub category must be removed from the tree.
		const removeUnauthorizedCats = (branch) => {
			let result = []

			// Check, for each category of the current level, if the user has access.
			for (let i = 0; i < branch.length; i++) {
				const filteredAccessibleIds = accessibleCategoriesIds.filter(
					(item) => {
						return item === branch[i]?.id
					},
				)

				branch[i].isInCategory = filteredAccessibleIds.length > 0

				// User can access the category, everything's fine !
				// We also must display a shared root category.
				if (
					branch[i].isInCategory ||
					branch[i].id === branch[i].mainId
				) {
					result.push({ ...branch[i], children: [] })

					if (branch[i].children) {
						result[result.length - 1].children =
							removeUnauthorizedCats(branch[i].children)
					} else {
						delete result[result.length - 1].children
					}

					// User can't access the category. It must not be put in the branch we'll display
					// and its children must take his place (only if they are accessible).
				} else {
					if (branch[i].children) {
						const allowedCategoryChildren = removeUnauthorizedCats(
							branch[i].children,
						)

						if (allowedCategoryChildren.length > 0) {
							result = result.concat(allowedCategoryChildren)
						}
					}
				}
			}

			return result
		}

		// const categoryChildren = response.tree[0].children
		const categoryChildren = removeUnauthorizedCats(response.tree)[0]
			.children

		for (let i = 0; i < newState.children.length; i++) {
			const item = newState.children[i]

			// Once we have found the branch we just fetched...
			if (item.id === data.id) {
				// ... set its fetched key to true, to prevent ulterior reloading.
				newState.children[i].fetched = true

				// If the branch has children, add the result to the sharedCategories state and

				// If the branch has children, add the result to the sharedCategories state and
				// register the fetch in the category object (if the user goes back and want to
				// do a search, the CatTree will need this).
				if (categoryChildren) {
					newState.children[i].children = [...categoryChildren]
				}

				categoryTreeCallback(newState)
				loaderCallback(false)

				return newState
			}
		}
	}

	// Select this category
	const handleSelect = (event) => {
		event.preventDefault()

		// If the category wasn't already selected, select it. Else, remove the selection.
		// And don't forget to remove the targetPath if we change the selection !
		if (data.id === selectedStateObject.newRep?.id) {
			selectedStateObject.setNewRep(undefined)
		} else {
			selectedStateObject.setNewRep(data)
		}

		targetPathStateObject.setTargetPath(undefined)
		setPartialTarget(undefined)
		return setIsSelected(true)
	}

	// Emit the 'display the whole branch' order.
	const handleBranchEmission = async (event) => {
		event.preventDefault()
		setDisplayBranchEmitter(true)
		if (sharedStateObject) return handleSharedClick()
		else return handlePersoClick()
	}

	// When the user hovers on the category title element, display the "deploy branch" button
	const handleEnterTitle = () => {
		setTitleHovered(true)
	}

	// When the user stops hovering on the category title element, display the "deploy branch" button
	const handleLeaveTitle = () => {
		setTitleHovered(false)
	}

	// ======== RENDER ========

	return (
		<li
			className={`Category${isMainCat ? " main" : ""} ${
				isRootCat ? " root" : ""
			}`}
		>
			<div
				className={`categoryTitle${!selectable ? " unselectable" : ""}`}
				id={isSelected ? "selected" : ""}
				onMouseEnter={handleEnterTitle}
				onMouseLeave={handleLeaveTitle}
			>
				{data.children || !childrenFetched ? (
					<button
						className={`deployHide${
							isMainCat && !childrenFetched && fetchingBranch
								? " inactive"
								: ""
						}`}
						onClick={() => {
							setDisplayBranchEmitter(false)
							return (
								!fetchingBranch &&
								(sharedStateObject
									? handleSharedClick(true)
									: handlePersoClick(true))
							)
						}}
					>
						{displayChildren ? (
							<SelectArrow />
						) : (
							<SelectArrow className="r270" />
						)}
					</button>
				) : (
					<div className="emptySpace" />
				)}

				<div
					className={`selectableContainer ${
						!props?.sharedStateObject ? "" : "is-shared-folder"
					}`}
					onClick={selectable ? handleSelect : () => {}}
				>
					{isRootCat ? (
						<Place />
					) : selectable ? (
						props?.persoStateObject ? (
							<Folder />
						) : (
							<SharedRep />
						)
					) : (
						<FolderPosition className="folderPositionSvg" />
					)}
					<TooltipHelper leftPos content={data.name}>
						<h2
							className={isRootCat ? "rootCat" : ""}
							onClick={selectable ? handleSelect : () => {}}
						>
							{data.name}
						</h2>
					</TooltipHelper>
				</div>

				{!(isRootCat && sharedStateObject) &&
					(data.children || !childrenFetched) &&
					titleHovered &&
					!fetchingBranch && (
						<button id="showTree" onClick={handleBranchEmission}>
							<div className="gradient" />
							<Recursive />
							{displayChildren ? (
								<p>{t("unseeBranch")}</p>
							) : (
								<p>{t("showBranch")}</p>
							)}
						</button>
					)}
			</div>
			<div>
				{displayChildren &&
					(isLoading ? (
						<div className="loaderContainer">
							<LoaderBars mini />
						</div>
					) : (
						data.children && (
							<ul className="categoriesList">
								{data.children.map((item) => {
									return (
										<Category
											key={`p${data.id}-c${item.id}`}
											data={item}
											isMainCat={
												isRootCat && sharedStateObject
													? true
													: false
											}
											displayBranch={
												displayBranchEmitter ||
												displayBranch
											}
											setDisplayBranch={
												displayBranchEmitter
													? setDisplayBranchEmitter
													: setDisplayBranch
											}
											persoStateObject={persoStateObject}
											sharedStateObject={
												sharedStateObject
											}
											displayStateObject={
												displayStateObject
											}
											selectedStateObject={
												selectedStateObject
											}
											targetPathStateObject={{
												targetPath: partialTarget,
												setTargetPath:
													targetPathStateObject.setTargetPath,
											}}
											fetchingBranchStateObject={
												fetchingBranchStateObject
											}
										/>
									)
								})}
							</ul>
						)
					))}
			</div>
		</li>
	)
}

export default Category

// ================= PROPS DECLARATION =================
Category.propTypes = {
	data: PropTypes.object.isRequired,
	isRootCat: PropTypes.bool,
	isMainCat: PropTypes.bool,
	displayBranch: PropTypes.bool.isRequired,
	setDisplayBranch: PropTypes.func.isRequired,
	selectedStateObject: PropTypes.object.isRequired,
	targetPathStateObject: PropTypes.array,
	fetchingBranchStateObject: PropTypes.object.isRequired,

	persoStateObject: function (props, propName) {
		if (!props[propName] && !props["sharedStateObject"]) {
			return new Error(
				"Category component : You must pass a persoStateObject or a sharedStateObject prop for this component to work correctly.",
			)
		} else if (props[propName] && props["sharedStateObject"]) {
			return new Error(
				"Category component : This category can't be personnal and shared at the same time. Please only pass a persoStateObject OR a sharedStateObject prop.",
			)
		}

		if (typeof props[propName] === "object") {
			return new Error(
				"Category component : persoStateObject should be an object",
			)
		}
	},

	sharedStateObject: function (props, propName) {
		if (!props["persoStateObject"] && !props[propName]) {
			return new Error(
				"Category component : You must pass a persoStateObject or a sharedStateObject prop for this component to work correctly.",
			)
		} else if (props["persoStateObject"] && props[propName]) {
			return new Error(
				"Category component : This category can't be personnal and shared at the same time. Please only pass a persoStateObject OR a sharedStateObject prop.",
			)
		}

		if (typeof props[propName] === "object") {
			return new Error(
				"Category component : sharedStateObject should be an object",
			)
		}
	},

	displayStateObject: function (props, propName) {
		if (!props[propName]) {
			return new Error(
				"Category component : displayStateObject is required for this component to work correctly",
			)
		} else {
			let errorContent = "Category component : "

			if (!props[propName].displayAll || !props[propName].setDisplayAll) {
				errorContent +=
					"Missing data in displayAll prop. It should contain the following keys :"

				if (!props[propName].displayAll) errorContent += " displayAll,"
				if (!props[propName].setDisplayAll)
					errorContent += " setDisplayAll,"

				errorContent = errorContent.substring(
					0,
					errorContent.length - 1,
				)
				return new Error(errorContent)
			}
		}
	},
}

/*
Props

data -> The data (id, mainId, name, children and parentId) of the category
isRootCat -> Is true if the category is at the root of shared space (these categories must trigger api call when clicked on)
isMainCat -> Is true if the category is at the top of a branch, on the shared space.
isPersoCat -> Optionnal, an object containing the shared states of the list.
displayBranch -> Is true if the whole branch must be displayed.
sharedStateObject -> Optionnal, an object containing the shared states of the list.
displayStateObject -> An object containing the displayAll state and setDisplayAll function.
selectedStateObject -> An object containing the selected category and the function to set it.
targetPathStateObject -> An array containing every categories needed to reach a target category as well as the setState function to modify this.
*/
