import React, { useState, useEffect, useRef } from "react"

import { useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

import TooltipHelper from "../../stories/tooltip/TooltipHelper"

import { ReactComponent as ChevronLeft } from "../../assets/images/chevronLeft.svg"
import { ReactComponent as Folder } from "../../assets/images/folder.svg"
import { ReactComponent as SharedRep } from "../../assets/images/SharedRep.svg"
import { ReactComponent as FolderPosition } from "../../assets/images/folderPosition.svg"

export default function Slider(p) {
	const {
		debouncedSliderWidth,
		data,
		title,
		currentSelection,
		clickHandler,
		sliderRef,
		sliderRightAction,
		sliderRightActionLabel,
		elementPerSlide,
		isCurrentCat,
	} = p

	const [t] = useTranslation()

	const selectedPasswords = useSelector((state) => state.pass.active)

	const [initialRender, setInitialRender] = useState(true)
	const [fluid, setFluid] = useState([])
	const [displayedIndex, setDisplayedIndex] = useState(0)
	const [changingSlider, setChangingSlider] = useState(false)
	const [lastDirection, setLastDirection] = useState()
	const [sliderLoaded, setSliderLoaded] = useState(false)
	const [inlineStyle, setInlineStyle] = useState({})
	//const [justResized, setJustResized] = useState(false)

	const scrollContainer = useRef()
	const slidesQuantity = data.length

	//const sliderWidth = scrollContainer.current?.offsetWidth

	// ======== USEEFFECTS ========
	// Put the last element of the slider content at the start. This is the initial
	// state. If the slider only contains 2 slides, we must dupplicate each element
	// to ensure the slider will have the target element to slide on, no matter
	// the direction the user wants him to go.
	useEffect(() => {
		if (data.length) {
			const newState = [...data]

			if (data.length > 2) {
				newState.unshift(newState.pop())
			} else if (data.length !== 1) {
				newState.push(newState[0])
				newState.unshift(newState[newState.length - 2])
			}

			// Reset the displayed index, change the fluid state and trigger
			// a new display initialization
			setDisplayedIndex(0)
			setFluid(newState)
			setSliderLoaded(false)
		}
	}, [data])

	// After the first render, when the scrollContainer ref has access to the DOM informations
	// AND the div is actually overflowing (you can't set a scroll value if you can't scroll),
	// display the second element of the list (the way this slider is build, the element the
	// user wants to see must be in second place in the list).
	useEffect(() => {
		// We need to be sure that the slider has loaded it's content, but we must also execute This
		// code only once, hence the use of sliderLoaded state.
		if (!sliderLoaded && fluid) {
			if (scrollContainer.current) {
				const width = scrollContainer.current.offsetWidth
				scrollContainer.current.scrollLeft =
					width + width * displayedIndex
				setSliderLoaded(true)
			}
		}
	}, [scrollContainer, fluid])

	// When the user has clicked on an arrow, after having changed the fluid state, trigger the scroll.
	useEffect(() => {
		if (changingSlider) {
			if (lastDirection === "left") {
				const width = scrollContainer.current.offsetWidth
				scrollContainer.current.scrollBy({
					left: -width,
					behavior: "smooth",
				})
				setChangingSlider(false)
			} else if (lastDirection === "right") {
				const width = scrollContainer.current.offsetWidth
				scrollContainer.current.scrollBy({
					left: width,
					behavior: "smooth",
				})
				setChangingSlider(false)
			}
		}
	}, [changingSlider])

	useEffect(() => {
		recalibrateScrollPos()
	}, [debouncedSliderWidth])

	useEffect(() => {
		if (slidesQuantity > 1) setTimeout(() => recalibrateScrollPos(), 500)
	}, [slidesQuantity])

	// If the slider only display 2 elements, set their width to almost 50%
	useEffect(() => {
		if (elementPerSlide === 2) {
			setInlineStyle({ width: "48%" })
		}
	}, [elementPerSlide])

	// Track the initial render
	useEffect(() => {
		if (initialRender && !scrollContainer.current) {
			setInitialRender(false)
		}
	})

	function recalibrateScrollPos() {
		if (!scrollContainer.current) return
		const width = scrollContainer.current.offsetWidth
		return (scrollContainer.current.scrollLeft = width)
	}

	// ======== FUNCTIONS AND HANDLERS ========
	function slideLeft() {
		// Change the changingSliderState to trigger the adequate useEffect
		// on next render.
		setChangingSlider(true)
		const width = scrollContainer.current.offsetWidth

		// Change the fluid state.
		const newState = [...fluid]
		newState.unshift(newState.pop())
		setFluid(newState)

		// Since the fluid state is updated, the element currently displayed
		// has gone to the third position in the list. To keep displaying him,
		// we must change the scroll value.
		setLastDirection("left")
		scrollContainer.current.scrollLeft = width * 2

		// Set the current index to its new value
		if (displayedIndex === 0) {
			return setDisplayedIndex(data.length - 1)
		} else {
			return setDisplayedIndex(displayedIndex - 1)
		}
	}

	function slideRight() {
		// Change the changingSliderState to trigger the adequate useEffect
		// on next render.
		setChangingSlider(true)

		const newState = [...fluid]
		newState.push(newState.shift())
		setFluid(newState)

		// Since the fluid state is updated, the element currently displayed
		// has gone to the first position in the list. To keep displaying him,
		// we must change the scroll value.
		setLastDirection("right")
		scrollContainer.current.scrollLeft = 0

		// Set the current index to its new value
		if (displayedIndex === data.length - 1) {
			return setDisplayedIndex(0)
		} else {
			return setDisplayedIndex(displayedIndex + 1)
		}
	}

	if (!fluid.length) return null
	// ======== RENDERS ========
	const renderSlideContent = () => {
		const newDomElement = fluid.map((currentSlide, i) => {
			let onlyElement = false
			if (currentSlide.length === 1) {
				onlyElement = true
			}
			return (
				<div
					id={`slide${i}`}
					key={i}
					className={`sliderItem-wrapper ${
						!sliderLoaded ? "hidden" : ""
					} ${onlyElement ? "leftPosition" : "centerPosition"}`}
				>
					{currentSlide.map((item, j) => {
						let isSelectable = true
						Object.keys(selectedPasswords).forEach((item2) => {
							// use of props "isCurrentCat" to prevent current categories from being displayed in the board slider
							if (
								selectedPasswords[item2].categoryId ===
									item.id &&
								isCurrentCat
							) {
								isSelectable = false
							}
						})

						const icon = !isSelectable ? (
							<FolderPosition className="folderPositionSvg" />
						) : item.mainId === 0 ? (
							<Folder />
						) : (
							<SharedRep />
						)

						return (
							<div
								key={`${i}-${j}`}
								className={`sliderItem ${
									(item?.id === currentSelection?.id &&
										"selected") ||
									(!isSelectable && "notSelected")
								}`}
								onClick={() => {
									isSelectable &&
										clickHandler(item, currentSelection)
								}}
								style={inlineStyle}
							>
								{icon}
								<TooltipHelper
									leftPos
									content={
										item.name === "mLayout.shared" ||
										item.name === "mLayout.personnalSpace"
											? t(item.name)
											: item.name
									}
								>
									<div className="sliderItem-name">
										{item.name === "mLayout.shared" ||
										item.name === "mLayout.personnalSpace"
											? t(item.name)
											: item.name}
									</div>
								</TooltipHelper>

								{!isSelectable && (
									<p>{t("pass.movePass.allReadyInCat")}</p>
								)}
							</div>
						)
					})}
				</div>
			)
		})

		return newDomElement
	}

	return (
		<div
			id={p.id ? "slider-" + p.id : undefined}
			className={`slider ${slidesQuantity === 1 ? "noArrows" : ""}`}
			ref={sliderRef}
		>
			<div className="title">
				<div
					className={`leftTitle ${
						isCurrentCat ? "leftTitlePop" : ""
					} `}
				>
					<span className="leftTitle-title">{title}</span>
					<div className="leftTitle-container">
						{data.length > 1 &&
							data.map((item, i) => {
								return (
									<span
										key={i}
										className={` rounded${
											displayedIndex === i
												? " active"
												: ""
										}`}
									/>
								)
							})}
					</div>
				</div>
				{sliderRightAction && sliderRightActionLabel && (
					<b className="title-action" onClick={sliderRightAction}>
						{sliderRightActionLabel}
					</b>
				)}
			</div>

			<div
				className={`sliderBtn btnLeft${
					slidesQuantity === 1 ? " hidden" : ""
				}`}
				onClick={slideLeft}
			>
				<ChevronLeft />
			</div>

			<div
				className="container x mandatory-scroll-snapping"
				dir="ltr"
				id={`scrollContainer-${p.id}`}
				ref={scrollContainer}
			>
				{renderSlideContent()}
			</div>

			<div
				className={`sliderBtn btnRight${
					slidesQuantity === 1 ? " hidden" : ""
				}`}
				onClick={slideRight}
			>
				<ChevronLeft className="r180" />
			</div>
		</div>
	)
}
