import { toastr } from "react-redux-toastr"

export function generatePass(useLength, useMaj, useMin, useSpec, useNum) {
	let credential = credentialGenerator(
		useLength,
		useMaj,
		useMin,
		useSpec,
		useNum,
	)
	let mins = [
		"a",
		"b",
		"c",
		"d",
		"e",
		"f",
		"g",
		"h",
		"i",
		"j",
		"k",
		"l",
		"m",
		"n",
		"o",
		"p",
		"q",
		"r",
		"s",
		"t",
		"u",
		"v",
		"w",
		"x",
		"y",
		"z",
	]
	let majs = [
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z",
	]
	let nums = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
	let specs = [
		"~",
		"!",
		"@",
		"#",
		"$",
		"%",
		"^",
		"&",
		"*",
		"(",
		")",
		"-",
		"_",
		"=",
		"+",
		"[",
		"]",
		"{",
		"}",
		";",
		":",
		",",
		".",
		"<",
		">",
		"/",
		"?",
		"|",
	]

	// Check if correctly created
	let rewind = false
	if (useMin) {
		if (!mins.some((v) => credential.includes(v))) rewind = true
	}
	if (useMaj) {
		if (!majs.some((v) => credential.includes(v))) rewind = true
	}
	if (useNum) {
		if (!nums.some((v) => credential.includes(v))) rewind = true
	}
	if (useSpec) {
		if (!specs.some((v) => credential.includes(v))) rewind = true
	}

	if (rewind && !isNaN(useLength)) {
		return generatePass(useLength, useMaj, useMin, useSpec, useNum)
	} else {
		return useLength <= 4 ? credential.substring(0, useLength) : credential
	}
}

function credentialGenerator(useLength, useMaj, useMin, useSpec, useNum) {
	let a = ""
	let pass = ""
	let passLength
	if (useMaj) a += "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
	if (useMin) a += "abcdefghijklmnopqrstuvwxyz"
	if (useNum) a += "123456789012345678901234567890"
	if (useSpec) a += "~!@#$%^&*()-_=+[]{};:,.<>/?|"

	useLength <= 4 ? (passLength = 4) : (passLength = useLength)

	for (let x = 0; x < passLength; x++) {
		let i = Math.floor(Math.random() * a.length)
		pass += a.charAt(i)
	}

	return pass
}

export function handleChangeTel(value, validLength) {
	if (value.length < 5) return

	if (validLength !== value.length) {
		return toastr.warning(
			t("forServices.error"),
			t("transfer.aDeposit.phoneError"),
		)
	}

	if (value[0] != "+") return "+" + value
	else return value
}
