import React, { Suspense, useEffect } from "react"
import ReactDOM from "react-dom"
import {
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
} from "react-router-dom"

import "moment/locale/fr"
import "./i18n"
import * as Sentry from "@sentry/react"

import { Provider } from "react-redux"
//import { compose, applyMiddleware } from "redux";
import store from "./store"
import { sagaMiddleware } from "./store"
import appSagas from "./sagas"

import Autocomplete from "./popup/AutoComplete.jsx"

import * as serviceWorker from "./serviceWorker"

import "./scss/mainDefault.scss"
import "./scss/chunk.scss"
import "./scss/chunkNavigation.scss"
import "./scss/chunkWM.scss"

const storedApiUrl = window.localStorage.getItem("apiUrl")
let sentryApiUrl = storedApiUrl

if (!storedApiUrl) {
	sentryApiUrl = window.location.origin + "/api/"
}

if (process.env.REACT_APP_SENTRY_USAGE === "true") {
	Sentry.init({
		dsn: `https://${process.env.REACT_APP_SENTRY_PUBLIC}@sentry.tools.lockself.com/7`,
		tunnel: `${sentryApiUrl}sentry-tunnel`,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 0.1, //  Capture 10% of the transactions
		// Session Replay
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})
}

// Lazy loading theses for perfs/error preventing between two sides of extension
// (Not load App part when we want juste autocomplete)
const App = React.lazy(() => import("./App"))
//const Autocomplete = React.lazy(() => import("./popup/AutoComplete.jsx"))

if (localStorage.getItem("customApi") || localStorage.getItem("userAuth")) {
	localStorage.clear()
}

let detectInternalRoot = document.getElementById(
	"_lsApplicationInternalRootFrame_",
)

function renderApp() {
	ReactDOM.render(
		<React.StrictMode>
			<Suspense fallback={<div />}>
				<Provider store={store}>
					<App />
				</Provider>
			</Suspense>
		</React.StrictMode>,
		document.getElementById("_lsApplicationInternalRootFrame_"),
	)

	serviceWorker.unregister()
}

function renderCompanion() {
	//console.log("%c--- BEFORE ReactDOM render ---", "background-color: yellow; color: black;")

	ReactDOM.render(
		<React.StrictMode>
			<Provider store={store}>
				<Autocomplete />
			</Provider>
		</React.StrictMode>,
		document.getElementById("lsExtensionFrame"),
	)
}

function initCompanion() {
	const injectWrapper = document.body
	const app = document.createElement("div")
	app.id = "lsExtensionFrame"
	app.style.cssText = `
		position: fixed;
        margin: 0;
        padding: 0;
		width: 0;
		height: 0;
        max-height: 0;
        max-width: 0;
        min-height: unset;
        min-width: unset;
		top: 0;
		left: 0;
        bottom: auto;
        right: auto;
		z-index: 1000000000;
        background: none;
        background-image: none;
	`

	if (injectWrapper) {
		const observer = new MutationObserver(mutationObserverCallback)
		const observerOptions = {
			childList: true,
		}
		observer.observe(injectWrapper, observerOptions)

		if (injectWrapper.tagName !== "BODY") {
			// create and inject body HTML tag in page (if it doesn't exist)
			// and insert lsExtensionFrame div in newly created body tag
			const bodyTag = document.createElement("body")

			bodyTag.appendChild(app)
			injectWrapper.insertAdjacentElement("afterend", bodyTag)
		} else {
			injectWrapper.appendChild(app)
		}

		renderCompanion()
	}
}

function mutationObserverCallback(mutationList) {
	mutationList.forEach((mutation) => {
		switch (mutation.type) {
			case "childList":
				const elementRemoved = Array.from(
					mutation.removedNodes.values(),
				).find((value) => value?.id === "lsExtensionFrame")

				setTimeout(() => {
					if (
						elementRemoved?.id === "lsExtensionFrame" &&
						!document.querySelector("#lsExtensionFrame")
					) {
						initCompanion()
					}
				}, 500)

				break
			default:
				break
		}
	})
}

/* eslint-disable no-undef */
try {
	if (detectInternalRoot) {
		// Detecting _lsApplicationInternalRootFrame_ id means we are loaded by our index.html -> Classic mode
		//console.log("before chrome?.extension")

		try {
			if (chrome?.extension) {
				let extUrl = chrome.runtime.getURL("") //chrome?.runtime?.id

				if (window.location.href.includes(extUrl)) {
					renderApp()
				}
			} else {
				renderApp()
			}
		} catch (e) {
			renderApp()
		}
	} else {
		//console.log("COMPANION MODE")
		// Elsewhere we are loaded at other place -> Companion mode -> Injection of autocomplete component */

		////////////////////////////////////////////////////////////
		// ---- BE AWARE ----
		// Newer use gsap for popup components
		// It breaks
		////////////////////////////////////////////////////////////

		initCompanion()
	}
} catch (e) {
	console.warn("[LS] Internal error.")
}

sagaMiddleware.run(appSagas)
